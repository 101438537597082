.OrderTagsColumn {
    .ListTags {
        .TagItem {
            margin-left: 0.2rem;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}