.DiscountNewContainer {
  width: 1198px;
  margin: 0 auto;
}

.DiscountNewPage {
  .ReturnPage {
    .BackButton {
      i {

      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
      }
    }

    margin-bottom: 8px;
  }

  .DiscountHeader {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .Heading {
      h1 {
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        color: #303238;
      }
    }
  }

  .DiscountContent {
    .FormItem {
      padding: 24px 40px;
      background: #FFFFFF;
      border: 1px solid #EFEFEF;
      box-sizing: border-box;
      border-radius: 4px;
      margin-bottom: 24px;

      .LabelDiscount {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #303238;
        display: block;
        margin-bottom: 16px;
      }

      input {
        padding: 7px 12px;
        background: #FFFFFF;
        border: 1px solid #DEE2E6;
        box-sizing: border-box;
        border-radius: 4px;

        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #000000;

        ::-webkit-input-placeholder { /* Edge */
          color: #B0B7BF;
        }

        :-ms-input-placeholder { /* Internet Explorer 10-11 */
          color: #B0B7BF;
        }

        ::placeholder {
          color: #B0B7BF;
        }

        &.NameDiscount {
          width: 100%;
        }

        &.TypeInput {
          width: calc(100% - 130px);

          &.Custom {
            width: calc(100%);
          }
        }

        &:disabled {
          background: #F8F9FA;
        }

        &.Error {
          border: 1px solid #f44336;
        }
      }

      .TypeContent {
        display: flex;
        align-content: center;
        justify-content: space-between;
        margin-bottom: 12px;

        label {
          margin-bottom: 0 !important;
        }
      }

      .TypeCharacter {
        display: inline-block;
        width: 40px;
        height: 40px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 40px;
        text-align: center;
        color: #303238;
        margin-left: -40px;
        background: #EAEEF1;
        border: 1px solid #D8DBDE;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        box-sizing: border-box;

        &.Custom {
          margin-left: -43px;
          width: 43px;
          border-color: #007BFF;
          color: #FFFFFF;
          background: #007BFF;

          &:hover {
            cursor: pointer;
          }
        }

        &.Disable {
          opacity: 0.4;
        }

        &.Error {
          border: 1px solid #f44336;
        }
      }

      .SmallText {
        display: block;
        margin-top: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #65676B;
      }

      .ant-radio-group {
        display: block;

        .ant-radio-wrapper {
          display: block;
          margin-bottom: 10px;
          margin-right: 7px;
        }
      }

      .ListStoreSelected {
        margin-top: 10px;

        li {
          width: 100%;
          padding: 12px;
          list-style: none;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          background: #F8F9FA;

          span {
            float: right;
            margin-top: 4px;

            &:hover {
              cursor: pointer;
              color: #0f9d58;
            }
          }

          &:last-child {
            border-bottom: none;
          }

          &:nth-child(2n+2) {
            background: #FDFDFD;
            border-top: 1px solid #DEE2E6;
            border-bottom: 1px solid #DEE2E6;
          }
        }
      }

      .LineList {
        display: flex;
        align-content: center;
        justify-content: space-between;

        .AddProductline {
          display: flex;
          align-content: center;

          .SupplierDiscount {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 33px;
            color: #303238;
            margin-right: 10px;
          }

          button {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            color: #FFFFFF;
            height: 36px;
            padding: 0 40px;
            border-radius: 4px;

            &.Disable {
              background: #007BFF;
              opacity: 0.4;
            }
          }
        }
      }

      .SelectDate {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        label {
          display: block;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #303238;
          margin-bottom: 8px;
        }

        input {
          border: none;
        }

        .Date {
          margin-right: 16px;
        }

        .ant-picker {
          padding: 0 11px;
          width: 200px;
        }

        .Error {
          border: 1px solid #f44336;
        }
      }

      .ant-checkbox-wrapper {
        margin-bottom: 20px;
      }

      .ListProductLineSelect {
        margin-top: 12px;

        li {
          padding: 12px;
          background: #F8F9FA;

          .Title {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }

          &:nth-child(2n+2) {
            background: #FDFDFD;
            border-top: 1px solid #DEE2E6;
            border-bottom: 1px solid #DEE2E6;
          }

          .VariantsList {
            // display: flex;
            // align-items: center;

            .ant-tag.ItemVariant {
              padding: 2px 10px;
              border: 1px solid #DEE2E6;
              box-sizing: border-box;
              border-radius: 2px;
              margin-right: 10px;
              margin-top: 12px;
              font-size: 16px;
              // display: flex;
              align-items: center;

              .Remove {
                margin-left: 10px;
                font-size: 12px;
              }
            }
          }
        }
      }

      .ant-checkbox-group {
        margin-bottom: 10px;

        .ant-checkbox-wrapper {
          margin-bottom: 0;
        }
      }
    }
  }

  .DiscountFooter {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: right;

    .text-danger {
      margin-right: 30px;
    }
  }

  .Actions {
    button {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      height: 36px;
      padding: 0 20px;
      border-radius: 4px;
    }
  }
}

.AddProductLine {
  width: 60% !important;
  border-radius: 4px;

  .ant-modal-header {
    padding: 24px;

    .ant-modal-title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #303238;
    }
  }

  .FiltersProductLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .FilterSupplier {
      width: 216px;

      .ant-select {
        width: 100%;

        .ant-select-selector {
          height: 38px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: #303238;
          border-radius: 4px;
          padding: 0 12px;

          .ant-select-selection-item {
            line-height: 38px;
          }
        }

        input {
          height: 38px;
        }
      }
    }

    .FilterInput {
      input {
        height: 38px;
        width: 216px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        border-radius: 4px;
        color: #2F3230;
        padding: 0 12px;
      }
    }
  }

  .ListProductLine {
    height: 350px;
    overflow-y: scroll;

    table {
      width: 100%;

      .SelectProductLine {
        width: 56px;
      }

      .Title {
        width: 400px;
      }

      .ProductLine, .ProductSku {
        width: 155px;
      }

      th.Title {
        padding-left: 78px;
      }

      .Actions {
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: #007BFF;

          i {
            margin-left: 10px;
          }

          &:hover {
            cursor: pointer;
          }
        }
      }

      thead {
        tr {
          th {
            background: #F8F9FA;
            padding: 16px;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #303238;
          }
        }
      }

      tbody {
        tr {
          td {
            padding: 16px;
            border-bottom: 1px solid #DEE2E6;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #303238;

            img {
              width: 40px;
              height: auto;
              margin-right: 24px;
            }

            .Variants {
              margin-left: 56px;
              background: #F8F9FA;
              padding: 2px 33px;
              min-height: 100px;

              .ant-spin-nested-loading {
                min-height: 100px;
              }

              .VariantItem {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding: 12px;
                border-bottom: 1px solid #DEE2E6;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: #303238;

                .Left {
                  display: flex;
                  align-items: center;

                  .SelectVariant {
                    margin-right: 20px;
                  }

                  .TitleVariant {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    color: #303238;
                  }
                }

                .SkuVariant {
                  width: 225px;
                  text-align: left;
                }

                &:last-child {
                  border-bottom: none;
                }
              }
            }
          }

          &.NotShow {
            display: none;
          }

          &.ShowVariant {
            td {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .ant-pagination {
    margin-top: 30px;
  }

  .ant-modal-footer {
    padding: 24px;

    button {
      height: 40px;
      padding: 0 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }
}

.Histories {
  padding: 24px;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  border-radius: 4px;

  .TitleHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #303238;
    margin-bottom: 15px;
  }

  .HistoriesItem {
    margin-bottom: 5px;
  }
}

.FilterDateSingle {
  .ant-picker {
    width: 100%;
  }
}

.listDiscounts {
  .GroupFilter {
    align-items: center;
  }

  .itemFilter.filterPushedAt {
    top: 0 !important;

    .FilterDateRange .Heading {
      margin-bottom: 3px !important;
    }
  }
}
