.ProductLineTitleSearch {
    > input[type=text] {
        width: 500px;
        max-width: 100%;
        padding-left: 36px;
    }

    > i {
        top: 12px;
        left: 12px;
    }

    select.form-control {
        width: 17%;
    }

    .supplierFilter{
        width: 300px;
        .ant-select-selector{
            overflow: auto;
            max-height: 117px;
            min-height: 38px;
            border-radius: 0.25rem;
            align-items: center;
        }
        .ant-select-arrow,
        .ant-select-clear {
            right: 17px;
        }
    }
}
