$created_color: #17a2b8;
$process_color: #2196F3;
$done_color: #33A746;

.SwitchCarrierContainer {
  .SwitchCarrierPage {
    .SectionInner {
      position: relative;
      padding: 24px 16px;

      .Total {
        text-align: right;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #303238;
        margin-bottom: 19px;

        span {
          text-align: right;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #303238;
          margin-bottom: 19px;
        }
      }

      .RequestFilters {
        .Filters {
          display: flex;
          align-items: center;

          .FilterDateRange {
            label {
              display: block;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 22px;
              color: #303238;
            }

            .ant-picker {
              height: 40px;
              border-radius: 4px;
            }
          }

          .FilterStatus {
            margin-left: 24px;

            label {
              display: block;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 22px;
              color: #303238;
            }

            .ant-select-selector {
              height: 40px;
              border-radius: 4px;

              .ant-select-selection-item {
                line-height: 40px;
              }
            }
          }
        }
      }

      .SwitchCarrierTable {
        table {
          width: 100%;

          .RequestThead {
            tr {
              th {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                color: #303238;
                padding: 16px;
                border-top: 1px solid #D8DBDE;
                background: #F4F6F8;
              }
            }
          }

          .RequestBody {
            tr {
              td {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: #303238;
                padding: 16px;
                border-top: 1px solid #D8DBDE;
              }

              &:last-child {
                border-bottom: 1px solid #D8DBDE;
              }

              &.RequestItem {
                .RequestId, .Type, .Info {
                  white-space: nowrap;
                }

                .RequestId {
                  color: #0A7BFE;
                  font-size: 14px;
                }

                .Source {
                  text-transform: uppercase;

                  &.StatusProduction {
                    text-transform: none;
                  }
                }

                .Info {
                  span {
                    color: #0A7BFE;
                    font-style: italic;

                    &:last-child {
                      font-style: normal;
                      color: #2F3230;
                      display: block;
                      font-weight: bold;
                    }
                  }
                }

                .Content, .Created, .Updated {
                  white-space: nowrap;
                }

                .Type {
                  span {
                    display: inline-block;
                    height: 18px;
                    line-height: 17px;
                    position: relative;
                    padding: 0 7px 0 12px;
                    background: #DC3545;
                    border-bottom-right-radius: 3px;
                    border-top-right-radius: 3px;
                    color: #fff;
                    font-size: 11px;
                    text-decoration: none;
                    font-weight: 400;
                    margin: 0 0 8px 15px;

                    &:before {
                      content: "";
                      position: absolute;
                      top: 0;
                      left: -7px;
                      width: 0;
                      height: 0;
                      border-color: transparent #DC3545 transparent transparent;
                      border-style: solid;
                      border-width: 9px 7px 9px 0;
                    }

                    &:after {
                      content: "";
                      position: absolute;
                      top: 7px;
                      left: 1px;
                      float: left;
                      width: 5px;
                      height: 5px;
                      border-radius: 50%;
                      background: #fff;
                    }
                  }
                }

                .Status {
                  white-space: nowrap;

                  > div {
                    display: inline-block;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 22px;
                    text-align: center;
                    padding: 0 10px 2px;
                    border-radius: 80px;
                    color: #FFFFFF;

                    &.waiting, &.created {
                      background-color: $created_color;
                      animation: progress-bar-stripes 1s linear infinite;
                      background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
                      background-size: 1rem 1rem;
                    }

                    &.processing {
                      background-color: $process_color;
                      animation: progress-bar-stripes 1s linear infinite;
                      background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
                      background-size: 1rem 1rem;
                    }

                    &.updated, &.done {
                      background-color: $done_color;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .ant-pagination {
        padding-top: 24px;
      }
    }
  }
}

.CreatedJobs {
  left: 117px !important;
}
