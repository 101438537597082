.SpecialCaseTable{
    .Store, .Country, .OrderTag, .Title, .ShippingPlan, .ProductCatalogSku{
        max-width: 200px;
    }
}

.SpecialCaseNewPageCreate, .SpecialCaseEditPage {
    .SectionInner .ant-select-selector {
        min-height: 40px;
        border-radius: 5px;
        align-items: center;
        max-height: 140px;
        overflow-y: auto;
        .ant-select-selection-overflow-item{
            .ant-select-selection-item{
                height: 30px !important;
                line-height: 26px !important;
                .ant-select-selection-item-remove{
                    font-size: 12px !important;
                    margin-left: 5px;
                    color: black;
                }
            }
        }
    }
}