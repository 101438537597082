.OrderPaymentStatusColumn {
    &.Pending {
        .Text {
            background-color: $default-info;
        }
    }

    &.Refunded {
        .Text {
            background-color: $default-danger;
        }
    }
}
