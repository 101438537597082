.ApiKeyResultModal {
    .ContentWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .Text {
            width: 100%;
            text-align: center;
            margin: .25rem 0;
        }

        .ApiKeyWrapper {
            margin: .5rem 0;
            padding: .375rem .75rem;
            border: 1px solid #0f9d58;
            border-radius: 4px;
            width: 100%;
        }
    }
}
