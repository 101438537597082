.TableFilter {
    width: 100%;
    padding: 0;

    .SelectWrapper {
        select {
            cursor: pointer;
            padding: 0 8px;
            border: 1px solid #ced4da;
            background: #f9fafb;
            border-radius: 5px 0 0 5px;
        }
    }

    .CategoriesSelector{
        width: 400px;
        @media (min-width: 1025px) and (max-width: 1400px) {
            width: 220px;
        }
    }

    .InputWrapper {
        width: 400px;
        @media (min-width: 1025px) and (max-width: 1400px) {
            width: 220px;
        }

        > i {
            top: 12px;
            left: 12px;
        }

        .InputSearch {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .TotalEntity {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .Entity {
            font-weight: 700;
        }
    }

    .TableLength {
        color: $default-dark;
        font-weight: 400;

        select {
            border: 1px solid $border-color;
            border-radius: 2px;
            box-shadow: none;
            height: 40px;
            font-size: 14px;
            padding: 5px;
            margin-left: 5px;
            margin-right: 5px;
            color: $default-text-color;
            transition: all 0.2s ease-in;
        }
    }
}

.tableWrapResponsive {
    @media (max-width: 768px) {
        overflow-x: scroll;
        table {
            width: auto;
            width: max-content;
        }
    }
}