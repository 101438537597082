.OrderStoreMode {
    margin-right: 15px;

    &.marketplace {
        .card {
            border-color: #DC3545;
        }

        .Mode {
            color: #DC3545;
        }
    }

    &.merchize-payment {
        .card {
            border-color: #28A745;
        }

        .Mode {
            color: #28A745;
        }
    }
}
