.PlatformEditModal {
    .CloseButton {
        color: #0f9d58;
        border: 1px solid #0f9d58;
        transition: all .2s linear;

        &:hover {
            background: #0f9d58;
            color: #fff;
        }
    }

    .SaveButton {
        color: #fff;
        background: #0f9d58;
        border: 1px solid #0f9d58;
        transition: all .2s linear;

        &:hover {
            background: darken(#0f9d58, 5%);
        }
    }
}
