.Tooltip1 {
    &[data-tooltip] {
        position: relative;
        z-index: 10;
    }

    /* Positioning and visibility settings of the tooltip */
    &[data-tooltip]:before,
    &[data-tooltip]:after {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        left: 50%;
        bottom: calc(100% + 5px);
        pointer-events: none;
        transition: 0.2s;
        will-change: transform;
    }

    /* The actual tooltip with a dynamic width */
    &[data-tooltip]:before {
        content: attr(data-tooltip);
        padding: 10px 18px;
        min-width: 50px;
        max-width: 300px;
        width: max-content;
        width: -moz-max-content;
        border-radius: 6px;
        font-size: 14px;
        background-color: rgba(59, 72, 80, 0.9);
        background-image: linear-gradient(30deg,
                rgba(59, 72, 80, 0.44),
                rgba(59, 68, 75, 0.44),
                rgba(60, 82, 88, 0.44));
        box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
        color: #fff;
        text-align: center;
        white-space: pre-wrap;
        transform: translate(-50%, -5px) scale(0.5);
    }

    /* Tooltip arrow */
    &[data-tooltip]:after {
        content: '';
        border-style: solid;
        border-width: 5px 5px 0 5px;
        border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
        transition-duration: 0s;
        /* If the mouse leaves the element,
                                     the transition effects for the
                                     tooltip arrow are "turned off" */
        transform-origin: top;
        /* Orientation setting for the
                                   slide-down effect */
        transform: translateX(-50%) scaleY(0);
    }

    /* Tooltip becomes visible at hover */
    &[data-tooltip]:hover:before,
    &[data-tooltip]:hover:after {
        visibility: visible;
        opacity: 1;
    }

    /* Scales from 0.5 to 1 -> grow effect */
    &[data-tooltip]:hover:before {
        transition-delay: 0.3s;
        transform: translate(-50%, -5px) scale(1);
    }

    /* Slide down effect only on mouseenter (NOT on mouseleave) */
    &[data-tooltip]:hover:after {
        transition-delay: 0.5s; /* Starting after the grow effect */
        transition-duration: 0.2s;
        transform: translateX(-50%) scaleY(1);
    }

    /* LEFT */
    /* Tooltip + arrow */
    &[data-tooltip-location="left"]:before,
    &[data-tooltip-location="left"]:after {
        left: auto;
        right: calc(100% + 5px);
        bottom: 50%;
    }

    /* Tooltip */
    &[data-tooltip-location="left"]:before {
        transform: translate(-5px, 50%) scale(0.5);
    }

    &[data-tooltip-location="left"]:hover:before {
        transform: translate(-5px, 50%) scale(1);
    }

    /* Arrow */
    &[data-tooltip-location="left"]:after {
        border-width: 5px 0 5px 5px;
        border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
        transform-origin: left;
        transform: translateY(50%) scaleX(0);
    }

    &[data-tooltip-location="left"]:hover:after {
        transform: translateY(50%) scaleX(1);
    }

    /* RIGHT */
    &[data-tooltip-location="right"]:before,
    &[data-tooltip-location="right"]:after {
        left: calc(100% + 5px);
        bottom: 50%;
    }

    &[data-tooltip-location="right"]:before {
        transform: translate(5px, 50%) scale(0.5);
    }

    &[data-tooltip-location="right"]:hover:before {
        transform: translate(5px, 50%) scale(1);
    }

    &[data-tooltip-location="right"]:after {
        border-width: 5px 5px 5px 0;
        border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
        transform-origin: right;
        transform: translateY(50%) scaleX(0);
    }

    &[data-tooltip-location="right"]:hover:after {
        transform: translateY(50%) scaleX(1);
    }

    /* BOTTOM */
    &[data-tooltip-location="bottom"]:before,
    &[data-tooltip-location="bottom"]:after {
        top: calc(100% + 5px);
        bottom: auto;
    }

    &[data-tooltip-location="bottom"]:before {
        transform: translate(-50%, 5px) scale(0.5);
    }

    &[data-tooltip-location="bottom"]:hover:before {
        transform: translate(-50%, 5px) scale(1);
    }

    &[data-tooltip-location="bottom"]:after {
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
        transform-origin: bottom;
    }
}

.Tooltip2 {
    position: relative;

    &Item {
        position: absolute;
        min-width: 250px;
        padding: 20px;
        visibility: hidden;
        opacity: 0;
        z-index: 4;
        zoom: 1.02;
        transition: all .3s;

        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
        }

        .ItemInner {
            border: 1px solid #cecece;
            border-radius: 3px;
            padding: 20px;
            background: white;
            transition: all .250s cubic-bezier(0, 0, 0.2, 1);
            box-shadow: 0 2px 1px #bcbcbc;
        }
    }

    &Initiator {
        cursor: pointer;
        z-index: 5;
    }

    &[data-direction="left"] {

        .Tooltip2Initiator:hover ~ .Tooltip2Item {
            transform: translate3d(0, -50%, 0);
            visibility: visible;
            opacity: 1;
        }

        .Tooltip2Item {
            top: 50%;
            right: calc(100% + 1em);
            transform: translate3d(15px, -50%, 0);

            &:after {
                top: 50%;
                right: -0.5em;
                transform: translate3d(0, -50%, 0);
                border-width: 0.5em 0 0.5em 0.5em;
                border-color: transparent transparent transparent white;
                -webkit-filter: drop-shadow(1px 2px 1px #bcbcbc);
                filter: drop-shadow(1px 2px 1px #bcbcbc);
            }
        }
    }

    &[data-direction="bottom"] {
        .Tooltip2Initiator:hover ~ .Tooltip2Item {
            transform: translate3d(-50%, 0, 0);
            visibility: visible;
            opacity: 1;
        }

        .Tooltip2Item {
            top: 100%;
            left: 50%;
            transform: translate3d(-50%, -15px, 0);

            &:after {
                top: 14px;
                left: 50%;
                transform: translate3d(-50%, 0, 0);
                border-width: 0 0.5em 0.5em 0.5em;
                border-color: transparent transparent white transparent;
                -webkit-filter: drop-shadow(1px 2px 1px #bcbcbc);
                filter: drop-shadow(1px -1px 1px #bcbcbc);
            }

            &:hover {
                transform: translate3d(-50%, 0, 0);
                visibility: visible;
                opacity: 1;
            }
        }
    }
}


.Tooltip3 {
    position: relative;

    &:hover {
        .TooltipContent {
            opacity: 1;
            z-index: 5;
        }
    }

    .TooltipContent {
        max-width: 300px;
        min-width: 200px;
        position: absolute;
        z-index: -1;
        opacity: 0;
        padding-top: 10px;
        transition: all .3s ease;

        &.Clipboard {
            min-width: 140px;

            .ContentInner {
                padding: 1px 0 4px;
            }
        }

        &::after {
            background: $secondary;
            content: "";
            height: 10px;
            position: absolute;
            left: 50%;
            margin-left: -5px;
            transform: rotate(45deg);
            width: 10px;
        }
    }

    .ContentInner {
        padding: 1em;
        background: $secondary;
        box-shadow: 0 5px 25px 5px rgba(205,210,214,.8);
        box-sizing: border-box;
        color: #ffffff;
        font-size: .875rem;
        letter-spacing: 1px;
        border-radius: 4px;
        font-weight: 400;
        line-height: 14px;
    }

    &.Top {
        .TooltipContent {
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            padding: 0 0 10px 0;

            &::after {
                bottom: 5px;
                left: 50%;
                margin-left: -5px;
            }
        }
    }

    &.Bottom {
        .TooltipContent {
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            &::after {
                top: 5px;
            }
        }
    }

    &.Right {
        .TooltipContent {
            left: 100%;
            top: 50%;
            transform: translateY(-50%);
            padding: 0 0 0 10px;
            &::after {
                left: 10px;
                margin-top: -5px;
                top: 50%;
            }
        }
    }
    &.Left {
        .TooltipContent {
            right: calc(100% + 1.5rem);
            top: 50%;
            transform: translateY(-50%);
            &::after {
                right: -5px;
                margin-top: -5px;
                top: 50%;
            }
        }
    }

}
