.Variants{
    padding-top: 16px;
    .VariantHeader{
        margin-top: 16px;
    }
    table{
        tbody tr{
            td:nth-child(4){
                white-space: nowrap;
            }
        }
    }
}