.OrdersPage {
  @media (min-width: 1025px) and (max-width: 1400px) {
    .FiltersTop {
      flex-direction: column-reverse;
      align-items: flex-start !important;

      .OrdersResetFilters {
        margin: 15px 0;
      }

      .Supplier {
        width: 220px;
      }
    }
  }

  .TableFilter {
    .InputSearch {
      padding: 0 12px 0 40px;
    }

    .InputWrapper > i {
      display: inline-block !important;
    }
  }

  .OrderFilters > [class^="col"] {
    padding: 0 10px;
  }

  .Platform {
    width: 200px;
  }

  .QuickFilter {
    text-align: center;
    line-height: 30px;
    position: fixed;
    right: 0;
    top: 125px;
    z-index: 9;
    width: 30px;
    height: 30px;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.16);

    &:hover {
      cursor: pointer;

      i {
        color: #00c853;
      }
    }
  }

  .OrderFilterPersonalized {
    opacity: 0;
    position: fixed;
    right: -300px;
    top: 0;
    bottom: 0;
    width: 300px;
    background: #FFFFFF;
    box-sizing: border-box;
    box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 8px 0 0 8px;
    z-index: 99;
    transition: all 0.3s;
    overflow-y: auto;

    &.Open {
      opacity: 1;
      right: 0;
    }

    &.Close {
      transition: all 0.3s;
    }

    .HeadingFilter {
      padding: 23px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #E5E5E5;

      h3.Heading {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #303238;
        margin: 0;
        padding: 0;
      }

      .Close {
        &:hover {
          cursor: pointer;
          color: #dc3545;
        }
      }
    }

    .ContentFilter {
      ul {
        margin: 0;
        padding: 0;

        li {
          padding: 12px 16px;
          border-bottom: 1px solid #E5E5E5;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 18px;
          color: #000000;

          &:hover, &.active {
            cursor: pointer;
            color: #0f9d58;
          }
        }
      }
    }
  }

  .BackgroundQuickFilter {
    &.Open {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 8;
    }

    &.Close {
      display: none;
    }
  }
}

.TrackingModal {
  #tracking_name {
    &.error {
      border: 1px solid #f44336;
    }
  }
}

@media screen and (max-width:588px)  {
  .FiltersTop{
    .d-flex:first-child{
      .mr-3{
        margin-right: 0 !important;
        width: 100% !important;
      }
      .Supplier{
        width:100%;
      }
      .Platform{
        width: 100%;
      }
      .ant-input-group-wrapper.ant-input-search{
        width: 100% !important;
      }
    }
    .d-flex:last-child{
      justify-content:space-between;
    }
  }
  .OrderFilters .FilterOptions{
    label.ant-radio-button-wrapper{
      margin-bottom: 5px;
    }
  }
  .mb-3.row.align-items-end{
    .col-auto.mb-3:nth-child(2){
      width: 100% !important;
    }
    .col-auto.mb-3:nth-child(3){
      width: 100% ;
      .ant-select.ant-select-single.ant-select-show-arrow{
        width: 100% !important;
      }
    }
    .col-auto.mb-3:nth-child(4){
      width: 100%;
      .ShipmentStatus{
        padding: 0 !important;
        .FilterOptions.ml-3{
          margin-left: 0 !important;
        }
      }
    }
    .col-auto.mb-3:nth-child(5){
      width: 100%;
      .ShipmentStatus{
        padding: 0 !important;
      }
    }
    .col-auto.mb-3:nth-child(7), .col-auto.mb-3:nth-child(6){
      label.ant-radio-button-wrapper{
        margin-bottom: 5px;
      }
    }
    .col-auto.mb-3:last-child{
      width: 100% !important;
      margin-bottom: 0.5rem !important;
      .FilterOptions.ml-sm-3{
        margin-left: 0 !important;
      }
    }
  }
  .row.mb-3.align-items-center{
    .FilterDateRange {
      width: 100%;
      .d-sm-inline-flex.align-items-baseline{
        margin-right: 1rem !important;
        .ant-picker.ant-picker-range{
          width: 100% !important;
        }
      }
    }
    .ml-3.mb-3:nth-child(2){
      width: 100% !important;
    }
    .mb-3:nth-child(3){
      width: 100% !important;
      margin-left: 1rem !important;
    }
  }
  .SearchGroup {
    .ant-input-group.ant-input-group-compact {
      display: flex;
  }
    margin-right: 0.9rem !important;
    .ant-select.ant-select-single.ant-select-show-arrow{
      width: auto !important;
    }
    .ant-input-group-wrapper.ant-input-search{
      width: 100% !important;
    }
  }

  .Left182,.Left646{
    left: 145px !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px){
  .FiltersTop{
    display: grid !important;
    .d-flex.flex-wrap.align-items-baseline{
      display: grid !important;
      grid-template-columns: repeat(auto-fill, minmax(200px, 2fr));
      grid-column-gap: 6px;
    }
    .d-flex:first-child{
      .mr-3{
        margin-right: 0 !important;
        width: 100% !important;
      }
      .Supplier{
        width:100%;
      }
      .Platform{
        width: 100%;
      }
      .ant-input-group-wrapper.ant-input-search{
        width: 100% !important;
      }
    }
    .d-flex:last-child{
      justify-content:space-between;
    }
  }
  .SectionInner{
    div.mb-3.row.align-items-end{
      .col-auto.ml-auto.mb-3{
        margin-left: 0 !important;
      }
    }
    div.row.mb-3.align-items-center{
      div.ml-sm-auto.ml-3.mb-3{
        margin-left: 1rem !important;
      }
      .ml-3.mb-3:nth-child(1), .ml-3.mb-3:nth-child(2){
        .d-sm-inline-flex{
          flex-direction: column;
        }
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width:1440px) {
  .SectionInner{
    div.row.mb-3.align-items-center{
      div.mb-3:nth-child(3){
        margin-left: 1rem !important;
      }
      div.mb-3:nth-child(4){
        margin-left: auto !important;
      }
    }
  }
}

@media screen and (max-width:466px) {
  .dropdown-menu.show{
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(-40px, 40px, 0px) !important;
  }

  .DashboardContainer {
    .MainContentInner {
      .SectionInner {
        .ShowTotal{
          display: block !important;
        }
      }
    }
  }
}

.tooltipLabel{
  .ant-tooltip-content{
    min-width: 350px;
  }
}