.OrdersFilter {

}

.OrdersPage {
  .ant-picker, .ant-picker-range {
    width: 100%;
  }
}

.Left182 {
  left: 182px !important;
}

.Left646 {
  left: 646px !important;
}
