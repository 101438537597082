.SaveSuccessModal {
    position: fixed;
    z-index: 1001;
    top: 0;
    left: 0;
    width: 10vw;
    transform: translate(45vw, 0);

    .Card {
        display: none;
        width: 100%;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .display {
        display: flex;
        align-items: center;
        justify-items: center;
    }

}
