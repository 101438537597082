.PopupRefundContent {
  .ant-modal-header {
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 0;
  }

  .ant-modal-close-x {
    width: 24px;
    height: 24px;
    line-height: 24px;
    margin-top: 10px;
    margin-right: 10px;
    color: #2F3230;
  }

  .CustomHeaderPopup {
    label {
      font-weight: bold;
      font-size: 28px;
      line-height: 33px;
    }

    .infoOrder {
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;

      .badge {
        margin-left: 8px;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .ant-modal-body {
    padding-top: 28px;
    padding-bottom: 0;
  }

  .ant-modal-footer {
    padding: 0 23px 23px 23px;
    border-top: none;

    button {
      word-break: break-all;
    }
  }

  .rowItem {
    select {
      width: 192px;

      &.error {
        border-color: #FF0000;
      }
    }

    .ant-select-single .ant-select-selector .ant-select-selection-item {
      line-height: 36px;
    }

    .ant-select {
      .ant-select-selector {
        height: 38px;
      }

      &.error {
        .ant-select-selector {
          border-color: #FF0000;
        }
      }
    }

    .ant-select-selection--single {
      height: 37px;
    }

    .error {
      .ant-select-selection--single {
        border-color: #FF0000;
      }
    }

    .ant-select-selection__rendered {
      line-height: 35px;
      font-size: 16px;
      color: #000000;
    }

    textarea {
      &.error {
        border-color: #FF0000;
      }
    }

    input {
      color: #000000;
      width: 330px;

      &:disabled {

      }

      &.error {
        border-color: #FF0000;
      }
    }

    label:not(.ant-radio-wrapper) {
      width: 140px;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      flex-shrink: 0;
    }

    .valueRefund {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      display: flex;
      align-items: center;

      input {
        border-radius: 0.25rem;
        border: none;
        padding: 0 10px;
        height: 35px;
        width: 130px;
        line-height: 35px;
      }

      > span {
        width: 30px;
        cursor: pointer;
        text-align: center;
        line-height: 35px;
        color: #000000;
        font-weight: normal;
        font-size: 16px;
        border-left: 1px solid #ced4da;

        &.active, &:hover {
          background-color: #0f9d58;
          color: #FFFFFF;
        }

        &:last-child {
          border-radius: 0 4px 4px 0;
        }
      }

      &.error {
        border-color: #FF0000;
      }
    }
  }
  &.disabled {
    .PopupRefundOptions {
      pointer-events: none;
      position: relative;

      &:after {
        content: '';
        z-index: 100;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: transparent;
      }
    }
  }
}

.OrderInvoice {
  .ShippingCost {
    //margin-top: 10px;

    .font-weight-500 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    .shippingCostValue{
      height: 38px;
    }

    .form-edit-shipping-cost{
      input.form-control{
        width: 120px;
        border-radius: 5px 0 0 5px;
      }
      button.submit{
        border-radius: 0 5px 5px 0;
        width: 75px;

        &.saving{
          width: 95px;
        }
      }
    }
  }

  .ChargeInvoice {
    margin-top: 5px;
    margin-bottom: 5px;

    .Title {
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
    }

    .Value {
      .Status {
        padding: 2px 15px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        border-radius: 3px;
        text-transform: capitalize;

        &.pending {
          background-color: #17a2b8;
        }

        &.paid {
          background-color: #33A746;
        }
      }

      .Amount {
        display: inline-block;
        margin-left: 10px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
        min-width: 68px;
      }
    }
  }
}

.ant-modal{
  &.refundChargeModal{
    width: 900px !important;

    .PopupRefundOptions{
      .container{
        padding-left: 0;
        padding-right: 0;
      }

      .rowItem{
        >label{
          width: 140px;
          font-size: 16px;
          line-height: 19px;
          color: #000;
          flex-shrink: 0;
        }
      }

      table, th, td{
        padding: 5px 10px;
        border: 1px solid #d9d9d9;
        border-collapse: collapse;
      }

      .refundChargeValueTableWrap{
        max-height: 169px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 8px;
        }
         
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
         
        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
          // outline: 1px solid slategrey;
          border-radius: 4px;
        }
      }

      .refundChargeValueTable{
        width: 100%;

        .RefundChargeValueTableRow{
          input{
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            padding: 5px 10px;
            width: 100%;
          }
        }
      }
    }

    .ListProcessingRefundCharge{
      ul{
        max-height: 160px;
        overflow: auto;

        &::-webkit-scrollbar {
          width: 8px;
        }
         
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
         
        &::-webkit-scrollbar-thumb {
          background-color: darkgrey;
          // outline: 1px solid slategrey;
          border-radius: 4px;
        }
      }
    }
  }
}
