.ProductDimension {
  .group {
    position: relative;
    padding-right: 35px!important;
    padding-bottom: 0!important;
    .iconClose {
      position: absolute;
      top: 22px;
      right: 11px;
    }

    .iconSave{
      position: absolute;
      top: 50px;
      right: 11px;
    }
  }
  .itemChild {
    > .row {
      margin-left: -5px;
      margin-right: -5px;
    }
    .itemSide {
      @media (min-width: 1700px) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  .itemSide {
    padding-left: 5px;
    padding-right: 5px;
    @media (min-width: 1500px) {
      .form-control {
        padding: 0 8px;
        width: 63px;
      }
    }
  }
  button {
    width: 100%;
    background-color: #FFFFFF;
    border: 1px dashed #cccccc;
    color: rgba(0,0,0,.85);
    &:active, &:focus {
      background-color: #FFFFFF;
      color: rgba(0,0,0,.85);
      border: 1px dashed #cccccc;
      box-shadow: none;
    }
  }
}

.ChildProducts {
  .group {
    position: relative;
    padding-right: 35px!important;
    padding-bottom: 0!important;
    .iconClose {
      position: absolute;
      top: 22px;
      right: 11px;
    }

    .iconSave{
      position: absolute;
      top: 50px;
      right: 11px;
    }
  }
  .itemChild {
    > .row {
      margin-left: -5px;
      margin-right: -5px;
    }
    .itemSide {
      @media (min-width: 1700px) {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  .itemSide {
    padding-left: 5px;
    padding-right: 5px;
    @media (min-width: 1500px) {
      .form-control {
        padding: 0 8px;
        width: 63px;
      }
    }
  }
  button.AddMore {
    width: 100%;
    background-color: #FFFFFF;
    border: 1px dashed #cccccc;
    color: rgba(0,0,0,.85);
    &:active, &:focus {
      background-color: #FFFFFF;
      color: rgba(0,0,0,.85);
      border: 1px dashed #cccccc;
      box-shadow: none;
    }
  }
}