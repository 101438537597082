.ShipmentStatus {
  .Statuses {
    display: inline-block;
    max-width: 180px;
    margin: -2px 7px 0 0;

    .badge {
      color: #fff;
      background-color: #999;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }
    }

    .in_transit {
      background-color: #3498db;
    }

    .delivered, .out_for_delivery {
      background-color: $success;
    }

    .cancelled, .failure {
      background-color: $danger;
    }
  }
}
