.Namespace {
    form {
        width: 300px;
        max-width: 100%;
    }

    button {
        //right: 1px;
        //height: 36px;
        //bottom: 1px;
        //border: 0;
        //outline: none;
        //border-radius: 0 0.25rem 0.25rem 0;
        //background-color: #e9ecef;
        //margin-left: -1px;
        //padding-left: 10px;
        //padding-right: 10px;
    }

    input[type=text] {
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
        overflow: hidden;
    }

    i {
        top: 2px;
    }

    .NamespaceInput {
        width: 300px;
    }
}
