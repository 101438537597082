.Platform {
    form {
        width: 300px;
        max-width: 100%;
    }
    label{
        font-size: .875rem;
    }

}
