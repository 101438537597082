@import '~react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

.EditorApp {
    padding: .375rem .75rem;
    border: 1px solid rgba(0, 0, 0, 0.0625);
    border-top: 0;
    border-radius: 0 0 .25rem .25rem;
    min-height: 15rem;
}

.EditorToolbar {
    margin: 0;
    border-radius: .25rem .25rem 0 0;
    padding: .375rem .75rem;
    background: #FAFBFC;
}

.EditorToolbarButton {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin: 0 .375rem;
    padding: .5rem auto;
    height: 100%;

    &:hover {
        box-shadow: none;
    }

    &:active {
        border-color: #80bdff;
    }
}

.rdw-list-wrapper {
    margin: 0;
}

.rdw-image-wrapper {
    margin: 0;
}

.rdw-image-wrapper {
    margin: 0;
}

.rdw-inline-wrapper {
    margin: 0;
}

.rdw-option-active {
    box-shadow: none;
    border-color: #80bdff;
}

.rdw-image-modal {
    margin-top: .25rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: .25rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    padding: .75rem;

    .rdw-image-modal-header {
        margin: 0;

        .rdw-image-modal-header-option {

            .rdw-image-modal-header-label-highlighted {
                width: 100%;
                border-bottom: 1px solid #80bdff;
            }
        }
    }

    .rdw-image-modal-url-input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
            border-color: #80bdff;
        }
    }

    .rdw-image-modal-size-input {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
            border-color: #80bdff;
        }
    }

    .rdw-image-mandatory-sign {
        display: none;
    }

    .rdw-image-modal-btn {
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        border-radius: .25rem;

        &:hover {
            box-shadow: none;
        }
    }
}
