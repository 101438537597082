.form-control{
    &:focus{
        box-shadow: none;
    }
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f9fafb;
}

.table td {
    vertical-align: middle;
}

table .pretty {
    margin-right: 0;
}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
    box-shadow: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: none;
}

body .pretty input:checked ~ .state.p-primary label:after, body .pretty.p-toggle .state.p-primary label:after {
    background-color: $primary !important;
}

.custom-control-label {
    cursor: pointer;
}

svg {
    vertical-align: initial;
}
