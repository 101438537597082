.MappingVariantDetailContainer{
    .TitlePage{
        .leftBox{
            .mapping-status{
                padding: 2px 15px;
                border-radius: 7px;
                background: green;
                color: #fff;
                font-size: 14px;
                line-height: 24px;
                text-transform: capitalize;

                &.unmapped{
                    background: red;
                }
            }
        }
    }

    .MappingVariantDetail{
        .FormMappingVariant{
            .MappingVariantField{
                .itemField{
                    .table-container{
                        table{
                            width: 100%;
                        }

                        @media (max-width: 1500px){
                            overflow: auto;
                        }
                    }
                    .itemValue{
                        .itemValueInput{
                            display: inline-block;
                            width: 400px;
                            background: rgba(0,0,0,0.03);
                            border: 1px solid rgba(0,0,0,0.125);
                            padding: 5px 15px;
                            border-radius: 7px;
                        }

                        .ant-radio-disabled{
                            .ant-radio-inner{
                                border-color: #d9d9d9 !important;
                                background-color: #fff;

                                &::after{
                                    background-color: #1890ff;
                                }
                            }
                        }

                        .ant-radio-checked {
                            .ant-radio-inner{
                                border-color: #1890ff !important;
                            }
                        }

                        .ant-radio-disabled+span{
                            color: rgba(0,0,0,.85);
                        }
                    }

                    table{
                        thead{
                            background: #e9ecef;

                            tr{
                                th{
                                    padding: 5px 15px;
                                }
                            }
                        }

                        tbody{
                            tr{
                                td{
                                    padding: 10px 15px;

                                    .searchAdvance{
                                        display: inline-block;
                                        position: relative;

                                        .std-select-arrow{
                                            position: absolute;
                                            top: 8px;
                                            right: 11px;
                                            font-size: 12px;
                                            color: rgba(0,0,0,0.25);
                                        }

                                        .std-select-search{
                                            position: absolute;
                                            top: 8px;
                                            right: 11px;
                                            font-size: 12px;
                                            color: rgba(0,0,0,0.25);
                                        }
                                    }
                                }

                                &.error{
                                    background-color: rgba(255, 0, 0, 0.15);
                                    // td{
                                    //     .ant-select:not(.ant-select-customize-input){
                                    //         .ant-select-selector{
                                    //             border-color: red;
                                    //         }
                                    //     }

                                    //     input{
                                    //         border-color: red;
                                    //     }
                                    // }
                                }
                            }
                        }
                    }

                    .searchAdvance{
                        position: relative;

                        .searchDroplist{
                            position: absolute;
                            top: 100%;
                            left: 0;
                            z-index: 9;
                            width: 1100px;

                            &.hide{
                                display: none;
                            }

                            &.show{
                                display: block;
                            }

                            table{
                                width: 100%;
                                border: 1px solid #d9d9d9;
                                border-radius: 4px;
                                background-color: #fff;

                                thead{
                                    tr{
                                        display:table;
                                        width:100%;
                                        table-layout:fixed;
                                    }
                                }

                                tbody{
                                    max-height: 300px;
                                    overflow: auto;
                                    display: block;

                                    tr{
                                        display:table;
                                        width:100%;
                                        table-layout:fixed;

                                        &:hover{
                                            cursor: pointer;
                                            background-color: rgba(0, 0, 0, 0.03);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}