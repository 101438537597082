.MappingConditionForm {
  .MappingConditionFormInner {
    .tab-content,
    .tab-history {
      display: none;
    }

    &[data-tab="content"] {
      .tab-content {
        display: block;
      }
    }

    &[data-tab="history"] {
      .tab-history {
        display: block;
      }
    }
  }

  .ant-modal-footer {
    > button,
    > span,
    > a {
      margin-left: 8px;

      &.disabled {
        color: #999;
        pointer-events: none;
      }

      &.cancel {
        float: left;
      }
    }

    > span {
      color: #ccc;
    }
  }

  &.Edit {
    .ant-modal-header {
      padding-bottom: 50px;
    }

    .tabs {
      position: absolute;
      top: 53px;
      font-size: 14px;

      > a {
        display: inline-block;
        height: 36px;
        line-height: 36px;
        padding: 0 14px;
        outline: none;
        box-shadow: none;
        color: #999;
        transition: none;
        -webkit-transition: none;
        -moz-transition: none;

        &.active {
          border: 1px solid #f0f0f0;
          border-bottom-color: #fff;
          color: #007bff;
        }
      }
    }

    .tab-history {
      ul {
        li {
          margin-bottom: 20px;
        }
      }

      .history-actions {
        text-align: right;

        > * {
          margin-left: 8px;
        }
      }

      .loading {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
        background: rgba(0, 0, 0, 0.45);

        .ant-spin {
          left: 50%;
          top: 50%;
          position: absolute;
          margin-left: -16px;
          margin-top: -16px;
        }
      }
    }

    .move-item {
      //position: absolute;
      //width: 50px;
      //height: 40px;
      //opacity: 0.2;
      position: fixed;
      width: 50px;
      height: 40px;
      opacity: 0.2;
      top: 150px;
      z-index: 100;
      left: 50%;

      &:hover {
        opacity: 1;
      }

      &.move-left {
        //left: -80px;
        margin-left: -435px;
      }

      &.move-right {
        //right: -80px;
        margin-left: 370px;
      }
    }
  }

  .ConfirmCancel {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0.45);

    > div {
      background: #fff;
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      margin: 50px auto;
      max-width: 80%;
      padding: 20px;

      .actions {
        text-align: right;

        button {
          margin-left: 8px;
        }
      }
    }
  }
}

.MappingConditionPageContainer {
  .MappingConditionTable {
    table {
      table-layout: fixed;

      td,
      th {
        overflow-wrap: break-word;

        &.RowIndex,
        &.Action {
          width: 100px;
        }

        &.Store {
          width: 150px;
        }

        &.UpdatedAt,
        &.User {
          width: 200px;
        }
      }
    }
    .BulkActions {
      display: flex;
      align-items: baseline;
      .Actions {
        i {
          position: relative;
          margin-left: 5px;
          z-index: 10;
          &:hover {
            cursor: pointer;
            color: #0f9d58;
          }
        }
        .ListActions {
          display: none;
          position: absolute;
          width: 250px;
          background: #fff;
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 0.25rem;
          margin: 0;
          padding: 0;
          z-index: 10;
          li {
            color: #333;
            list-style: none;
            padding: 10px 15px;
            font-size: 15px;
            font-weight: 400;
            &:hover {
              cursor: pointer;
              color: #0f9d58;
            }
          }
        }
        .Disable li{
          pointer-events: none;
          color: #adb5bd;
        }
        .Background {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          opacity: 0;
          z-index: 9;
        }
      }
    }
  }

  .TableFooter {
    display: flex;
  }

  .page-counter {
    margin: 0 8px 0 auto;

    span {
      font-weight: bold;
    }
  }
}
