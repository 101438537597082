.AutoPushContent {
    padding: 24px 0;

    .TogglePush {
        align-items: center;
        margin-bottom: 18px;

        label {
            margin-right: 15px;
            margin-bottom: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
            color: #303238;
        }

        .ant-switch-checked {
            background: #477BF6;
        }
    }

    .ModeCheck {
        margin-bottom: 16px;

        label {
            span {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                color: #303238;
            }
        }

        .ant-checkbox-checked:after {
            border: 1px solid #477BF6;
        }

        .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #477BF6;
            border-color: #477BF6;
        }
    }

    .title {
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #303238;
    }

    .note {
        padding-bottom: 24px;
        border-bottom: 1px solid #E0E3E7;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #303238;

        span {
            margin-right: 5px;
        }
    }

    .Action {
        button {
            padding: 8px 40px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: #FFFFFF;
            background: #4A80F7;
            border: 1px solid #4A80F7;

            &:hover {
                color: #4A80F7;
                background: #FFFFFF;
            }
        }
    }

    .CheckBoxGroup {
        .ModeCheckGroup {
            margin-bottom: 17px;
        }

        .ant-checkbox-group {
            display: block;
            margin-left: 28px;

            label.ant-checkbox-wrapper.ant-checkbox-group-item {
                width: 100%;
                margin-bottom: 16px;
            }
        }

        label {
            span {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                color: #303238;
            }
        }
    }
    .CheckBoxGroup {
        .ant-checkbox-group {
            display: flex;
            flex-direction: column;
        }
    }
    .list-1, .list-2, .list-3 {
        min-width: 33.3%;
        @media (max-width: 768px) {
            min-width: 100%;
        }
    }
}
