.ShippingFeeNewPageContainer, .ShippingFeeEditPageContainer{
    .TabNewShipping{
        padding: 30px 0;
        ul{
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
            .itemTab{
                display: inline-block;
                line-height: 50px;
                padding: 0 45px;
                text-transform: uppercase;
                cursor: pointer;
                color: #fff;
                background-color: #bfbfbf;
                font-weight: 500;
            }
            .active{
                background-color: #477bf6;
                color: #fff;
            }
        }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border-radius: 4px !important;
        min-height: 38px;
        max-height: 112px;
        overflow: auto;
        border-radius: 4px;
        align-items: center;

        .ant-input-affix-wrapper {
            min-height: 38px;
            border-radius: 4px;
        }
    }
    .GeneralPage{
        .General_store {
            max-width:350px;
            .storeItem{
                background: #E9ECEF;
                border-radius: 4px;
                padding: 0px 9px;
            }
        }
    }

    .ProductlinePage{
        .VariantTable{
            table {
                thead{
                    .itemAtt{
                        width:180px;
                        transition: all .2s ease;
                    }
                    .ant-select-arrow{
                        right: 107px !important;
                    }
                    .ant-select-selector{
                       width:80px;
                    }
                    .AdditionalItem{
                        width: 130px;
                    }
                }
            }
        }
    }

    .ShippingFeeNewPages,
    .ShippingFeeEditPages{
        .ProductlinePage{
            .ProductPrice{
                .form-control{
                    max-width: 100px;
                    margin-left: 20px;
                }
            }
            .ProductVariant {
                .History:hover {
                    color: #0f9d58;
                }
            }
            .VariantTable{
                table{
                    tbody{
                        td{
                            .form-control{
                                max-width: 100px;
                                margin-right: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.shippingFeeHistoryModal {
    .ant-modal-body {
        max-height: 431px;
        overflow: auto;
        ul {
            li {
                margin-bottom: 20px;
            }
        }
    }
}
