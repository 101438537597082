.FulfilledPackage {
  .card-header {
    background-color: #e9ecef;

    .CustomAlert {
      i {
        margin-left: 5px;
        color: #007BFF;

        &:hover {
          color: #0f9d58;
        }
      }
    }
  }
  .card-footer {
    .btnConfirm[disabled] {
      background: gray;
      border-color: gray;
      opacity: 0.65;
    }
  }
}

.badgeUnfulfilled .ant-badge-count {
  color: #303238;
  box-shadow: 0 0 0 1px rgb(222, 222, 222) inset;
}

.FulfilledPackageTracking {
  .other {
    margin-left: 10px;
  }

  .listTracking {
    width: 100%;
    background-color: #FFFFFF;
    right: 0;
    z-index: 3;
    min-width: 270px;
    top: calc(100% + 8px);
    box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.06);
    border: 1px solid #DFDFDF;

    span {
      padding: 10px 20px;
      display: block;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);

      &:last-child {
        border-bottom: none;
      }
    }
  }
}

.PackageEmbroiderySurcharge {
  display: inline-block;
  height: 38px;
  line-height: 36px;
  position: relative;
  padding: 0 10px;
  background: #f5f5f5;
  color: #78858f;
  border: 1px solid #78858f;
  border-radius: 4px;
  font-size: 14px;
  text-decoration: none;
  font-weight: bold;
  white-space: nowrap;
  margin:0;
}