.ProductsTable {
    @media (max-width: 1500px) {
        overflow-x: scroll;
        min-height: 300px;
        .table {
            width: auto;
            width: -moz-max-content;
            width: max-content;
        }
    }

    .ProductTitle, .ProductType {
        max-width: 200px;
        word-break: break-word;
    }

    .ProductDescription {
        max-width: 300px;
        word-break: break-word;
    }

    .ProductStoreMode {
        .listStoreMode {
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                margin-right: 5px;
                border: 1px solid #0f9d58;
                padding: 5px;
                color: #0f9d58;
                font-weight: 400;

                &:hover {
                    cursor: pointer;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .ProductsSupplier{
        ul.listSupplier{
            li{
                position: relative;

                .supplierModalContainer{
                    position: absolute;
                    top: 100%;
                    right:0;
                    margin-right: -100px;
                    min-width: 1200px;
                    background-color: #fff;
                    z-index: 99;
                    padding: 16px;
                    border-radius: 2px;
                    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
                    visibility: visible;
                    opacity: 1;
                  }
            }
        }
    }
}

