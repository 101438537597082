.ShippingZoneCreateForm{
    .Header{
        .BtnCancel{
            width: 115px;
            background: #BFBFBF ;
            height: 40px;
            border-radius: 4px;
            border-color: #BFBFBF;
        }
        .BtnSave{
            margin: 0 0 0 15px;
            background: #4A80F7;
            width: 115px;
            height: 40px;
            border-radius: 4px;
        }
    }
    .FormCreate{
        margin:51px 0 0 70px;
        display: flex;
        width: 35%;
        flex-direction: column;
        .formFilter{
            
            margin-bottom: 15px;
        }
    }
}