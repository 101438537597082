.TopSellingProducts {
    margin-top: 30px;

    .Heading {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .TablePlaceholder {
        .Thumbnail {
            width: 60px;
            height: 60px;
        }

        span{
            width: 100px;
            height: 10px;
            max-width: 100%;
        }
    }

    .ImageCell {
        .ThumbnailWrapper {
            display: flex;
        }

        .ThumbnailInner {
            position: relative;
        }

        .OriginImage {
            display: none;
            position: absolute;
            left: calc(100% + 15px);
            top: 50%;
            transform: translateY(-50%);
            max-width: 200px;
        }

        .ThumbnailImage:hover {
            & + .OriginImage {
                display: block;
            }
        }
    }
}
