.ProductVariants {
  .TopPageHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ProductVariantSupplier {
      position: relative;

      .add-store{
        margin-right: 15px;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        border: 1px solid #dddddd;
        border-radius: 4px;
        &:hover {
          cursor: pointer;
          border: 1px solid #0f9d58;

          span {
            color: #0f9d58;
          }

          i {
            color: #0f9d58;
          }
        }
      }
      .ListSupplierVariant {
        .CloneVariant {
          padding: 5px 15px;
          display: flex;
          align-items: center;
          border: 1px solid #dddddd;
          border-radius: 4px;

          span {
            color: #333333;
          }


          i {
            color: #333333;
            margin-left: 5px;
          }

          &:hover {
            cursor: pointer;
            border: 1px solid #0f9d58;

            span {
              color: #0f9d58;
            }

            i {
              color: #0f9d58;
            }
          }
        }
      }

      .ListSupplier {
        display: none;
        position: absolute;
        top: 37px;
        right: 0;
        min-width: 270px;
        background: #FFFFFF;
        border: 1px solid #dddddd;
        max-height: 300px;
        overflow: auto;
        z-index: 10;
        border-radius: 6px;

        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-thumb {
          background: #D3DCD7;
          border-radius: 6px;
        }

        &.IsOpen {
          display: block;
        }

        li {
          padding: 5px 15px;
          border-bottom: 1px solid #dddddd;

          &:hover {
            cursor: pointer;
            background: #0f9d58;
            color: #FFFFFF;
          }

          &:last-child {
            border-bottom: none;
          }

          &.Disable {
            pointer-events: none;
            opacity: .5;
          }
        }
      }

      .Background {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: 8;
      }
    }
  }
}

.ProductVariants {
  .VariantTab {
    .VariantTabHeader {
      ul {
        display: flex;
        align-items: center;
        padding-bottom: 5px;
        border-bottom: 2px solid #007BFF;
        overflow: auto;

        &::-webkit-scrollbar {
          height: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background: #D3DCD7;
          border-radius: 4px;
        }

        li {
          border: 2px solid #D8DBDE;
          padding: 8px 22px;
          color: #65676B;
          font-weight: 600;
          margin-right: 5px;
          border-radius: 4px;
          white-space: pre;

          &:hover {
            cursor: pointer;
            background: #007BFF;
            color: #FFFFFF;
            border: 2px solid #007BFF;
          }

          &:last-child {
            margin-right: 0;
          }

          &.Active {
            background: #007BFF;
            color: #FFFFFF;
            border: 2px solid #007BFF;
          }
        }
      }
    }

    .VariantTabBody {
      .ProductVariantsFilter {
        padding: 15px 0;
        display: flex;
        align-items: center;

        .FilterItem {
          min-width: 150px;
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .ProductVariantsTable {
        overflow: auto;

        .table {
          min-width: 1740px;
        }

        .ProductVariantRowDimension {
          width: 118px;
          text-align: center;

          input {
            text-align: center;
          }
        }

        .Quantity {
          width: 50px !important;
        }

        tbody {
          border-bottom: 1px solid #dddddd;

          td {
            word-break: break-all;

            &.ProductVariantPrice{
              .hasHistory{
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }

  .WarningVariantsDuplicate {
    background: #ffc58b33;
    border: 1px solid #ffe2c8;
    padding: 0.8em 1.2em;
  }

  .MoveToDuplicateSKU {
    transition: none;
    font-size: 0.9em;
    background: #DDD;
    padding: 5px 8px;
    border-radius: 4px;
    font-weight: bold;

    &:first-child {
      margin-left: 10px;
    }

    &:hover {
      background: #0f9d58;
      color: #FFF;
    }
  }

  .ProductVariantsRow {
    &.highlight {
      background: #007bff0d;
    }
  }

  .VariantsHighlight {
    > p > span {
      display: inline-block;
      margin-right: 10px;
    }

    &.highlight span {
      color: #28a745;
    }
  }

  .VariantsHighlight {
    > p {
      display: inline-block;
      margin: 0;
    }
  }
}
