.WebhookTable {
    @media (max-width: 1400px) {
        .wrapTable {
            overflow-x: scroll;

            table {
                width: max-content;
            }
        }
    }
}

.WebhookTableHead {
    th.WebhookSelection {
        width: 100px;
    }

    th.WebhookActions {
        width: 200px;
    }

    th.OrderNumber {
        width: 250px;
    }

    th.WebhookAddress {
        width: 200px;
    }

    th.WebhookStatus {
        width: 120px;
    }

    th.WebhookType {
        width: 220px;
    }

    th.WebhookTotalSent {
        width: 125px;
    }

    th.WebhookSendTime {
        width: 200px;
    }
}

.WebhooksTableRow {
    .WebhookMessage {
        word-break: break-all;
    }

    .OrderNumber {
        span.ti-info-alt {
            margin-left: 10px;

            &:hover {
                cursor: pointer;
                color: #0f9d58;
            }
        }
    }
}


ul.ant-menu.BulkJobsMenu {
    padding: 4px 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);

    .ant-menu-item {
        margin: 0;
        padding: 5px 12px;
        height: auto;
        line-height: 22px;

        &:hover {
            background-color: #e6f7ff;
            color: inherit;
        }
    }
}
