.PackageSingleItemEditor {
  td {
    min-width: 150px;

    .Warning {
      border: 3px solid #F70000;
    }
  }
}

.PackageAddressEditor {
  td {
    min-width: 150px;
  }
}

.PackagePusherEditorModal {
  max-width: 98%;
}

.ItemHightLight {
  background: rgba(255, 208, 26, 0.3) !important;
}
