.CategoriesModal {
}

.CategoriesModalDialog {
    width: 800px;
    max-width: 100%;

    .SearchInput {
        border-width: 0 0 2px 0;
        border-radius: 0;
    }
}
