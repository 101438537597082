.CustomMultiSelect {
  position: relative;
  margin-left: 12px;

  .Background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 8;
  }

  .InputSelect {
    display: block;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #303238;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #D8DBDE;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    min-width: 200px;
    padding: 8px 12px;

    span {
      overflow-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      height: auto;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    &:after {
      content: '\e64b';
      font-family: 'themify';
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }
  }

  .PopupSelect {
    position: absolute;
    //padding: 20px 14px 0 14px;
    padding: 1px 0 0 0;
    top: 42px;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    opacity: 0;
    z-index: -1;
    border: 1px solid #eeeeee;
    min-width: 225px;

    &.true {
      z-index: 9;
      opacity: 1;
      top: 42px;
    }

    .Item {
      display: flex;
      align-items: center;
      margin-bottom: 1px;
      padding: 5px 8px;

      label {
        margin-bottom: 0 !important;
        margin-left: 10px;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 22px !important;
        color: #303238 !important;
      }
    }

    .Actions {
      button {
        border: 1px solid #0a6ebd;
        background-color: #0a6ebd;
        color: #FFFFFF;
        margin-right: 10px;
        padding: 0 10px;
        border-radius: 4px;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 12px !important;
        line-height: 22px !important;

        &.LinkAction {
          border: 0;
          background-color: #FFFFFF;
          color: #0a6ebd;
          padding: 0 10px;
        }

        &:hover, &:active, &:focus {
          cursor: pointer;
          background-color: #0aa7ef;
          border-color: #0aa7ef;
          outline: none;

          &.LinkAction {
            background-color: #FFFFFF;
            color: #0aa7ef;
            outline: none;
          }
        }

        &:disabled {
          cursor: not-allowed;
          color: #999;
        }
      }
    }
  }

  .SubItemList {
    margin-left: 25px;

    .Item {
      .bg {
        margin-left: -25px;
      }
    }
  }
}
