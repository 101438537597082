.FulfilledPackageItemDesign {
  ul.ListArtworks {
    > li:nth-child(2n + 1) {
      margin-top: 16px;
    }

    > li:first-child {
      margin-top: 0;
    }
  }
}

.ArtworkType {
  .ValidateDimension {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    svg {
      width: 20px;
      margin-right: 5px;
    }

    span {
      width: calc(100% - 25px);
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #F70000;
    }
  }
}

.ListArtworks {
  .ArtworkPreview {
    img {
      &.HasWrong {
        border: 4px solid #F70000;
      }
    }

    .ErrorDimension {
      margin-top: 3px;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #F70000;
    }
  }
}
