.NavbarLeft {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1000;
    border-right: solid 1px $border-color;
    // overflow-y: scroll;
    // &::-webkit-scrollbar {
    //     display: none;
    // }

    .NavbarMask{
        position: absolute;
        top: 21px;
        right: -12px;
        background: #0f9d58;
        width: 24px;
        height: 24px;
        border-width: 1px 1px 1px 1px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.0625);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999999;
        cursor: pointer;
    }

    .NavbarLeftBox{
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .NavbarLeftInner {
        width: $offscreen-size;
        flex-direction: column;
        min-height: 100%;
        background-color: #fff;
        transition: transform .3s cubic-bezier(.9,0,.3,.7);

        .BottomMenu {
            margin-bottom: 10px;
        }
    }

    .nav-link {
        display: flex;
        align-items: baseline;
        font-size: 1rem;
        font-weight: 500;
        padding: 5px 15px;
        position: relative;
        white-space: nowrap;
        color: $default-text-color;
        transition: all 0.3s ease;

        &:hover, &.active {
            color: $default-primary;
            text-decoration: none;
        }

        .BadgePosition{
            position: absolute;
            top: -3px;
            right: 10px;

            &.expand{
                flex-grow: 1;
                display: flex;
                align-content: space-between;
                align-items: center;
                top: 14px;

                .Title{
                    flex-grow: 1;
                }

                .ant-badge-count{
                    position: static;
                    transform: translate(0,0);
                }
            }
        }

        .UserInfo{
            &.expand{
                // position: absolute;
                // top: 50%;
                // left: 100%;
                // transform: translate(0, -50%);
                // z-index: 9999;
            }
        }
    }

    .Icon {
        border-radius: 6px;
        display: inline-block;
        font-size: 17px;
        height: 35px;
        left: 0;
        line-height: 35px;
        position: relative;
        text-align: center;
        width: 35px;
        cursor: pointer;
    }

    .NavLogo {
        border-bottom: solid 1px $border-color;

        .SiteName {
            color: $grey-900;
            font-weight: 500;
            margin-left: 5px;
            font-size: 1.09375rem;
            vertical-align: middle;
        }

        .LinkLogo {
            text-decoration: none;
            display: flex;
            align-items: center;
        }

        .LogoImage {
            flex: 0 0 66px;

            img {
                width: 100%;
                padding: 10px;
            }
        }
    }

    .PrimaryMenu {
        margin-top: 10px;
        // max-height: 585px;
        // overflow-y: auto;

        // &::-webkit-scrollbar {
        //     width: 6px;
        // }

        // &::-webkit-scrollbar-track {
        //     background-color: rgba(0,0,0,0.3);
        //     border-radius: 3px;
        // }

        // &::-webkit-scrollbar-thumb {
        //     border-radius: 3px;
        //     background-color: rgba(0,0,0,0.5); 
        // }

        .Title {
            display: inline-block;
            margin-left: 14px;
        }
    }

    .BottomMenu{
        .Title {
            display: inline-block;
            margin-left: 14px;
        }
    }
}

@include media-breakpoint-down(md){
    .NavbarLeft {
        width: 100%;

        .NavbarLeftInner {
            position: fixed;
            z-index: 2;
            overflow: hidden;
            transition: all .3s cubic-bezier(.9,0,.3,.7);
        }

        .NavbarMask {
            position: fixed;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: .3;
            background-color: rgba(0, 0, 0, 0.65);
            transition: opacity .3s;
        }
    }
}

.BadgePosition{
    .ant-badge-count{
        // top: -20px !important;
    }
    .ant-scroll-number-only{
        p{
            line-height: 20px !important;
            height: 20px !important;
        }
    }
}

.BadgePosition.issue-badge{
    .ant-badge-count{
        top: 4px !important;
        // right: -2px;
    }
    .ant-scroll-number-only{
        p{
            line-height: 20px !important;
            height: 20px !important;
        }
    }
}
