.Pagination {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #dee2e6;
    padding-top: 32px;

    .pagination {
        flex-wrap: wrap;
    }

    .page-item, .break {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 5px;
        transition: all 0.2s ease-in-out;
    }

    .page-item {
        &.active {
            .page-link {
                color: #fff !important;
                background: #007bff;
            }
        }

        &.disabled {
            pointer-events: none;
            opacity: .4;
        }
    }

    .page-link {
        border: none;
        color: $default-text-color !important;
        padding: 6px 12px;
        border-radius: 2px;
        cursor: pointer;
        text-decoration: none;
        display: inline-block;
        box-shadow: none;

        &:hover {
            color: #fff !important;
            background: #007bff;
        }
    }
}
