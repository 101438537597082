.ScanDetailTable {
  tr {
    th {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .BulkSelect, .OrderCheckbox {
    width: 30px;

    .custom-control {
      padding-left: 0;
    }
  }

  .MenuActions {
    position: absolute;
    left: 10px;
    top: 5px;
  }

  .HasSwitch {
    background-color: #0f9d5847;
  }
}

.ScanDetailFilter {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  .FilterItem {
    margin-right: 15px;

    label {
      display: block;
      font-size: 14px;
      font-weight: 600;
    }

    &.Input {
      input {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #303238;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #D8DBDE;
        box-sizing: border-box;
        border-radius: 4px;
        position: relative;
        min-width: 350px;
        padding: 8px 12px;
      }
    }

    .ant-select-selector {
      height: 40px;
      border-radius: 4px;
      width: 200px;

      .ant-select-selection-item {
        line-height: 40px;
      }
    }
  }
}
