.ModalChangeSupplier.HaveForm {
    .ant-modal-header {
        border-bottom: 0;
        padding-top: 21px;
        padding-bottom: 0;

        .ant-modal-title {
            font-style: normal;
            font-weight: bold;
            font-size: 28px;
            line-height: 33px;
            color: #000000;
            margin-bottom: 7px;
        }
    }

    .ant-modal-body {
        padding-top: 0;
    }

    .ant-modal-footer {
        .ant-btn {
            height: 40px;
        }
    }
}

.FormRefund {
    .TopForm {
        .Title {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #000000;
            padding-bottom: 16px;
            margin-bottom: 21px;
            border-bottom: 1px solid #DFDFDF;
        }

        .ToggleRefund {
            margin-bottom: 22px;

            input {
                width: 18px;
                height: 18px;
                margin-right: 11px;
            }

            span {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
            }
        }
    }

    .ContentForm {
        label {
            width: 140px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            margin: 0;
        }

        input, select {
            width: 184px;
            background: #FFFFFF;
            border: 1px solid #DFDFDF;
            box-sizing: border-box;
            border-radius: 3px;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            color: #000000;
            padding: 6px 10px;

            &:disabled {
                background-color: #F0F0F0
            }
        }

        .Left {
            width: 50%;
            margin-bottom: 25px;

            #Value {
                width: 50%;
                border-right: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            span.ChangeType {
                display: inline-block;
                width: 28px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
                padding: 7px 0;
                border: 1px solid #DFDFDF;
                text-align: center;
                border-right: 0;

                &.Last {
                    border-right: 1px solid #DFDFDF;
                    border-bottom-right-radius: 3px;
                    border-top-right-radius: 3px;
                }

                &.active {
                    color: #FFFFFF;
                    background-color: #0f9d58;
                }

                &:hover {
                    color: #FFFFFF;
                    cursor: pointer;
                    background-color: #0f9d58;
                }
            }

            &.Error {
                position: relative;

                #Value {
                    border-color: #FF0000;
                }

                span.ChangeType {
                    border-color: #FF0000;
                    border-left-color: #DFDFDF;

                    &.Last {
                        border-color: #FF0000;
                        border-left-color: #DFDFDF;
                    }
                }

                .ant-select-selector {
                    border-color: #FF0000;
                }

                p {
                    position: absolute;
                    left: 140px;
                    bottom: -25px;
                    margin: 0;
                }
            }
        }

        .Right {
            width: 50%;
            margin-bottom: 25px;

            &.Error {
                position: relative;

                input {
                    border-color: #FF0000;
                }

                p {
                    position: absolute;
                    left: 140px;
                    bottom: -25px;
                    margin: 0;
                }
            }
        }

        .Reason {
            margin-bottom: 18px;

            label {
                display: block;
                margin-bottom: 11px;
            }

            textarea {
                width: 100%;
                resize: none;
                background: #FFFFFF;
                border: 1px solid #DFDFDF;
                box-sizing: border-box;
                border-radius: 3px;
                padding: 25px 18px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 16px;
                color: #000000;
            }

            &.Error {
                position: relative;

                textarea {
                    border-color: #FF0000;
                }

                p {
                    position: static;
                }
            }
        }
    }
}

.ant-radio-button-wrapper {
    padding: 0 10px;
}
