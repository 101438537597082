.DatePaid {
    .Label {
        position: relative;
        top: 2px;
    }

    .DateInput_input {
        font-weight: 400;
    }

    .DatePickerWrapper {
        padding: 2px 10px;
        border: solid 1px #ced4da;
    }

    .DateRangePickerInput_clearDates_svg {
        position: relative;
        top: -3px;
    }

    .DateRangePickerInput_clearDates {
        margin-right: 0;
    }

    .DateRangePickerInput_clearDates_default:focus, .DateRangePickerInput_clearDates_default:hover {
        background-color: transparent;

        .DateRangePickerInput_clearDates_svg {
            fill: $danger;
        }
    }
}
