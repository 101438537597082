.OrderProfitPageContainer{

    .OrderProfitTable{
        .wrapTable{
            .table{
                thead tr th{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 22px;
                    color: #303238;
                    padding: 16px;
                    border-top: 1px solid #d8dbde;
                    background: #f4f6f8;
                    border-bottom: none;
                }
                thead tr th.Supplier{
                    width: unset;
                }
                tbody tr {
                    td{
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                        color: #303238;
                        padding: 16px;
                        border-top: 1px solid #d8dbde;
                    }
                    .profit{
                        a:not([href]){
                            color: #007bff;;
                        }
                        a:hover {
                            // text-decoration: underline;
                            color: #0062cc
                        }
                    }
                    .Supplier {
                        width: unset;
                    }
                }
                tbody tr:last-child{
                    border-bottom: 1px solid #d8dbde;
                }
            }
        }
        @media only screen and (max-width: 1733px) {
            .wrapTable{
                overflow: auto;
            }    
        }
        
    }
    .OrderProfitPage{
        .PageHeading{
            .RightTop{
                display: flex;
                align-items: center;
            }
        }
        .SectionInner{
            .Filter{
                .FilterWrapper {
                    .searchFileName, .search{
                        height: 40px;
                        border-radius: 4px;
                        align-items: center;

                        &.site-input-left{
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        &.site-input-split{
                            border-radius: 0;
                        }

                        &.site-input-right{
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }
                }
                .CustomSelect {
                    .ant-select{
                        .ant-select-selector{
                            height: 40px;
                            border-radius: 4px;
                            align-items: center;
                        }
                    }
                    .ant-picker.ant-picker-range{
                        height: 40px;
                        border-radius: 4px;
                        align-items: center;
                    }
                }
            }
            .Total{
                span{
                    text-align: right;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 22px;
                    color: #303238;
                    margin-bottom: 19px;
                }
            }
        }
    }
}
.ExportOrderProfitModal{
    .ant-btn{
        border-radius: 4px;
    }
    .ant-btn.ant-btn-primary{
        background: #477BF6 ;
    }
    .FileName {
        border-radius: 4px;
    }
}