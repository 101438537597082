.OrderItemMapDetails, .FulfilledPackageItemMap {
    .Label {
        min-width: 125px;
    }

    .CustomButton {
        margin-top: 30px;
        height: 40px;
        padding: 0 32px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        border-radius: 4px;
        color: #FFFFFF;
        border: 2px solid;

        &.AddNewButton {
            background: #477BF6;
            border-color: #477BF6;

            &:hover {
                background: #FFFFFF;
                color: #477BF6;
            }
        }

        &.ReplaceButton {
            background: #FAA23A;
            border-color: #FAA23A;

            &:hover {
                background: #FFFFFF;
                color: #FAA23A;
            }
        }
    }
}
