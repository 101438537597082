.MultiFilterInput {
  background: #FFFFFF;
  border: 1px solid #D8DBDE;
  box-sizing: border-box;
  border-radius: 4px;

  .MultiFilter {
    display: flex;
    align-items: center;

    .ant-select {
      .ant-select-selector {
        border-radius: 4px 0 0 4px;
        border: 0;
        border-right: 1px solid #D8DBDE;
        padding: 5px 12px;
        height: 38px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        min-width: 170px;
        color: #303238;
      }

      .ant-select-arrow {
        color: #303238;
      }
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none !important;
      border-color: #D8DBDE;
    }

    .Right {
      svg {
        margin-left: 14.25px;
        margin-right: 6.25px;

        path {
          fill: #303238 !important;
        }
      }

      input {
        border: 0;
        height: 38px;
        padding: 9px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        border-radius: 4px;
        width: 300px;

        &::placeholder {
          color: #979B9F;
        }
      }
    }
  }
}

.NeedCharge {
  .ant-select-selector {
    height: 38px !important;
  }

  .ant-select-selection-item {
    line-height: 38px !important;
  }
}
