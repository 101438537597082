.FulfilledPackageItems {
  .table {
    @media (max-width: 1600px) and (min-width: 1200px) {
      td.FulfilledPackageItemProduct {
        width: 30%;
      }
      td.FulfilledPackageItemMap {
        width: 30%;
      }
      td.OrderItemDesignV3 {
        width: 40%;
      }
    }
  }
}