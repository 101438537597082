.SettingHolidayContainer {
    .NewSettingBoxes{
        .FormSettingBoxes{
            .setting-boxes-table{
                thead{
                    tr{
                        background: #eee;

                        th{
                            padding: 7px 22px;
                            color: #000;
                            font-style: normal;
                            font-weight: bold;
                            line-height: 24px;
                        }
                    }
                }

                tbody{
                    tr{
                        border-bottom: 1px solid rgba(119, 113, 113, 0.09);

                        td{
                            padding: 15px 22px;

                            .SettingRulerInput{
                                .ListInput{
                                    .CustomSelect{
                                        padding: 2px 12px;
                                        // font-style: normal;
                                        // font-weight: 400;
                                        // font-size: 14px;
                                        line-height: 24px;
                                        // color: #303238;

                                        .Selected{
                                            span{
                                                display: inline-block;
                                                font-style: normal;
                                                font-weight: 400;
                                                font-size: 14px;
                                                line-height: 24px;
                                                color: #303238;
                                                padding: 0px 12px;
                                                margin-right: 4px;
                                                background: rgba(119, 113, 113, 0.09);
                                                border-radius: 2px;
                                                position: relative;

                                                // i{
                                                //     position: absolute;
                                                //     top: 0;
                                                //     right: 0;
        
                                                // }
                                            }
                                            .Input{
                                                display: inline-block;

                                                input{
                                                    border: 0;

                                                    &:hover,
                                                    &:focus{
                                                        border: 0;
                                                        outline: none;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                &.disabled{
                                    cursor: not-allowed;

                                    .ListInput{
                                        .CustomSelect{
                                            color: rgba(0, 0, 0, 0.25);
                                            background: #f5f5f5;
                                            &:hover{
                                                border: 1px solid #d9d9d9;
                                            }

                                            .Selected{
                                                .Input{
                                                    input{
                                                        &:hover{
                                                            cursor: not-allowed;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .remove-item{
                                &:hover{
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }

            .add-box{
                color: #1890FF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; /* 150% */

                &:hover,
                &:focus{
                    background: none;
                }
            }
        }
    }
}