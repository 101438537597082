.SettingHolidayDetailContainer {
    .ant-select {
        .ant-select-selector {
            min-height: 40px;
            border-radius: 5px;
            align-items: center;
            max-height: 120px;
            overflow: auto;

            .ant-input-affix-wrapper {
                min-height: 40px;
                border-radius: 5px;
            }
        }
    }

    .ant-picker.ant-picker-range,
    .ant-picker {
        min-height: 40px;
        border-radius: 5px;
        align-items: center;
    }

    .ant-input-affix-wrapper {
        min-height: 40px;
        border-radius: 5px;
        align-items: center;
    }

    td {
        vertical-align: top;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}