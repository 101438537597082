.Button {
    background-color: #0f9d58;
    color: #fff;
    border: 1px solid #0f9d58;

    &:hover {
        color: #0f9d58;
        background-color: #fff;
    }

    &:focus {
        box-shadow: 0 0 0 3px rgba(55, 201, 54, 0.25);
    }
}


.OutlineButton {
    background: #fff;
    color: #0f9d58;
    border: 1px solid #0f9d58;

    &:hover {
        color: #0f9d58;
        background: darken(#fff, 5%);
    }

    &:focus {
        box-shadow: 0 0 0 3px rgba(55, 201, 54, 0.25);
    }
}
