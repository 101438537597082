.LabelFilter {
    //display: flex;
    align-items: baseline;
    font-size: .875rem;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    label {
        min-width: 200px;
    }

    ul.FilterItems {
        margin-left: 30px;

        > li {
            border: solid 1px $border-color;
            display: inline-block;
            cursor: pointer;
            transition: all .3s ease;

            &:first-child {
                border-radius: 4px 0 0 4px;
            }

            &:last-child {
                border-radius: 0 4px 4px 0;
            }

            &:not(:first-child) {
                margin-left: -1px;
            }

            &.active {
                border-color: $default-primary;
                color: $default-primary;
                position: relative;
                z-index: 2;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .LabelFilter {
        ul.FilterItems {
            padding-left: 15px;
        }
    }
}

@include media-breakpoint-down(xs) {
    .LabelFilter {
        flex-direction: column;

        label {
            margin-bottom: 10px;
        }

        ul.FilterItems {
            padding: 0;
            margin: 0;
        }
    }
}
