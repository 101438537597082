.ProductCategories {
  margin-top: 40px;
}

.ProductEditor {
  .ActionProductLine {
    padding: 5px 15px;
    margin-bottom: 5px;
    border: 1px solid #0f9d58;
  }

  .ant-select {
    display: block;
  }
}
