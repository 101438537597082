.ProductSource {
  .Label {
    min-width: 100px;
  }

  .Quantity .Number {
    font-size: 2rem;
    font-weight: bold;
  }

  .CopyTitleMap {
    position: absolute;
    right: 0;
    z-index: 9;
  }
}
