.OrderItemProduct {
  .Label {
    font-weight: 500;
    min-width: 125px;
  }

  .Quantity {
    align-items: center;

    .Number {
      font-size: 2rem;
      font-weight: bold;
    }
  }

  .ThumbnailWrapper {
    .Actions {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, .8);
      visibility: hidden;
      opacity: 0;
      transition: all .3s;
    }

    &:hover {
      .Actions {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .CopyImageUrl {
    right: 0;
    top: 0;
  }
}

.CustomVariant {
  .Label {
    margin-right: 8px !important;
    font-weight: bold;
  }
}

.CustomVariable {
  margin-top: 5px;

  ul {
    li {
      padding-left: 15px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 10px;
        // transform: translateY(-50%);
        background-color: #333;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        left: 0;
      }
    }
  }

  .Label {
    text-transform: capitalize;
    min-width: 49px !important;
    font-size: 14px;
  }

  .text-break {
    font-size: 14px;

    &.size {
      text-transform: uppercase;
    }
  }
}

.OrderItemMap {
  .OrderItemMapDetails {
    .ThumbnailWrapper {
      min-height: 125px;
      border: 1px solid #dddddd;
      border-radius: 4px;

      .Actions {
        opacity: 0;
        background: #FFFFFF;
        transition: all 0.2s;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:hover {
        .Actions {
          opacity: 1;
        }
      }
    }

    .Stock {
      margin-top: 15px;
      font-size: 16px;
      font-weight: 600;
      color: #c82333;
    }
  }
}
