.ProductAttributeItem {
  .ListAttributes {
    .Value {
      position: relative;
    }
  }

  select {
    option.NotSelect {
      background: #dddddd;
    }
  }
}

.selectSupplier {
  .ant-select-selection__placeholder {
    color: #495057;
  }
}

.CompleteGenerateSKU {
  right: 0;
  left: 0;
  width: 500px;
  max-width: 100%;
  margin: auto;
}

.ProductPage {
  .input-error {
    border-color: #dc3545;
  }

  .select-error {
    .ant-select-selection {
      border-color: #dc3545;
    }
  }
}
