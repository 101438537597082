#ButtonPushFactory {
    margin-right: 10px;
}

.PackagePusherEditorModal {
    &.Factory {
        .modal-body {
            &.Pushing {
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    background: #ffffff85;
                    bottom: 0;
                }
            }
        }

        .modal-footer {
            display: none;

            .custom-control {
                &.custom-checkbox {
                    display: inline-block;
                    margin-right: 10px;
                }
            }
        }

        .PushMessage {
            text-align: right;
        }
    }
}

.ExportFactory {
    .PackageSingleItemEditor {
        td {
            min-width: 150px;

            > p {
                display: flex;
                position: relative;

                input {
                    position: absolute;
                    left: 70px;
                    width: calc(100% - 70px);
                }
            }
        }
    }

    .ItemHightLight {
        background: rgba(255, 208, 26, 0.3) !important;
    }

    .PackageAddressEditor {
        td {
            min-width: 150px;
        }
    }

    .PackagePusherEditorModal {
        max-width: 98%;
    }
}

.ModalBulkPushFactoryOrdersMonitor {
    .ant-modal-confirm-btns {
        display: none;
    }
}

.BulkPushFactoryOrdersMonitor {

    .TopMessage {
        line-height: 23px;
        font-size: 18px;

        &.Error {
            color: #FF0000;
        }
    }

    .OrdersList {
        margin: 20px 0 0 0;
        width: 100%;

        .OrdersList__Order {
            > div {
                display: flex;
            }

            .OrderPackages {
                display: block;
                margin: 10px 0;

                p {
                    text-align: right;
                    margin: 0;
                    font-size: 12px;
                }
            }

            .Index {
                flex: 0 0 40px;
                text-align: right;
            }

            .Name {
                margin-left: 10px;
                flex: 0 0 150px;
            }

            .Status {
                flex: 1;
                text-align: right;
            }

            &.Current {
                font-weight: bold;
            }

            &.Success {
                color: #37c936;
            }

            &.Error {
                color: #FF0000;
            }
        }

        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .OrdersOverview {
        display: flex;
        margin: 0;
        clear: both;

        .Status {
            margin-right: 10px;
            flex: 1;
        }

        .Overview {
            text-align: right;
        }

        &.Error {
            color: #FF0000;
        }

        &.Success {
            color: #37c936;
        }
    }

    .OrderActions {
        text-align: right;
        margin: 30px 0 0;

        > *:not(.ButtonCancel) {
            margin-left: 10px;
            display: inline-block;
        }

        label {
            input {
                margin-right: 5px;
            }
        }

        .ButtonCancel {
            float: left;
        }
    }
}

.PackagePusherEditorModal {
    .modal-content {
        .modal-header {
            background: #2F3230;

            .modal-title {
                color: #FFFFFF;
                font-weight: normal;
            }
        }

        .modal-body {
            padding: 0;

            .Editor {
                .PackageAddressEditor, .PackageListItemsEditor {

                    .HeadingPackage {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background: #F3F3F5;

                        label {
                            font-style: normal;
                            font-weight: bold;
                            font-size: 14px;
                            line-height: 20px;
                            color: #2F3230;
                            margin-right: 5px;
                        }

                        .ant-select {
                            width: 200px;
                            margin-right: 15px;

                            .ant-select-selector {
                                border-radius: 4px;
                            }
                        }
                    }

                    .HeadingTitle {
                        padding: 15px;
                        background: #F3F3F5;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 16px;
                        line-height: 20px;
                        color: #2F3230;
                        text-transform: uppercase;
                    }

                    .table-responsive {
                        padding: 0 15px;

                        .table {
                            margin-bottom: 0;
                            border: 1px solid #dddddd;
                            margin-top: 15px;
                            border-radius: 0.25rem;

                            thead {
                                tr {
                                    th {
                                        border-bottom: 0;
                                        padding: 14px 5px;
                                        background: rgba(#28a745, 0.5);
                                        font-style: normal;
                                        font-weight: bold;
                                        font-size: 14px;
                                        line-height: 20px;
                                        color: #2F3230;
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    td {
                                        padding: 18px 5px;

                                        input {
                                            font-style: normal;
                                            font-weight: normal;
                                            font-size: 15px;
                                            line-height: 20px;
                                            color: #2F3230;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .PackageListItemsEditor {
                    margin-top: 15px;
                    margin-bottom: 15px;
                }

                .PackageIssuesStyles{
                    .table-striped {
                        tbody{
                            tr:nth-of-type(odd){
                                background-color: transparent;
                            }

                            tr.dark{
                                background-color: #f9fafb;
                            }

                            tr.PackageSingleItemEditorIssues{
                                td{
                                    border-top: none;
                                    padding-top: 0;
                                }
                            }
                        }
                    }
                }
            }

            .Loading {
                padding: 15px;
                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 20px;
                color: #2F3230;
            }

            .Error {
                padding: 15px;
            }
        }
    }
}
