.FulfilledPackageItems {
    table td{
        vertical-align: top;
        .Label {
            min-width: 80px;
        }

        .price-detail{
            .price-detail-button{
                border: none;
                padding: 0;
                background: none;
                box-shadow: none;
                color: #0062cc;
            }
        }
    }
}

.priceDetailItem{
    .Label{
        min-width: 90px;
    }
}
