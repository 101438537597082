input.Input {
    width: 100%;
    border-radius: 4px;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding: .375rem .75rem;
    outline-offset: -2px;

    &:focus {
        border-color: #37c936;
        box-shadow: 0 0 0 3px rgba(55, 201, 54, 0.25);
    }

    &:disabled {
        background-color: #e9ecef;
        opacity: 1;
    }
}
