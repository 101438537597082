.TrackingEdit {
  max-width: 920px;

  .modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: #000000;
  }
}

.FormTracking {
  .ListTracking {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;

    &.Label {
      margin-bottom: 0;
    }

    input {
      height: 35px;
      border-color: #dfdfdf;
      border-radius: 3px;
    }

    .ant-select {
      width: 230px;

      .ant-select-selector {
        height: 35px;
        border-color: #dfdfdf;
        border-radius: 3px;
      }
    }

    .Column1 {
      width: 230px;
      margin-right: 20px;
    }

    .Column2 {
      width: 300px;
      margin-right: 20px;
    }

    .Column3 {
      width: 300px;
    }
  }
}

.ModalEditTracking {
  .CheckboxLockTracking {
    color: #555;

    input[type="checkbox"] {
      display: none;

      ~ i {
        color: #AAA;
        margin:-3px 3px 0 0;
        display: inline-block;
        vertical-align: middle;
        font-size: 90%;
      }

      &:checked ~ i {
        color: #0a6ebd;
        font-weight: bold;
      }
    }

    margin: 5px 12px;
  }
}