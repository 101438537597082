.ProductImagesItem {
  position: relative;
  padding: 10px;

  .ProductImageView {
    width: 100%;
    height: 100%;
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
  }

  .RemoveImage {
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 2px;
  }
}
