.PlatformStatus {

    .StatusWrapper {
        position: relative;
        width: fit-content;

        .StatusButton {
            padding: .375rem .75rem;
            background-color: #fff;
            border-radius: 4px;
            z-index: 101;
            border: 1px solid rgba(33, 43, 54, .1);
            outline: none;

            &:hover {
                background-color: rgba(33, 43, 54, .1);
            }
        }

        .StatusList {
            position: absolute;
            margin: .5rem 0 0;
            border-radius: 4px;
            border: 1px solid #ccc;
            padding: .375rem 0;
            background: #fff;
            z-index: 101;
            transform: scale(0);
            transition: all .1s linear;
            width: max-content;

            li {
                padding: .375rem .75rem;
                cursor: pointer;

                &:hover {
                    background: #0f9d58;
                    color: #fff;
                }
            }
        }

        .Show {
            transform: scale(1);
        }
    }

    .Background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        opacity: 0;
    }
}
