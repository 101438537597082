.PackageIssuesContainer {
    .MenuTitle {
        font-weight: 600;
        font-size: 1.75rem;
        border-bottom: 1px solid #dfe3e8;
        padding-bottom: 0.5rem;
    }

    .SectionInner {
        .Group.ant-select {
            .ant-select-selector {
                min-height: 40px;
                border-top-left-radius: 5px;
                border-top-right-radius: 0;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 0;
                align-items: center;

                .ant-input-affix-wrapper {
                    min-height: 40px;
                    border-radius: 5px;
                }
            }
        }

        .ant-input-group .ant-input-affix-wrapper {
            min-height: 40px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            align-items: center;
        }

        .ant-select {
            .ant-select-selector {
                min-height: 40px;
                max-height: 144px;
                overflow: auto;
                border-radius: 5px;
                align-items: center;

                .ant-input-affix-wrapper {
                    min-height: 40px;
                    border-radius: 5px;
                }
            }
        }

        .ant-picker.ant-picker-range {
            min-height: 40px;
            border-radius: 5px;
            align-items: center;
        }

        .ProductType .ant-input-affix-wrapper {
            min-height: 40px;
            border-radius: 5px;
            align-items: center;
        }

        .CreatedAt::before {
            // content: 'Created at:';
            /* font-size: 12px; */
            margin-right: 10px;
            display: inline-block;
            min-width: 70px;
            color: #bfbfbf;
        }

        .refunded-tag {
            display: inline-block;
            height: 24px;
            line-height: 24px;
            position: relative;
            margin: 10px 0 0 10px;
            padding: 0 10px 0 12px;
            background: #40a73f;
            border-bottom-right-radius: 3px;
            border-top-right-radius: 3px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
            color: #fff;
            font-size: 11px;
            text-decoration: none;
            text-shadow: 0 1px 2px rgba(0, 0, 0, .2);
            font-weight: 400;

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: -12px;
                width: 0;
                height: 0;
                border-color: transparent #40a73f transparent transparent;
                border-style: solid;
                border-width: 12px 12px 12px 0;
            }

            &::after {
                content: "";
                position: absolute;
                top: 10px;
                left: 1px;
                float: left;
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: #fff;
                box-shadow: -1px -1px 2px rgba(0, 0, 0, .4);
            }
        }
    }

    .HiddenProductTypes {
        max-height: 98px;
        overflow: hidden;
    }

    .TotalEntity {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .Entity {
            font-weight: 700;
        }
    }

    .table-wrapper {
        overflow: auto
    }

    .PackageIssuesTable {
        overflow: auto;
        font-size: 0.9rem;

        td .NoteHover:hover {
            color: #0f9d58;
        }

        .fixed-column0,
        .fixed-column1,
        .fixed-column2,
        .fixed-column3,
        .fixed-column-right {
            // background-color: #f2f2f2;
            position: sticky;
            z-index: 1;
        }

        // .fixed-column1 {
        //     left: 0;
        // }

        // .fixed-column2 {
        //     left: 135px;
        // }

        // .fixed-column3 {
        //     left: 0;
        // }

        .fixed-column-right {
            right: 0;
        }

        .scrollable {
            overflow-x: auto;
        }

        table {

            border-collapse: separate;
            border-spacing: 0px;
            width: 100%;
        }

        th,
        td {
            // border: 1px solid #dddddd;
            text-align: left;
            padding: 0.7rem;
            white-space: nowrap;
            // vertical-align: top;
        }

        th.fixed-column0,
        td.fixed-column0 {
            // background-color: #f2f2f2;
            position: sticky;
            left: 0;
            // z-index: 2;
        }

        th.fixed-column0 {
            z-index: 2;
        }

        th.fixed-column1,
        td.fixed-column1 {
            // background-color: #f2f2f2;
            position: sticky;
            left: 0;
            // z-index: 2;
        }

        th.fixed-column2,
        td.fixed-column2 {
            // background-color: #f2f2f2;
            position: sticky;
            left: 135px;
            // z-index: 2;
        }

        th.fixed-column3,
        td.fixed-column3 {
            // background-color: #f2f2f2;
            position: sticky;
            left: 301px;
            // z-index: 2;
            border-right: 1px solid #dddddd;
        }

        th.fixed-column-right,
        td.fixed-column-right {
            // background-color: #f2f2f2;
            position: sticky;
            right: 0;
            // z-index: 2;
            border-left: 1px solid #dddddd;
        }

        td.fixed-column0,
        td.fixed-column1,
        td.fixed-column2,
        td.fixed-column3,
        td.fixed-column-right {
            background-color: #fff;
        }

        td {
            .status.confirmed {
                padding: 5px 10px;
                background: #268fff;
                color: #fff;
                border-radius: 10px;
                min-width: 80px;
            }

            .status.checking {
                padding: 5px 10px;
                background: #fd9e04;
                color: #4e3101;
                border-radius: 10px;
                min-width: 80px;
            }

            .status.approved {
                padding: 5px 10px;
                background: #ffd042;
                color: #534416;
                border-radius: 10px;
                min-width: 80px;
            }

            .status.pending {
                padding: 5px 10px;
                background: #34adc1;
                color: #fff;
                border-radius: 10px;
                min-width: 80px;
            }

            .status.done {
                padding: 5px 10px;
                background: #a4a0a0;
                color: #fff;
                border-radius: 10px;
                min-width: 80px;
            }

            .status.closed {
                padding: 5px 10px;
                background: #d8d6d6;
                color: #fff;
                border-radius: 10px;
                min-width: 80px;
            }

            .supplierConfirm.reject {
                background: #fb5b5b;
                color: #fff;
                padding: 7px 20px;
                border-radius: 10px;
                min-width: 90px;
            }

            .supplierConfirm.none {
                background: #bebdbd;
                color: #fff;
                padding: 7px 20px;
                border-radius: 10px;
                min-width: 90px;
            }

            .supplierConfirm.approve {
                background: #4bb682;
                color: #fff;
                padding: 10px 20px;
                border-radius: 10px;
                min-width: 90px;
            }

            .supplierConfirm.checking {
                background: #ffd042;
                padding: 10px 20px;
                border-radius: 10px;
                min-width: 90px;
            }

            .supplierConfirm.half {
                background: #fdb2b2;
                padding: 10px 20px;
                border-radius: 10px;
                color: #f64545;
                width: 90px;
            }

            .pushedAt,
            .error_qty {
                color: #a9a5a5;
                font-size: 14px;
            }

            .SupplierName {
                min-width: 40px;
            }
        }

        .colNote {
            .note {
                word-break: break-word;
                width: 93px;
                white-space: normal;
                max-height: 82px;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }

        .BulkActions {
            display: flex;
            align-items: baseline;

            .Actions {
                i {
                    position: relative;
                    margin-left: 5px;
                    z-index: 10;

                    &:hover {
                        cursor: pointer;
                        color: #0f9d58;
                    }
                }

                .ListActions {
                    display: none;
                    position: absolute;
                    width: 250px;
                    background: #fff;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    border-radius: 0.25rem;
                    margin: 0;
                    padding: 0;
                    z-index: 10;

                    li {
                        color: #333;
                        list-style: none;
                        padding: 10px 15px;
                        font-size: 15px;
                        font-weight: 400;
                        border-bottom: 1px solid #ddd;

                        &:hover {
                            cursor: pointer;
                            color: #0f9d58;
                        }
                    }
                }

                .Disable li {
                    pointer-events: none;
                    color: #adb5bd;
                }

                .Background {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    opacity: 0;
                    z-index: 9;
                }
            }
        }
    }

    .Store {
        font-size: 15px;
        color: #e83e8c;
        word-wrap: break-word;
    }
}

.ant-popover-inner-content {
    word-break: break-word;
}

.ApproveIssueModal {
    .ant-select {
        .ant-select-selector {
            min-height: 40px;
            max-height: 144px;
            overflow: auto;
            border-radius: 5px;
            align-items: center;

            .ant-input-affix-wrapper {
                min-height: 40px;
                border-radius: 5px;
            }
        }
    }
}

.IssuesHistoryModal {
    .ant-modal-body {
        max-height: 431px;
        overflow: auto;

        ul {
            li {
                margin-bottom: 20px;
            }
        }
    }
}

.ImportModal {
    .ant-modal-content {
        padding: 24px;
    }

    .ant-modal-header {
        border-bottom: 1px solid #f0f0f0;
        padding: 0 0 20px;
        margin: 0 0 20px !important;
    }

    .ant-modal-body {
        padding: 0;

        .modal-loading {
            text-align: center;
        }
    }

    .ant-modal-footer {
        padding: 20px 0 0;
        border-top: 1px solid #f0f0f0;
    }

    .noti-error {
        display: block;
        margin: 0 0 20px;

        .anticon-warning {
            color: red;
        }
    }

    .ImportIssueShipment {
        .AttachFile {
            input {
                display: none;
            }

            label {
                display: block;
                width: 100%;
                text-align: center;
                padding: 15px 25px;
                margin-right: 30px;
                border: 1px dashed #333;

                i {
                    font-size: 14px;
                    margin-right: 5px;
                }

                &:hover {
                    cursor: pointer;
                    border-color: #0f9d58;
                    color: #0f9d58;
                }
            }
        }

        .Upload {
            .Title {
                font-size: 14px;
            }

            .FileTemplate {
                p {
                    font-style: italic;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: #64666b;
                    margin-bottom: 16px;
                }
            }
        }

        .ListFileInput {
            margin-top: 30px;

            .file-item {
                display: flex;
                justify-content: space-between;

                .Left {
                    flex-grow: 1;
                }
            }
        }
    }
}

.ListRows {
    .ant-tabs-content-holder {
        overflow: hidden;
        overflow-x: auto;
    }
}

.disableBulk{
    pointer-events: none;
    color: #adb5bd !important;
}