.PlatformApiKeys {
    .Title {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    .ApiKeysTable {
        overflow: auto;
        max-height: 500px;
    }
}
