.ProductVariantsHead {
  th.Price {
    width: 150px;
  }

  th.Quantity {
    width: 100px;
  }

  th.Color {
    width: 150px;
  }

  th.Size {
    width: 100px;
  }

  th.AdditionalPrice {
    width: 170px;
  }
}
