.SuppliersTableRow {
    i {
        cursor: pointer;
    }
}

.FiltersSuppliers {
    .Name {
        .ant-select {
            width: 150px !important;
        }

        .Heading {
            font-size: unset;
        }
    }

    .site-input-split {
        background-color: #fff;
    }

    .site-input-right {
        border-left-width: 0;
    }

    .site-input-right:hover,
    .site-input-right:focus {
        border-left-width: 1px;
    }

    .ant-input-rtl.site-input-right {
        border-right-width: 0;
    }

    .ant-input-rtl.site-input-right:hover,
    .ant-input-rtl.site-input-right:focus {
        border-right-width: 1px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
}

.input-currency-box{
    position: relative;
    .currency{
        display: block;
        width: 30px;
        height: 38px;
        background: #ced4da;
        text-align: center;
        line-height: 38px;
        border-radius: 5px 0 0 5px;
        position: absolute;
        top: 0;
        left: 0;
    }

    input{
        padding-left: 40px;
    }
}

.ModalNewSupplier, .ModalEditSupplier{
    .customSelect{
        .ant-select-selector{
            height: 38px !important;
            border-radius: 4px !important;
            .ant-select-selection-item{
                line-height: 36px !important;
            }
        }
    }
    @media (min-width: 576px){
        max-width: 680px !important; 
    }
}