.OrdersTable {
    @media (max-width: 1600px) {
        overflow-x: scroll;
        min-height: 300px;
        .table {
            width: auto;
            width: -moz-max-content;
            width: max-content;
        }
    }

    .product-item, .product-item:hover {
        color: unset;
    }

    .TotalItems {
        float: right;
        display: inline-block;
        margin: 15px 0;
        font-weight: 700;
        color: #313435;
    }

    .OrderNote {
        width: 200px;
        word-break: break-all;
    }

    .table .thead-light th {
        vertical-align: middle;
    }
}

.Table {
    border-collapse: collapse;
    border-radius: 6px 6px 0 0;
}

.TableHead {
    > tr {
        > th {
            background: #fafafa;
            color: #535353;
            border-bottom: 1px solid #e8e8e8;
        }
    }
}

.remove-tags-title{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;

    label{
        flex-grow: 1;
    }
}

.ant-popover.note-order-popover{
    width: auto !important;
    .ant-popover-inner-content{
        max-width: 400px;
        max-height: 600px;
        overflow-y: auto;
    }
}

