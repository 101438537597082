.OrderFulfillmentStatus {
    &.unfulfilled {
        > .badge {
            background-color: #ffc107;
            color: #212529;
        }
    }

    &.partial {
        > .badge {
            background-color: #ffc58b;
            color: #212b36;
        }
    }
}
