.BulkSelectOrder {
    vertical-align: middle !important;

    .Message {
        position: fixed;
        width: 400px;
        left: 50%;
        margin-left: -200px;
        top: 100%;

        .Inner {
            background-color: rgba(0, 0, 0, .8);
            color: #fff;
            min-height: 100px;
            transform: translateY(-120%);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            font-size: 18px;
            font-weight: 400;
        }
    }

    .ToggleActions {
        font-size: 14px;
    }

    .MenuActions {
        top: 0;
        bottom: 0;
        left: 40px;
        width: calc(100vw - 217px);
        background-color: #e9ecef;
        display: flex;
        align-items: center;
    }
}

.contentResults {
    max-height: 250px;
    overflow-y: scroll;
    label {
        font-size: 16px;
    }
}

.listErrorsExportSheet {
    max-height: 250px;
    overflow-y: scroll;
}