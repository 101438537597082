.ShippingLabelDetails {
  .ShippingAddress {
    background-color: #e9ecef;

    .AddressStatus {
      font-size: 30px;
    }
  }

  .Items {
    ul {
      font-size: .875em;
      list-style: initial;
      margin-bottom: 16px;
      padding-left: 23px;
    }
  }
}

.ShipingMethodsActions {
  cursor: pointer;
}

.ShippingZoneCountries {
  .ShippingZoneListCountries {
    button {
      margin-top: 15px;
    }
    @media (min-width: 576px) {
      display: flex;
      button {
        margin-top: 0;
        width: 300px;
        margin-left: 30px;
      }
    }
  }
}