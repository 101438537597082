.ManageAutoMapContainer {
    .ManageAutoMapPage {
        .CustomSelect {
            .ant-select {
                width: 100px;
                margin-right: 24px;

                .ant-select-selector {
                    height: 40px;
                    border-radius: 4px;

                    .ant-select-selection-item {
                        line-height: 40px;
                    }
                }
            }
        }

        .RuleTable {
            table {
                width: 100%;
                border-bottom: 1px solid #dddddd;
                margin-bottom: 15px;

                .RuleThead {
                    tr {
                        th {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 22px;
                            color: #303238;
                            padding: 16px;
                            border-top: 1px solid #D8DBDE;
                            background: #F4F6F8;
                        }
                    }

                    .PackageChangeStatus {
                        display: flex;
                        align-items: baseline;

                        .Actions {
                            position: relative;

                            i {
                                position: relative;
                                margin-left: 5px;
                                z-index: 10;

                                &:hover {
                                    cursor: pointer;
                                    color: #0f9d58;
                                }
                            }

                            .Background {
                                position: fixed;
                                top: 0;
                                left: 0;
                                width: 100vw;
                                height: 100vh;
                                opacity: 0;
                                z-index: 9;
                            }

                            .ListActions {
                                display: none;
                                position: absolute;
                                width: 148px;
                                background: #fff;
                                border: 1px solid rgba(0, 0, 0, .15);
                                border-radius: 0.25rem;
                                margin: 0;
                                padding: 0;
                                z-index: 10;

                                li {
                                    color: #333333;
                                    list-style: none;
                                    padding: 10px 15px;
                                    font-size: 15px;
                                    font-weight: normal;
                                    border-bottom: 1px solid #dddddd;

                                    &:last-child {
                                        border-bottom: 0;
                                    }

                                    &:hover {
                                        color: #0f9d58;
                                        cursor: pointer;
                                    }
                                }

                                &.IsOpen {
                                    display: block;
                                }

                                &.Disable {
                                    li {
                                        pointer-events: none;
                                        color: #adb5bd;;
                                    }
                                }
                            }
                        }
                    }
                }

                .RuleBody {
                    tr {
                        td {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 22px;
                            color: #303238;
                            padding: 16px;
                            border-top: 1px solid #D8DBDE;

                            .MappingRuleDetail {
                                span {
                                    cursor: pointer;
                                    color: #007BFF;

                                    &:hover {
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }

                .ProductTitle {
                    width: 400px;
                }
            }
        }

        .noData {
            padding: 90px 0;
        }

        .Total {
            text-align: right;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: #303238;
            margin-bottom: 19px;

            span {
                text-align: right;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                color: #303238;
                margin-bottom: 19px;
            }
        }
    }
}

.MappingRuleModal {
    width: 76% !important;

    .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt:hover .Hover {
        right: -190%;
    }

    .table {
        tbody {
            tr {
                td {
                    vertical-align: top;
                }
            }
        }
    }

    .Information {
        margin-bottom: 24px;
    }

    .ant-modal-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span.text-danger.cursor-pointer {
            background: #DF1F1F;
            border-radius: 4px;
            padding: 8px 32px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #FFFFFF !important;
        }
    }
}

.ManageAutoMapPage {
    @media (max-width: 1536px) {
        .CustomMultiSelect .InputSelect {
            min-width: 200px;
        }

        .MultiFilterInput .MultiFilter .ant-select .ant-select-selector {
            min-width: 200px;
        }

        .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
            width: 300px;
        }
    }

    @media (max-width: 1440px) {
        .CustomMultiSelect .InputSelect {
            min-width: 200px;
        }

        .MultiFilterInput .MultiFilter .ant-select .ant-select-selector {
            min-width: 200px;
        }

        .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
            width: 300px;
        }

        .MultiFilterInput .MultiFilter .Right input {
            width: 300px;
        }
    }

    @media (max-width: 1367px) {
        .CustomMultiSelect .InputSelect {
            min-width: 200px;
        }

        .MultiFilterInput .MultiFilter .ant-select .ant-select-selector {
            min-width: 200px;
        }

        .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
            width: 300px;
        }
    }

}
