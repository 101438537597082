.ShippingZonesPageContainer{
    .ShippingZonesHeader{
        h1.PageTitle{
            margin:12px 0 0 0; 
            font-size:20px;
            width:100%;
        }
        .BtnAddZone{
            margin:27px 0 10px 0;
            background: #477BF6;
            width: 123px;
            height: 40px;
            border-radius: 4px;
        }
    }
    .ShippingZonesPage{
        .SectionInner{

            .filterSelect{
                width: 200px;
                margin: 0 0 0 22px;
            }
        }
        .tableWrapResponsive{
            table tbody tr td.ShippingZoneSupplier{
                width: 13%;
            }
            table tbody tr td.ShippingZoneTitle{
                width: 9%;
            }
            table tbody tr td.ShippingZoneCountries{
                width: 44%
            }
            table tbody tr td.ShippingZoneCountries span{
                // width: 800px; 
                word-break: break-all;
            }
            table tbody tr td.action{
                // width: 9%;
                a.edit{ color: #477BF6;}
                a.delete{color: #df1f1f;}
            }
        }
    }
}
.HiddenCountry{
    max-height: 90px;
    overflow: hidden;
}