@import 'OrderFulfillmentContainer';
@import "./OrderFulfillmentProduct";
@import "OrderFulfilmentSelectItem";

.fetch-production-status {
  > * {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  .anticon-spin {
    animation: loadingCircle 3s linear infinite;
    font-size: 20px;
  }

  span{
    color: #666;
  }
}