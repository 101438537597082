.OrderPreferenceShipping {
  .Title {
    margin-bottom: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #303238;
  }

  .Row {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #7A8087;

    .Label {
      margin-right: 10px;
      text-transform: uppercase;
    }

    .Value {

    }
  }
}
