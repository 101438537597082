.PackageSplittingRuleCreateContainer {
    .MenuTitle {
        font-weight: 600;
        font-size: 1.75rem;
        border-bottom: 1px solid #dfe3e8;
        padding-bottom: 0.5rem;
    }

    .BackWrapper {
        .BackButton {
            display: inline-block;
            cursor: pointer;
            padding-left: 0;
            padding-right: 0;

            span {
                margin: 0 1rem;
            }
        }
    }

    .SectionInner {
        .ant-select {
            .ant-select-selector {
                min-height: 40px;
                max-height: 144px;
                overflow: auto;
                border-radius: 5px;
                align-items: center;

                .ant-input-affix-wrapper {
                    min-height: 40px;
                    border-radius: 5px;
                }
            }
        }

        .ant-picker.ant-picker-range {
            min-height: 40px;
            border-radius: 5px;
            align-items: center;
        }

        .Priority .ant-input-affix-wrapper {
            min-height: 40px;
            border-radius: 5px;
            align-items: center;
        }
        textarea {
            min-height: 40px;
            border-radius: 5px;
            align-items: center;
        }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    .History:hover {
        color: #0f9d58;
    }
}