@charset "UTF-8";
.CreatePackageIssueContainer .BackWrapper .BackButton {
  display: inline-block;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0; }
  .CreatePackageIssueContainer .BackWrapper .BackButton span {
    margin: 0 1rem; }

.CreatePackageIssueContainer .MenuTitle {
  font-weight: 600;
  font-size: 1.75rem;
  border-bottom: 1px solid #dfe3e8;
  padding-bottom: 0.5rem; }

.CreatePackageIssueContainer .SectionInner .ant-select .ant-select-selector {
  min-height: 40px;
  max-height: 144px;
  overflow: auto;
  border-radius: 5px;
  align-items: center; }
  .CreatePackageIssueContainer .SectionInner .ant-select .ant-select-selector .ant-input-affix-wrapper {
    min-height: 40px;
    border-radius: 5px; }

.CreatePackageIssueContainer .SectionInner .ant-picker.ant-picker-range {
  min-height: 40px;
  border-radius: 5px;
  align-items: center; }

.CreatePackageIssueContainer .SectionInner .ant-input-affix-wrapper {
  min-height: 40px;
  border-radius: 5px;
  align-items: center; }

.CreatePackageIssueContainer .SectionInner textarea {
  min-height: 40px;
  border-radius: 5px;
  align-items: center; }

.IssueItemsTable .pushedAt {
  color: #a9a5a5;
  font-size: 14px; }

.IssueItemsTable textarea {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */ }

.IssueItemsTable textarea::-webkit-scrollbar {
  display: none;
  /* Safari và Chrome */ }

.SelectItemsModal .ant-modal-content .ant-modal-body {
  min-height: 300px; }

.SelectItemsModal .modalContent {
  max-height: 500px;
  width: 100%;
  overflow: auto; }
  .SelectItemsModal .modalContent .BulkActions {
    display: flex;
    align-items: baseline; }
    .SelectItemsModal .modalContent .BulkActions .Actions i {
      position: relative;
      margin-left: 5px;
      z-index: 10; }
      .SelectItemsModal .modalContent .BulkActions .Actions i:hover {
        cursor: pointer;
        color: #0f9d58; }
    .SelectItemsModal .modalContent .BulkActions .Actions .ListActions {
      display: none;
      position: absolute;
      width: 250px;
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 0.25rem;
      margin: 0;
      padding: 0;
      z-index: 10; }
      .SelectItemsModal .modalContent .BulkActions .Actions .ListActions li {
        color: #333;
        list-style: none;
        padding: 10px 15px;
        font-weight: 400;
        border-bottom: 1px solid #ddd; }
        .SelectItemsModal .modalContent .BulkActions .Actions .ListActions li:hover {
          cursor: pointer;
          color: #0f9d58; }
    .SelectItemsModal .modalContent .BulkActions .Actions .Disable li {
      pointer-events: none;
      color: #adb5bd; }
    .SelectItemsModal .modalContent .BulkActions .Actions .Background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      opacity: 0;
      z-index: 9; }

.SelectItemsModal .table {
  border-collapse: separate;
  border-spacing: 0px;
  min-width: 854px; }
  .SelectItemsModal .table thead th {
    background: #f9fafb;
    border-top: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6; }
  .SelectItemsModal .table tbody td {
    border-bottom: 1px solid #dee2e6; }
    .SelectItemsModal .table tbody td .referenceNumber .referenceNumberText {
      position: relative; }
      .SelectItemsModal .table tbody td .referenceNumber .referenceNumberText .referenceNumberImage {
        display: inline-block;
        width: 110px;
        position: absolute;
        top: 0;
        left: 100%;
        transform: translate(10px, -15px);
        opacity: 0;
        visibility: hidden;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        padding: 5px; }
      .SelectItemsModal .table tbody td .referenceNumber .referenceNumberText:hover {
        cursor: pointer; }
        .SelectItemsModal .table tbody td .referenceNumber .referenceNumberText:hover .referenceNumberImage {
          opacity: 1;
          visibility: visible; }
  .SelectItemsModal .table thead th {
    top: 0;
    background-color: #ffffff;
    z-index: 1; }
  .SelectItemsModal .table tr td {
    vertical-align: top; }

.SelectItemsModal .ant-select .ant-select-selector {
  min-height: 40px;
  max-height: 144px;
  overflow: auto;
  border-radius: 5px;
  align-items: center; }
  .SelectItemsModal .ant-select .ant-select-selector .ant-input-affix-wrapper {
    min-height: 40px;
    border-radius: 5px; }

.SelectItemsModal .ant-input-affix-wrapper {
  min-height: 40px;
  border-radius: 5px;
  align-items: center; }

.SelectItemsModal textarea {
  min-height: 40px;
  border-radius: 5px;
  align-items: center; }

.SelectItemsModal textarea {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */ }

.SelectItemsModal textarea::-webkit-scrollbar {
  display: none;
  /* Safari và Chrome */ }

.custom-input-partnerConfirm {
  min-height: 40px;
  max-height: 144px;
  overflow: auto;
  border-radius: 4px !important;
  align-items: center; }
  .custom-input-partnerConfirm .ant-select-selector {
    min-height: 40px;
    max-height: 144px;
    overflow: auto;
    border-radius: 4px !important;
    align-items: center; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }

.showMoreGroupModal .ant-modal-content .ant-modal-body {
  min-height: 300px; }

.showMoreGroupModal .modalContent {
  max-height: 500px;
  width: 100%;
  overflow: auto; }

.HiddenReferenceNumber {
  max-height: 48px;
  overflow: hidden; }
