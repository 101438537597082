.Supplier {
    width: 300px;

    label {
        font-size: .875rem;
    }
}

.PackageStatus {
    label {
        font-size: .875rem;
    }
}