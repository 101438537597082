.FulfilledPackageItemMap {
  .col-12 {
    margin-top: 30px;

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 16px;
      color: #000000;
      margin-bottom: 15px;
    }
  }

  .DimensionItem {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 16px;
    background: #F4FAFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    .RuleItem {
      display: flex;
      align-items: center;
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      .Side {
        margin-right: 3px;
      }

      .Width {

      }

      .And {

      }

      .Height {

      }
    }
  }
}
