.ProductLineItems {
  .Wrapper {
    display: grid;
    grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
    margin: 0 -10px;
  }

  .ProductLineItem {
    padding: 0 10px;
    transition: all .3s;
    word-break: break-word;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-left: 5px;
    margin-right: 5px;

    &:hover {
      background-color: rgba(15, 157, 88, .4);
    }
  }
}
