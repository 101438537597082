/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url(~react-draft-wysiwyg/dist/react-draft-wysiwyg.css);
@import url(~font-awesome/css/font-awesome.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #007bff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #80bdff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff;
    border-color: #b3d7ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #007bff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b3d7ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #0062cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #004085;
    background-color: #9fcdff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

html, html a, body {
  -webkit-font-smoothing: antialiased; }

a {
  transition: all 0.3s ease-in-out; }

body {
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #303238;
  line-height: 1.5;
  letter-spacing: 0.2px;
  overflow-x: hidden; }

h1, h2, h3, h4, h5, h6 {
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 0.5px;
  line-height: 1.5; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    font-weight: 300;
    color: #3d4142; }

p {
  font-family: Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
  line-height: 1.9; }

.lead {
  font-size: 18px; }

ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

a {
  color: #007bff; }
  a:hover, a:focus {
    text-decoration: none;
    color: #0062cc; }
  a:focus {
    outline: none; }
  a.text-gray:hover, a.text-gray:focus, a.text-gray.active {
    color: #313435 !important; }

:focus {
  outline: none; }

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.0625); }

img {
  max-width: 100%;
  height: auto; }

.LabelFilter {
  align-items: baseline;
  font-size: .875rem;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }
  .LabelFilter label {
    min-width: 200px; }
  .LabelFilter ul.FilterItems {
    margin-left: 30px; }
    .LabelFilter ul.FilterItems > li {
      border: solid 1px rgba(0, 0, 0, 0.0625);
      display: inline-block;
      cursor: pointer;
      transition: all .3s ease; }
      .LabelFilter ul.FilterItems > li:first-child {
        border-radius: 4px 0 0 4px; }
      .LabelFilter ul.FilterItems > li:last-child {
        border-radius: 0 4px 4px 0; }
      .LabelFilter ul.FilterItems > li:not(:first-child) {
        margin-left: -1px; }
      .LabelFilter ul.FilterItems > li.active {
        border-color: #0f9d58;
        color: #0f9d58;
        position: relative;
        z-index: 2; }

@media (max-width: 991.98px) {
  .LabelFilter ul.FilterItems {
    padding-left: 15px; } }

@media (max-width: 575.98px) {
  .LabelFilter {
    flex-direction: column; }
    .LabelFilter label {
      margin-bottom: 10px; }
    .LabelFilter ul.FilterItems {
      padding: 0;
      margin: 0; } }

.Pagination {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #dee2e6;
  padding-top: 32px; }
  .Pagination .pagination {
    flex-wrap: wrap; }
  .Pagination .page-item, .Pagination .break {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 5px;
    transition: all 0.2s ease-in-out; }
  .Pagination .page-item.active .page-link {
    color: #fff !important;
    background: #007bff; }
  .Pagination .page-item.disabled {
    pointer-events: none;
    opacity: .4; }
  .Pagination .page-link {
    border: none;
    color: #303238 !important;
    padding: 6px 12px;
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    box-shadow: none; }
    .Pagination .page-link:hover {
      color: #fff !important;
      background: #007bff; }

.PageTitle {
  font-size: 1.75rem;
  font-weight: 500; }

@media (max-width: 991.98px) {
  .PageTitle {
    padding: 0 15px; } }

.spin {
  display: inline-block;
  margin-left: 10px;
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 2px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.table-loading-placeholder {
  display: inline-block;
  animation: table-loading-placeholder 1s infinite ease-in-out; }

@keyframes table-loading-placeholder {
  0% {
    background-color: rgba(165, 165, 165, 0.1); }
  50% {
    background-color: rgba(165, 165, 165, 0.3); }
  100% {
    background-color: rgba(165, 165, 165, 0.1); } }

@keyframes blink {
  0% {
    opacity: .2; }
  20% {
    opacity: 1; }
  100% {
    opacity: .2; } }

.ReturnPage .BackButton {
  display: inline-block;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0; }
  .ReturnPage .BackButton span {
    margin: 0 1rem;
    position: relative; }

input.Input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ced4da;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  padding: .375rem .75rem;
  outline-offset: -2px; }
  input.Input:focus {
    border-color: #37c936;
    box-shadow: 0 0 0 3px rgba(55, 201, 54, 0.25); }
  input.Input:disabled {
    background-color: #e9ecef;
    opacity: 1; }

.FocusOutline:focus {
  border-color: #37c936;
  box-shadow: 0 0 0 3px rgba(55, 201, 54, 0.25); }

.Button {
  background-color: #0f9d58;
  color: #fff;
  border: 1px solid #0f9d58; }
  .Button:hover {
    color: #0f9d58;
    background-color: #fff; }
  .Button:focus {
    box-shadow: 0 0 0 3px rgba(55, 201, 54, 0.25); }

.OutlineButton {
  background: #fff;
  color: #0f9d58;
  border: 1px solid #0f9d58; }
  .OutlineButton:hover {
    color: #0f9d58;
    background: #f2f2f2; }
  .OutlineButton:focus {
    box-shadow: 0 0 0 3px rgba(55, 201, 54, 0.25); }

.Tooltip1 {
  /* Positioning and visibility settings of the tooltip */
  /* The actual tooltip with a dynamic width */
  /* Tooltip arrow */
  /* Tooltip becomes visible at hover */
  /* Scales from 0.5 to 1 -> grow effect */
  /* Slide down effect only on mouseenter (NOT on mouseleave) */
  /* LEFT */
  /* Tooltip + arrow */
  /* Tooltip */
  /* Arrow */
  /* RIGHT */
  /* BOTTOM */ }
  .Tooltip1[data-tooltip] {
    position: relative;
    z-index: 10; }
  .Tooltip1[data-tooltip]:before, .Tooltip1[data-tooltip]:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    left: 50%;
    bottom: calc(100% + 5px);
    pointer-events: none;
    transition: 0.2s;
    will-change: transform; }
  .Tooltip1[data-tooltip]:before {
    content: attr(data-tooltip);
    padding: 10px 18px;
    min-width: 50px;
    max-width: 300px;
    width: max-content;
    width: -moz-max-content;
    border-radius: 6px;
    font-size: 14px;
    background-color: rgba(59, 72, 80, 0.9);
    background-image: linear-gradient(30deg, rgba(59, 72, 80, 0.44), rgba(59, 68, 75, 0.44), rgba(60, 82, 88, 0.44));
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
    color: #fff;
    text-align: center;
    white-space: pre-wrap;
    transform: translate(-50%, -5px) scale(0.5); }
  .Tooltip1[data-tooltip]:after {
    content: '';
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: rgba(55, 64, 70, 0.9) transparent transparent transparent;
    transition-duration: 0s;
    /* If the mouse leaves the element,
                                     the transition effects for the
                                     tooltip arrow are "turned off" */
    transform-origin: top;
    /* Orientation setting for the
                                   slide-down effect */
    transform: translateX(-50%) scaleY(0); }
  .Tooltip1[data-tooltip]:hover:before, .Tooltip1[data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1; }
  .Tooltip1[data-tooltip]:hover:before {
    transition-delay: 0.3s;
    transform: translate(-50%, -5px) scale(1); }
  .Tooltip1[data-tooltip]:hover:after {
    transition-delay: 0.5s;
    /* Starting after the grow effect */
    transition-duration: 0.2s;
    transform: translateX(-50%) scaleY(1); }
  .Tooltip1[data-tooltip-location="left"]:before, .Tooltip1[data-tooltip-location="left"]:after {
    left: auto;
    right: calc(100% + 5px);
    bottom: 50%; }
  .Tooltip1[data-tooltip-location="left"]:before {
    transform: translate(-5px, 50%) scale(0.5); }
  .Tooltip1[data-tooltip-location="left"]:hover:before {
    transform: translate(-5px, 50%) scale(1); }
  .Tooltip1[data-tooltip-location="left"]:after {
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent rgba(55, 64, 70, 0.9);
    transform-origin: left;
    transform: translateY(50%) scaleX(0); }
  .Tooltip1[data-tooltip-location="left"]:hover:after {
    transform: translateY(50%) scaleX(1); }
  .Tooltip1[data-tooltip-location="right"]:before, .Tooltip1[data-tooltip-location="right"]:after {
    left: calc(100% + 5px);
    bottom: 50%; }
  .Tooltip1[data-tooltip-location="right"]:before {
    transform: translate(5px, 50%) scale(0.5); }
  .Tooltip1[data-tooltip-location="right"]:hover:before {
    transform: translate(5px, 50%) scale(1); }
  .Tooltip1[data-tooltip-location="right"]:after {
    border-width: 5px 5px 5px 0;
    border-color: transparent rgba(55, 64, 70, 0.9) transparent transparent;
    transform-origin: right;
    transform: translateY(50%) scaleX(0); }
  .Tooltip1[data-tooltip-location="right"]:hover:after {
    transform: translateY(50%) scaleX(1); }
  .Tooltip1[data-tooltip-location="bottom"]:before, .Tooltip1[data-tooltip-location="bottom"]:after {
    top: calc(100% + 5px);
    bottom: auto; }
  .Tooltip1[data-tooltip-location="bottom"]:before {
    transform: translate(-50%, 5px) scale(0.5); }
  .Tooltip1[data-tooltip-location="bottom"]:hover:before {
    transform: translate(-50%, 5px) scale(1); }
  .Tooltip1[data-tooltip-location="bottom"]:after {
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent rgba(55, 64, 70, 0.9) transparent;
    transform-origin: bottom; }

.Tooltip2 {
  position: relative; }
  .Tooltip2Item {
    position: absolute;
    min-width: 250px;
    padding: 20px;
    visibility: hidden;
    opacity: 0;
    z-index: 4;
    zoom: 1.02;
    transition: all .3s; }
    .Tooltip2Item:after {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid; }
    .Tooltip2Item .ItemInner {
      border: 1px solid #cecece;
      border-radius: 3px;
      padding: 20px;
      background: white;
      transition: all 0.25s cubic-bezier(0, 0, 0.2, 1);
      box-shadow: 0 2px 1px #bcbcbc; }
  .Tooltip2Initiator {
    cursor: pointer;
    z-index: 5; }
  .Tooltip2[data-direction="left"] .Tooltip2Initiator:hover ~ .Tooltip2Item {
    transform: translate3d(0, -50%, 0);
    visibility: visible;
    opacity: 1; }
  .Tooltip2[data-direction="left"] .Tooltip2Item {
    top: 50%;
    right: calc(100% + 1em);
    transform: translate3d(15px, -50%, 0); }
    .Tooltip2[data-direction="left"] .Tooltip2Item:after {
      top: 50%;
      right: -0.5em;
      transform: translate3d(0, -50%, 0);
      border-width: 0.5em 0 0.5em 0.5em;
      border-color: transparent transparent transparent white;
      -webkit-filter: drop-shadow(1px 2px 1px #bcbcbc);
      filter: drop-shadow(1px 2px 1px #bcbcbc); }
  .Tooltip2[data-direction="bottom"] .Tooltip2Initiator:hover ~ .Tooltip2Item {
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
    opacity: 1; }
  .Tooltip2[data-direction="bottom"] .Tooltip2Item {
    top: 100%;
    left: 50%;
    transform: translate3d(-50%, -15px, 0); }
    .Tooltip2[data-direction="bottom"] .Tooltip2Item:after {
      top: 14px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      border-width: 0 0.5em 0.5em 0.5em;
      border-color: transparent transparent white transparent;
      -webkit-filter: drop-shadow(1px 2px 1px #bcbcbc);
      filter: drop-shadow(1px -1px 1px #bcbcbc); }
    .Tooltip2[data-direction="bottom"] .Tooltip2Item:hover {
      transform: translate3d(-50%, 0, 0);
      visibility: visible;
      opacity: 1; }

.Tooltip3 {
  position: relative; }
  .Tooltip3:hover .TooltipContent {
    opacity: 1;
    z-index: 5; }
  .Tooltip3 .TooltipContent {
    max-width: 300px;
    min-width: 200px;
    position: absolute;
    z-index: -1;
    opacity: 0;
    padding-top: 10px;
    transition: all .3s ease; }
    .Tooltip3 .TooltipContent.Clipboard {
      min-width: 140px; }
      .Tooltip3 .TooltipContent.Clipboard .ContentInner {
        padding: 1px 0 4px; }
    .Tooltip3 .TooltipContent::after {
      background: #6c757d;
      content: "";
      height: 10px;
      position: absolute;
      left: 50%;
      margin-left: -5px;
      transform: rotate(45deg);
      width: 10px; }
  .Tooltip3 .ContentInner {
    padding: 1em;
    background: #6c757d;
    box-shadow: 0 5px 25px 5px rgba(205, 210, 214, 0.8);
    box-sizing: border-box;
    color: #ffffff;
    font-size: .875rem;
    letter-spacing: 1px;
    border-radius: 4px;
    font-weight: 400;
    line-height: 14px; }
  .Tooltip3.Top .TooltipContent {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 0 10px 0; }
    .Tooltip3.Top .TooltipContent::after {
      bottom: 5px;
      left: 50%;
      margin-left: -5px; }
  .Tooltip3.Bottom .TooltipContent {
    top: 100%;
    left: 50%;
    transform: translateX(-50%); }
    .Tooltip3.Bottom .TooltipContent::after {
      top: 5px; }
  .Tooltip3.Right .TooltipContent {
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 0 0 10px; }
    .Tooltip3.Right .TooltipContent::after {
      left: 10px;
      margin-top: -5px;
      top: 50%; }
  .Tooltip3.Left .TooltipContent {
    right: calc(100% + 1.5rem);
    top: 50%;
    transform: translateY(-50%); }
    .Tooltip3.Left .TooltipContent::after {
      right: -5px;
      margin-top: -5px;
      top: 50%; }

.table-col-5 {
  width: 5%; }

.table-col-10 {
  width: 10%; }

.table-col-15 {
  width: 15%; }

.table-col-20 {
  width: 20%; }

.table-col-25 {
  width: 25%; }

.table-col-30 {
  width: 30%; }

.table-col-35 {
  width: 35%; }

.table-col-40 {
  width: 40%; }

.table-col-45 {
  width: 45%; }

.table-col-50 {
  width: 50%; }

.table-col-55 {
  width: 55%; }

.table-col-60 {
  width: 60%; }

.table-col-65 {
  width: 65%; }

.table-col-70 {
  width: 70%; }

.table-col-75 {
  width: 75%; }

.table-col-80 {
  width: 80%; }

.table-col-85 {
  width: 85%; }

.table-col-90 {
  width: 90%; }

.table-col-95 {
  width: 95%; }

.table-col-100 {
  width: 100%; }

.SectionHeading {
  font-weight: 500;
  font-size: 1.5rem; }

.cursor-pointer {
  cursor: pointer; }

.font-weight-500 {
  font-weight: 500; }

.AspectRatioBox {
  position: relative;
  width: 100%;
  padding-bottom: 100%; }
  .AspectRatioBox img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto; }

.UploadInput {
  position: relative; }
  .UploadInput input[type=file] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px); }

.ant-notification-notice-description {
  word-break: break-all; }

.LargePage {
  font-size: 25px; }
  .LargePage .PageTitle {
    font-size: 1.75em;
    margin-bottom: .5em; }
  .LargePage input[type=text], .LargePage button {
    height: 60px;
    font-size: 25px;
    padding: calc(.375 * 25px) calc(.75 * 25px); }
  .LargePage .ant-divider {
    font-size: 25px; }
  .LargePage .ant-input-clear-icon {
    font-size: 30px; }

@keyframes FadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 0.3; } }

@keyframes FadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

.processing-animation {
  animation: progress-bar-stripes 1s linear infinite;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  background-color: #007bff; }

.pending-animation {
  animation: progress-bar-stripes 1s linear infinite;
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
  background-color: #17a2b8; }

.tracking-modified {
  color: #fff;
  background-color: #6c757d;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }

.form-control:focus {
  box-shadow: none; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f9fafb; }

.table td {
  vertical-align: middle; }

table .pretty {
  margin-right: 0; }

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: none; }

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none; }

body .pretty input:checked ~ .state.p-primary label:after, body .pretty.p-toggle .state.p-primary label:after {
  background-color: #007bff !important; }

.custom-control-label {
  cursor: pointer; }

svg {
  vertical-align: initial; }

@media (min-width: 1025px) and (max-width: 1400px) {
  .OrdersPage .FiltersTop {
    flex-direction: column-reverse;
    align-items: flex-start !important; }
    .OrdersPage .FiltersTop .OrdersResetFilters {
      margin: 15px 0; }
    .OrdersPage .FiltersTop .Supplier {
      width: 220px; } }

.OrdersPage .TableFilter .InputSearch {
  padding: 0 12px 0 40px; }

.OrdersPage .TableFilter .InputWrapper > i {
  display: inline-block !important; }

.OrdersPage .OrderFilters > [class^="col"] {
  padding: 0 10px; }

.OrdersPage .Platform {
  width: 200px; }

.OrdersPage .QuickFilter {
  text-align: center;
  line-height: 30px;
  position: fixed;
  right: 0;
  top: 125px;
  z-index: 9;
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.16); }
  .OrdersPage .QuickFilter:hover {
    cursor: pointer; }
    .OrdersPage .QuickFilter:hover i {
      color: #00c853; }

.OrdersPage .OrderFilterPersonalized {
  opacity: 0;
  position: fixed;
  right: -300px;
  top: 0;
  bottom: 0;
  width: 300px;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px 0 0 8px;
  z-index: 99;
  transition: all 0.3s;
  overflow-y: auto; }
  .OrdersPage .OrderFilterPersonalized.Open {
    opacity: 1;
    right: 0; }
  .OrdersPage .OrderFilterPersonalized.Close {
    transition: all 0.3s; }
  .OrdersPage .OrderFilterPersonalized .HeadingFilter {
    padding: 23px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E5E5E5; }
    .OrdersPage .OrderFilterPersonalized .HeadingFilter h3.Heading {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: #303238;
      margin: 0;
      padding: 0; }
    .OrdersPage .OrderFilterPersonalized .HeadingFilter .Close:hover {
      cursor: pointer;
      color: #dc3545; }
  .OrdersPage .OrderFilterPersonalized .ContentFilter ul {
    margin: 0;
    padding: 0; }
    .OrdersPage .OrderFilterPersonalized .ContentFilter ul li {
      padding: 12px 16px;
      border-bottom: 1px solid #E5E5E5;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #000000; }
      .OrdersPage .OrderFilterPersonalized .ContentFilter ul li:hover, .OrdersPage .OrderFilterPersonalized .ContentFilter ul li.active {
        cursor: pointer;
        color: #0f9d58; }

.OrdersPage .BackgroundQuickFilter.Open {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 8; }

.OrdersPage .BackgroundQuickFilter.Close {
  display: none; }

.TrackingModal #tracking_name.error {
  border: 1px solid #f44336; }

@media screen and (max-width: 588px) {
  .FiltersTop .d-flex:first-child .mr-3 {
    margin-right: 0 !important;
    width: 100% !important; }
  .FiltersTop .d-flex:first-child .Supplier {
    width: 100%; }
  .FiltersTop .d-flex:first-child .Platform {
    width: 100%; }
  .FiltersTop .d-flex:first-child .ant-input-group-wrapper.ant-input-search {
    width: 100% !important; }
  .FiltersTop .d-flex:last-child {
    justify-content: space-between; }
  .OrderFilters .FilterOptions label.ant-radio-button-wrapper {
    margin-bottom: 5px; }
  .mb-3.row.align-items-end .col-auto.mb-3:nth-child(2) {
    width: 100% !important; }
  .mb-3.row.align-items-end .col-auto.mb-3:nth-child(3) {
    width: 100%; }
    .mb-3.row.align-items-end .col-auto.mb-3:nth-child(3) .ant-select.ant-select-single.ant-select-show-arrow {
      width: 100% !important; }
  .mb-3.row.align-items-end .col-auto.mb-3:nth-child(4) {
    width: 100%; }
    .mb-3.row.align-items-end .col-auto.mb-3:nth-child(4) .ShipmentStatus {
      padding: 0 !important; }
      .mb-3.row.align-items-end .col-auto.mb-3:nth-child(4) .ShipmentStatus .FilterOptions.ml-3 {
        margin-left: 0 !important; }
  .mb-3.row.align-items-end .col-auto.mb-3:nth-child(5) {
    width: 100%; }
    .mb-3.row.align-items-end .col-auto.mb-3:nth-child(5) .ShipmentStatus {
      padding: 0 !important; }
  .mb-3.row.align-items-end .col-auto.mb-3:nth-child(7) label.ant-radio-button-wrapper, .mb-3.row.align-items-end .col-auto.mb-3:nth-child(6) label.ant-radio-button-wrapper {
    margin-bottom: 5px; }
  .mb-3.row.align-items-end .col-auto.mb-3:last-child {
    width: 100% !important;
    margin-bottom: 0.5rem !important; }
    .mb-3.row.align-items-end .col-auto.mb-3:last-child .FilterOptions.ml-sm-3 {
      margin-left: 0 !important; }
  .row.mb-3.align-items-center .FilterDateRange {
    width: 100%; }
    .row.mb-3.align-items-center .FilterDateRange .d-sm-inline-flex.align-items-baseline {
      margin-right: 1rem !important; }
      .row.mb-3.align-items-center .FilterDateRange .d-sm-inline-flex.align-items-baseline .ant-picker.ant-picker-range {
        width: 100% !important; }
  .row.mb-3.align-items-center .ml-3.mb-3:nth-child(2) {
    width: 100% !important; }
  .row.mb-3.align-items-center .mb-3:nth-child(3) {
    width: 100% !important;
    margin-left: 1rem !important; }
  .SearchGroup {
    margin-right: 0.9rem !important; }
    .SearchGroup .ant-input-group.ant-input-group-compact {
      display: flex; }
    .SearchGroup .ant-select.ant-select-single.ant-select-show-arrow {
      width: auto !important; }
    .SearchGroup .ant-input-group-wrapper.ant-input-search {
      width: 100% !important; }
  .Left182, .Left646 {
    left: 145px !important; } }

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .FiltersTop {
    display: grid !important; }
    .FiltersTop .d-flex.flex-wrap.align-items-baseline {
      display: grid !important;
      grid-template-columns: repeat(auto-fill, minmax(200px, 2fr));
      grid-column-gap: 6px; }
    .FiltersTop .d-flex:first-child .mr-3 {
      margin-right: 0 !important;
      width: 100% !important; }
    .FiltersTop .d-flex:first-child .Supplier {
      width: 100%; }
    .FiltersTop .d-flex:first-child .Platform {
      width: 100%; }
    .FiltersTop .d-flex:first-child .ant-input-group-wrapper.ant-input-search {
      width: 100% !important; }
    .FiltersTop .d-flex:last-child {
      justify-content: space-between; }
  .SectionInner div.mb-3.row.align-items-end .col-auto.ml-auto.mb-3 {
    margin-left: 0 !important; }
  .SectionInner div.row.mb-3.align-items-center div.ml-sm-auto.ml-3.mb-3 {
    margin-left: 1rem !important; }
  .SectionInner div.row.mb-3.align-items-center .ml-3.mb-3:nth-child(1) .d-sm-inline-flex, .SectionInner div.row.mb-3.align-items-center .ml-3.mb-3:nth-child(2) .d-sm-inline-flex {
    flex-direction: column; } }

@media screen and (min-width: 481px) and (max-width: 1440px) {
  .SectionInner div.row.mb-3.align-items-center div.mb-3:nth-child(3) {
    margin-left: 1rem !important; }
  .SectionInner div.row.mb-3.align-items-center div.mb-3:nth-child(4) {
    margin-left: auto !important; } }

@media screen and (max-width: 466px) {
  .dropdown-menu.show {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(-40px, 40px, 0px) !important; }
  .DashboardContainer .MainContentInner .SectionInner .ShowTotal {
    display: block !important; } }

.tooltipLabel .ant-tooltip-content {
  min-width: 350px; }

.FulfillmentStatus.unfulfilled .Text {
  background-color: #ffc107;
  color: #212529; }

.OrderPaymentStatusColumn.Pending .Text {
  background-color: #007bff; }

.OrderPaymentStatusColumn.Refunded .Text {
  background-color: #f44336; }

.OrderTagsColumn .ListTags .TagItem {
  margin-left: 0.2rem; }
  .OrderTagsColumn .ListTags .TagItem:first-child {
    margin-left: 0; }

.OrdersTableRow {
  transition: all 200ms ease-out; }
  .OrdersTableRow.archived td, .OrdersTableRow.archived a, .OrdersTableRow.archived code {
    color: #637381; }
  .OrdersTableRow.archived .badge, .OrdersTableRow.archived i.fas {
    opacity: 0.6; }
  .OrdersTableRow.is_international .OrderAddress .Country {
    font-weight: bold; }
  .OrdersTableRow:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.01); }
  .OrdersTableRow:hover {
    background-color: #f9fafb; }
  .OrdersTableRow.fulfillment-fulfilled td {
    color: #637381; }
    .OrdersTableRow.fulfillment-fulfilled td a {
      color: #637381; }
  .OrdersTableRow .MappingCondition {
    text-align: center; }

.ant-popover.MappingConditionPopover {
  width: 500px !important; }
  .ant-popover.MappingConditionPopover .ant-popover-inner-content {
    display: flex;
    max-height: 300px;
    flex-direction: column; }
    .ant-popover.MappingConditionPopover .ant-popover-inner-content .OrderRowMappingContent {
      overflow: auto; }
      .ant-popover.MappingConditionPopover .ant-popover-inner-content .OrderRowMappingContent .MappingContentLine {
        margin-bottom: 10px; }

.MappingConditionPopover .CreatedAt {
  font-size: 100%;
  color: #666; }

.MappingConditionPopover .MappingConditionAction {
  color: #666;
  margin: 0 5px; }
  .MappingConditionPopover .MappingConditionAction:hover {
    color: #28a745; }
  .MappingConditionPopover .MappingConditionAction.Edit {
    float: right; }

.MappingConditionPopover .XXX {
  margin: 10px 0 0 0;
  color: #666;
  font-style: italic;
  font-size: small;
  line-height: 1; }

@media (max-width: 1600px) {
  .OrdersTable {
    overflow-x: scroll;
    min-height: 300px; }
    .OrdersTable .table {
      width: auto;
      width: -moz-max-content;
      width: max-content; } }

.OrdersTable .product-item, .OrdersTable .product-item:hover {
  color: unset; }

.OrdersTable .TotalItems {
  float: right;
  display: inline-block;
  margin: 15px 0;
  font-weight: 700;
  color: #313435; }

.OrdersTable .OrderNote {
  width: 200px;
  word-break: break-all; }

.OrdersTable .table .thead-light th {
  vertical-align: middle; }

.Table {
  border-collapse: collapse;
  border-radius: 6px 6px 0 0; }

.TableHead > tr > th {
  background: #fafafa;
  color: #535353;
  border-bottom: 1px solid #e8e8e8; }

.remove-tags-title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8; }
  .remove-tags-title label {
    flex-grow: 1; }

.ant-popover.note-order-popover {
  width: auto !important; }
  .ant-popover.note-order-popover .ant-popover-inner-content {
    max-width: 400px;
    max-height: 600px;
    overflow-y: auto; }

.OrderAddress table {
  border: none;
  border-collapse: collapse;
  background-color: transparent; }
  .OrderAddress table tr, .OrderAddress table td {
    border: none; }

.OrdersPage .ant-picker, .OrdersPage .ant-picker-range {
  width: 100%; }

.Left182 {
  left: 182px !important; }

.Left646 {
  left: 646px !important; }

.img-popover {
  max-width: 340px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 2px; }

.TopSellingProducts {
  margin-top: 30px; }
  .TopSellingProducts .Heading {
    font-size: 24px;
    margin-bottom: 15px; }
  .TopSellingProducts .TablePlaceholder .Thumbnail {
    width: 60px;
    height: 60px; }
  .TopSellingProducts .TablePlaceholder span {
    width: 100px;
    height: 10px;
    max-width: 100%; }
  .TopSellingProducts .ImageCell .ThumbnailWrapper {
    display: flex; }
  .TopSellingProducts .ImageCell .ThumbnailInner {
    position: relative; }
  .TopSellingProducts .ImageCell .OriginImage {
    display: none;
    position: absolute;
    left: calc(100% + 15px);
    top: 50%;
    transform: translateY(-50%);
    max-width: 200px; }
  .TopSellingProducts .ImageCell .ThumbnailImage:hover + .OriginImage {
    display: block; }

.OrderFulfillmentStatus.unfulfilled > .badge {
  background-color: #ffc107;
  color: #212529; }

.OrderFulfillmentStatus.partial > .badge {
  background-color: #ffc58b;
  color: #212b36; }

@media (min-width: 1025px) and (max-width: 1400px) {
  .OrdersPage .FiltersTop {
    flex-direction: column-reverse;
    align-items: flex-start !important; }
    .OrdersPage .FiltersTop .OrdersResetFilters {
      margin: 15px 0; }
    .OrdersPage .FiltersTop .Supplier {
      width: 220px; } }

.OrdersPage .TableFilter .InputSearch {
  padding: 0 12px 0 40px; }

.OrdersPage .TableFilter .InputWrapper > i {
  display: inline-block !important; }

.OrdersPage .OrderFilters > [class^="col"] {
  padding: 0 10px; }

.OrdersPage .Platform {
  width: 200px; }

.OrdersPage .QuickFilter {
  text-align: center;
  line-height: 30px;
  position: fixed;
  right: 0;
  top: 125px;
  z-index: 9;
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.16); }
  .OrdersPage .QuickFilter:hover {
    cursor: pointer; }
    .OrdersPage .QuickFilter:hover i {
      color: #00c853; }

.OrdersPage .OrderFilterPersonalized {
  opacity: 0;
  position: fixed;
  right: -300px;
  top: 0;
  bottom: 0;
  width: 300px;
  background: #FFFFFF;
  box-sizing: border-box;
  box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 8px 0 0 8px;
  z-index: 99;
  transition: all 0.3s;
  overflow-y: auto; }
  .OrdersPage .OrderFilterPersonalized.Open {
    opacity: 1;
    right: 0; }
  .OrdersPage .OrderFilterPersonalized.Close {
    transition: all 0.3s; }
  .OrdersPage .OrderFilterPersonalized .HeadingFilter {
    padding: 23px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #E5E5E5; }
    .OrdersPage .OrderFilterPersonalized .HeadingFilter h3.Heading {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 18px;
      color: #303238;
      margin: 0;
      padding: 0; }
    .OrdersPage .OrderFilterPersonalized .HeadingFilter .Close:hover {
      cursor: pointer;
      color: #dc3545; }
  .OrdersPage .OrderFilterPersonalized .ContentFilter ul {
    margin: 0;
    padding: 0; }
    .OrdersPage .OrderFilterPersonalized .ContentFilter ul li {
      padding: 12px 16px;
      border-bottom: 1px solid #E5E5E5;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: #000000; }
      .OrdersPage .OrderFilterPersonalized .ContentFilter ul li:hover, .OrdersPage .OrderFilterPersonalized .ContentFilter ul li.active {
        cursor: pointer;
        color: #0f9d58; }

.OrdersPage .BackgroundQuickFilter.Open {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 8; }

.OrdersPage .BackgroundQuickFilter.Close {
  display: none; }

.TrackingModal #tracking_name.error {
  border: 1px solid #f44336; }

@media screen and (max-width: 588px) {
  .FiltersTop .d-flex:first-child .mr-3 {
    margin-right: 0 !important;
    width: 100% !important; }
  .FiltersTop .d-flex:first-child .Supplier {
    width: 100%; }
  .FiltersTop .d-flex:first-child .Platform {
    width: 100%; }
  .FiltersTop .d-flex:first-child .ant-input-group-wrapper.ant-input-search {
    width: 100% !important; }
  .FiltersTop .d-flex:last-child {
    justify-content: space-between; }
  .OrderFilters .FilterOptions label.ant-radio-button-wrapper {
    margin-bottom: 5px; }
  .mb-3.row.align-items-end .col-auto.mb-3:nth-child(2) {
    width: 100% !important; }
  .mb-3.row.align-items-end .col-auto.mb-3:nth-child(3) {
    width: 100%; }
    .mb-3.row.align-items-end .col-auto.mb-3:nth-child(3) .ant-select.ant-select-single.ant-select-show-arrow {
      width: 100% !important; }
  .mb-3.row.align-items-end .col-auto.mb-3:nth-child(4) {
    width: 100%; }
    .mb-3.row.align-items-end .col-auto.mb-3:nth-child(4) .ShipmentStatus {
      padding: 0 !important; }
      .mb-3.row.align-items-end .col-auto.mb-3:nth-child(4) .ShipmentStatus .FilterOptions.ml-3 {
        margin-left: 0 !important; }
  .mb-3.row.align-items-end .col-auto.mb-3:nth-child(5) {
    width: 100%; }
    .mb-3.row.align-items-end .col-auto.mb-3:nth-child(5) .ShipmentStatus {
      padding: 0 !important; }
  .mb-3.row.align-items-end .col-auto.mb-3:nth-child(7) label.ant-radio-button-wrapper, .mb-3.row.align-items-end .col-auto.mb-3:nth-child(6) label.ant-radio-button-wrapper {
    margin-bottom: 5px; }
  .mb-3.row.align-items-end .col-auto.mb-3:last-child {
    width: 100% !important;
    margin-bottom: 0.5rem !important; }
    .mb-3.row.align-items-end .col-auto.mb-3:last-child .FilterOptions.ml-sm-3 {
      margin-left: 0 !important; }
  .row.mb-3.align-items-center .FilterDateRange {
    width: 100%; }
    .row.mb-3.align-items-center .FilterDateRange .d-sm-inline-flex.align-items-baseline {
      margin-right: 1rem !important; }
      .row.mb-3.align-items-center .FilterDateRange .d-sm-inline-flex.align-items-baseline .ant-picker.ant-picker-range {
        width: 100% !important; }
  .row.mb-3.align-items-center .ml-3.mb-3:nth-child(2) {
    width: 100% !important; }
  .row.mb-3.align-items-center .mb-3:nth-child(3) {
    width: 100% !important;
    margin-left: 1rem !important; }
  .SearchGroup {
    margin-right: 0.9rem !important; }
    .SearchGroup .ant-input-group.ant-input-group-compact {
      display: flex; }
    .SearchGroup .ant-select.ant-select-single.ant-select-show-arrow {
      width: auto !important; }
    .SearchGroup .ant-input-group-wrapper.ant-input-search {
      width: 100% !important; }
  .Left182, .Left646 {
    left: 145px !important; } }

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .FiltersTop {
    display: grid !important; }
    .FiltersTop .d-flex.flex-wrap.align-items-baseline {
      display: grid !important;
      grid-template-columns: repeat(auto-fill, minmax(200px, 2fr));
      grid-column-gap: 6px; }
    .FiltersTop .d-flex:first-child .mr-3 {
      margin-right: 0 !important;
      width: 100% !important; }
    .FiltersTop .d-flex:first-child .Supplier {
      width: 100%; }
    .FiltersTop .d-flex:first-child .Platform {
      width: 100%; }
    .FiltersTop .d-flex:first-child .ant-input-group-wrapper.ant-input-search {
      width: 100% !important; }
    .FiltersTop .d-flex:last-child {
      justify-content: space-between; }
  .SectionInner div.mb-3.row.align-items-end .col-auto.ml-auto.mb-3 {
    margin-left: 0 !important; }
  .SectionInner div.row.mb-3.align-items-center div.ml-sm-auto.ml-3.mb-3 {
    margin-left: 1rem !important; }
  .SectionInner div.row.mb-3.align-items-center .ml-3.mb-3:nth-child(1) .d-sm-inline-flex, .SectionInner div.row.mb-3.align-items-center .ml-3.mb-3:nth-child(2) .d-sm-inline-flex {
    flex-direction: column; } }

@media screen and (min-width: 481px) and (max-width: 1440px) {
  .SectionInner div.row.mb-3.align-items-center div.mb-3:nth-child(3) {
    margin-left: 1rem !important; }
  .SectionInner div.row.mb-3.align-items-center div.mb-3:nth-child(4) {
    margin-left: auto !important; } }

@media screen and (max-width: 466px) {
  .dropdown-menu.show {
    position: absolute;
    will-change: transform;
    top: 0px;
    left: 0px;
    transform: translate3d(-40px, 40px, 0px) !important; }
  .DashboardContainer .MainContentInner .SectionInner .ShowTotal {
    display: block !important; } }

.tooltipLabel .ant-tooltip-content {
  min-width: 350px; }

.Namespace form {
  width: 300px;
  max-width: 100%; }

.Namespace input[type=text] {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  overflow: hidden; }

.Namespace i {
  top: 2px; }

.Namespace .NamespaceInput {
  width: 300px; }

.DatePaid .Label {
  position: relative;
  top: 2px; }

.DatePaid .DateInput_input {
  font-weight: 400; }

.DatePaid .DatePickerWrapper {
  padding: 2px 10px;
  border: solid 1px #ced4da; }

.DatePaid .DateRangePickerInput_clearDates_svg {
  position: relative;
  top: -3px; }

.DatePaid .DateRangePickerInput_clearDates {
  margin-right: 0; }

.DatePaid .DateRangePickerInput_clearDates_default:focus, .DatePaid .DateRangePickerInput_clearDates_default:hover {
  background-color: transparent; }
  .DatePaid .DateRangePickerInput_clearDates_default:focus .DateRangePickerInput_clearDates_svg, .DatePaid .DateRangePickerInput_clearDates_default:hover .DateRangePickerInput_clearDates_svg {
    fill: #dc3545; }

.Supplier {
  width: 300px; }
  .Supplier label {
    font-size: .875rem; }

.PackageStatus label {
  font-size: .875rem; }

.ImportTrackingModal .modal-content {
  min-height: 500px; }

.ImportTrackingModal .ImportResult {
  border-left: solid 5px #28a745;
  background-color: rgba(40, 167, 69, 0.2); }

.Platform form {
  width: 300px;
  max-width: 100%; }

.Platform label {
  font-size: .875rem; }

.OrderItem .Image {
  width: 60px;
  padding: 0 0 60px 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.OrderItemsContainer td {
  border-top: none; }

.BulkSelectOrder {
  vertical-align: middle !important; }
  .BulkSelectOrder .Message {
    position: fixed;
    width: 400px;
    left: 50%;
    margin-left: -200px;
    top: 100%; }
    .BulkSelectOrder .Message .Inner {
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      min-height: 100px;
      transform: translateY(-120%);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 400; }
  .BulkSelectOrder .ToggleActions {
    font-size: 14px; }
  .BulkSelectOrder .MenuActions {
    top: 0;
    bottom: 0;
    left: 40px;
    width: calc(100vw - 217px);
    background-color: #e9ecef;
    display: flex;
    align-items: center; }

.contentResults {
  max-height: 250px;
  overflow-y: scroll; }
  .contentResults label {
    font-size: 16px; }

.listErrorsExportSheet {
  max-height: 250px;
  overflow-y: scroll; }

.OrderContainer h3 {
  color: gray; }

.OrderPage {
  word-break: break-word; }
  .OrderPage .OrderItemRow .ThumbnailWrapper {
    min-width: 125px; }
  .OrderPage .OrderItemRow .Label {
    min-width: 90px; }
  .OrderPage .OrderItemMapDetails .Label {
    min-width: 60px; }
  .OrderPage .alertWarning .alert.alert-warning {
    border-color: #e2c46f;
    color: #cb9801;
    font-weight: 400;
    background: #fff8e3;
    padding: 0.75rem 4rem 0.75rem 0.75rem; }
    .OrderPage .alertWarning .alert.alert-warning button.close {
      color: #cb9801;
      position: absolute;
      right: 0.75rem;
      display: flex;
      align-items: center;
      opacity: unset;
      padding: unset; }
      .OrderPage .alertWarning .alert.alert-warning button.close:hover {
        opacity: 0.5; }
      .OrderPage .alertWarning .alert.alert-warning button.close:focus {
        outline: unset; }

.thumbnail-bottom {
  display: flex; }
  .thumbnail-bottom .mockup-open.disable {
    cursor: not-allowed;
    opacity: 0.5; }

.mockup-message {
  color: red; }

.modal-dialog.modal-mockup {
  max-width: 900px; }
  .modal-dialog.modal-mockup .modal-body .mockup-image {
    text-align: center; }

.OrderHistories {
  margin-top: 20px; }
  .OrderHistories .card {
    margin-bottom: 10px; }

.OrderNotes {
  margin-top: 20px; }

.OrderBuyerDetails .card-header > i {
  cursor: pointer; }

.OrderBuyerDetails .Error {
  display: none; }
  .OrderBuyerDetails .Error .Text {
    font-size: 0.75rem;
    margin-bottom: 0; }

.OrderBuyerDetails.isChecked.valid .card {
  border-color: #37c936; }

.OrderBuyerDetails.isChecked.invalid .card {
  border-color: #f44336; }

.OrderBuyerDetails.isChecked.invalid .card-body .Error {
  display: block;
  margin-top: 10px; }

.OrderBuyerDetails .RightActions {
  display: flex;
  align-items: center; }
  .OrderBuyerDetails .RightActions .RequestUpdateModal {
    line-height: 1px;
    margin-right: 1rem; }

.RequestUpdateModal {
  line-height: 1px; }
  .RequestUpdateModal:hover svg {
    cursor: pointer; }
    .RequestUpdateModal:hover svg path {
      fill: #0f9d58; }

.OrderTags .ListTags {
  padding: 0; }
  .OrderTags .ListTags .Tag {
    background: #dfe3e8;
    border-radius: 10px;
    padding: 4px 10px 4px 10px;
    font-size: 15px;
    list-style: none;
    display: inline-block;
    margin-top: 5px; }
    .OrderTags .ListTags .Tag .Close {
      margin-left: 10px;
      font-size: 16px;
      cursor: pointer; }

@media (max-width: 768px) {
  .OrderItemTable {
    overflow-x: scroll; }
    .OrderItemTable table {
      width: max-content; } }

.OrderItemTable table {
  table-layout: fixed; }
  .OrderItemTable table td {
    vertical-align: top; }

@media (max-width: 768px) {
  .OrderJobTable {
    overflow-x: scroll; }
    .OrderJobTable table {
      width: max-content; } }

.OrderJobTable td.Information span.ti-info-alt {
  margin-left: 10px; }
  .OrderJobTable td.Information span.ti-info-alt:hover {
    cursor: pointer;
    color: #0f9d58; }

.OrderItemProduct .Label {
  font-weight: 500;
  min-width: 125px; }

.OrderItemProduct .Quantity {
  align-items: center; }
  .OrderItemProduct .Quantity .Number {
    font-size: 2rem;
    font-weight: bold; }

.OrderItemProduct .ThumbnailWrapper .Actions {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  visibility: hidden;
  opacity: 0;
  transition: all .3s; }

.OrderItemProduct .ThumbnailWrapper:hover .Actions {
  visibility: visible;
  opacity: 1; }

.OrderItemProduct .CopyImageUrl {
  right: 0;
  top: 0; }

.CustomVariant .Label {
  margin-right: 8px !important;
  font-weight: bold; }

.CustomVariable {
  margin-top: 5px; }
  .CustomVariable ul li {
    padding-left: 15px;
    position: relative; }
    .CustomVariable ul li:before {
      content: '';
      position: absolute;
      top: 10px;
      background-color: #333;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      left: 0; }
  .CustomVariable .Label {
    text-transform: capitalize;
    min-width: 49px !important;
    font-size: 14px; }
  .CustomVariable .text-break {
    font-size: 14px; }
    .CustomVariable .text-break.size {
      text-transform: uppercase; }

.OrderItemMap .OrderItemMapDetails .ThumbnailWrapper {
  min-height: 125px;
  border: 1px solid #dddddd;
  border-radius: 4px; }
  .OrderItemMap .OrderItemMapDetails .ThumbnailWrapper .Actions {
    opacity: 0;
    background: #FFFFFF;
    transition: all 0.2s;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .OrderItemMap .OrderItemMapDetails .ThumbnailWrapper:hover .Actions {
    opacity: 1; }

.OrderItemMap .OrderItemMapDetails .Stock {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #c82333; }

.FulfilledPackageItemProduct .Quantity {
  align-items: center; }
  .FulfilledPackageItemProduct .Quantity .Number {
    font-size: 2rem;
    font-weight: bold; }

.OrderMeta .Statues.unfulfilled > .badge {
  background-color: #ffc107;
  color: #212529; }

.OrderMeta .Statues.partial > .badge {
  background-color: #ffc58b;
  color: #212b36; }

.OrderInvoice .card-header > * {
  vertical-align: middle;
  display: inline-block; }

.OrderInvoice .card-header .EmbroiderySurchargeTooltip {
  color: #FF9800;
  margin-left: 5px; }

.ProductLineModal {
  width: calc(100vw - 60px);
  max-width: 100%; }
  .ProductLineModal .modal-content {
    height: calc(100vh - 56px);
    overflow-y: auto; }
  .ProductLineModal .ProductLineCategories .ListCategories {
    border-bottom: none; }
    .ProductLineModal .ProductLineCategories .ListCategories .ProductLineCategory span.nav-link {
      padding: 5px 15px;
      border: 1px solid #dddddd;
      margin-bottom: 5px;
      margin-right: 5px;
      border-radius: 4px; }
      .ProductLineModal .ProductLineCategories .ListCategories .ProductLineCategory span.nav-link.active, .ProductLineModal .ProductLineCategories .ListCategories .ProductLineCategory span.nav-link:hover {
        border-color: #0f9d58;
        border-radius: 4px;
        background: #0f9d58;
        color: #ffffff; }

.AddChildProductsModal .modalContent {
  max-height: 497px;
  overflow: auto; }

.AddChildProductsModal .list-child.table tr td {
  vertical-align: top; }

.OrderItemMapDetails .Label, .FulfilledPackageItemMap .Label {
  min-width: 125px; }

.OrderItemMapDetails .CustomButton, .FulfilledPackageItemMap .CustomButton {
  margin-top: 30px;
  height: 40px;
  padding: 0 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  border-radius: 4px;
  color: #FFFFFF;
  border: 2px solid; }
  .OrderItemMapDetails .CustomButton.AddNewButton, .FulfilledPackageItemMap .CustomButton.AddNewButton {
    background: #477BF6;
    border-color: #477BF6; }
    .OrderItemMapDetails .CustomButton.AddNewButton:hover, .FulfilledPackageItemMap .CustomButton.AddNewButton:hover {
      background: #FFFFFF;
      color: #477BF6; }
  .OrderItemMapDetails .CustomButton.ReplaceButton, .FulfilledPackageItemMap .CustomButton.ReplaceButton {
    background: #FAA23A;
    border-color: #FAA23A; }
    .OrderItemMapDetails .CustomButton.ReplaceButton:hover, .FulfilledPackageItemMap .CustomButton.ReplaceButton:hover {
      background: #FFFFFF;
      color: #FAA23A; }

.ProductLineTitleSearch > input[type=text] {
  width: 500px;
  max-width: 100%;
  padding-left: 36px; }

.ProductLineTitleSearch > i {
  top: 12px;
  left: 12px; }

.ProductLineTitleSearch select.form-control {
  width: 17%; }

.ProductLineTitleSearch .supplierFilter {
  width: 300px; }
  .ProductLineTitleSearch .supplierFilter .ant-select-selector {
    overflow: auto;
    max-height: 117px;
    min-height: 38px;
    border-radius: 0.25rem;
    align-items: center; }
  .ProductLineTitleSearch .supplierFilter .ant-select-arrow,
  .ProductLineTitleSearch .supplierFilter .ant-select-clear {
    right: 17px; }

.ProductLineModalNavigation {
  height: 40px; }

.ProductLineVariants .ProductLineVariantsRow.Disabled {
  color: #212529;
  background-color: rgba(117, 107, 107, 0.5);
  border-bottom: 1px solid #dddddd; }

.ProductLineItems .Wrapper {
  display: grid;
  grid-template-columns: 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%;
  margin: 0 -10px; }

.ProductLineItems .ProductLineItem {
  padding: 0 10px;
  transition: all .3s;
  word-break: break-word;
  border: 1px solid #dddddd;
  border-radius: 4px;
  margin-left: 5px;
  margin-right: 5px; }
  .ProductLineItems .ProductLineItem:hover {
    background-color: rgba(15, 157, 88, 0.4); }

.FulfilledPackageItems table td {
  vertical-align: top; }
  .FulfilledPackageItems table td .Label {
    min-width: 80px; }
  .FulfilledPackageItems table td .price-detail .price-detail-button {
    border: none;
    padding: 0;
    background: none;
    box-shadow: none;
    color: #0062cc; }

.priceDetailItem .Label {
  min-width: 90px; }

.FulfilledPackage .card-header {
  background-color: #e9ecef; }
  .FulfilledPackage .card-header .CustomAlert i {
    margin-left: 5px;
    color: #007BFF; }
    .FulfilledPackage .card-header .CustomAlert i:hover {
      color: #0f9d58; }

.FulfilledPackage .card-footer .btnConfirm[disabled] {
  background: gray;
  border-color: gray;
  opacity: 0.65; }

.badgeUnfulfilled .ant-badge-count {
  color: #303238;
  box-shadow: 0 0 0 1px #dedede inset; }

.FulfilledPackageTracking .other {
  margin-left: 10px; }

.FulfilledPackageTracking .listTracking {
  width: 100%;
  background-color: #FFFFFF;
  right: 0;
  z-index: 3;
  min-width: 270px;
  top: calc(100% + 8px);
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.06);
  border: 1px solid #DFDFDF; }
  .FulfilledPackageTracking .listTracking span {
    padding: 10px 20px;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
    .FulfilledPackageTracking .listTracking span:last-child {
      border-bottom: none; }

.PackageEmbroiderySurcharge {
  display: inline-block;
  height: 38px;
  line-height: 36px;
  position: relative;
  padding: 0 10px;
  background: #f5f5f5;
  color: #78858f;
  border: 1px solid #78858f;
  border-radius: 4px;
  font-size: 14px;
  text-decoration: none;
  font-weight: bold;
  white-space: nowrap;
  margin: 0; }

.OrderRejectedItems .OrderItem .Label {
  min-width: 125px; }

.OrderItemMap .OrderItemMapActions button {
  width: 120px; }

.OrderItemMap .Thumbnail ~ .Actions {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: all .3s; }
  .OrderItemMap .Thumbnail ~ .Actions .OrderItemMapActions {
    display: flex;
    flex-direction: column;
    justify-content: center; }

.OrderItemMap .ThumbnailWrapper:hover .Thumbnail ~ .Actions {
  opacity: 1;
  visibility: visible; }

.ant-modal-wrap:has(.confirm-force) {
  z-index: 1051; }

.FulfilledPackageItemDesign ul.ListArtworks > li:nth-child(2n + 1) {
  margin-top: 16px; }

.FulfilledPackageItemDesign ul.ListArtworks > li:first-child {
  margin-top: 0; }

.ArtworkType .ValidateDimension {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px; }
  .ArtworkType .ValidateDimension svg {
    width: 20px;
    margin-right: 5px; }
  .ArtworkType .ValidateDimension span {
    width: calc(100% - 25px);
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #F70000; }

.ListArtworks .ArtworkPreview img.HasWrong {
  border: 4px solid #F70000; }

.ListArtworks .ArtworkPreview .ErrorDimension {
  margin-top: 3px;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #F70000; }

.ProductSource .Label {
  min-width: 100px; }

.ProductSource .Quantity .Number {
  font-size: 2rem;
  font-weight: bold; }

.ProductSource .CopyTitleMap {
  position: absolute;
  right: 0;
  z-index: 9; }

.FulfilledPackageSupplier {
  position: relative; }
  .FulfilledPackageSupplier .IconWrapper {
    position: absolute;
    right: 20px;
    top: 8px; }
    .FulfilledPackageSupplier .IconWrapper.done .text-success {
      animation-name: FadeOut;
      animation-duration: 1s;
      animation-fill-mode: forwards;
      animation-delay: .5s; }
    .FulfilledPackageSupplier .IconWrapper .spin {
      border-color: transparent; }
  .FulfilledPackageSupplier .form-control {
    width: auto !important; }

.OrderAdvanceActions .Options {
  top: calc(100% + 15px);
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  background-color: #fff;
  border: none;
  min-width: 200px;
  overflow: hidden;
  box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 2px 7px 1px rgba(39, 44, 48, 0.16); }

.OrderAdvanceActions .Option:hover {
  background-color: rgba(33, 43, 54, 0.1); }

.OrderAdvanceActions.active .ToggleMenu {
  background-color: rgba(33, 43, 54, 0.1); }

.OrderAdvanceActions.active .Options {
  opacity: 1;
  visibility: visible; }

.OrderAdvanceActions .dropdown-item {
  min-width: 250px; }

.OrderAdvanceActions .PushOrderExport.disabled {
  opacity: .4;
  pointer-events: none; }

.OrderRestore.disabled {
  opacity: .4;
  pointer-events: none; }

.ConfirmRestoreModal {
  min-height: 200px;
  display: flex; }

.CheckDuplicateOrder {
  margin-left: 10px; }
  .CheckDuplicateOrder .badge {
    margin-right: 5px; }

.ArtworkPreview .ArtworkItem {
  border: 1px dashed red;
  transition: all 200ms ease-out; }

.ArtworkPreview .Colors .Color {
  cursor: pointer;
  padding: 2px 5px;
  border: 1px solid #bbb;
  color: #eee; }
  .ArtworkPreview .Colors .Color.active {
    border-color: #333;
    color: #333; }

.OrderHistoriesItem:not(:last-child) {
  margin-bottom: 16px; }

.OrderRevalidateAddress {
  transition: all 200ms ease-out; }
  .OrderRevalidateAddress.loading {
    opacity: .5; }

.OrderPreferenceShipping .Title {
  margin-bottom: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #303238; }

.OrderPreferenceShipping .Row {
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #7A8087; }
  .OrderPreferenceShipping .Row .Label {
    margin-right: 10px;
    text-transform: uppercase; }

.OrderShippingPlan .Title {
  font-weight: 600; }

.OrderStoreMode {
  margin-right: 15px; }
  .OrderStoreMode.marketplace .card {
    border-color: #DC3545; }
  .OrderStoreMode.marketplace .Mode {
    color: #DC3545; }
  .OrderStoreMode.merchize-payment .card {
    border-color: #28A745; }
  .OrderStoreMode.merchize-payment .Mode {
    color: #28A745; }

.PackageSingleItemEditor td {
  min-width: 150px; }
  .PackageSingleItemEditor td .Warning {
    border: 3px solid #F70000; }

.PackageAddressEditor td {
  min-width: 150px; }

.PackagePusherEditorModal {
  max-width: 98%; }

.ItemHightLight {
  background: rgba(255, 208, 26, 0.3) !important; }

.PackageListItemsEditor table {
  table-layout: fixed; }
  .PackageListItemsEditor table th.Actions {
    width: 50px; }
  .PackageListItemsEditor table th.Quantity {
    width: 100px; }
  .PackageListItemsEditor table th.Size {
    width: 100px; }

.PopupRefundContent .ant-modal-header {
  border-bottom: none;
  padding-top: 20px;
  padding-bottom: 0; }

.PopupRefundContent .ant-modal-close-x {
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-top: 10px;
  margin-right: 10px;
  color: #2F3230; }

.PopupRefundContent .CustomHeaderPopup label {
  font-weight: bold;
  font-size: 28px;
  line-height: 33px; }

.PopupRefundContent .CustomHeaderPopup .infoOrder {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px; }
  .PopupRefundContent .CustomHeaderPopup .infoOrder .badge {
    margin-left: 8px;
    font-size: 12px;
    font-weight: 500; }

.PopupRefundContent .ant-modal-body {
  padding-top: 28px;
  padding-bottom: 0; }

.PopupRefundContent .ant-modal-footer {
  padding: 0 23px 23px 23px;
  border-top: none; }
  .PopupRefundContent .ant-modal-footer button {
    word-break: break-all; }

.PopupRefundContent .rowItem select {
  width: 192px; }
  .PopupRefundContent .rowItem select.error {
    border-color: #FF0000; }

.PopupRefundContent .rowItem .ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 36px; }

.PopupRefundContent .rowItem .ant-select .ant-select-selector {
  height: 38px; }

.PopupRefundContent .rowItem .ant-select.error .ant-select-selector {
  border-color: #FF0000; }

.PopupRefundContent .rowItem .ant-select-selection--single {
  height: 37px; }

.PopupRefundContent .rowItem .error .ant-select-selection--single {
  border-color: #FF0000; }

.PopupRefundContent .rowItem .ant-select-selection__rendered {
  line-height: 35px;
  font-size: 16px;
  color: #000000; }

.PopupRefundContent .rowItem textarea.error {
  border-color: #FF0000; }

.PopupRefundContent .rowItem input {
  color: #000000;
  width: 330px; }
  .PopupRefundContent .rowItem input.error {
    border-color: #FF0000; }

.PopupRefundContent .rowItem label:not(.ant-radio-wrapper) {
  width: 140px;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  flex-shrink: 0; }

.PopupRefundContent .rowItem .valueRefund {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  display: flex;
  align-items: center; }
  .PopupRefundContent .rowItem .valueRefund input {
    border-radius: 0.25rem;
    border: none;
    padding: 0 10px;
    height: 35px;
    width: 130px;
    line-height: 35px; }
  .PopupRefundContent .rowItem .valueRefund > span {
    width: 30px;
    cursor: pointer;
    text-align: center;
    line-height: 35px;
    color: #000000;
    font-weight: normal;
    font-size: 16px;
    border-left: 1px solid #ced4da; }
    .PopupRefundContent .rowItem .valueRefund > span.active, .PopupRefundContent .rowItem .valueRefund > span:hover {
      background-color: #0f9d58;
      color: #FFFFFF; }
    .PopupRefundContent .rowItem .valueRefund > span:last-child {
      border-radius: 0 4px 4px 0; }
  .PopupRefundContent .rowItem .valueRefund.error {
    border-color: #FF0000; }

.PopupRefundContent.disabled .PopupRefundOptions {
  pointer-events: none;
  position: relative; }
  .PopupRefundContent.disabled .PopupRefundOptions:after {
    content: '';
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent; }

.OrderInvoice .ShippingCost .font-weight-500 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000; }

.OrderInvoice .ShippingCost .shippingCostValue {
  height: 38px; }

.OrderInvoice .ShippingCost .form-edit-shipping-cost input.form-control {
  width: 120px;
  border-radius: 5px 0 0 5px; }

.OrderInvoice .ShippingCost .form-edit-shipping-cost button.submit {
  border-radius: 0 5px 5px 0;
  width: 75px; }
  .OrderInvoice .ShippingCost .form-edit-shipping-cost button.submit.saving {
    width: 95px; }

.OrderInvoice .ChargeInvoice {
  margin-top: 5px;
  margin-bottom: 5px; }
  .OrderInvoice .ChargeInvoice .Title span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000; }
  .OrderInvoice .ChargeInvoice .Value .Status {
    padding: 2px 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    border-radius: 3px;
    text-transform: capitalize; }
    .OrderInvoice .ChargeInvoice .Value .Status.pending {
      background-color: #17a2b8; }
    .OrderInvoice .ChargeInvoice .Value .Status.paid {
      background-color: #33A746; }
  .OrderInvoice .ChargeInvoice .Value .Amount {
    display: inline-block;
    margin-left: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    min-width: 68px; }

.ant-modal.refundChargeModal {
  width: 900px !important; }
  .ant-modal.refundChargeModal .PopupRefundOptions .container {
    padding-left: 0;
    padding-right: 0; }
  .ant-modal.refundChargeModal .PopupRefundOptions .rowItem > label {
    width: 140px;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    flex-shrink: 0; }
  .ant-modal.refundChargeModal .PopupRefundOptions table, .ant-modal.refundChargeModal .PopupRefundOptions th, .ant-modal.refundChargeModal .PopupRefundOptions td {
    padding: 5px 10px;
    border: 1px solid #d9d9d9;
    border-collapse: collapse; }
  .ant-modal.refundChargeModal .PopupRefundOptions .refundChargeValueTableWrap {
    max-height: 169px;
    overflow: auto; }
    .ant-modal.refundChargeModal .PopupRefundOptions .refundChargeValueTableWrap::-webkit-scrollbar {
      width: 8px; }
    .ant-modal.refundChargeModal .PopupRefundOptions .refundChargeValueTableWrap::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
    .ant-modal.refundChargeModal .PopupRefundOptions .refundChargeValueTableWrap::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 4px; }
  .ant-modal.refundChargeModal .PopupRefundOptions .refundChargeValueTable {
    width: 100%; }
    .ant-modal.refundChargeModal .PopupRefundOptions .refundChargeValueTable .RefundChargeValueTableRow input {
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      padding: 5px 10px;
      width: 100%; }
  .ant-modal.refundChargeModal .ListProcessingRefundCharge ul {
    max-height: 160px;
    overflow: auto; }
    .ant-modal.refundChargeModal .ListProcessingRefundCharge ul::-webkit-scrollbar {
      width: 8px; }
    .ant-modal.refundChargeModal .ListProcessingRefundCharge ul::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
    .ant-modal.refundChargeModal .ListProcessingRefundCharge ul::-webkit-scrollbar-thumb {
      background-color: darkgrey;
      border-radius: 4px; }

#ButtonPushFactory {
  margin-right: 10px; }

.PackagePusherEditorModal.Factory .modal-body.Pushing {
  position: relative; }
  .PackagePusherEditorModal.Factory .modal-body.Pushing:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #ffffff85;
    bottom: 0; }

.PackagePusherEditorModal.Factory .modal-footer {
  display: none; }
  .PackagePusherEditorModal.Factory .modal-footer .custom-control.custom-checkbox {
    display: inline-block;
    margin-right: 10px; }

.PackagePusherEditorModal.Factory .PushMessage {
  text-align: right; }

.ExportFactory .PackageSingleItemEditor td {
  min-width: 150px; }
  .ExportFactory .PackageSingleItemEditor td > p {
    display: flex;
    position: relative; }
    .ExportFactory .PackageSingleItemEditor td > p input {
      position: absolute;
      left: 70px;
      width: calc(100% - 70px); }

.ExportFactory .ItemHightLight {
  background: rgba(255, 208, 26, 0.3) !important; }

.ExportFactory .PackageAddressEditor td {
  min-width: 150px; }

.ExportFactory .PackagePusherEditorModal {
  max-width: 98%; }

.ModalBulkPushFactoryOrdersMonitor .ant-modal-confirm-btns {
  display: none; }

.BulkPushFactoryOrdersMonitor .TopMessage {
  line-height: 23px;
  font-size: 18px; }
  .BulkPushFactoryOrdersMonitor .TopMessage.Error {
    color: #FF0000; }

.BulkPushFactoryOrdersMonitor .OrdersList {
  margin: 20px 0 0 0;
  width: 100%; }
  .BulkPushFactoryOrdersMonitor .OrdersList .OrdersList__Order > div {
    display: flex; }
  .BulkPushFactoryOrdersMonitor .OrdersList .OrdersList__Order .OrderPackages {
    display: block;
    margin: 10px 0; }
    .BulkPushFactoryOrdersMonitor .OrdersList .OrdersList__Order .OrderPackages p {
      text-align: right;
      margin: 0;
      font-size: 12px; }
  .BulkPushFactoryOrdersMonitor .OrdersList .OrdersList__Order .Index {
    flex: 0 0 40px;
    text-align: right; }
  .BulkPushFactoryOrdersMonitor .OrdersList .OrdersList__Order .Name {
    margin-left: 10px;
    flex: 0 0 150px; }
  .BulkPushFactoryOrdersMonitor .OrdersList .OrdersList__Order .Status {
    flex: 1;
    text-align: right; }
  .BulkPushFactoryOrdersMonitor .OrdersList .OrdersList__Order.Current {
    font-weight: bold; }
  .BulkPushFactoryOrdersMonitor .OrdersList .OrdersList__Order.Success {
    color: #37c936; }
  .BulkPushFactoryOrdersMonitor .OrdersList .OrdersList__Order.Error {
    color: #FF0000; }
  .BulkPushFactoryOrdersMonitor .OrdersList:after {
    content: '';
    display: block;
    clear: both; }

.BulkPushFactoryOrdersMonitor .OrdersOverview {
  display: flex;
  margin: 0;
  clear: both; }
  .BulkPushFactoryOrdersMonitor .OrdersOverview .Status {
    margin-right: 10px;
    flex: 1; }
  .BulkPushFactoryOrdersMonitor .OrdersOverview .Overview {
    text-align: right; }
  .BulkPushFactoryOrdersMonitor .OrdersOverview.Error {
    color: #FF0000; }
  .BulkPushFactoryOrdersMonitor .OrdersOverview.Success {
    color: #37c936; }

.BulkPushFactoryOrdersMonitor .OrderActions {
  text-align: right;
  margin: 30px 0 0; }
  .BulkPushFactoryOrdersMonitor .OrderActions > *:not(.ButtonCancel) {
    margin-left: 10px;
    display: inline-block; }
  .BulkPushFactoryOrdersMonitor .OrderActions label input {
    margin-right: 5px; }
  .BulkPushFactoryOrdersMonitor .OrderActions .ButtonCancel {
    float: left; }

.PackagePusherEditorModal .modal-content .modal-header {
  background: #2F3230; }
  .PackagePusherEditorModal .modal-content .modal-header .modal-title {
    color: #FFFFFF;
    font-weight: normal; }

.PackagePusherEditorModal .modal-content .modal-body {
  padding: 0; }
  .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageAddressEditor .HeadingPackage, .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageListItemsEditor .HeadingPackage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F3F3F5; }
    .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageAddressEditor .HeadingPackage label, .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageListItemsEditor .HeadingPackage label {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      color: #2F3230;
      margin-right: 5px; }
    .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageAddressEditor .HeadingPackage .ant-select, .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageListItemsEditor .HeadingPackage .ant-select {
      width: 200px;
      margin-right: 15px; }
      .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageAddressEditor .HeadingPackage .ant-select .ant-select-selector, .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageListItemsEditor .HeadingPackage .ant-select .ant-select-selector {
        border-radius: 4px; }
  .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageAddressEditor .HeadingTitle, .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageListItemsEditor .HeadingTitle {
    padding: 15px;
    background: #F3F3F5;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2F3230;
    text-transform: uppercase; }
  .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageAddressEditor .table-responsive, .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageListItemsEditor .table-responsive {
    padding: 0 15px; }
    .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageAddressEditor .table-responsive .table, .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageListItemsEditor .table-responsive .table {
      margin-bottom: 0;
      border: 1px solid #dddddd;
      margin-top: 15px;
      border-radius: 0.25rem; }
      .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageAddressEditor .table-responsive .table thead tr th, .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageListItemsEditor .table-responsive .table thead tr th {
        border-bottom: 0;
        padding: 14px 5px;
        background: rgba(40, 167, 69, 0.5);
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #2F3230; }
      .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageAddressEditor .table-responsive .table tbody tr td, .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageListItemsEditor .table-responsive .table tbody tr td {
        padding: 18px 5px; }
        .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageAddressEditor .table-responsive .table tbody tr td input, .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageListItemsEditor .table-responsive .table tbody tr td input {
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 20px;
          color: #2F3230; }
  .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageListItemsEditor {
    margin-top: 15px;
    margin-bottom: 15px; }
  .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageIssuesStyles .table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent; }
  .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageIssuesStyles .table-striped tbody tr.dark {
    background-color: #f9fafb; }
  .PackagePusherEditorModal .modal-content .modal-body .Editor .PackageIssuesStyles .table-striped tbody tr.PackageSingleItemEditorIssues td {
    border-top: none;
    padding-top: 0; }
  .PackagePusherEditorModal .modal-content .modal-body .Loading {
    padding: 15px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: #2F3230; }
  .PackagePusherEditorModal .modal-content .modal-body .Error {
    padding: 15px; }

.ShippingAddressCod .Info {
  padding: 12px 0;
  border-bottom: 1px solid #eee; }
  .ShippingAddressCod .Info .Title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #303238;
    min-width: 150px; }
    .ShippingAddressCod .Info .Title small {
      display: block;
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #737A81; }
  .ShippingAddressCod .Info .Value {
    text-align: right;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #303238;
    word-break: break-word; }
  .ShippingAddressCod .Info:first-child {
    padding-top: 0; }
  .ShippingAddressCod .Info:last-child {
    border-bottom: none;
    padding-bottom: 0; }

.ModalChangeSupplier.HaveForm .ant-modal-header {
  border-bottom: 0;
  padding-top: 21px;
  padding-bottom: 0; }
  .ModalChangeSupplier.HaveForm .ant-modal-header .ant-modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: #000000;
    margin-bottom: 7px; }

.ModalChangeSupplier.HaveForm .ant-modal-body {
  padding-top: 0; }

.ModalChangeSupplier.HaveForm .ant-modal-footer .ant-btn {
  height: 40px; }

.FormRefund .TopForm .Title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  padding-bottom: 16px;
  margin-bottom: 21px;
  border-bottom: 1px solid #DFDFDF; }

.FormRefund .TopForm .ToggleRefund {
  margin-bottom: 22px; }
  .FormRefund .TopForm .ToggleRefund input {
    width: 18px;
    height: 18px;
    margin-right: 11px; }
  .FormRefund .TopForm .ToggleRefund span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000; }

.FormRefund .ContentForm label {
  width: 140px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 0; }

.FormRefund .ContentForm input, .FormRefund .ContentForm select {
  width: 184px;
  background: #FFFFFF;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #000000;
  padding: 6px 10px; }
  .FormRefund .ContentForm input:disabled, .FormRefund .ContentForm select:disabled {
    background-color: #F0F0F0; }

.FormRefund .ContentForm .Left {
  width: 50%;
  margin-bottom: 25px; }
  .FormRefund .ContentForm .Left #Value {
    width: 50%;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .FormRefund .ContentForm .Left span.ChangeType {
    display: inline-block;
    width: 28px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding: 7px 0;
    border: 1px solid #DFDFDF;
    text-align: center;
    border-right: 0; }
    .FormRefund .ContentForm .Left span.ChangeType.Last {
      border-right: 1px solid #DFDFDF;
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px; }
    .FormRefund .ContentForm .Left span.ChangeType.active {
      color: #FFFFFF;
      background-color: #0f9d58; }
    .FormRefund .ContentForm .Left span.ChangeType:hover {
      color: #FFFFFF;
      cursor: pointer;
      background-color: #0f9d58; }
  .FormRefund .ContentForm .Left.Error {
    position: relative; }
    .FormRefund .ContentForm .Left.Error #Value {
      border-color: #FF0000; }
    .FormRefund .ContentForm .Left.Error span.ChangeType {
      border-color: #FF0000;
      border-left-color: #DFDFDF; }
      .FormRefund .ContentForm .Left.Error span.ChangeType.Last {
        border-color: #FF0000;
        border-left-color: #DFDFDF; }
    .FormRefund .ContentForm .Left.Error .ant-select-selector {
      border-color: #FF0000; }
    .FormRefund .ContentForm .Left.Error p {
      position: absolute;
      left: 140px;
      bottom: -25px;
      margin: 0; }

.FormRefund .ContentForm .Right {
  width: 50%;
  margin-bottom: 25px; }
  .FormRefund .ContentForm .Right.Error {
    position: relative; }
    .FormRefund .ContentForm .Right.Error input {
      border-color: #FF0000; }
    .FormRefund .ContentForm .Right.Error p {
      position: absolute;
      left: 140px;
      bottom: -25px;
      margin: 0; }

.FormRefund .ContentForm .Reason {
  margin-bottom: 18px; }
  .FormRefund .ContentForm .Reason label {
    display: block;
    margin-bottom: 11px; }
  .FormRefund .ContentForm .Reason textarea {
    width: 100%;
    resize: none;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 25px 18px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #000000; }
  .FormRefund .ContentForm .Reason.Error {
    position: relative; }
    .FormRefund .ContentForm .Reason.Error textarea {
      border-color: #FF0000; }
    .FormRefund .ContentForm .Reason.Error p {
      position: static; }

.ant-radio-button-wrapper {
  padding: 0 10px; }

.TrackingEdit {
  max-width: 920px; }
  .TrackingEdit .modal-title {
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
    color: #000000; }

.FormTracking .ListTracking {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px; }
  .FormTracking .ListTracking.Label {
    margin-bottom: 0; }
  .FormTracking .ListTracking input {
    height: 35px;
    border-color: #dfdfdf;
    border-radius: 3px; }
  .FormTracking .ListTracking .ant-select {
    width: 230px; }
    .FormTracking .ListTracking .ant-select .ant-select-selector {
      height: 35px;
      border-color: #dfdfdf;
      border-radius: 3px; }
  .FormTracking .ListTracking .Column1 {
    width: 230px;
    margin-right: 20px; }
  .FormTracking .ListTracking .Column2 {
    width: 300px;
    margin-right: 20px; }
  .FormTracking .ListTracking .Column3 {
    width: 300px; }

.ModalEditTracking .CheckboxLockTracking {
  color: #555;
  margin: 5px 12px; }
  .ModalEditTracking .CheckboxLockTracking input[type="checkbox"] {
    display: none; }
    .ModalEditTracking .CheckboxLockTracking input[type="checkbox"] ~ i {
      color: #AAA;
      margin: -3px 3px 0 0;
      display: inline-block;
      vertical-align: middle;
      font-size: 90%; }
    .ModalEditTracking .CheckboxLockTracking input[type="checkbox"]:checked ~ i {
      color: #0a6ebd;
      font-weight: bold; }

.FulfilledPackageItemMap .col-12 {
  margin-top: 30px; }
  .FulfilledPackageItemMap .col-12 h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 15px; }

.FulfilledPackageItemMap .DimensionItem {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 16px;
  background: #F4FAFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 5px; }
  .FulfilledPackageItemMap .DimensionItem:last-child {
    margin-bottom: 0; }
  .FulfilledPackageItemMap .DimensionItem .RuleItem {
    display: flex;
    align-items: center;
    margin-right: 10px; }
    .FulfilledPackageItemMap .DimensionItem .RuleItem:last-child {
      margin-right: 0; }
    .FulfilledPackageItemMap .DimensionItem .RuleItem .Side {
      margin-right: 3px; }

.OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst {
  margin-bottom: 5px; }
  .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst:last-child, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst:last-child, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst:last-child {
    border-bottom: 0; }
  .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst.More, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst.More, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst.More {
    border-top: 1px solid #D8DBDE;
    border-bottom: 0; }
  .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .TypeArtwork, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .TypeArtwork, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .TypeArtwork {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #303238;
    margin-bottom: 5px; }
    .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .TypeArtwork span, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .TypeArtwork span, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .TypeArtwork span {
      font-size: 18px;
      float: right;
      color: #0A7BFE;
      font-weight: bold; }
  .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ValidateDimension, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ValidateDimension, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ValidateDimension {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px; }
    .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ValidateDimension span, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ValidateDimension span, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ValidateDimension span {
      margin-left: 5px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #F70000; }
  .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 10px; }
    .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .QuantityArtwork, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .QuantityArtwork, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .QuantityArtwork {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #303238; }
      .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .QuantityArtwork span, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .QuantityArtwork span, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .QuantityArtwork span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #303238; }
    .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .ActionBackground button, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .ActionBackground button, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .ActionBackground button {
      padding: 1px 5px;
      border: 1px solid #D8DBDE;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      background: #FFFFFF;
      color: #303238;
      outline: none; }
      .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .ActionBackground button:active, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .ActionBackground button:active, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .ActionBackground button:active {
        border: 1px solid #0f9d58;
        outline: none; }
      .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .ActionBackground button.Active, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .ActionBackground button.Active, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .Actions .ActionBackground button.Active {
        background: #0f9d58;
        border: 1px solid #0f9d58;
        color: #FFFFFF;
        outline: none; }
  .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages {
    position: relative;
    margin-bottom: 5px; }
    .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt {
      text-align: center;
      width: 100px;
      height: 100px;
      margin: 0 auto; }
      .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt .OriginImage, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt .OriginImage, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt .OriginImage {
        text-align: center;
        width: 100px;
        height: 100px;
        overflow: hidden;
        border: 1px solid #D8DBDE; }
      .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt img.ArtworkItem, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt img.ArtworkItem, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt img.ArtworkItem {
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        margin: 0 auto; }
        .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt img.ArtworkItem.HasWrong, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt img.ArtworkItem.HasWrong, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt img.ArtworkItem.HasWrong {
          border: 3px solid #F70000; }
      .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt .Hover, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt .Hover, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt .Hover {
        display: none; }
      .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt:hover, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt:hover, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt:hover {
        cursor: pointer; }
        .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt:hover .Hover, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt:hover .Hover, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt:hover .Hover {
          display: block;
          position: absolute;
          right: -426%;
          top: 30px;
          left: 99px;
          z-index: 99;
          padding: 5px;
          background: #FFFFFF;
          border: 1px solid #D8DBDE;
          box-shadow: 3px 3px 8px -4px rgba(0, 0, 0, 0.08), 3px 6px 16px rgba(0, 0, 0, 0.06), 3px 9px 32px 8px rgba(0, 0, 0, 0.04); }
          .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt:hover .Hover img, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt:hover .Hover img, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt:hover .Hover img {
            width: 400px;
            height: auto; }
    .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages > .mb-2, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages > .mb-2, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages > .mb-2 {
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages i:hover, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages i:hover, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages i:hover {
      color: #0f9d58; }
    .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages a, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages a, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages a {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px; }
    .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .Meta, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .Meta, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .Meta {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      margin-top: 5px; }
    .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ErrorDimension, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ErrorDimension, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ErrorDimension {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #F70000; }
  .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt .OriginImage, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt .OriginImage, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt .OriginImage {
    position: relative; }
    .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt .OriginImage .CheckComplex, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt .OriginImage .CheckComplex, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt .OriginImage .CheckComplex {
      position: absolute;
      width: 24px;
      height: 24px;
      border: 2px solid #ff3b15;
      right: 5px;
      top: 5px;
      z-index: 200; }
  .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt.selected .OriginImage:before, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt.selected .OriginImage:before, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt.selected .OriginImage:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background: rgba(0, 0, 0, 0.8);
    z-index: 100; }
  .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt.selected .OriginImage .CheckComplex:after, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt.selected .OriginImage .CheckComplex:after, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt.selected .OriginImage .CheckComplex:after {
    width: 14px;
    height: 10px;
    border: 4px solid #ff3b15;
    border-top: none;
    border-right: none;
    content: '';
    transform: rotate(-45deg);
    position: absolute;
    left: 4px;
    top: 3px; }
  .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt:hover .Hover, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt:hover .Hover, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ImageArt:hover .Hover {
    left: 102px !important;
    width: 212px; }
  .OrderItemRow .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ArtworkListNew > li:hover, .FulfilledPackageItem .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ArtworkListNew > li:hover, .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst.EmbroiderySurchargeContainer .ArtworkListNew > li:hover {
    z-index: 1000; }

.OrderItemRow .OrderItemDesignV3 .ShowAll, .FulfilledPackageItem .OrderItemDesignV3 .ShowAll, .ContentDetail .OrderItemDesignV3 .ShowAll {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #278DFC; }
  .OrderItemRow .OrderItemDesignV3 .ShowAll:hover, .FulfilledPackageItem .OrderItemDesignV3 .ShowAll:hover, .ContentDetail .OrderItemDesignV3 .ShowAll:hover {
    cursor: pointer;
    text-decoration-line: underline; }

.ProductSource .ThumbnailWrapper {
  text-align: center; }

.ProductSource .Actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 10px; }
  .ProductSource .Actions .QuantityArtwork {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #303238; }
    .ProductSource .Actions .QuantityArtwork span {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #303238; }
  .ProductSource .Actions .ActionBackground button {
    padding: 1px 5px;
    border: 1px solid #D8DBDE;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    background: #FFFFFF;
    color: #303238;
    outline: none; }
    .ProductSource .Actions .ActionBackground button:active {
      border: 1px solid #0f9d58;
      outline: none; }
    .ProductSource .Actions .ActionBackground button.Active {
      background: #0f9d58;
      border: 1px solid #0f9d58;
      color: #FFFFFF;
      outline: none; }

.ProductSource .listArtwork {
  margin-top: 30px; }
  .ProductSource .listArtwork .ArtworkImages {
    position: relative;
    margin-bottom: 5px; }
    .ProductSource .listArtwork .ArtworkImages .ImageArt {
      text-align: center;
      width: 100px;
      height: 100px;
      margin: 0 auto; }
      .ProductSource .listArtwork .ArtworkImages .ImageArt .OriginImage {
        text-align: center;
        width: 100px;
        height: 100px;
        overflow: hidden;
        border: 1px solid #D8DBDE; }
      .ProductSource .listArtwork .ArtworkImages .ImageArt img.ArtworkItem {
        height: 100%;
        -o-object-fit: contain;
        object-fit: contain;
        margin: 0 auto; }
        .ProductSource .listArtwork .ArtworkImages .ImageArt img.ArtworkItem.HasWrong {
          border: 3px solid #F70000; }
      .ProductSource .listArtwork .ArtworkImages .ImageArt .Hover {
        display: none; }
      .ProductSource .listArtwork .ArtworkImages .ImageArt:hover {
        cursor: pointer; }
        .ProductSource .listArtwork .ArtworkImages .ImageArt:hover .Hover {
          display: block;
          position: absolute;
          right: -117%;
          top: 30px;
          z-index: 99;
          padding: 5px;
          background: #FFFFFF;
          border: 1px solid #D8DBDE;
          box-shadow: 3px 3px 8px -4px rgba(0, 0, 0, 0.08), 3px 6px 16px rgba(0, 0, 0, 0.06), 3px 9px 32px 8px rgba(0, 0, 0, 0.04); }
          .ProductSource .listArtwork .ArtworkImages .ImageArt:hover .Hover img {
            width: 200px;
            height: auto; }
    .ProductSource .listArtwork .ArtworkImages a {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px; }
    .ProductSource .listArtwork .ArtworkImages .Meta {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      margin-top: 5px; }
    .ProductSource .listArtwork .ArtworkImages .ErrorDimension {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #F70000; }

.ProductSource .ShowAll {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  color: #278DFC; }
  .ProductSource .ShowAll:hover {
    cursor: pointer;
    text-decoration-line: underline; }

.OrderItemDesign {
  position: relative; }
  .OrderItemDesign .RequestUpdateModal svg {
    position: absolute;
    right: 10px;
    top: 10px; }
    .OrderItemDesign .RequestUpdateModal svg:hover {
      cursor: pointer; }
      .OrderItemDesign .RequestUpdateModal svg:hover path {
        fill: #0f9d58; }

.RequestModal .ContentModal .RequestType {
  margin-bottom: 15px; }
  .RequestModal .ContentModal .RequestType label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #303238;
    min-width: 100px; }
  .RequestModal .ContentModal .RequestType .ant-select {
    width: calc(100% - 100px); }
    .RequestModal .ContentModal .RequestType .ant-select .ant-select-selection-item {
      color: #333333; }

.RequestModal .ContentModal .RequestDescription {
  margin-bottom: 15px;
  position: relative; }
  .RequestModal .ContentModal .RequestDescription label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #303238;
    display: block; }
  .RequestModal .ContentModal .RequestDescription textarea {
    padding: 16px;
    width: 100%;
    resize: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #303238; }
    .RequestModal .ContentModal .RequestDescription textarea::placeholder {
      font-style: italic;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #BFBFBF; }
    .RequestModal .ContentModal .RequestDescription textarea:focus, .RequestModal .ContentModal .RequestDescription textarea:active, .RequestModal .ContentModal .RequestDescription textarea:visited {
      border: 1px solid #0f9d58;
      outline: none; }
  .RequestModal .ContentModal .RequestDescription .Language {
    margin-left: 10px; }
    .RequestModal .ContentModal .RequestDescription .Language .NameSpace {
      font-size: 87.5%;
      color: #e83e8c;
      word-wrap: break-word;
      margin-right: 5px; }
  .RequestModal .ContentModal .RequestDescription .Results {
    position: absolute;
    width: 100%;
    background: #FFFFFF;
    z-index: 99;
    max-height: 150px;
    overflow-y: scroll;
    box-sizing: border-box;
    box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 2px 0 0 2px; }
    .RequestModal .ContentModal .RequestDescription .Results ul {
      margin: 0;
      padding: 0; }
      .RequestModal .ContentModal .RequestDescription .Results ul li {
        list-style: none;
        padding: 10px 20px;
        border-bottom: 1px solid #E5E5E5; }
        .RequestModal .ContentModal .RequestDescription .Results ul li:hover {
          cursor: pointer;
          background: #0f9d58;
          color: #FFFFFF; }

.RequestModal .ContentModal .AttachFile {
  margin-bottom: 14px; }
  .RequestModal .ContentModal .AttachFile input {
    display: none; }
  .RequestModal .ContentModal .AttachFile label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #477BF6; }

.RequestModal .ContentModal .ListFileInput ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; }
  .RequestModal .ContentModal .ListFileInput ul li:last-child {
    margin-bottom: 0; }
  .RequestModal .ContentModal .ListFileInput ul li .Left {
    display: flex;
    align-items: center; }
    .RequestModal .ContentModal .ListFileInput ul li .Left .FileName {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #477BF6;
      margin-left: 10px; }
  .RequestModal .ContentModal .ListFileInput ul li svg.Delete:hover {
    cursor: pointer; }
    .RequestModal .ContentModal .ListFileInput ul li svg.Delete:hover path {
      fill: #9a0000; }

.RequestModal .ant-modal-footer {
  padding: 24px; }
  .RequestModal .ant-modal-footer button {
    height: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    padding: 0 32px;
    border-radius: 4px; }

.RequestList {
  margin-bottom: 15px; }
  .RequestList .HeadingRequest {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 12px;
    background: rgba(222, 226, 230, 0.73);
    border: 1px solid rgba(117, 107, 107, 0.6);
    border-radius: 4px; }
    .RequestList .HeadingRequest .Title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #303238; }
    .RequestList .HeadingRequest:hover {
      cursor: pointer; }
  .RequestList .ContentRequest {
    display: none; }
    .RequestList .ContentRequest .ItemRequest {
      padding: 12px;
      margin-top: 5px;
      border-radius: 4px; }
      .RequestList .ContentRequest .ItemRequest.waiting {
        border: 1px solid #F3CC00;
        background: rgba(247, 231, 147, 0.33); }
      .RequestList .ContentRequest .ItemRequest.processing {
        border: 1px solid #F3CC00;
        background: rgba(247, 231, 147, 0.33); }
      .RequestList .ContentRequest .ItemRequest.updated {
        background: rgba(64, 146, 87, 0.13);
        border: 1px solid #409257; }
      .RequestList .ContentRequest .ItemRequest > div {
        margin-bottom: 7px; }
        .RequestList .ContentRequest .ItemRequest > div span {
          text-transform: capitalize;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #303238; }
        .RequestList .ContentRequest .ItemRequest > div .Link {
          font-weight: normal;
          color: #0A7BFE; }
          .RequestList .ContentRequest .ItemRequest > div .Link:hover {
            cursor: pointer;
            text-decoration: underline; }
    .RequestList .ContentRequest.isOpen {
      display: block; }

.ImageRequest {
  width: 50% !important;
  text-align: center; }

@media (max-width: 1600px) and (min-width: 1200px) {
  .FulfilledPackageItems .table td.FulfilledPackageItemProduct {
    width: 30%; }
  .FulfilledPackageItems .table td.FulfilledPackageItemMap {
    width: 30%; }
  .FulfilledPackageItems .table td.OrderItemDesignV3 {
    width: 40%; } }

.OrderFulfillmentContainer {
  margin-top: 20px; }
  .OrderFulfillmentContainer .Fulfillment .Summary {
    margin-top: 20px; }
  .OrderFulfillmentContainer .MarkAsFulfilled {
    margin-top: 20px; }
    .OrderFulfillmentContainer .MarkAsFulfilled .cursor-pointer {
      cursor: pointer; }
    .OrderFulfillmentContainer .MarkAsFulfilled .NumericInput {
      width: 80px;
      height: 100%;
      text-align: center; }
  .OrderFulfillmentContainer .QuantityFulfill tr {
    border-bottom: 1px solid #eee; }
  .OrderFulfillmentContainer .QuantityFulfill .ItemInfo {
    margin: auto; }

.OrderFulfillmentPage .is-dragging-over {
  background-color: rgba(0, 123, 255, 0.15) !important; }
  .OrderFulfillmentPage .is-dragging-over .split-package__empty {
    border: none;
    background-color: rgba(0, 123, 255, 0.15) !important; }

.OrderFulfillmentPage .is-dragging {
  user-select: none; }
  .OrderFulfillmentPage .is-dragging .split-package__item {
    background-color: #d9d9d9; }

.OrderFulfillmentPage__loading {
  min-height: 95px; }
  .OrderFulfillmentPage__loading > .ant-spin-dot {
    left: 0 !important;
    margin-left: 0 !important; }
  .OrderFulfillmentPage__loading .ant-spin-text {
    text-align: left; }

.OrderFulfillmentPage .split-package__item {
  margin: 15px 0;
  border: 1px solid #cccc;
  border-radius: 2px; }

.OrderFulfillmentPage .split-package__header {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #cccc;
  padding: 10px 15px; }
  .OrderFulfillmentPage .split-package__header-name {
    margin-bottom: 0; }
  .OrderFulfillmentPage .split-package__header-input-skip {
    display: inline-block;
    min-width: 130px; }

.OrderFulfillmentPage .split-package__btn-remove {
  color: #f44336;
  cursor: pointer; }
  .OrderFulfillmentPage .split-package__btn-remove--is-disabled {
    color: #72777a;
    cursor: not-allowed;
    opacity: 0.8; }

.OrderFulfillmentPage .split-package__body {
  background-color: #ffffff; }
  .OrderFulfillmentPage .split-package__body-error .ant-spin-text {
    top: 20px !important;
    font-weight: 700;
    color: #f44336 !important; }
  .OrderFulfillmentPage .split-package__body-error .ant-spin-dot {
    display: none !important; }
  .OrderFulfillmentPage .split-package__body-skip.split-package__body-processing .ant-spin {
    display: none !important; }

.OrderFulfillmentPage .split-package__empty {
  min-height: 200px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  color: #565a5c;
  border: 10px solid #f0f0f0;
  background: #dee2e6; }

.OrderFulfillmentPage .split-package__supplier {
  display: inline-block;
  padding: 0 100px; }
  .OrderFulfillmentPage .split-package__supplier-select {
    min-width: 150px; }
  .OrderFulfillmentPage .split-package__supplier--is-carrier {
    min-width: 190px; }

.OrderFulfillmentPage .split-package__order-item {
  border-radius: 2px;
  padding: 15px;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  cursor: move;
  word-break: break-word; }
  .OrderFulfillmentPage .split-package__order-item li {
    margin-bottom: 5px; }
  .OrderFulfillmentPage .split-package__order-item .Label {
    display: inline-block;
    padding-right: 5px;
    min-width: 85px;
    font-weight: bold; }
  .OrderFulfillmentPage .split-package__order-item .Value {
    word-break: break-word; }

.OrderFulfillmentPage .split-package__order-item-thumbnail {
  flex: 0 0 100px; }

.OrderFulfillmentPage .split-package__order-item-info {
  padding: 0 15px;
  width: 100%; }

.OrderFulfillmentPage .split-package__footer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end; }
  .OrderFulfillmentPage .split-package__footer-btn {
    margin-left: 20px; }

.split-package__modal {
  width: 90% !important;
  max-width: 600px; }

.split-quantity {
  text-align: center; }
  .split-quantity__button {
    margin-left: 10px; }
  .split-quantity__footer {
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    margin-top: 10px;
    padding-top: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center; }
    .split-quantity__footer button {
      margin: 0 5px; }

.OrderFulfillmentProduct .Label {
  min-width: 125px; }

.OrderFulfilmentSelectItem {
  /* Rounded sliders */ }
  .OrderFulfilmentSelectItem .switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 20px; }
  .OrderFulfilmentSelectItem .switch input {
    opacity: 0;
    width: 0;
    height: 0; }
  .OrderFulfilmentSelectItem .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s; }
  .OrderFulfilmentSelectItem .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s; }
  .OrderFulfilmentSelectItem input:checked + .slider {
    background-color: #2196F3; }
  .OrderFulfilmentSelectItem input:focus + .slider {
    box-shadow: 0 0 1px #2196F3; }
  .OrderFulfilmentSelectItem input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px); }
  .OrderFulfilmentSelectItem .slider.round {
    border-radius: 34px; }
  .OrderFulfilmentSelectItem .slider.round:before {
    border-radius: 50%; }

.fetch-production-status > * {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px; }

.fetch-production-status .anticon-spin {
  animation: loadingCircle 3s linear infinite;
  font-size: 20px; }

.fetch-production-status span {
  color: #666; }

.DashboardContainer .DashboardInner {
  min-height: 100vh;
  padding-left: 280px;
  transition: all 0.2s ease; }

.DashboardContainer .MainContentInner .SectionInner {
  background-color: #fff;
  padding: 15px;
  border: solid 1px rgba(0, 0, 0, 0.0625);
  border-radius: 4px; }
  .DashboardContainer .MainContentInner .SectionInner .imageMockup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background: #FFFFFF;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    z-index: 999999;
    padding: 15px;
    opacity: 0;
    visibility: hidden; }
    .DashboardContainer .MainContentInner .SectionInner .imageMockup.visible {
      opacity: 1;
      visibility: visible; }

.DashboardContainer .MainContent {
  padding: 0 30px 60px;
  min-height: 100vh;
  background-color: #f9fafb; }

.DashboardContainer .nav-link .Label {
  color: #fff; }

.DashboardContainer.isCollapsed .nav-link .Label {
  display: none; }

.DashboardContainer.isCollapsed .NavbarLeft .Title {
  display: none; }

.DashboardContainer.isCollapsed .NavbarLeft .NavbarLeftInner {
  width: 70px; }

.DashboardContainer.isCollapsed .DashboardInner {
  padding-left: 70px; }

.DashboardContainer.isCollapsed .NavbarTop {
  width: calc(100% - 70px); }

.nav-item-tooltip .ant-tooltip-inner {
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  word-wrap: break-word;
  background-color: #0f9d58;
  color: #fff;
  font-weight: 400;
  padding: 4px 15px;
  line-height: 2;
  font-size: 15px; }

.nav-item-tooltip .ant-tooltip-arrow {
  display: none; }

.popover-user .ant-popover-inner {
  border-radius: 0.3ren;
  max-width: 276px; }

@media (max-width: 991.98px) {
  .DashboardContainer {
    overflow-x: hidden; }
    .DashboardContainer .MainContent {
      padding: 0; }
    .DashboardContainer .MainContentInner .SectionInner {
      border: none; }
    .DashboardContainer.isCollapsed .NavbarLeft {
      width: 0;
      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.7, 0.3, 0.1, 1); }
      .DashboardContainer.isCollapsed .NavbarLeft .NavbarMask {
        position: static;
        opacity: 0; }
    .DashboardContainer.isCollapsed .NavbarTop {
      width: 100%; } }

.ant-drawer.OrdersWarnings .ant-collapse-content-box {
  padding: 24px; }

.ant-drawer.OrdersWarnings ul.ant-list-items {
  list-style: auto; }

.NavbarLeft {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;
  border-right: solid 1px rgba(0, 0, 0, 0.0625); }
  .NavbarLeft .NavbarMask {
    position: absolute;
    top: 21px;
    right: -12px;
    background: #0f9d58;
    width: 24px;
    height: 24px;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.0625);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
    cursor: pointer; }
  .NavbarLeft .NavbarLeftBox {
    width: 100%;
    height: 100%;
    overflow-y: scroll; }
    .NavbarLeft .NavbarLeftBox::-webkit-scrollbar {
      display: none; }
  .NavbarLeft .NavbarLeftInner {
    width: 280px;
    flex-direction: column;
    min-height: 100%;
    background-color: #fff;
    transition: transform 0.3s cubic-bezier(0.9, 0, 0.3, 0.7); }
    .NavbarLeft .NavbarLeftInner .BottomMenu {
      margin-bottom: 10px; }
  .NavbarLeft .nav-link {
    display: flex;
    align-items: baseline;
    font-size: 1rem;
    font-weight: 500;
    padding: 5px 15px;
    position: relative;
    white-space: nowrap;
    color: #303238;
    transition: all 0.3s ease; }
    .NavbarLeft .nav-link:hover, .NavbarLeft .nav-link.active {
      color: #0f9d58;
      text-decoration: none; }
    .NavbarLeft .nav-link .BadgePosition {
      position: absolute;
      top: -3px;
      right: 10px; }
      .NavbarLeft .nav-link .BadgePosition.expand {
        flex-grow: 1;
        display: flex;
        align-content: space-between;
        align-items: center;
        top: 14px; }
        .NavbarLeft .nav-link .BadgePosition.expand .Title {
          flex-grow: 1; }
        .NavbarLeft .nav-link .BadgePosition.expand .ant-badge-count {
          position: static;
          transform: translate(0, 0); }
  .NavbarLeft .Icon {
    border-radius: 6px;
    display: inline-block;
    font-size: 17px;
    height: 35px;
    left: 0;
    line-height: 35px;
    position: relative;
    text-align: center;
    width: 35px;
    cursor: pointer; }
  .NavbarLeft .NavLogo {
    border-bottom: solid 1px rgba(0, 0, 0, 0.0625); }
    .NavbarLeft .NavLogo .SiteName {
      color: #313435;
      font-weight: 500;
      margin-left: 5px;
      font-size: 1.09375rem;
      vertical-align: middle; }
    .NavbarLeft .NavLogo .LinkLogo {
      text-decoration: none;
      display: flex;
      align-items: center; }
    .NavbarLeft .NavLogo .LogoImage {
      flex: 0 0 66px; }
      .NavbarLeft .NavLogo .LogoImage img {
        width: 100%;
        padding: 10px; }
  .NavbarLeft .PrimaryMenu {
    margin-top: 10px; }
    .NavbarLeft .PrimaryMenu .Title {
      display: inline-block;
      margin-left: 14px; }
  .NavbarLeft .BottomMenu .Title {
    display: inline-block;
    margin-left: 14px; }

@media (max-width: 991.98px) {
  .NavbarLeft {
    width: 100%; }
    .NavbarLeft .NavbarLeftInner {
      position: fixed;
      z-index: 2;
      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.9, 0, 0.3, 0.7); }
    .NavbarLeft .NavbarMask {
      position: fixed;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: .3;
      background-color: rgba(0, 0, 0, 0.65);
      transition: opacity .3s; } }

.BadgePosition .ant-scroll-number-only p {
  line-height: 20px !important;
  height: 20px !important; }

.BadgePosition.issue-badge .ant-badge-count {
  top: 4px !important; }

.BadgePosition.issue-badge .ant-scroll-number-only p {
  line-height: 20px !important;
  height: 20px !important; }

.NavbarTop {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0625);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  margin-bottom: 0;
  position: fixed;
  transition: all .2s ease;
  width: calc(100% - 280px);
  z-index: 800;
  padding: 0 15px; }
  .NavbarTop .SideBarToggle {
    cursor: pointer; }
    .NavbarTop .SideBarToggle i {
      font-size: 17px; }
  .NavbarTop .LogoLink img {
    height: 40px;
    width: auto; }
  .NavbarTop .LogoLink .SiteName {
    display: inline-block;
    color: #303238;
    font-size: 1.625rem;
    margin-top: -6px; }
  .NavbarTop > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: inline-flex; }
    .NavbarTop > ul > li {
      display: inline-flex;
      align-items: center;
      padding: 0 15px; }
  .NavbarTop .LogoLink {
    display: inline-flex;
    align-items: center; }
  .NavbarTop ul.NavRight > li:not(:last-child) {
    border-right: solid 1px #e8e8e8; }

@media (max-width: 991.98px) {
  .NavbarTop {
    width: 100%; }
    .NavbarTop .NavRight {
      font-size: .875rem; }
    .NavbarTop > ul {
      margin: 0 -10px; }
      .NavbarTop > ul > li {
        padding: 0 10px; } }

.SettingsContainer .Card {
  padding: 0 15px; }
  .SettingsContainer .Card .SettingsWrapper {
    display: flex;
    flex-wrap: wrap; }
    .SettingsContainer .Card .SettingsWrapper .SettingInner {
      padding: 12px; }
      .SettingsContainer .Card .SettingsWrapper .SettingInner:hover {
        background: #f9fafb; }
        .SettingsContainer .Card .SettingsWrapper .SettingInner:hover .IconWrapper {
          background: #dfe3e8; }
        .SettingsContainer .Card .SettingsWrapper .SettingInner:hover .Info .Title {
          color: #084e8a; }
    .SettingsContainer .Card .SettingsWrapper .SettingWrapper {
      cursor: pointer; }
      .SettingsContainer .Card .SettingsWrapper .SettingWrapper .IconWrapper {
        height: 2.5rem;
        width: 2.5rem;
        background: #f4f6f8;
        color: #919eab;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        flex-shrink: 0; }
      .SettingsContainer .Card .SettingsWrapper .SettingWrapper .Info {
        padding: 0 1rem;
        margin-top: -6px; }
        .SettingsContainer .Card .SettingsWrapper .SettingWrapper .Info p {
          padding: 0;
          margin: 0; }
        .SettingsContainer .Card .SettingsWrapper .SettingWrapper .Info .Title {
          color: #007ace;
          margin: 0; }

.SettingsContainer .ProductInfo .SettingInput label {
  flex-shrink: 0;
  margin-right: 20px; }

#discount_value {
  max-width: 100px; }

.SettingContainer .BackWrapper .BackButton, .RolesPageContainer .BackWrapper .BackButton {
  display: inline-block;
  cursor: pointer;
  padding-left: 0;
  padding-right: 0; }
  .SettingContainer .BackWrapper .BackButton span, .RolesPageContainer .BackWrapper .BackButton span {
    margin: 0 1rem; }

.SettingContainer .MenuTitle, .RolesPageContainer .MenuTitle {
  font-weight: 600;
  font-size: 1.75rem;
  border-bottom: 1px solid #dfe3e8;
  padding-bottom: 0.5rem; }

.SettingContainer .Section, .RolesPageContainer .Section {
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding: 1.5rem 0; }
  .SettingContainer .Section .SectionSetting, .RolesPageContainer .Section .SectionSetting {
    width: 100%;
    grid-column: 2/5;
    height: 100%; }
    .SettingContainer .Section .SectionSetting .Card, .RolesPageContainer .Section .SectionSetting .Card {
      height: 100%; }

.SettingContainer .ButtonSave, .RolesPageContainer .ButtonSave {
  float: right; }
  .SettingContainer .ButtonSave button, .RolesPageContainer .ButtonSave button {
    margin: 1rem 0 2rem;
    min-width: 150px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all .3s; }

.SettingContainer .BreakLine, .RolesPageContainer .BreakLine {
  border-bottom: 1px solid #ccc;
  margin: 1rem 0; }

.rolePermissions .ant-select-selector {
  overflow: auto;
  max-height: 300px; }

.SectionDetails {
  width: 100%;
  grid-column: 1/2; }

.StoreCurrency .SectionHeader {
  display: block; }
  .StoreCurrency .SectionHeader label {
    float: left; }
  .StoreCurrency .SectionHeader span {
    float: right;
    color: #007bff;
    cursor: pointer; }

.SaveSuccessModal {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;
  width: 10vw;
  transform: translate(45vw, 0); }
  .SaveSuccessModal .Card {
    display: none;
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .SaveSuccessModal .display {
    display: flex;
    align-items: center;
    justify-items: center; }

.SettingCheckbox {
  margin-bottom: 1rem; }

.EditorApp {
  padding: .375rem .75rem;
  border: 1px solid rgba(0, 0, 0, 0.0625);
  border-top: 0;
  border-radius: 0 0 .25rem .25rem;
  min-height: 15rem; }

.EditorToolbar {
  margin: 0;
  border-radius: .25rem .25rem 0 0;
  padding: .375rem .75rem;
  background: #FAFBFC; }

.EditorToolbarButton {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  margin: 0 .375rem;
  padding: .5rem auto;
  height: 100%; }
  .EditorToolbarButton:hover {
    box-shadow: none; }
  .EditorToolbarButton:active {
    border-color: #80bdff; }

.rdw-list-wrapper {
  margin: 0; }

.rdw-image-wrapper {
  margin: 0; }

.rdw-image-wrapper {
  margin: 0; }

.rdw-inline-wrapper {
  margin: 0; }

.rdw-option-active {
  box-shadow: none;
  border-color: #80bdff; }

.rdw-image-modal {
  margin-top: .25rem;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  padding: .75rem; }
  .rdw-image-modal .rdw-image-modal-header {
    margin: 0; }
    .rdw-image-modal .rdw-image-modal-header .rdw-image-modal-header-option .rdw-image-modal-header-label-highlighted {
      width: 100%;
      border-bottom: 1px solid #80bdff; }
  .rdw-image-modal .rdw-image-modal-url-input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    .rdw-image-modal .rdw-image-modal-url-input:focus {
      border-color: #80bdff; }
  .rdw-image-modal .rdw-image-modal-size-input {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    .rdw-image-modal .rdw-image-modal-size-input:focus {
      border-color: #80bdff; }
  .rdw-image-modal .rdw-image-mandatory-sign {
    display: none; }
  .rdw-image-modal .rdw-image-modal-btn {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    border-radius: .25rem; }
    .rdw-image-modal .rdw-image-modal-btn:hover {
      box-shadow: none; }

.TableFilter {
  width: 100%;
  padding: 0; }
  .TableFilter .SelectWrapper select {
    cursor: pointer;
    padding: 0 8px;
    border: 1px solid #ced4da;
    background: #f9fafb;
    border-radius: 5px 0 0 5px; }
  .TableFilter .CategoriesSelector {
    width: 400px; }
    @media (min-width: 1025px) and (max-width: 1400px) {
      .TableFilter .CategoriesSelector {
        width: 220px; } }
  .TableFilter .InputWrapper {
    width: 400px; }
    @media (min-width: 1025px) and (max-width: 1400px) {
      .TableFilter .InputWrapper {
        width: 220px; } }
    .TableFilter .InputWrapper > i {
      top: 12px;
      left: 12px; }
    .TableFilter .InputWrapper .InputSearch {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .TableFilter .TotalEntity {
    display: flex;
    justify-content: flex-end;
    align-items: center; }
    .TableFilter .TotalEntity .Entity {
      font-weight: 700; }
  .TableFilter .TableLength {
    color: #313435;
    font-weight: 400; }
    .TableFilter .TableLength select {
      border: 1px solid rgba(0, 0, 0, 0.0625);
      border-radius: 2px;
      box-shadow: none;
      height: 40px;
      font-size: 14px;
      padding: 5px;
      margin-left: 5px;
      margin-right: 5px;
      color: #303238;
      transition: all 0.2s ease-in; }

@media (max-width: 768px) {
  .tableWrapResponsive {
    overflow-x: scroll; }
    .tableWrapResponsive table {
      width: auto;
      width: max-content; } }

.FilterOptions .Heading {
  font-weight: 500;
  font-size: .875rem; }

.FilterDateRange {
  position: relative; }
  .FilterDateRange .Heading {
    font-weight: 500;
    font-size: .875rem; }

.ShipmentStatus .Statuses {
  display: inline-block;
  max-width: 180px;
  margin: -2px 7px 0 0; }
  .ShipmentStatus .Statuses .badge {
    color: #fff;
    background-color: #999;
    margin-right: 5px; }
    .ShipmentStatus .Statuses .badge:last-child {
      margin-right: 0; }
  .ShipmentStatus .Statuses .in_transit {
    background-color: #3498db; }
  .ShipmentStatus .Statuses .delivered, .ShipmentStatus .Statuses .out_for_delivery {
    background-color: #28a745; }
  .ShipmentStatus .Statuses .cancelled, .ShipmentStatus .Statuses .failure {
    background-color: #dc3545; }

.ArtworksTableRow .ThumbnailWrapper {
  display: flex; }
  .ArtworksTableRow .ThumbnailWrapper .ThumbnailInner {
    position: relative; }
    .ArtworksTableRow .ThumbnailWrapper .ThumbnailInner .ThumbnailImage {
      height: 35px;
      width: 35px;
      object-fit: cover;
      border-radius: 5px; }
      .ArtworksTableRow .ThumbnailWrapper .ThumbnailInner .ThumbnailImage:hover + .OriginImage {
        display: block; }
    .ArtworksTableRow .ThumbnailWrapper .ThumbnailInner .OriginImage {
      display: none;
      position: absolute;
      left: calc(100% + 15px);
      top: 50%;
      transform: translateY(-50%);
      max-width: 200px; }

.PlatformsPage .TopTableButtonGroup {
  margin: 0 0 15px;
  display: flex;
  width: 100%;
  justify-content: flex-end; }

.PlatformsTable .Table td, .PlatformsTable .Table th {
  text-align: center; }

.PlatformsTable .Table .EditButton {
  background-color: #0f9d58;
  min-width: 2rem;
  height: 2rem;
  border: 1px solid #0f9d58;
  color: #fff;
  border-radius: 4px;
  outline: none;
  transition: all .1s linear; }
  .PlatformsTable .Table .EditButton:hover {
    color: #0f9d58;
    background: #fff; }

.PlatformEditModal .CloseButton {
  color: #0f9d58;
  border: 1px solid #0f9d58;
  transition: all .2s linear; }
  .PlatformEditModal .CloseButton:hover {
    background: #0f9d58;
    color: #fff; }

.PlatformEditModal .SaveButton {
  color: #fff;
  background: #0f9d58;
  border: 1px solid #0f9d58;
  transition: all .2s linear; }
  .PlatformEditModal .SaveButton:hover {
    background: #0d864b; }

.PlatformSettingsPage .SettingsWrapper {
  display: flex;
  flex-wrap: wrap; }
  .PlatformSettingsPage .SettingsWrapper .SettingInner {
    padding: 12px; }
    .PlatformSettingsPage .SettingsWrapper .SettingInner:hover {
      background: #f9fafb; }
      .PlatformSettingsPage .SettingsWrapper .SettingInner:hover .IconWrapper {
        background: #dfe3e8; }
      .PlatformSettingsPage .SettingsWrapper .SettingInner:hover .Info .Title {
        color: #084e8a; }
      .PlatformSettingsPage .SettingsWrapper .SettingInner:hover .Info .Description {
        color: #303238 !important; }
  .PlatformSettingsPage .SettingsWrapper .SettingWrapper {
    cursor: pointer; }
    .PlatformSettingsPage .SettingsWrapper .SettingWrapper .IconWrapper {
      height: 2.5rem;
      width: 2.5rem;
      background: #f4f6f8;
      color: #919eab;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      flex-shrink: 0; }
    .PlatformSettingsPage .SettingsWrapper .SettingWrapper .Info {
      padding: 0 1rem;
      margin-top: -6px; }
      .PlatformSettingsPage .SettingsWrapper .SettingWrapper .Info p {
        padding: 0;
        margin: 0; }
      .PlatformSettingsPage .SettingsWrapper .SettingWrapper .Info .Title {
        color: #007ace;
        margin: 0; }

.PlatformSettingPage .SettingInner .Info .Description {
  color: #303238 !important; }

.PlatformSettingPage .BackTitle {
  margin: 0 1rem; }

.PlatformSettingPage .SettingWrapper .Webhook .HeaderTop {
  border-bottom: 1px solid #dfe3e8; }

.PlatformSettingPage .SettingWrapper .Title {
  width: 100%;
  font-weight: 600;
  font-size: 1.75rem;
  padding: .5rem 0; }

.PlatformSettingPage .SettingWrapper .SettingSection {
  display: flex;
  width: 100%;
  margin: 1.5rem 0; }
  .PlatformSettingPage .SettingWrapper .SettingSection .SectionDetails {
    width: 25%; }
  .PlatformSettingPage .SettingWrapper .SettingSection .SectionSettings {
    width: 75%; }
    .PlatformSettingPage .SettingWrapper .SettingSection .SectionSettings .InputWrapper {
      margin-bottom: 1rem; }
      .PlatformSettingPage .SettingWrapper .SettingSection .SectionSettings .InputWrapper label {
        margin: 0; }

.PlatformSettingPage .SettingWrapper .SaveButtonGroup {
  display: flex;
  justify-content: flex-end; }

.SettingModal .ant-modal-body {
  padding: 20px 24px;
  height: 620px;
  overflow: auto; }

.SettingModal .ant-modal-close:focus {
  border: 0;
  outline: none !important; }

.SettingModal .Content .Webhook .HeaderTop {
  text-align: right;
  margin-bottom: 5px; }

.SettingModal .Content .Webhook .SettingSection .SectionSettings .SectionInner .Url .InputWrapper {
  margin-bottom: 20px; }
  .SettingModal .Content .Webhook .SettingSection .SectionSettings .SectionInner .Url .InputWrapper label {
    margin-bottom: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000; }
  .SettingModal .Content .Webhook .SettingSection .SectionSettings .SectionInner .Url .InputWrapper input {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #000000; }
  .SettingModal .Content .Webhook .SettingSection .SectionSettings .SectionInner .Url .InputWrapper:last-child {
    margin-bottom: 0; }

.SettingModal .ant-modal-footer {
  padding: 15px 24px; }
  .SettingModal .ant-modal-footer button {
    padding: 6.4px 25px; }

.PlatformPage .StatusBar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.PlatformPage .DetailsWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 1rem 0; }
  .PlatformPage .DetailsWrapper .P-5 {
    width: 50%; }
    .PlatformPage .DetailsWrapper .P-5:nth-child(2n + 1) {
      padding-right: .5rem; }
    .PlatformPage .DetailsWrapper .P-5:nth-child(2n) {
      padding-left: .5rem; }

.PlatformPage .SaveButtonGroup {
  display: flex;
  justify-content: flex-end; }

.PlatformApiKeys .Title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem; }

.PlatformApiKeys .ApiKeysTable {
  overflow: auto;
  max-height: 500px; }

.PlatformStatus .StatusWrapper {
  position: relative;
  width: fit-content; }
  .PlatformStatus .StatusWrapper .StatusButton {
    padding: .375rem .75rem;
    background-color: #fff;
    border-radius: 4px;
    z-index: 101;
    border: 1px solid rgba(33, 43, 54, 0.1);
    outline: none; }
    .PlatformStatus .StatusWrapper .StatusButton:hover {
      background-color: rgba(33, 43, 54, 0.1); }
  .PlatformStatus .StatusWrapper .StatusList {
    position: absolute;
    margin: .5rem 0 0;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: .375rem 0;
    background: #fff;
    z-index: 101;
    transform: scale(0);
    transition: all .1s linear;
    width: max-content; }
    .PlatformStatus .StatusWrapper .StatusList li {
      padding: .375rem .75rem;
      cursor: pointer; }
      .PlatformStatus .StatusWrapper .StatusList li:hover {
        background: #0f9d58;
        color: #fff; }
  .PlatformStatus .StatusWrapper .Show {
    transform: scale(1); }

.PlatformStatus .Background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0; }

.ApiKeyResultModal .ContentWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .ApiKeyResultModal .ContentWrapper .Text {
    width: 100%;
    text-align: center;
    margin: .25rem 0; }
  .ApiKeyResultModal .ContentWrapper .ApiKeyWrapper {
    margin: .5rem 0;
    padding: .375rem .75rem;
    border: 1px solid #0f9d58;
    border-radius: 4px;
    width: 100%; }

.BulkEditPrice i {
  cursor: pointer; }

tr.ProductVariantsRow > td > input {
  border-color: transparent; }

tr.ProductVariantsRow:hover > td > input {
  border-color: #ced4da; }

tr.ProductVariantsRow > td > .hasHistory > input {
  border-color: transparent; }

tr.ProductVariantsRow:hover > td > .hasHistory > input {
  border-color: #ced4da; }

.CategoriesModalDialog {
  width: 800px;
  max-width: 100%; }
  .CategoriesModalDialog .SearchInput {
    border-width: 0 0 2px 0;
    border-radius: 0; }

.CategoryItem:hover {
  background-color: rgba(0, 0, 0, 0.05); }

.CategoryItem .Title {
  line-height: 2.4; }

.ProductImagesItem {
  position: relative;
  padding: 10px; }
  .ProductImagesItem .ProductImageView {
    width: 100%;
    height: 100%;
    max-width: 100px;
    max-height: 100px;
    object-fit: cover; }
  .ProductImagesItem .RemoveImage {
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: 2px; }

.ProductAttributeItem .ListAttributes .Value {
  position: relative; }

.ProductAttributeItem select option.NotSelect {
  background: #dddddd; }

.selectSupplier .ant-select-selection__placeholder {
  color: #495057; }

.CompleteGenerateSKU {
  right: 0;
  left: 0;
  width: 500px;
  max-width: 100%;
  margin: auto; }

.ProductPage .input-error {
  border-color: #dc3545; }

.ProductPage .select-error .ant-select-selection {
  border-color: #dc3545; }

.ProductVariantsHead th.Price {
  width: 150px; }

.ProductVariantsHead th.Quantity {
  width: 100px; }

.ProductVariantsHead th.Color {
  width: 150px; }

.ProductVariantsHead th.Size {
  width: 100px; }

.ProductVariantsHead th.AdditionalPrice {
  width: 170px; }

.ProductCategories {
  margin-top: 40px; }

.ProductEditor .ActionProductLine {
  padding: 5px 15px;
  margin-bottom: 5px;
  border: 1px solid #0f9d58; }

.ProductEditor .ant-select {
  display: block; }

.ProductDimension .group {
  position: relative;
  padding-right: 35px !important;
  padding-bottom: 0 !important; }
  .ProductDimension .group .iconClose {
    position: absolute;
    top: 22px;
    right: 11px; }
  .ProductDimension .group .iconSave {
    position: absolute;
    top: 50px;
    right: 11px; }

.ProductDimension .itemChild > .row {
  margin-left: -5px;
  margin-right: -5px; }

@media (min-width: 1700px) {
  .ProductDimension .itemChild .itemSide {
    flex: 0 0 50%;
    max-width: 50%; } }

.ProductDimension .itemSide {
  padding-left: 5px;
  padding-right: 5px; }
  @media (min-width: 1500px) {
    .ProductDimension .itemSide .form-control {
      padding: 0 8px;
      width: 63px; } }

.ProductDimension button {
  width: 100%;
  background-color: #FFFFFF;
  border: 1px dashed #cccccc;
  color: rgba(0, 0, 0, 0.85); }
  .ProductDimension button:active, .ProductDimension button:focus {
    background-color: #FFFFFF;
    color: rgba(0, 0, 0, 0.85);
    border: 1px dashed #cccccc;
    box-shadow: none; }

.ChildProducts .group {
  position: relative;
  padding-right: 35px !important;
  padding-bottom: 0 !important; }
  .ChildProducts .group .iconClose {
    position: absolute;
    top: 22px;
    right: 11px; }
  .ChildProducts .group .iconSave {
    position: absolute;
    top: 50px;
    right: 11px; }

.ChildProducts .itemChild > .row {
  margin-left: -5px;
  margin-right: -5px; }

@media (min-width: 1700px) {
  .ChildProducts .itemChild .itemSide {
    flex: 0 0 50%;
    max-width: 50%; } }

.ChildProducts .itemSide {
  padding-left: 5px;
  padding-right: 5px; }
  @media (min-width: 1500px) {
    .ChildProducts .itemSide .form-control {
      padding: 0 8px;
      width: 63px; } }

.ChildProducts button.AddMore {
  width: 100%;
  background-color: #FFFFFF;
  border: 1px dashed #cccccc;
  color: rgba(0, 0, 0, 0.85); }
  .ChildProducts button.AddMore:active, .ChildProducts button.AddMore:focus {
    background-color: #FFFFFF;
    color: rgba(0, 0, 0, 0.85);
    border: 1px dashed #cccccc;
    box-shadow: none; }

.SuppliersTableRow i {
  cursor: pointer; }

.FiltersSuppliers {
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */ }
  .FiltersSuppliers .Name .ant-select {
    width: 150px !important; }
  .FiltersSuppliers .Name .Heading {
    font-size: unset; }
  .FiltersSuppliers .site-input-split {
    background-color: #fff; }
  .FiltersSuppliers .site-input-right {
    border-left-width: 0; }
  .FiltersSuppliers .site-input-right:hover,
  .FiltersSuppliers .site-input-right:focus {
    border-left-width: 1px; }
  .FiltersSuppliers .ant-input-rtl.site-input-right {
    border-right-width: 0; }
  .FiltersSuppliers .ant-input-rtl.site-input-right:hover,
  .FiltersSuppliers .ant-input-rtl.site-input-right:focus {
    border-right-width: 1px; }
  .FiltersSuppliers input::-webkit-outer-spin-button,
  .FiltersSuppliers input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .FiltersSuppliers input[type=number] {
    -moz-appearance: textfield; }

.input-currency-box {
  position: relative; }
  .input-currency-box .currency {
    display: block;
    width: 30px;
    height: 38px;
    background: #ced4da;
    text-align: center;
    line-height: 38px;
    border-radius: 5px 0 0 5px;
    position: absolute;
    top: 0;
    left: 0; }
  .input-currency-box input {
    padding-left: 40px; }

.ModalNewSupplier .customSelect .ant-select-selector, .ModalEditSupplier .customSelect .ant-select-selector {
  height: 38px !important;
  border-radius: 4px !important; }
  .ModalNewSupplier .customSelect .ant-select-selector .ant-select-selection-item, .ModalEditSupplier .customSelect .ant-select-selector .ant-select-selection-item {
    line-height: 36px !important; }

@media (min-width: 576px) {
  .ModalNewSupplier, .ModalEditSupplier {
    max-width: 680px !important; } }

@media (max-width: 1500px) {
  .ProductsTable {
    overflow-x: scroll;
    min-height: 300px; }
    .ProductsTable .table {
      width: auto;
      width: -moz-max-content;
      width: max-content; } }

.ProductsTable .ProductTitle, .ProductsTable .ProductType {
  max-width: 200px;
  word-break: break-word; }

.ProductsTable .ProductDescription {
  max-width: 300px;
  word-break: break-word; }

.ProductsTable .ProductStoreMode .listStoreMode {
  margin: 0;
  padding: 0; }
  .ProductsTable .ProductStoreMode .listStoreMode li {
    display: inline-block;
    margin-right: 5px;
    border: 1px solid #0f9d58;
    padding: 5px;
    color: #0f9d58;
    font-weight: 400; }
    .ProductsTable .ProductStoreMode .listStoreMode li:hover {
      cursor: pointer; }
    .ProductsTable .ProductStoreMode .listStoreMode li:last-child {
      margin-right: 0; }

.ProductsTable .ProductsSupplier ul.listSupplier li {
  position: relative; }
  .ProductsTable .ProductsSupplier ul.listSupplier li .supplierModalContainer {
    position: absolute;
    top: 100%;
    right: 0;
    margin-right: -100px;
    min-width: 1200px;
    background-color: #fff;
    z-index: 99;
    padding: 16px;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    visibility: visible;
    opacity: 1; }

.ProductsPage .CustomSelect {
  color: #495057; }
  .ProductsPage .CustomSelect .ant-select-selection--multiple, .ProductsPage .CustomSelect .ant-select-selection--multiple .ant-select-selection__rendered {
    min-height: 38px !important;
    padding-bottom: 0 !important; }
  .ProductsPage .CustomSelect .ant-select-selection--multiple .ant-select-selection__rendered > ul > li, .ProductsPage .CustomSelect .ant-select-selection--multiple > ul > li {
    height: 30px;
    line-height: 30px; }
  .ProductsPage .CustomSelect .ant-select-selection--multiple .ant-select-arrow, .ProductsPage .CustomSelect .ant-select-selection--multiple .ant-select-selection__clear {
    top: 20px; }

.ProductsPage .customTotal.TotalEntity {
  align-items: normal; }

@media (max-width: 1850px) {
  .ProductsPage .TableFilter {
    display: block !important; }
    .ProductsPage .TableFilter .ShowTotal .TableLength {
      margin-left: 0 !important; }
    .ProductsPage .TableFilter .Filter {
      position: relative; }
      .ProductsPage .TableFilter .Filter .FilterOptions {
        width: calc(25% - 1rem); } }
      @media (max-width: 1850px) and (max-width: 768px) {
        .ProductsPage .TableFilter .Filter .FilterOptions {
          width: calc(50% - 1rem); } }

@media (max-width: 1850px) {
        .ProductsPage .TableFilter .Filter .FilterOptions .ant-select {
          width: 100% !important; } }

@media (max-width: 768px) {
  .ProductsPage .TableFilter .InputWrapper {
    width: 220px; } }

@media (max-width: 575px) {
  .ProductsPage .TableFilter .TotalEntity {
    justify-content: flex-start;
    margin-left: 0 !important;
    margin-top: 10px; } }

.ListPrintFileContainer .ListPrintFilePage .PrintFilesFilters .itemFilter .ant-select.ant-select-multiple .ant-select-selector {
  max-height: 88px;
  overflow-y: auto; }
  .ListPrintFileContainer .ListPrintFilePage .PrintFilesFilters .itemFilter .ant-select.ant-select-multiple .ant-select-selector::-webkit-scrollbar {
    width: 6px; }
  .ListPrintFileContainer .ListPrintFilePage .PrintFilesFilters .itemFilter .ant-select.ant-select-multiple .ant-select-selector::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0px; }
  .ListPrintFileContainer .ListPrintFilePage .PrintFilesFilters .itemFilter .ant-select.ant-select-multiple .ant-select-selector::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.5); }

.ShippingLabelDetails .ShippingAddress {
  background-color: #e9ecef; }
  .ShippingLabelDetails .ShippingAddress .AddressStatus {
    font-size: 30px; }

.ShippingLabelDetails .Items ul {
  font-size: .875em;
  list-style: initial;
  margin-bottom: 16px;
  padding-left: 23px; }

.ShipingMethodsActions {
  cursor: pointer; }

.ShippingZoneCountries .ShippingZoneListCountries button {
  margin-top: 15px; }

@media (min-width: 576px) {
  .ShippingZoneCountries .ShippingZoneListCountries {
    display: flex; }
    .ShippingZoneCountries .ShippingZoneListCountries button {
      margin-top: 0;
      width: 300px;
      margin-left: 30px; } }

@media (max-width: 1400px) {
  .WebhookTable .wrapTable {
    overflow-x: scroll; }
    .WebhookTable .wrapTable table {
      width: max-content; } }

.WebhookTableHead th.WebhookSelection {
  width: 100px; }

.WebhookTableHead th.WebhookActions {
  width: 200px; }

.WebhookTableHead th.OrderNumber {
  width: 250px; }

.WebhookTableHead th.WebhookAddress {
  width: 200px; }

.WebhookTableHead th.WebhookStatus {
  width: 120px; }

.WebhookTableHead th.WebhookType {
  width: 220px; }

.WebhookTableHead th.WebhookTotalSent {
  width: 125px; }

.WebhookTableHead th.WebhookSendTime {
  width: 200px; }

.WebhooksTableRow .WebhookMessage {
  word-break: break-all; }

.WebhooksTableRow .OrderNumber span.ti-info-alt {
  margin-left: 10px; }
  .WebhooksTableRow .OrderNumber span.ti-info-alt:hover {
    cursor: pointer;
    color: #0f9d58; }

ul.ant-menu.BulkJobsMenu {
  padding: 4px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); }
  ul.ant-menu.BulkJobsMenu .ant-menu-item {
    margin: 0;
    padding: 5px 12px;
    height: auto;
    line-height: 22px; }
    ul.ant-menu.BulkJobsMenu .ant-menu-item:hover {
      background-color: #e6f7ff;
      color: inherit; }

.CreateProductPage .input-error {
  border-color: #dc3545; }

.CreateProductPage .select-error .ant-select-selection {
  border-color: #dc3545; }

.UserCreateModal .input-error {
  border-color: #dc3545; }

.ChangePasswordUser .input-error {
  border-color: #dc3545; }

.SettingAutoPage .TabList .FilterTab {
  border-bottom: 1px solid #E0E3E7; }
  .SettingAutoPage .TabList .FilterTab .FilterList {
    display: flex;
    align-items: center; }
    .SettingAutoPage .TabList .FilterTab .FilterList .Filter {
      position: relative;
      padding: 10px 25px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center; }
      .SettingAutoPage .TabList .FilterTab .FilterList .Filter:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #477BF6;
        opacity: 0;
        z-index: 2; }
      .SettingAutoPage .TabList .FilterTab .FilterList .Filter.active, .SettingAutoPage .TabList .FilterTab .FilterList .Filter:hover {
        cursor: pointer;
        color: #477BF6;
        position: relative; }
        .SettingAutoPage .TabList .FilterTab .FilterList .Filter.active:after, .SettingAutoPage .TabList .FilterTab .FilterList .Filter:hover:after {
          opacity: 1; }

.AutoPushContent {
  padding: 24px 0; }
  .AutoPushContent .TogglePush {
    align-items: center;
    margin-bottom: 18px; }
    .AutoPushContent .TogglePush label {
      margin-right: 15px;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #303238; }
    .AutoPushContent .TogglePush .ant-switch-checked {
      background: #477BF6; }
  .AutoPushContent .ModeCheck {
    margin-bottom: 16px; }
    .AutoPushContent .ModeCheck label span {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #303238; }
    .AutoPushContent .ModeCheck .ant-checkbox-checked:after {
      border: 1px solid #477BF6; }
    .AutoPushContent .ModeCheck .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #477BF6;
      border-color: #477BF6; }
  .AutoPushContent .title {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #303238; }
  .AutoPushContent .note {
    padding-bottom: 24px;
    border-bottom: 1px solid #E0E3E7;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #303238; }
    .AutoPushContent .note span {
      margin-right: 5px; }
  .AutoPushContent .Action button {
    padding: 8px 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    background: #4A80F7;
    border: 1px solid #4A80F7; }
    .AutoPushContent .Action button:hover {
      color: #4A80F7;
      background: #FFFFFF; }
  .AutoPushContent .CheckBoxGroup .ModeCheckGroup {
    margin-bottom: 17px; }
  .AutoPushContent .CheckBoxGroup .ant-checkbox-group {
    display: block;
    margin-left: 28px; }
    .AutoPushContent .CheckBoxGroup .ant-checkbox-group label.ant-checkbox-wrapper.ant-checkbox-group-item {
      width: 100%;
      margin-bottom: 16px; }
  .AutoPushContent .CheckBoxGroup label span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #303238; }
  .AutoPushContent .CheckBoxGroup .ant-checkbox-group {
    display: flex;
    flex-direction: column; }
  .AutoPushContent .list-1, .AutoPushContent .list-2, .AutoPushContent .list-3 {
    min-width: 33.3%; }
    @media (max-width: 768px) {
      .AutoPushContent .list-1, .AutoPushContent .list-2, .AutoPushContent .list-3 {
        min-width: 100%; } }

.AutoMapping .ant-switch-checked, .AutoMapping .ant-radio-inner:after, .AutoMapping .ant-btn-primary, .AutoMapping .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #477BF6; }

.AutoMapping .ant-btn-primary[disabled] {
  color: #ffffff; }

.AutoMapping .ant-checkbox-checked:after, .AutoMapping .ant-checkbox-checked .ant-checkbox-inner, .AutoMapping .ant-radio-checked .ant-radio-inner, .AutoMapping .ant-radio-checked:after {
  border-color: #477BF6; }

.AutoMapping input {
  font-size: 14px; }

.AutoMapping .ant-radio, .AutoMapping .ant-radio-wrapper, .AutoMapping .ant-checkbox-wrapper {
  font-size: 16px; }

.AutoMapping .ant-input-affix-wrapper .ant-input-prefix {
  left: 14px; }

.AutoMapping .ant-input-affix-wrapper .ant-input:not(:first-child) {
  padding-left: 10px; }

.AutoMapping .TogglePush > label {
  font-weight: bold; }

.AutoMapping .MappingCheck > label {
  padding: 10px 0; }

.AutoMapping .MappingCheck input {
  height: 40px; }

.AutoMapping .listStore {
  margin-top: 25px;
  border: 1px solid #E0E3E7;
  border-radius: 4px;
  padding: 15px 15px 5px 15px;
  display: flex;
  flex-wrap: wrap;
  max-height: 350px;
  overflow-y: scroll; }
  .AutoMapping .listStore .itemStore {
    width: 20%;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 24px; }
    .AutoMapping .listStore .itemStore span:not(.anticon.anticon-close) {
      background: #F5F5F5;
      border-radius: 2px;
      line-height: 28px;
      padding: 0 12px;
      display: inline-block; }
      .AutoMapping .listStore .itemStore span:not(.anticon.anticon-close) .anticon.anticon-close {
        color: #6C757D;
        margin-left: 5px;
        font-size: 11px;
        cursor: pointer; }
    .AutoMapping .listStore .itemStore.error {
      color: #DC3545; }
      .AutoMapping .listStore .itemStore.error span {
        background-color: #F9E3E5; }
        .AutoMapping .listStore .itemStore.error span i {
          color: #DC3545; }

.AutoMapping .actions {
  margin-top: 24px;
  padding-top: 24px;
  position: relative;
  border-top: 1px solid #E0E3E7; }
  .AutoMapping .actions .errorStore {
    position: absolute;
    width: 100%;
    text-align: center;
    padding: 0 140px;
    top: 24px; }
  .AutoMapping .actions button {
    padding-left: 40px;
    padding-right: 40px; }

.NotificationFilters.OrdersSuplier {
  width: 100%;
  position: relative;
  z-index: 11; }
  .NotificationFilters.OrdersSuplier .GroupFilter {
    margin-right: -15px; }
    @media (max-width: 1200px) {
      .NotificationFilters.OrdersSuplier .GroupFilter {
        flex-wrap: wrap; } }
  .NotificationFilters.OrdersSuplier .itemFilter {
    padding-right: 15px;
    width: 16.67%; }
    @media (max-width: 1200px) {
      .NotificationFilters.OrdersSuplier .itemFilter {
        width: 33%; } }
    @media (max-width: 767px) {
      .NotificationFilters.OrdersSuplier .itemFilter {
        width: 50%; } }
    @media (max-width: 480px) {
      .NotificationFilters.OrdersSuplier .itemFilter {
        width: 100%; } }
    .NotificationFilters.OrdersSuplier .itemFilter .InputSearch {
      position: relative; }
    .NotificationFilters.OrdersSuplier .itemFilter .DatePaid .DatePickerWrapper {
      padding-top: 0;
      padding-bottom: 0; }
    .NotificationFilters.OrdersSuplier .itemFilter .FilterDateRange > div {
      flex-direction: column; }
    .NotificationFilters.OrdersSuplier .itemFilter .FilterDateRange .Heading {
      margin-bottom: 0.25rem; }
    .NotificationFilters.OrdersSuplier .itemFilter.end {
      margin-right: 0 !important; }
    .NotificationFilters.OrdersSuplier .itemFilter .d-sm-inline-flex.align-items-baseline {
      width: 100%; }
      .NotificationFilters.OrdersSuplier .itemFilter .d-sm-inline-flex.align-items-baseline .ant-picker.ant-picker-range {
        width: 100%; }
  .NotificationFilters.OrdersSuplier.listDiscounts .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px; }
  .NotificationFilters.OrdersSuplier.listDiscounts .ant-select-single .ant-select-selector .ant-select-selection-item, .NotificationFilters.OrdersSuplier.listDiscounts .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 36px; }
  .NotificationFilters.OrdersSuplier.listDiscounts .ant-input {
    line-height: 28px; }
  .NotificationFilters.OrdersSuplier.listDiscounts .itemFilter .icon {
    bottom: 7px; }
  .NotificationFilters.OrdersSuplier.listDiscounts .ant-picker-input > input {
    line-height: 28px; }
  .NotificationFilters.OrdersSuplier .CustomMultiSelect {
    margin-left: 0; }
    .NotificationFilters.OrdersSuplier .CustomMultiSelect .InputSelect {
      padding-top: 4px;
      padding-bottom: 4px;
      border-radius: 0; }
      .NotificationFilters.OrdersSuplier .CustomMultiSelect .InputSelect:after {
        display: none; }
    .NotificationFilters.OrdersSuplier .CustomMultiSelect .PopupSelect {
      max-height: 100px;
      overflow: auto; }
      .NotificationFilters.OrdersSuplier .CustomMultiSelect .PopupSelect::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: rgba(0, 0, 0, 0.1); }
      .NotificationFilters.OrdersSuplier .CustomMultiSelect .PopupSelect::-webkit-scrollbar {
        width: 6px;
        background-color: transparent; }
    .NotificationFilters.OrdersSuplier .CustomMultiSelect .PopupSelect.true {
      max-height: 300px;
      z-index: 9;
      opacity: 1;
      top: 60px; }

.NotificationFilters .itemFilter {
  position: relative;
  width: 320px; }
  .NotificationFilters .itemFilter .icon {
    position: absolute;
    right: 10px;
    bottom: 5px;
    color: #A2A2A4;
    z-index: 2;
    font-size: 14px; }

@media (max-width: 767px) {
  .NotificationFilters {
    flex-wrap: wrap; }
    .NotificationFilters .itemFilter {
      width: 45%; } }

.CustomMultiSelect {
  position: relative; }
  .CustomMultiSelect .Background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 8; }
  .CustomMultiSelect .InputSelect {
    display: block;
    width: 100%;
    padding: 0 11px;
    line-height: 30px;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ddd;
    position: relative;
    border-radius: 2px; }
    .CustomMultiSelect .InputSelect span {
      overflow-wrap: break-word;
      overflow: hidden;
      height: 31px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical; }
    .CustomMultiSelect .InputSelect svg {
      position: absolute;
      top: 10px;
      fill: #cccccc;
      right: 15px; }
  .CustomMultiSelect .PopupSelect {
    position: absolute;
    padding: 1px 0 0 0;
    top: 65px;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    opacity: 0;
    z-index: -1;
    border: 1px solid #eeeeee; }
    .CustomMultiSelect .PopupSelect.true {
      z-index: 9;
      opacity: 1;
      top: 65px; }
    .CustomMultiSelect .PopupSelect .Item {
      display: flex;
      align-items: center;
      margin-bottom: 1px;
      position: relative;
      padding: 5px 10px; }
      .CustomMultiSelect .PopupSelect .Item label {
        margin-bottom: 0;
        margin-left: 10px;
        font-size: 14px;
        position: relative;
        z-index: 10; }
      .CustomMultiSelect .PopupSelect .Item > * {
        position: relative;
        z-index: 10; }
      .CustomMultiSelect .PopupSelect .Item .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #e0f5ff;
        content: '';
        z-index: 0;
        display: none; }
      .CustomMultiSelect .PopupSelect .Item input:checked ~ .bg {
        display: block;
        background: #eef9ff; }
      .CustomMultiSelect .PopupSelect .Item:hover .bg {
        display: block; }
    .CustomMultiSelect .PopupSelect .Actions {
      position: sticky;
      padding: 10px 0;
      bottom: 0;
      white-space: nowrap;
      text-align: left;
      background: #FFF;
      z-index: 10;
      box-shadow: 0 0 13px 0 #30323826; }
      .CustomMultiSelect .PopupSelect .Actions button {
        border: 1px solid #0a6ebd;
        background-color: #0a6ebd;
        color: #FFFFFF;
        padding: 5px 15px;
        border-radius: 4px; }
        .CustomMultiSelect .PopupSelect .Actions button.LinkAction {
          border: 0;
          padding: 0;
          background-color: #FFFFFF;
          color: #0a6ebd; }
        .CustomMultiSelect .PopupSelect .Actions button:hover, .CustomMultiSelect .PopupSelect .Actions button:active, .CustomMultiSelect .PopupSelect .Actions button:focus {
          cursor: pointer;
          background-color: #0aa7ef;
          border-color: #0aa7ef;
          outline: none; }
          .CustomMultiSelect .PopupSelect .Actions button:hover.LinkAction, .CustomMultiSelect .PopupSelect .Actions button:active.LinkAction, .CustomMultiSelect .PopupSelect .Actions button:focus.LinkAction {
            background-color: #FFFFFF;
            color: #0aa7ef;
            outline: none; }
        .CustomMultiSelect .PopupSelect .Actions button:disabled {
          cursor: not-allowed;
          color: #999; }
        .CustomMultiSelect .PopupSelect .Actions button:last-child {
          margin-right: 10px;
          float: right; }

@media (max-width: 991px) {
  .NotificationPage {
    padding-left: 15px;
    padding-right: 15px; }
    .NotificationPage .PageTitle {
      padding: 0; } }

.NotificationPage .hiden-suffix .ant-picker-suffix {
  display: none !important; }

.NotificationTable .ShippingMethod .ShowMore {
  cursor: pointer;
  color: #13b838;
  transition: all 0.3s ease-in-out; }
  .NotificationTable .ShippingMethod .ShowMore:hover {
    color: #0f9d58; }

@media (max-width: 1024px) {
  .NotificationTable .wrapTable {
    overflow-x: scroll; }
    .NotificationTable .wrapTable table {
      width: max-content; } }

.NotificationTable .noData {
  padding: 90px 0; }

.NotificationTable .BulkSelectNotification {
  width: 65px; }

.NotificationTable .MenuActions {
  position: absolute;
  left: 10px;
  top: 8px; }

.NotificationTable .custom-control {
  padding-left: 10px; }

.NotificationTable .storeId {
  width: 100px; }

.NotificationTable .title {
  width: 200px; }

.NotificationTable .orderNumber {
  width: 168px;
  word-break: break-all; }

.NotificationTable .image {
  width: 110px; }
  .NotificationTable .image a {
    text-decoration: underline;
    text-underline-position: under; }

.NotificationTable .status {
  width: 150px; }

@media (max-width: 1700px) {
  .NotificationTable.OrderSupplies .wrapTable {
    overflow-x: scroll; }
    .NotificationTable.OrderSupplies .wrapTable .table {
      width: -moz-max-content;
      width: max-content; } }

.NotificationTable.OrderSupplies .PackageChangeStatus {
  display: flex;
  align-items: baseline; }
  .NotificationTable.OrderSupplies .PackageChangeStatus .Actions {
    position: relative; }
    .NotificationTable.OrderSupplies .PackageChangeStatus .Actions i {
      position: relative;
      margin-left: 5px;
      z-index: 10; }
      .NotificationTable.OrderSupplies .PackageChangeStatus .Actions i:hover {
        cursor: pointer;
        color: #0f9d58; }
    .NotificationTable.OrderSupplies .PackageChangeStatus .Actions .Background {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      opacity: 0;
      z-index: 9; }
    .NotificationTable.OrderSupplies .PackageChangeStatus .Actions .ListActions {
      display: none;
      position: absolute;
      width: 250px;
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 0.25rem;
      margin: 0;
      padding: 0;
      z-index: 10; }
      .NotificationTable.OrderSupplies .PackageChangeStatus .Actions .ListActions li {
        color: #333333;
        list-style: none;
        padding: 10px 15px;
        font-size: 15px;
        font-weight: normal; }
        .NotificationTable.OrderSupplies .PackageChangeStatus .Actions .ListActions li:hover {
          color: #0f9d58;
          cursor: pointer; }
        .NotificationTable.OrderSupplies .PackageChangeStatus .Actions .ListActions li.Disable {
          pointer-events: none;
          color: #adb5bd; }
      .NotificationTable.OrderSupplies .PackageChangeStatus .Actions .ListActions.IsOpen {
        display: block; }
      .NotificationTable.OrderSupplies .PackageChangeStatus .Actions .ListActions.Disable li {
        pointer-events: none;
        color: #adb5bd; }

.NotificationTable.OrderSupplies .Address {
  width: 145px; }

.NotificationTable.OrderSupplies .Info {
  width: 270px; }
  .NotificationTable.OrderSupplies .Info .StoreName {
    font-size: 15px;
    color: #e83e8c;
    word-wrap: break-word; }
  .NotificationTable.OrderSupplies .Info .OrderReference {
    font-size: 15px;
    color: #2F3230; }
  .NotificationTable.OrderSupplies .Info .PackagePushTime {
    font-size: 15px; }
  .NotificationTable.OrderSupplies .Info .CustomReference {
    font-size: 15px; }

.NotificationTable.OrderSupplies .Supplier {
  width: 130px; }

.NotificationTable.OrderSupplies .ShippingMethod {
  width: 300px; }
  .NotificationTable.OrderSupplies .ShippingMethod > div span {
    font-weight: bold;
    font-size: 14px; }

.NotificationTable.OrderSupplies .Status {
  width: 200px; }

.NotificationTable.OrderSupplies .PushType {
  width: 130px; }

.NotificationTable.OrderSupplies .Tracking {
  width: 300px; }

.NotificationTable.OrderSupplies .Note {
  width: 250px;
  word-break: break-word; }
  .NotificationTable.OrderSupplies .Note .ShowMore {
    cursor: pointer; }
    .NotificationTable.OrderSupplies .Note .ShowMore:hover {
      color: #0f9d58; }
  .NotificationTable.OrderSupplies .Note .NotePackage span {
    margin-right: 5px;
    color: #9a0000; }
  .NotificationTable.OrderSupplies .Note .NotePackage i:hover {
    cursor: pointer;
    color: #0f9d58; }

.NotificationTable.OrderSupplies td.Note {
  font-size: 14px; }

.NotificationTable.OrderSupplies table tbody td .error-box {
  position: relative; }
  .NotificationTable.OrderSupplies table tbody td .error-box .error-message {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, -5px);
    width: 250px;
    background: #e9ecef;
    padding: 7px 15px;
    border-radius: 4px;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    word-break: break-word; }
    .NotificationTable.OrderSupplies table tbody td .error-box .error-message:before {
      position: absolute;
      top: 100%;
      left: 50%;
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 7px solid #e9ecef;
      transform: translate(-3px, 0); }
  .NotificationTable.OrderSupplies table tbody td .error-box:hover .error-message {
    visibility: visible;
    opacity: 1; }

.NotificationTable.OrderSupplies table tbody td .supplier {
  position: relative; }
  .NotificationTable.OrderSupplies table tbody td .supplier .supplierModalContainer {
    position: absolute;
    top: 100%;
    left: 0;
    margin-left: -100px;
    min-width: 1200px;
    background-color: #fff;
    z-index: 99;
    padding: 16px;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    visibility: visible;
    opacity: 1; }

.NotificationTable.FulfillmentDiscounts .DiscountName {
  width: 400px;
  word-break: break-all; }

.NotificationTable.FulfillmentDiscounts .Status {
  width: 100px; }

.NotificationTable.FulfillmentDiscounts .Date {
  width: 200px; }

.NotificationTable.FulfillmentDiscounts .DateApply {
  width: 250px; }

.NotificationTable.FulfillmentDiscounts .Type {
  width: 150px; }

.NotificationTable.FulfillmentDiscounts .Value {
  width: 150px; }

.NotificationTable.FulfillmentDiscounts .Actions {
  width: 100px; }

.NotificationTable.FulfillmentDiscounts .Store {
  width: 200px; }

.NotificationTable.FulfillmentDiscounts .badge {
  padding: 6px 10px;
  border-radius: 80px;
  font-weight: 500; }

.PackageNoteModal .ant-modal-close {
  outline: none; }

.PackageNoteModal textarea {
  resize: none; }
  .PackageNoteModal textarea::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1); }
  .PackageNoteModal textarea::-webkit-scrollbar {
    width: 6px;
    background-color: transparent; }

.ChangeStatusPackageModal, .ChangeConfirmPackageModal, .AddFastProductionModalTag {
  width: 680px !important; }
  .ChangeStatusPackageModal .ant-modal-close, .ChangeConfirmPackageModal .ant-modal-close, .AddFastProductionModalTag .ant-modal-close {
    outline: none; }
  .ChangeStatusPackageModal .FormItem, .ChangeConfirmPackageModal .FormItem, .AddFastProductionModalTag .FormItem {
    margin-bottom: 15px; }
    .ChangeStatusPackageModal .FormItem:nth-child(2), .ChangeConfirmPackageModal .FormItem:nth-child(2), .AddFastProductionModalTag .FormItem:nth-child(2) {
      padding-bottom: 15px;
      border-bottom: 1px solid #dddddd; }
    .ChangeStatusPackageModal .FormItem label, .ChangeConfirmPackageModal .FormItem label, .AddFastProductionModalTag .FormItem label {
      font-weight: 500;
      margin-bottom: 5px; }
    .ChangeStatusPackageModal .FormItem .ant-select, .ChangeConfirmPackageModal .FormItem .ant-select, .AddFastProductionModalTag .FormItem .ant-select {
      width: 100%; }
  .ChangeStatusPackageModal h3, .ChangeConfirmPackageModal h3, .AddFastProductionModalTag h3 {
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 500; }
    .ChangeStatusPackageModal h3 span, .ChangeConfirmPackageModal h3 span, .AddFastProductionModalTag h3 span {
      font-weight: bold; }
  .ChangeStatusPackageModal .Results, .ChangeConfirmPackageModal .Results, .AddFastProductionModalTag .Results {
    display: flex;
    align-items: center; }
    .ChangeStatusPackageModal .Results .ResultSuccess, .ChangeConfirmPackageModal .Results .ResultSuccess, .AddFastProductionModalTag .Results .ResultSuccess {
      margin-right: 10px; }
      .ChangeStatusPackageModal .Results .ResultSuccess span, .ChangeConfirmPackageModal .Results .ResultSuccess span, .AddFastProductionModalTag .Results .ResultSuccess span {
        color: #0f9d58;
        font-weight: bold; }
    .ChangeStatusPackageModal .Results .ResultError span, .ChangeConfirmPackageModal .Results .ResultError span, .AddFastProductionModalTag .Results .ResultError span {
      font-weight: bold;
      color: #9a0000; }
  .ChangeStatusPackageModal .ListProcessing, .ChangeConfirmPackageModal .ListProcessing, .AddFastProductionModalTag .ListProcessing {
    margin-top: 15px;
    max-height: 250px;
    overflow: auto; }
    .ChangeStatusPackageModal .ListProcessing::-webkit-scrollbar-thumb, .ChangeConfirmPackageModal .ListProcessing::-webkit-scrollbar-thumb, .AddFastProductionModalTag .ListProcessing::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: rgba(0, 0, 0, 0.1); }
    .ChangeStatusPackageModal .ListProcessing::-webkit-scrollbar, .ChangeConfirmPackageModal .ListProcessing::-webkit-scrollbar, .AddFastProductionModalTag .ListProcessing::-webkit-scrollbar {
      width: 6px;
      background-color: transparent; }
    .ChangeStatusPackageModal .ListProcessing ul li, .ChangeConfirmPackageModal .ListProcessing ul li, .AddFastProductionModalTag .ListProcessing ul li {
      margin-bottom: 10px; }
      .ChangeStatusPackageModal .ListProcessing ul li span:first-child, .ChangeConfirmPackageModal .ListProcessing ul li span:first-child, .AddFastProductionModalTag .ListProcessing ul li span:first-child {
        font-weight: 500; }
      .ChangeStatusPackageModal .ListProcessing ul li .Error, .ChangeConfirmPackageModal .ListProcessing ul li .Error, .AddFastProductionModalTag .ListProcessing ul li .Error {
        margin-left: 10px;
        color: #9a0000; }
        .ChangeStatusPackageModal .ListProcessing ul li .Error .anticon svg, .ChangeConfirmPackageModal .ListProcessing ul li .Error .anticon svg, .AddFastProductionModalTag .ListProcessing ul li .Error .anticon svg {
          fill: #9a0000; }
      .ChangeStatusPackageModal .ListProcessing ul li .Success, .ChangeConfirmPackageModal .ListProcessing ul li .Success, .AddFastProductionModalTag .ListProcessing ul li .Success {
        margin-left: 10px;
        color: #0f9d58; }
        .ChangeStatusPackageModal .ListProcessing ul li .Success .anticon svg, .ChangeConfirmPackageModal .ListProcessing ul li .Success .anticon svg, .AddFastProductionModalTag .ListProcessing ul li .Success .anticon svg {
          fill: #0f9d58; }
      .ChangeStatusPackageModal .ListProcessing ul li:last-child, .ChangeConfirmPackageModal .ListProcessing ul li:last-child, .AddFastProductionModalTag .ListProcessing ul li:last-child {
        margin-bottom: 0; }

.ant-popover {
  width: 300px !important; }
  .ant-popover .ant-popover-inner-content {
    max-height: 168px;
    overflow: auto; }
    .ant-popover .ant-popover-inner-content::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: rgba(0, 0, 0, 0.1); }
    .ant-popover .ant-popover-inner-content::-webkit-scrollbar {
      width: 6px;
      background-color: transparent; }
  .ant-popover.listBarcodeNumbers {
    width: 200px !important; }

.addTagsModal .ListProcessing {
  margin-top: 15px;
  max-height: 270px;
  overflow: auto; }
  .addTagsModal .ListProcessing::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1); }
  .addTagsModal .ListProcessing::-webkit-scrollbar {
    width: 6px;
    background-color: transparent; }
  .addTagsModal .ListProcessing ul li {
    margin-bottom: 10px; }
    .addTagsModal .ListProcessing ul li span:first-child {
      font-weight: 500; }
    .addTagsModal .ListProcessing ul li .Error {
      margin-left: 10px;
      color: #9a0000; }
      .addTagsModal .ListProcessing ul li .Error .anticon svg {
        fill: #9a0000; }
    .addTagsModal .ListProcessing ul li .Success {
      margin-left: 10px;
      color: #0f9d58; }
      .addTagsModal .ListProcessing ul li .Success .anticon svg {
        fill: #0f9d58; }
    .addTagsModal .ListProcessing ul li:last-child {
      margin-bottom: 0; }

.Upload .UploadFile {
  display: flex;
  align-items: center;
  margin-top: 16px;
  position: relative; }
  .Upload .UploadFile .ClickUpload {
    padding: 7px 23px;
    background: #FFFFFF;
    border: 1px solid #C9CCCE;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 16px;
    cursor: pointer;
    width: 164px; }
    .Upload .UploadFile .ClickUpload span {
      margin-left: 14px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #303238; }
    .Upload .UploadFile .ClickUpload .anticon-upload {
      margin-left: 0; }
  .Upload .UploadFile .File {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #64666B;
    width: calc(100% - 180px); }
    .Upload .UploadFile .File .FileName {
      display: flex;
      align-items: center;
      padding: 5px 6px; }
      .Upload .UploadFile .File .FileName .FloatRight {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 14px); }
        .Upload .UploadFile .File .FileName .FloatRight span {
          margin-left: 10px; }
        .Upload .UploadFile .File .FileName .FloatRight .anticon-delete {
          display: none; }
      .Upload .UploadFile .File .FileName:hover {
        background-color: #F9FAFB; }
        .Upload .UploadFile .File .FileName:hover .FloatRight .anticon-delete {
          display: block; }
          .Upload .UploadFile .File .FileName:hover .FloatRight .anticon-delete:hover {
            color: #dc3545; }
  .Upload .UploadFile.Error .ClickUpload {
    border: 1px solid #DC3545; }
    .Upload .UploadFile.Error .ClickUpload span {
      color: #DC3545; }
    .Upload .UploadFile.Error .ClickUpload .anticon-upload {
      color: #DC3545; }
  .Upload .UploadFile.Error .File {
    color: #dc3545; }
  .Upload .UploadFile #CustomUpload {
    position: absolute;
    width: 164px;
    height: 40px;
    z-index: 9;
    opacity: 0;
    border-radius: 4px; }
    .Upload .UploadFile #CustomUpload:hover {
      cursor: pointer; }
  .Upload .UploadFile:hover .ClickUpload {
    border: 1px solid #477BF6; }
    .Upload .UploadFile:hover .ClickUpload span {
      color: #477BF6; }
    .Upload .UploadFile:hover .ClickUpload .anticon-upload {
      color: #477BF6; }

.Upload .ErrorText {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #64666B;
  margin-top: 8px; }
  .Upload .ErrorText .anticon-exclamation-circle {
    font-size: 15px;
    margin-right: 5px;
    color: #DC3545; }

.Upload .hide {
  display: none; }

.ModalUploadFile {
  border-radius: 4px; }
  .ModalUploadFile .ant-modal-content .ant-modal-close:hover, .ModalUploadFile .ant-modal-content .ant-modal-close:active, .ModalUploadFile .ant-modal-content .ant-modal-close:focus {
    outline: none; }
  .ModalUploadFile .ant-modal-content .ant-modal-body .Upload .Title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #303238;
    margin-bottom: 4px;
    margin-right: 15px; }
  .ModalUploadFile .ant-modal-content .ant-modal-body .Upload .FileTemplate {
    margin-bottom: 4px; }
    .ModalUploadFile .ant-modal-content .ant-modal-body .Upload .FileTemplate a {
      text-decoration: none; }
  .ModalUploadFile .ant-modal-content .ant-modal-body .Upload p {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #64666B;
    margin-bottom: 16px; }
  .ModalUploadFile .ant-modal-content .ant-modal-footer .ant-btn {
    height: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #303238;
    padding: 0 32px;
    border-radius: 4px; }
    .ModalUploadFile .ant-modal-content .ant-modal-footer .ant-btn.ant-btn-primary {
      color: #FFFFFF; }
    .ModalUploadFile .ant-modal-content .ant-modal-footer .ant-btn:disabled {
      background: #1890ff;
      opacity: 0.8; }

.UploadFilePage .ant-btn.ant-btn-primary {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  padding: 0 27px;
  background: #4A80F7;
  border-radius: 4px;
  height: 40px; }

.OrderPartnerPage .OrderPreference .OrderPreferenceShipping .Tracking .Value a {
  color: #0A7BFE; }

.ScanTracking .Content {
  display: flex;
  align-items: center; }
  .ScanTracking .Content label {
    color: #303238;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-right: 30px;
    margin-bottom: 0; }
  .ScanTracking .Content .ant-select .ant-select-selector {
    min-width: 300px;
    width: 100%; }

.ScanTracking .ant-modal-footer {
  padding: 24px 16px; }
  .ScanTracking .ant-modal-footer button {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    height: 40px;
    padding: 0 32px;
    margin-right: 10px; }
    .ScanTracking .ant-modal-footer button:last-child {
      margin-right: 0; }

.NotificationPage .SaveFilter button {
  font-size: 14px;
  padding: 0 15px;
  height: auto;
  line-height: 30px; }

.NotificationPage .cursor-pointer.OrderNote:hover {
  color: #0f9d58; }

.QuickReportContainer .IconFilters {
  position: fixed;
  top: 100px;
  background-color: #ffffff;
  right: -1px;
  width: 32px;
  z-index: 3;
  border: solid 1px rgba(0, 0, 0, 0.0625);
  align-items: center;
  justify-content: center;
  border-radius: 4px 0 0 4px;
  display: flex;
  height: 32px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
  cursor: pointer; }
  .QuickReportContainer .IconFilters svg {
    font-size: 24px;
    transition: color 0.3s; }
  .QuickReportContainer .IconFilters:hover i {
    color: #00c853; }

.QuickReportContainer .QuickReportOverlay {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1001; }

.QuickReportContainer .QuickReportsListsWrap {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 1002;
  width: 285px;
  margin-left: auto;
  background-color: #FFFFFF;
  border: 0.5px solid rgba(217, 217, 217, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 2px 2px 2px rgba(222, 226, 230, 0.73);
  overflow-y: auto; }
  .QuickReportContainer .QuickReportsListsWrap .ReportHead {
    padding: 15px;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 10px; }
    .QuickReportContainer .QuickReportsListsWrap .ReportHead h3 {
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 0; }
    .QuickReportContainer .QuickReportsListsWrap .ReportHead .iconClose:hover svg {
      color: #1890ff; }
  .QuickReportContainer .QuickReportsListsWrap .ReportContent .QuickReportsItem {
    border-bottom: 1px solid #dee2e6;
    padding: 10px 15px;
    position: relative; }
    .QuickReportContainer .QuickReportsListsWrap .ReportContent .QuickReportsItem .IconDelete {
      position: absolute;
      right: 15px;
      top: 10px;
      cursor: pointer; }
      .QuickReportContainer .QuickReportsListsWrap .ReportContent .QuickReportsItem .IconDelete:hover svg {
        color: #1890ff; }
    .QuickReportContainer .QuickReportsListsWrap .ReportContent .QuickReportsItem.canDelete .Title {
      margin-right: 30px; }
    .QuickReportContainer .QuickReportsListsWrap .ReportContent .QuickReportsItem.active .Title {
      color: #1890ff; }

.SaveFilter {
  text-align: right;
  position: relative; }
  .SaveFilter #btnSaveFilter {
    background: rgba(222, 226, 230, 0.5);
    border: 1px solid #DEE2E6; }
  .SaveFilter .note {
    font-size: 12px;
    margin-bottom: 12px; }
  .SaveFilter .overlaySave {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9; }
  .SaveFilter .PopupSave {
    text-align: left;
    position: absolute;
    top: calc(100% + 15px);
    right: 0;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    padding: 15px;
    z-index: 999;
    width: 353px; }
    .SaveFilter .PopupSave .arrow {
      position: absolute;
      display: block;
      width: 1rem;
      right: 45px;
      top: -9px;
      height: 0.5rem;
      margin: 0 0.3rem; }
      .SaveFilter .PopupSave .arrow:before, .SaveFilter .PopupSave .arrow:after {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid; }
      .SaveFilter .PopupSave .arrow:before {
        top: 0;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: rgba(0, 0, 0, 0.25); }
      .SaveFilter .PopupSave .arrow:after {
        top: 1px;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: #fff; }
    .SaveFilter .PopupSave.isAdmin .checkDefault .ant-checkbox-wrapper {
      padding-bottom: 6px; }

.itemFilter .SaveFilter {
  margin-top: 17px; }

.listSuggestNotes {
  margin-top: 5px;
  position: absolute;
  z-index: 2;
  background: white;
  width: 100%;
  transition: all 0.2s ease;
  max-height: 200px;
  overflow-x: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; }
  .listSuggestNotes .suggestItem {
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    padding: 5px 10px; }
    .listSuggestNotes .suggestItem:hover {
      background-color: #f5f5f5; }

.tabsStatics {
  padding-top: 30px;
  padding-bottom: 30px; }
  .tabsStatics .itemTab {
    display: inline-block;
    line-height: 50px;
    margin-right: 10px;
    padding: 0 45px;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #FFFFFF;
    border: 1px solid #dee2e6; }
    .tabsStatics .itemTab.active {
      background-color: #0f9d58;
      color: #FFFFFF; }

.BtnClearStore .anticon {
  color: #777;
  margin-left: 5px; }
  .BtnClearStore .anticon.anticon-close {
    display: none; }

.BtnClearStore:hover {
  background: #FFF; }
  .BtnClearStore:hover .anticon {
    color: #ff3b15;
    display: none; }
    .BtnClearStore:hover .anticon.anticon-close {
      display: inline; }

.StaticsContainer .StaticsPage .PageTitle {
  margin-top: 30px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 50px; }

.StaticsContainer .StaticsPage .InnerPage .DetailStatics {
  width: 100%; }
  .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table {
    text-align: center;
    width: 100%; }
    .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table .Supplier, .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table .ColumnTable {
      width: 10%; }
    .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table .Delay {
      color: #D94646; }
      .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table .Delay a {
        color: #D94646; }
    .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table thead {
      background: #4A80F7; }
      .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table thead th {
        padding: 28px 0;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF !important; }
    .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table tbody tr td {
      padding: 28px 0;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #000000; }
      .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table tbody tr td a {
        color: #000000; }
      .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table tbody tr td.Supplier {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #000000; }
    .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table tbody tr.Total td {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: #000000; }
      .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table tbody tr.Total td a {
        color: #000000; }
      .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table tbody tr.Total td.Delay {
        color: #D94646; }
        .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table tbody tr.Total td.Delay a {
          color: #D94646; }

.StaticsContainer .StaticsPage .ShipmentStatusTable, .StaticsContainer .StaticsPage .ProductionTimeTable, .StaticsContainer .StaticsPage .ShipmentStatusByStoreTable {
  border-collapse: separate;
  border-spacing: 0px; }
  .StaticsContainer .StaticsPage .ShipmentStatusTable thead th, .StaticsContainer .StaticsPage .ProductionTimeTable thead th, .StaticsContainer .StaticsPage .ShipmentStatusByStoreTable thead th {
    background: #f9fafb;
    border-top: 1px solid #dee2e6;
    border-bottom: 2px solid #dee2e6;
    border-left: 1px solid #dee2e6; }
  .StaticsContainer .StaticsPage .ShipmentStatusTable thead th:last-child, .StaticsContainer .StaticsPage .ProductionTimeTable thead th:last-child, .StaticsContainer .StaticsPage .ShipmentStatusByStoreTable thead th:last-child {
    border-right: 1px solid #dee2e6; }
  .StaticsContainer .StaticsPage .ShipmentStatusTable tbody td, .StaticsContainer .StaticsPage .ProductionTimeTable tbody td, .StaticsContainer .StaticsPage .ShipmentStatusByStoreTable tbody td {
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6; }
  .StaticsContainer .StaticsPage .ShipmentStatusTable tbody td:last-child, .StaticsContainer .StaticsPage .ProductionTimeTable tbody td:last-child, .StaticsContainer .StaticsPage .ShipmentStatusByStoreTable tbody td:last-child {
    border-right: 1px solid #dee2e6; }

.fixedHeader .tabsStatics {
  position: sticky;
  top: 0;
  background: #f9fafb;
  z-index: 1000; }

.fixedHeader .contentTab .DailyReports .ReportShipStatus .Filter, .fixedHeader .contentTab .DailyReports .ReportShipStatusByStore .Filter {
  position: sticky;
  background: #f9fafb;
  z-index: 1; }

.fixedHeader .contentTab .DailyReports .ReportShipStatus .Error, .fixedHeader .contentTab .DailyReports .ReportShipStatusByStore .Error {
  position: sticky;
  background: #f9fafb;
  z-index: 1; }

.fixedHeader .contentTab .DailyReports .ReportShipStatus .ShipmentStatusTable, .fixedHeader .contentTab .DailyReports .ReportShipStatusByStore .ShipmentStatusTable {
  border-collapse: separate;
  border-spacing: 0px; }
  .fixedHeader .contentTab .DailyReports .ReportShipStatus .ShipmentStatusTable thead, .fixedHeader .contentTab .DailyReports .ReportShipStatusByStore .ShipmentStatusTable thead {
    position: sticky;
    z-index: 100; }

.fixedHeader .contentTab .ProductionReports .ReportHeading, .fixedHeader .contentTab .ReportFastProductionTime .ReportHeading {
  position: sticky;
  background: #f9fafb;
  z-index: 1; }

.fixedHeader .contentTab .ProductionReports .Error, .fixedHeader .contentTab .ReportFastProductionTime .Error {
  position: sticky;
  background: #f9fafb;
  z-index: 1; }

.fixedHeader .contentTab .ProductionReports .ProductionTimeTable, .fixedHeader .contentTab .ReportFastProductionTime .ProductionTimeTable {
  border-collapse: separate;
  border-spacing: 0px; }
  .fixedHeader .contentTab .ProductionReports .ProductionTimeTable thead, .fixedHeader .contentTab .ReportFastProductionTime .ProductionTimeTable thead {
    position: sticky;
    z-index: 100; }

@media (max-width: 1645px) {
  .StaticsContainer .StaticsPage .InnerPage {
    overflow-x: scroll; }
    .StaticsContainer .StaticsPage .InnerPage .DetailStatics {
      width: 1550px; }
      .StaticsContainer .StaticsPage .InnerPage .DetailStatics .table {
        overflow: scroll;
        -webkit-overflow-scrolling: touch; } }

.ReportOverInTransit .bg_1, .ReportOverShip .bg_1, .ReportShipStatus .bg_1, .ReportUSPreTransitDelay .bg_1, .ReportShipStatusByStore .bg_1 {
  background-color: #0f9d58;
  color: #FFFFFF; }

.ReportOverInTransit .bg_2, .ReportOverShip .bg_2, .ReportShipStatus .bg_2, .ReportUSPreTransitDelay .bg_2, .ReportShipStatusByStore .bg_2 {
  background-color: #9a0000;
  color: #FFFFFF; }

.ReportOverInTransit .Total td, .ReportOverShip .Total td, .ReportShipStatus .Total td, .ReportUSPreTransitDelay .Total td, .ReportShipStatusByStore .Total td {
  font-weight: 600; }

.ReportOverInTransit tbody td a, .ReportOverShip tbody td a, .ReportShipStatus tbody td a, .ReportUSPreTransitDelay tbody td a, .ReportShipStatusByStore tbody td a {
  color: #212529; }

.ReportOverInTransit tbody td:hover a, .ReportOverShip tbody td:hover a, .ReportShipStatus tbody td:hover a, .ReportUSPreTransitDelay tbody td:hover a, .ReportShipStatusByStore tbody td:hover a {
  color: #0f9d58; }

.ReportOverInTransit .rowSub td, .ReportOverShip .rowSub td, .ReportShipStatus .rowSub td, .ReportUSPreTransitDelay .rowSub td, .ReportShipStatusByStore .rowSub td {
  background-color: #dedede; }

.ReportOverShip .FilterDateRange,
.ReportOverInTransit .FilterDateRange,
.ReportShipStatus .FilterDateRange,
.ReportShipStatusByStore .FilterDateRange {
  margin-top: 8px; }
  .ReportOverShip .FilterDateRange .Heading,
  .ReportOverInTransit .FilterDateRange .Heading,
  .ReportShipStatus .FilterDateRange .Heading,
  .ReportShipStatusByStore .FilterDateRange .Heading {
    float: left;
    margin: 5px 0 0 0; }

.ReportOverShip .ax,
.ReportOverInTransit .ax,
.ReportShipStatus .ax,
.ReportShipStatusByStore .ax {
  color: #666; }

.ReportShipStatus th span, .ReportShipStatus td span,
.ReportShipStatusByStore th span,
.ReportShipStatusByStore td span {
  display: inline-block;
  margin-left: 5px;
  color: #666; }

.ReportProductionTime .col-total,
.ReportProductionTime .col-completed,
.ReportProductionTime .col-processing,
.ReportProductionTime .col-due {
  width: 150px; }

.ReportProductionTime .col-avg {
  width: 250px; }

.ReportProductionTime .col-type {
  width: 350px; }

.ReportProductionTime tbody tr:hover {
  background: #44895d08; }

.ReportProductionTime td.linkable, .ReportProductionTime th.linkable {
  padding: 0; }
  .ReportProductionTime td.linkable a, .ReportProductionTime th.linkable a {
    color: #212529;
    padding: 0.75rem;
    display: block; }
  .ReportProductionTime td.linkable:hover, .ReportProductionTime th.linkable:hover {
    cursor: pointer; }
    .ReportProductionTime td.linkable:hover a, .ReportProductionTime th.linkable:hover a {
      color: #0f9d58; }

.ReportProductionTime table thead th {
  vertical-align: top !important; }

@media (max-width: 1200px) {
  .ReportProductionTime .ReportHeading {
    flex-direction: column; }
    .ReportProductionTime .ReportHeading .Filter {
      margin-left: auto; } }

.ReportShipStatusByStore {
  position: relative; }
  .ReportShipStatusByStore .Filters, .ReportShipStatusByStore .FilterAndAction {
    position: relative; }
    .ReportShipStatusByStore .Filters .Clear, .ReportShipStatusByStore .FilterAndAction .Clear {
      color: #AAA; }
      .ReportShipStatusByStore .Filters .Clear svg, .ReportShipStatusByStore .FilterAndAction .Clear svg {
        font-size: 14px; }
      .ReportShipStatusByStore .Filters .Clear:hover, .ReportShipStatusByStore .FilterAndAction .Clear:hover {
        color: #0f9d58; }
  .ReportShipStatusByStore .ListTopBar {
    position: sticky;
    top: 0;
    z-index: 100;
    background: #f9fafb;
    padding-bottom: 20px;
    margin-right: -30px;
    padding-right: 30px; }
  .ReportShipStatusByStore .ListHeader {
    position: sticky;
    top: 70px;
    z-index: 100;
    background: #f9fafb; }
  .ReportShipStatusByStore table {
    border-collapse: separate;
    border-top: 0;
    border-left: 0;
    border-spacing: 0; }
    .ReportShipStatusByStore table td, .ReportShipStatusByStore table th {
      border-spacing: 0;
      border-right-width: 0;
      border-bottom-width: 0; }
      .ReportShipStatusByStore table td.sorted, .ReportShipStatusByStore table th.sorted {
        background: #fbfbfbeb; }
    .ReportShipStatusByStore table thead tr:last-child td, .ReportShipStatusByStore table thead tr:last-child th {
      border-bottom-width: 1px; }
    .ReportShipStatusByStore table tbody tr:first-child td, .ReportShipStatusByStore table tbody tr:first-child th {
      border-top-width: 0; }
  .ReportShipStatusByStore thead td, .ReportShipStatusByStore thead th {
    position: relative;
    padding: 0; }
    .ReportShipStatusByStore thead td > div, .ReportShipStatusByStore thead th > div {
      padding: 10px 14px 10px 5px;
      margin: -1px -1px 0 0;
      white-space: nowrap; }
    .ReportShipStatusByStore thead td .SortColumnHandle, .ReportShipStatusByStore thead th .SortColumnHandle {
      display: inline-block;
      margin-right: 3px; }
      .ReportShipStatusByStore thead td .SortColumnHandle .anticon svg, .ReportShipStatusByStore thead th .SortColumnHandle .anticon svg {
        font-size: 12px;
        color: #CCC; }
      .ReportShipStatusByStore thead td .SortColumnHandle.Up .SortDown svg, .ReportShipStatusByStore thead th .SortColumnHandle.Up .SortDown svg {
        color: #0f9d58; }
      .ReportShipStatusByStore thead td .SortColumnHandle.Down .SortUp svg, .ReportShipStatusByStore thead th .SortColumnHandle.Down .SortUp svg {
        color: #0f9d58; }
      .ReportShipStatusByStore thead td .SortColumnHandle .SortDown:hover svg,
      .ReportShipStatusByStore thead td .SortColumnHandle .SortUp:hover svg, .ReportShipStatusByStore thead th .SortColumnHandle .SortDown:hover svg,
      .ReportShipStatusByStore thead th .SortColumnHandle .SortUp:hover svg {
        color: #0f9d58; }
  .ReportShipStatusByStore thead .merge-total {
    border-bottom-width: 1px; }
    .ReportShipStatusByStore thead .merge-total div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-indent: -1000px; }
  .ReportShipStatusByStore thead tr th:last-child div {
    border-right: 0; }
  .ReportShipStatusByStore tbody {
    background: #F5F5F5; }
  .ReportShipStatusByStore tfoot {
    background: #FFF; }
  .ReportShipStatusByStore .SortField .dropdown-item.Selected {
    background: #007bff;
    color: #fff;
    border-radius: 4px; }
  .ReportShipStatusByStore .SortField.hasSorted .Sort {
    display: inline;
    color: #0f9d58; }

.ProductionReports .SortField .Sort, .ReportFastProductionTime .SortField .Sort {
  display: none; }

.ProductionReports .SortField:hover .Sort, .ReportFastProductionTime .SortField:hover .Sort {
  display: inline; }

.ProductionReports .SortField.hasSorted .Sort, .ReportFastProductionTime .SortField.hasSorted .Sort {
  display: inline;
  color: #0f9d58; }

.ProductionReports table .Sorted, .ReportFastProductionTime table .Sorted {
  background: #1890ff0a; }

.ProductionReports .ant-spin-spinning, .ReportFastProductionTime .ant-spin-spinning {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 300px;
  background: #ffffff3b; }

.ProductionReports .SwitchButton,
.ReportShipStatus .SwitchButton,
.ReportShipStatusByStore .SwitchButton,
.ReportFastProductionTime .SwitchButton {
  background: #FFF;
  border: 1px solid #DDD;
  font-size: 14px;
  vertical-align: middle;
  padding: 4px 14px; }
  .ProductionReports .SwitchButton.btn-export,
  .ReportShipStatus .SwitchButton.btn-export,
  .ReportShipStatusByStore .SwitchButton.btn-export,
  .ReportFastProductionTime .SwitchButton.btn-export {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.ProductionReports .Export-table-xls-button,
.ReportShipStatus .Export-table-xls-button,
.ReportShipStatusByStore .Export-table-xls-button,
.ReportFastProductionTime .Export-table-xls-button {
  padding: 4px 14px;
  font-size: 14px;
  vertical-align: middle;
  border: 1px solid #007bff;
  color: #fff;
  background-color: #007bff; }

.ProductionReports table tr td, .ProductionReports table tr th,
.ReportShipStatus table tr td,
.ReportShipStatus table tr th,
.ReportShipStatusByStore table tr td,
.ReportShipStatusByStore table tr th,
.ReportFastProductionTime table tr td,
.ReportFastProductionTime table tr th {
  width: 230px; }
  .ProductionReports table tr td:first-child, .ProductionReports table tr th:first-child,
  .ReportShipStatus table tr td:first-child,
  .ReportShipStatus table tr th:first-child,
  .ReportShipStatusByStore table tr td:first-child,
  .ReportShipStatusByStore table tr th:first-child,
  .ReportFastProductionTime table tr td:first-child,
  .ReportFastProductionTime table tr th:first-child {
    width: auto; }

.DailyReports {
  position: relative; }
  .DailyReports.Loading {
    pointer-events: none; }
    .DailyReports.Loading .ant-spin {
      position: fixed;
      top: 200px;
      z-index: 1000;
      width: 200px;
      left: 50%;
      margin-left: -100px;
      background: #FFF;
      padding: 20px;
      box-shadow: 0 0 30px 0 rgba(171, 171, 171, 0.37);
      -webkit-box-shadow: 0 0 30px 0 rgba(171, 171, 171, 0.37);
      -moz-box-shadow: 0 0 30px 0 rgba(171, 171, 171, 0.37);
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px; }
    .DailyReports.Loading:after {
      opacity: 0.5;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #FFF;
      content: '';
      z-index: 100; }
  .DailyReports .ToTop {
    position: fixed;
    bottom: 5px;
    z-index: 1000;
    right: 5px;
    background: rgba(255, 255, 255, 0.42);
    padding: 10px;
    cursor: pointer; }
    .DailyReports .ToTop:hover {
      background: #FFF;
      color: #0f9d58; }
    .DailyReports .ToTop > * {
      margin: 0; }

.DiscountNewContainer {
  width: 1198px;
  margin: 0 auto; }

.DiscountNewPage .ReturnPage {
  margin-bottom: 8px; }
  .DiscountNewPage .ReturnPage .BackButton span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px; }

.DiscountNewPage .DiscountHeader {
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 16px; }
  .DiscountNewPage .DiscountHeader .Heading h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    color: #303238; }

.DiscountNewPage .DiscountContent .FormItem {
  padding: 24px 40px;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 24px; }
  .DiscountNewPage .DiscountContent .FormItem .LabelDiscount {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #303238;
    display: block;
    margin-bottom: 16px; }
  .DiscountNewPage .DiscountContent .FormItem input {
    padding: 7px 12px;
    background: #FFFFFF;
    border: 1px solid #DEE2E6;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #000000; }
    .DiscountNewPage .DiscountContent .FormItem input ::-webkit-input-placeholder {
      /* Edge */
      color: #B0B7BF; }
    .DiscountNewPage .DiscountContent .FormItem input :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #B0B7BF; }
    .DiscountNewPage .DiscountContent .FormItem input ::placeholder {
      color: #B0B7BF; }
    .DiscountNewPage .DiscountContent .FormItem input.NameDiscount {
      width: 100%; }
    .DiscountNewPage .DiscountContent .FormItem input.TypeInput {
      width: calc(100% - 130px); }
      .DiscountNewPage .DiscountContent .FormItem input.TypeInput.Custom {
        width: calc(100%); }
    .DiscountNewPage .DiscountContent .FormItem input:disabled {
      background: #F8F9FA; }
    .DiscountNewPage .DiscountContent .FormItem input.Error {
      border: 1px solid #f44336; }
  .DiscountNewPage .DiscountContent .FormItem .TypeContent {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 12px; }
    .DiscountNewPage .DiscountContent .FormItem .TypeContent label {
      margin-bottom: 0 !important; }
  .DiscountNewPage .DiscountContent .FormItem .TypeCharacter {
    display: inline-block;
    width: 40px;
    height: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 40px;
    text-align: center;
    color: #303238;
    margin-left: -40px;
    background: #EAEEF1;
    border: 1px solid #D8DBDE;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    box-sizing: border-box; }
    .DiscountNewPage .DiscountContent .FormItem .TypeCharacter.Custom {
      margin-left: -43px;
      width: 43px;
      border-color: #007BFF;
      color: #FFFFFF;
      background: #007BFF; }
      .DiscountNewPage .DiscountContent .FormItem .TypeCharacter.Custom:hover {
        cursor: pointer; }
    .DiscountNewPage .DiscountContent .FormItem .TypeCharacter.Disable {
      opacity: 0.4; }
    .DiscountNewPage .DiscountContent .FormItem .TypeCharacter.Error {
      border: 1px solid #f44336; }
  .DiscountNewPage .DiscountContent .FormItem .SmallText {
    display: block;
    margin-top: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #65676B; }
  .DiscountNewPage .DiscountContent .FormItem .ant-radio-group {
    display: block; }
    .DiscountNewPage .DiscountContent .FormItem .ant-radio-group .ant-radio-wrapper {
      display: block;
      margin-bottom: 10px;
      margin-right: 7px; }
  .DiscountNewPage .DiscountContent .FormItem .ListStoreSelected {
    margin-top: 10px; }
    .DiscountNewPage .DiscountContent .FormItem .ListStoreSelected li {
      width: 100%;
      padding: 12px;
      list-style: none;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      background: #F8F9FA; }
      .DiscountNewPage .DiscountContent .FormItem .ListStoreSelected li span {
        float: right;
        margin-top: 4px; }
        .DiscountNewPage .DiscountContent .FormItem .ListStoreSelected li span:hover {
          cursor: pointer;
          color: #0f9d58; }
      .DiscountNewPage .DiscountContent .FormItem .ListStoreSelected li:last-child {
        border-bottom: none; }
      .DiscountNewPage .DiscountContent .FormItem .ListStoreSelected li:nth-child(2n+2) {
        background: #FDFDFD;
        border-top: 1px solid #DEE2E6;
        border-bottom: 1px solid #DEE2E6; }
  .DiscountNewPage .DiscountContent .FormItem .LineList {
    display: flex;
    align-content: center;
    justify-content: space-between; }
    .DiscountNewPage .DiscountContent .FormItem .LineList .AddProductline {
      display: flex;
      align-content: center; }
      .DiscountNewPage .DiscountContent .FormItem .LineList .AddProductline .SupplierDiscount {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 33px;
        color: #303238;
        margin-right: 10px; }
      .DiscountNewPage .DiscountContent .FormItem .LineList .AddProductline button {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #FFFFFF;
        height: 36px;
        padding: 0 40px;
        border-radius: 4px; }
        .DiscountNewPage .DiscountContent .FormItem .LineList .AddProductline button.Disable {
          background: #007BFF;
          opacity: 0.4; }
  .DiscountNewPage .DiscountContent .FormItem .SelectDate {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .DiscountNewPage .DiscountContent .FormItem .SelectDate label {
      display: block;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #303238;
      margin-bottom: 8px; }
    .DiscountNewPage .DiscountContent .FormItem .SelectDate input {
      border: none; }
    .DiscountNewPage .DiscountContent .FormItem .SelectDate .Date {
      margin-right: 16px; }
    .DiscountNewPage .DiscountContent .FormItem .SelectDate .ant-picker {
      padding: 0 11px;
      width: 200px; }
    .DiscountNewPage .DiscountContent .FormItem .SelectDate .Error {
      border: 1px solid #f44336; }
  .DiscountNewPage .DiscountContent .FormItem .ant-checkbox-wrapper {
    margin-bottom: 20px; }
  .DiscountNewPage .DiscountContent .FormItem .ListProductLineSelect {
    margin-top: 12px; }
    .DiscountNewPage .DiscountContent .FormItem .ListProductLineSelect li {
      padding: 12px;
      background: #F8F9FA; }
      .DiscountNewPage .DiscountContent .FormItem .ListProductLineSelect li .Title {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: space-between; }
      .DiscountNewPage .DiscountContent .FormItem .ListProductLineSelect li:nth-child(2n+2) {
        background: #FDFDFD;
        border-top: 1px solid #DEE2E6;
        border-bottom: 1px solid #DEE2E6; }
      .DiscountNewPage .DiscountContent .FormItem .ListProductLineSelect li .VariantsList .ant-tag.ItemVariant {
        padding: 2px 10px;
        border: 1px solid #DEE2E6;
        box-sizing: border-box;
        border-radius: 2px;
        margin-right: 10px;
        margin-top: 12px;
        font-size: 16px;
        align-items: center; }
        .DiscountNewPage .DiscountContent .FormItem .ListProductLineSelect li .VariantsList .ant-tag.ItemVariant .Remove {
          margin-left: 10px;
          font-size: 12px; }
  .DiscountNewPage .DiscountContent .FormItem .ant-checkbox-group {
    margin-bottom: 10px; }
    .DiscountNewPage .DiscountContent .FormItem .ant-checkbox-group .ant-checkbox-wrapper {
      margin-bottom: 0; }

.DiscountNewPage .DiscountFooter {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: right; }
  .DiscountNewPage .DiscountFooter .text-danger {
    margin-right: 30px; }

.DiscountNewPage .Actions button {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  height: 36px;
  padding: 0 20px;
  border-radius: 4px; }

.AddProductLine {
  width: 60% !important;
  border-radius: 4px; }
  .AddProductLine .ant-modal-header {
    padding: 24px; }
    .AddProductLine .ant-modal-header .ant-modal-title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #303238; }
  .AddProductLine .FiltersProductLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px; }
    .AddProductLine .FiltersProductLine .FilterSupplier {
      width: 216px; }
      .AddProductLine .FiltersProductLine .FilterSupplier .ant-select {
        width: 100%; }
        .AddProductLine .FiltersProductLine .FilterSupplier .ant-select .ant-select-selector {
          height: 38px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          color: #303238;
          border-radius: 4px;
          padding: 0 12px; }
          .AddProductLine .FiltersProductLine .FilterSupplier .ant-select .ant-select-selector .ant-select-selection-item {
            line-height: 38px; }
        .AddProductLine .FiltersProductLine .FilterSupplier .ant-select input {
          height: 38px; }
    .AddProductLine .FiltersProductLine .FilterInput input {
      height: 38px;
      width: 216px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      border-radius: 4px;
      color: #2F3230;
      padding: 0 12px; }
  .AddProductLine .ListProductLine {
    height: 350px;
    overflow-y: scroll; }
    .AddProductLine .ListProductLine table {
      width: 100%; }
      .AddProductLine .ListProductLine table .SelectProductLine {
        width: 56px; }
      .AddProductLine .ListProductLine table .Title {
        width: 400px; }
      .AddProductLine .ListProductLine table .ProductLine, .AddProductLine .ListProductLine table .ProductSku {
        width: 155px; }
      .AddProductLine .ListProductLine table th.Title {
        padding-left: 78px; }
      .AddProductLine .ListProductLine table .Actions span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #007BFF; }
        .AddProductLine .ListProductLine table .Actions span i {
          margin-left: 10px; }
        .AddProductLine .ListProductLine table .Actions span:hover {
          cursor: pointer; }
      .AddProductLine .ListProductLine table thead tr th {
        background: #F8F9FA;
        padding: 16px;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #303238; }
      .AddProductLine .ListProductLine table tbody tr td {
        padding: 16px;
        border-bottom: 1px solid #DEE2E6;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #303238; }
        .AddProductLine .ListProductLine table tbody tr td img {
          width: 40px;
          height: auto;
          margin-right: 24px; }
        .AddProductLine .ListProductLine table tbody tr td .Variants {
          margin-left: 56px;
          background: #F8F9FA;
          padding: 2px 33px;
          min-height: 100px; }
          .AddProductLine .ListProductLine table tbody tr td .Variants .ant-spin-nested-loading {
            min-height: 100px; }
          .AddProductLine .ListProductLine table tbody tr td .Variants .VariantItem {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 12px;
            border-bottom: 1px solid #DEE2E6;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #303238; }
            .AddProductLine .ListProductLine table tbody tr td .Variants .VariantItem .Left {
              display: flex;
              align-items: center; }
              .AddProductLine .ListProductLine table tbody tr td .Variants .VariantItem .Left .SelectVariant {
                margin-right: 20px; }
              .AddProductLine .ListProductLine table tbody tr td .Variants .VariantItem .Left .TitleVariant {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: #303238; }
            .AddProductLine .ListProductLine table tbody tr td .Variants .VariantItem .SkuVariant {
              width: 225px;
              text-align: left; }
            .AddProductLine .ListProductLine table tbody tr td .Variants .VariantItem:last-child {
              border-bottom: none; }
      .AddProductLine .ListProductLine table tbody tr.NotShow {
        display: none; }
      .AddProductLine .ListProductLine table tbody tr.ShowVariant td {
        padding: 0; }
  .AddProductLine .ant-pagination {
    margin-top: 30px; }
  .AddProductLine .ant-modal-footer {
    padding: 24px; }
    .AddProductLine .ant-modal-footer button {
      height: 40px;
      padding: 0 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px; }

.Histories {
  padding: 24px;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  border-radius: 4px; }
  .Histories .TitleHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #303238;
    margin-bottom: 15px; }
  .Histories .HistoriesItem {
    margin-bottom: 5px; }

.FilterDateSingle .ant-picker {
  width: 100%; }

.listDiscounts .GroupFilter {
  align-items: center; }

.listDiscounts .itemFilter.filterPushedAt {
  top: 0 !important; }
  .listDiscounts .itemFilter.filterPushedAt .FilterDateRange .Heading {
    margin-bottom: 3px !important; }

.MultiFilterInput {
  background: #FFFFFF;
  border: 1px solid #D8DBDE;
  box-sizing: border-box;
  border-radius: 4px; }
  .MultiFilterInput .MultiFilter {
    display: flex;
    align-items: center; }
    .MultiFilterInput .MultiFilter .ant-select .ant-select-selector {
      border-radius: 4px 0 0 4px;
      border: 0;
      border-right: 1px solid #D8DBDE;
      padding: 5px 12px;
      height: 38px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      min-width: 170px;
      color: #303238; }
    .MultiFilterInput .MultiFilter .ant-select .ant-select-arrow {
      color: #303238; }
    .MultiFilterInput .MultiFilter .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none !important;
      border-color: #D8DBDE; }
    .MultiFilterInput .MultiFilter .Right svg {
      margin-left: 14.25px;
      margin-right: 6.25px; }
      .MultiFilterInput .MultiFilter .Right svg path {
        fill: #303238 !important; }
    .MultiFilterInput .MultiFilter .Right input {
      border: 0;
      height: 38px;
      padding: 9px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      border-radius: 4px;
      width: 300px; }
      .MultiFilterInput .MultiFilter .Right input::placeholder {
        color: #979B9F; }

.NeedCharge .ant-select-selector {
  height: 38px !important; }

.NeedCharge .ant-select-selection-item {
  line-height: 38px !important; }

.CustomMultiSelect {
  position: relative;
  margin-left: 12px; }
  .CustomMultiSelect .Background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 8; }
  .CustomMultiSelect .InputSelect {
    display: block;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #303238;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #D8DBDE;
    box-sizing: border-box;
    border-radius: 4px;
    position: relative;
    min-width: 200px;
    padding: 8px 12px; }
    .CustomMultiSelect .InputSelect span {
      overflow-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      height: auto;
      display: -webkit-box;
      -webkit-box-orient: vertical; }
    .CustomMultiSelect .InputSelect:after {
      content: '\e64b';
      font-family: 'themify';
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%); }
  .CustomMultiSelect .PopupSelect {
    position: absolute;
    padding: 1px 0 0 0;
    top: 42px;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    opacity: 0;
    z-index: -1;
    border: 1px solid #eeeeee;
    min-width: 225px; }
    .CustomMultiSelect .PopupSelect.true {
      z-index: 9;
      opacity: 1;
      top: 42px; }
    .CustomMultiSelect .PopupSelect .Item {
      display: flex;
      align-items: center;
      margin-bottom: 1px;
      padding: 5px 8px; }
      .CustomMultiSelect .PopupSelect .Item label {
        margin-bottom: 0 !important;
        margin-left: 10px;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 22px !important;
        color: #303238 !important; }
    .CustomMultiSelect .PopupSelect .Actions button {
      border: 1px solid #0a6ebd;
      background-color: #0a6ebd;
      color: #FFFFFF;
      margin-right: 10px;
      padding: 0 10px;
      border-radius: 4px;
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 22px !important; }
      .CustomMultiSelect .PopupSelect .Actions button.LinkAction {
        border: 0;
        background-color: #FFFFFF;
        color: #0a6ebd;
        padding: 0 10px; }
      .CustomMultiSelect .PopupSelect .Actions button:hover, .CustomMultiSelect .PopupSelect .Actions button:active, .CustomMultiSelect .PopupSelect .Actions button:focus {
        cursor: pointer;
        background-color: #0aa7ef;
        border-color: #0aa7ef;
        outline: none; }
        .CustomMultiSelect .PopupSelect .Actions button:hover.LinkAction, .CustomMultiSelect .PopupSelect .Actions button:active.LinkAction, .CustomMultiSelect .PopupSelect .Actions button:focus.LinkAction {
          background-color: #FFFFFF;
          color: #0aa7ef;
          outline: none; }
      .CustomMultiSelect .PopupSelect .Actions button:disabled {
        cursor: not-allowed;
        color: #999; }
  .CustomMultiSelect .SubItemList {
    margin-left: 25px; }
    .CustomMultiSelect .SubItemList .Item .bg {
      margin-left: -25px; }

.RequestsContainer .RequestsPage .PageHeading .RightTop {
  display: flex;
  align-items: center; }
  .RequestsContainer .RequestsPage .PageHeading .RightTop .ExportRequestGoogleSheet {
    margin-left: 24px; }
    .RequestsContainer .RequestsPage .PageHeading .RightTop .ExportRequestGoogleSheet button {
      height: 40px; }
  .RequestsContainer .RequestsPage .PageHeading .RightTop .MoreFilter {
    border: 1px solid #eeeeee;
    padding: 8px 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #303238;
    margin-left: 5px;
    border-radius: 4px; }
    .RequestsContainer .RequestsPage .PageHeading .RightTop .MoreFilter i {
      font-size: 14px; }
    .RequestsContainer .RequestsPage .PageHeading .RightTop .MoreFilter:hover {
      cursor: pointer;
      background: #0f9d58;
      color: #FFFFFF; }
      .RequestsContainer .RequestsPage .PageHeading .RightTop .MoreFilter:hover i {
        color: #FFFFFF; }

.RequestsContainer .RequestsPage .SectionInner {
  position: relative;
  padding: 24px 16px; }
  .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters {
    display: flex;
    align-items: center;
    margin-bottom: 24px; }
    .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .MultiFilterInput {
      margin-right: 24px; }
    .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange {
      margin-right: 24px; }
      .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
        height: 40px;
        border-radius: 4px; }
    .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .CustomMultiSelect {
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 24px; }
      .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .CustomMultiSelect:last-child {
        margin-right: 0; }
  .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter {
    display: flex;
    align-items: center;
    margin-top: 12px; }
    .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .ProductStatus {
      margin-right: 12px; }
    .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .ProductStatus, .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .Variants {
      padding: 5px 12px;
      background: #F8F9FA;
      border: 1px solid #D8DBDE;
      box-sizing: border-box;
      border-radius: 4px; }
      .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .ProductStatus span, .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .Variants span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #303238; }
        .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .ProductStatus span:not(.Title), .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .Variants span:not(.Title) {
          font-weight: 500;
          margin-right: 7px;
          position: relative; }
          .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .ProductStatus span:not(.Title):after, .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .Variants span:not(.Title):after {
            content: ',';
            position: absolute;
            right: -4px;
            bottom: 0;
            color: #303238; }
        .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .ProductStatus span:last-of-type:after, .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .Variants span:last-of-type:after {
          display: none; }
      .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .ProductStatus i, .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .Variants i {
        font-size: 12px;
        margin-left: 5px; }
        .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .ProductStatus i:hover, .RequestsContainer .RequestsPage .SectionInner .RequestFilters .DisplayFilter .Variants i:hover {
          cursor: pointer; }
  .RequestsContainer .RequestsPage .SectionInner .Total {
    text-align: right;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #303238;
    margin-bottom: 19px; }
    .RequestsContainer .RequestsPage .SectionInner .Total span {
      text-align: right;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #303238;
      margin-bottom: 19px; }
  .RequestsContainer .RequestsPage .SectionInner .RequestTable table {
    width: 100%; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestThead tr th {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #303238;
      padding: 16px;
      border-top: 1px solid #D8DBDE;
      background: #F4F6F8; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr td {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #303238;
      padding: 16px;
      border-top: 1px solid #D8DBDE; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr:last-child {
      border-bottom: 1px solid #D8DBDE; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .RequestId, .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type, .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Info {
      width: 10%;
      word-break: break-word; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Source {
      width: 7%; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .RequestId {
      color: #0A7BFE;
      font-size: 14px; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Source {
      text-transform: uppercase; }
      .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Source.StatusProduction {
        text-transform: none; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Info span {
      color: #0A7BFE;
      font-style: italic; }
      .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Info span:last-child {
        font-style: normal;
        color: #2F3230;
        display: block;
        font-weight: bold; }
      .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Info span a {
        color: inherit; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Content, .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Created, .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Updated {
      width: 10%;
      word-break: break-word; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type .MoreInfo span {
      display: inline-block;
      height: 18px;
      line-height: 17px;
      position: relative;
      padding: 0 7px 0 12px;
      background: #DC3545;
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
      color: #fff;
      font-size: 11px;
      text-decoration: none;
      font-weight: 400;
      margin: 0 0 0 7px;
      white-space: nowrap; }
      .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type .MoreInfo span:before {
        content: "";
        position: absolute;
        top: 0;
        left: -7px;
        width: 0;
        height: 0;
        border-color: transparent #DC3545 transparent transparent;
        border-style: solid;
        border-width: 9px 7px 9px 0; }
      .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type .MoreInfo span:after {
        content: "";
        position: absolute;
        top: 7px;
        left: 1px;
        float: left;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #fff; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type span.pending, .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type span.paid, .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type span.cancelled, .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type span.NoCharge {
      display: block;
      width: fit-content;
      white-space: nowrap; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type span.pending {
      background: #007BFF; }
      .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type span.pending:before {
        border-color: transparent #007BFF transparent transparent; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type span.paid {
      background: #0f9d58; }
      .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type span.paid:before {
        border-color: transparent #0f9d58 transparent transparent; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type span.cancelled, .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type span.NoCharge {
      background: #9a0000; }
      .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type span.cancelled:before, .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Type span.NoCharge:before {
        border-color: transparent #9a0000 transparent transparent; }
    .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Status {
      width: 8%;
      word-break: break-word;
      white-space: nowrap; }
      .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Status .itemStatus {
        display: inline-block;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        padding: 1px 10px;
        border-radius: 80px;
        color: #FFFFFF; }
        .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Status .itemStatus.waiting {
          background: #0aa7ef; }
        .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Status .itemStatus.processing {
          background: #00897b; }
        .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Status .itemStatus.updated {
          background: #00c853; }
        .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Status .itemStatus.hold {
          background-color: #ECD141; }
      .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Status .ConfirmCharge {
        color: #0A7BFE; }
        .RequestsContainer .RequestsPage .SectionInner .RequestTable table .RequestBody tr.RequestItem .Status .ConfirmCharge svg {
          font-size: 22px;
          margin-left: 12px;
          top: 2px;
          position: relative; }
  .RequestsContainer .RequestsPage .SectionInner .ant-pagination {
    padding-top: 24px; }

.ModalConfirmChargeUpdateDesign .RequestInfo {
  margin-bottom: 10px; }
  .ModalConfirmChargeUpdateDesign .RequestInfo strong {
    margin-bottom: 10px;
    display: block; }
  .ModalConfirmChargeUpdateDesign .RequestInfo label {
    margin-bottom: 4px;
    width: 130px; }

.ModalConfirmChargeUpdateDesign .Note {
  background: #F9FAFB;
  border: 1px solid #DFDFDF;
  border-radius: 3px;
  padding: 14px 20px;
  margin-bottom: 18px; }

.ModalConfirmChargeUpdateDesign .ChargeInfo strong {
  margin-bottom: 10px;
  display: block; }

.ModalConfirmChargeUpdateDesign .ChargeInfo label {
  margin-bottom: 4px;
  width: 100px; }

.ModalConfirmChargeUpdateDesign .ChargeInfo .ValueCharge input[type="text"] {
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  line-height: 30px;
  padding: 0 10px; }

.ModalConfirmChargeUpdateDesign .ChargeInfo .ValueCharge .error input[type="text"] {
  border-color: #ff0000; }

.ModalConfirmChargeUpdateDesign .ChargeInfo .ValueCharge .error button[type="button"] {
  border-bottom-color: #ff0000;
  border-top-color: #ff0000;
  border-right-color: #ff0000; }

.Created {
  left: 733px !important; }

.CreatedMapping {
  left: 770px !important; }

.Updated {
  left: 1045px !important; }

@media (max-width: 1898px) {
  .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
    width: 250px; } }

@media (max-width: 1536px) {
  .CustomMultiSelect .InputSelect {
    min-width: 120px; }
  .MultiFilterInput .MultiFilter .ant-select .ant-select-selector {
    min-width: 100px; }
  .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
    width: 240px; } }

@media (max-width: 1440px) {
  .CustomMultiSelect .InputSelect {
    min-width: 120px; }
  .MultiFilterInput .MultiFilter .ant-select .ant-select-selector {
    min-width: 100px; }
  .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
    width: 220px; }
  .MultiFilterInput .MultiFilter .Right input {
    width: 100px; } }

@media (max-width: 1367px) {
  .CustomMultiSelect .InputSelect {
    min-width: 110px; }
  .MultiFilterInput .MultiFilter .ant-select .ant-select-selector {
    min-width: 100px; }
  .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
    width: 200px; } }

.ExportRequestUpdate .Content .FormItem {
  margin-bottom: 20px; }
  .ExportRequestUpdate .Content .FormItem label {
    display: block;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #303238; }
  .ExportRequestUpdate .Content .FormItem input {
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #D8DBDE;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 9px 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #303238; }
    .ExportRequestUpdate .Content .FormItem input:focus {
      border: 1px solid #0f9d58; }

.RequestFilters .Filters {
  display: flex;
  align-items: center;
  margin-bottom: 15px; }
  .RequestFilters .Filters .MultiFilterInput {
    margin-right: 24px; }
  .RequestFilters .Filters .FilterDateRange .ant-picker {
    height: 40px;
    border-radius: 4px; }
  .RequestFilters .Filters .CustomMultiSelect {
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 24px; }
    .RequestFilters .Filters .CustomMultiSelect:last-child {
      margin-right: 0; }

.RequestFilters .DisplayFilter {
  display: flex;
  align-items: center;
  margin-top: 12px; }
  .RequestFilters .DisplayFilter .ProductStatus {
    margin-right: 12px; }
  .RequestFilters .DisplayFilter .ProductStatus, .RequestFilters .DisplayFilter .Variants {
    padding: 5px 12px;
    background: #F8F9FA;
    border: 1px solid #D8DBDE;
    box-sizing: border-box;
    border-radius: 4px; }
    .RequestFilters .DisplayFilter .ProductStatus span, .RequestFilters .DisplayFilter .Variants span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: #303238; }
      .RequestFilters .DisplayFilter .ProductStatus span:not(.Title), .RequestFilters .DisplayFilter .Variants span:not(.Title) {
        font-weight: 500;
        margin-right: 7px;
        position: relative; }
        .RequestFilters .DisplayFilter .ProductStatus span:not(.Title):after, .RequestFilters .DisplayFilter .Variants span:not(.Title):after {
          content: ',';
          position: absolute;
          right: -4px;
          bottom: 0;
          color: #303238; }
      .RequestFilters .DisplayFilter .ProductStatus span:last-of-type:after, .RequestFilters .DisplayFilter .Variants span:last-of-type:after {
        display: none; }
    .RequestFilters .DisplayFilter .ProductStatus i, .RequestFilters .DisplayFilter .Variants i {
      font-size: 12px;
      margin-left: 5px; }
      .RequestFilters .DisplayFilter .ProductStatus i:hover, .RequestFilters .DisplayFilter .Variants i:hover {
        cursor: pointer; }

.SwitchCarrierContainer .SwitchCarrierPage .SectionInner {
  position: relative;
  padding: 24px 16px; }
  .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .Total {
    text-align: right;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #303238;
    margin-bottom: 19px; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .Total span {
      text-align: right;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #303238;
      margin-bottom: 19px; }
  .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .RequestFilters .Filters {
    display: flex;
    align-items: center; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .RequestFilters .Filters .FilterDateRange label {
      display: block;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #303238; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
      height: 40px;
      border-radius: 4px; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .RequestFilters .Filters .FilterStatus {
      margin-left: 24px; }
      .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .RequestFilters .Filters .FilterStatus label {
        display: block;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #303238; }
      .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .RequestFilters .Filters .FilterStatus .ant-select-selector {
        height: 40px;
        border-radius: 4px; }
        .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .RequestFilters .Filters .FilterStatus .ant-select-selector .ant-select-selection-item {
          line-height: 40px; }
  .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table {
    width: 100%; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestThead tr th {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #303238;
      padding: 16px;
      border-top: 1px solid #D8DBDE;
      background: #F4F6F8; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr td {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #303238;
      padding: 16px;
      border-top: 1px solid #D8DBDE; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr:last-child {
      border-bottom: 1px solid #D8DBDE; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .RequestId, .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Type, .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Info {
      white-space: nowrap; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .RequestId {
      color: #0A7BFE;
      font-size: 14px; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Source {
      text-transform: uppercase; }
      .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Source.StatusProduction {
        text-transform: none; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Info span {
      color: #0A7BFE;
      font-style: italic; }
      .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Info span:last-child {
        font-style: normal;
        color: #2F3230;
        display: block;
        font-weight: bold; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Content, .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Created, .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Updated {
      white-space: nowrap; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Type span {
      display: inline-block;
      height: 18px;
      line-height: 17px;
      position: relative;
      padding: 0 7px 0 12px;
      background: #DC3545;
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
      color: #fff;
      font-size: 11px;
      text-decoration: none;
      font-weight: 400;
      margin: 0 0 8px 15px; }
      .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Type span:before {
        content: "";
        position: absolute;
        top: 0;
        left: -7px;
        width: 0;
        height: 0;
        border-color: transparent #DC3545 transparent transparent;
        border-style: solid;
        border-width: 9px 7px 9px 0; }
      .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Type span:after {
        content: "";
        position: absolute;
        top: 7px;
        left: 1px;
        float: left;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #fff; }
    .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Status {
      white-space: nowrap; }
      .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Status > div {
        display: inline-block;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        padding: 0 10px 2px;
        border-radius: 80px;
        color: #FFFFFF; }
        .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Status > div.waiting, .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Status > div.created {
          background-color: #17a2b8;
          animation: progress-bar-stripes 1s linear infinite;
          background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 0, transparent 50%, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0.15) 75%, transparent 0, transparent);
          background-size: 1rem 1rem; }
        .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Status > div.processing {
          background-color: #2196F3;
          animation: progress-bar-stripes 1s linear infinite;
          background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 0, transparent 50%, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0.15) 75%, transparent 0, transparent);
          background-size: 1rem 1rem; }
        .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Status > div.updated, .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .SwitchCarrierTable table .RequestBody tr.RequestItem .Status > div.done {
          background-color: #33A746; }
  .SwitchCarrierContainer .SwitchCarrierPage .SectionInner .ant-pagination {
    padding-top: 24px; }

.CreatedJobs {
  left: 117px !important; }

.ScanDetailTable tr th {
  padding-top: 10px;
  padding-bottom: 10px; }

.ScanDetailTable .BulkSelect, .ScanDetailTable .OrderCheckbox {
  width: 30px; }
  .ScanDetailTable .BulkSelect .custom-control, .ScanDetailTable .OrderCheckbox .custom-control {
    padding-left: 0; }

.ScanDetailTable .MenuActions {
  position: absolute;
  left: 10px;
  top: 5px; }

.ScanDetailTable .HasSwitch {
  background-color: #0f9d5847; }

.ScanDetailFilter {
  display: flex;
  align-items: center;
  margin-bottom: 24px; }
  .ScanDetailFilter .FilterItem {
    margin-right: 15px; }
    .ScanDetailFilter .FilterItem label {
      display: block;
      font-size: 14px;
      font-weight: 600; }
    .ScanDetailFilter .FilterItem.Input input {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #303238;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #D8DBDE;
      box-sizing: border-box;
      border-radius: 4px;
      position: relative;
      min-width: 350px;
      padding: 8px 12px; }
    .ScanDetailFilter .FilterItem .ant-select-selector {
      height: 40px;
      border-radius: 4px;
      width: 200px; }
      .ScanDetailFilter .FilterItem .ant-select-selector .ant-select-selection-item {
        line-height: 40px; }

.ShippingPriceContainer .ShippingPricePage .SectionInner .QuickSelect {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center; }
  .ShippingPriceContainer .ShippingPricePage .SectionInner .QuickSelect li {
    position: relative;
    border-bottom: 2px solid transparent;
    margin-right: 15px;
    color: #000000; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .QuickSelect li span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      padding: 16px 20px;
      position: relative;
      display: block; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .QuickSelect li [data-action="remove"] {
      position: absolute;
      visibility: hidden;
      top: 50%;
      right: 0;
      width: 16px;
      height: 16px;
      text-align: center;
      line-height: 16px;
      margin-top: -8px;
      color: #999; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .QuickSelect li [data-action="remove"]:hover {
        color: #9a0000; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .QuickSelect li:last-child {
      margin-right: 0; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .QuickSelect li:hover {
      cursor: pointer;
      color: #007BFF;
      border-bottom: 2px solid #007BFF; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .QuickSelect li:hover [data-action="remove"] {
        visibility: visible; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .QuickSelect li.Active {
      color: #007BFF;
      border-bottom: 2px solid #007BFF; }

.ShippingPriceContainer .ShippingPricePage .SectionInner .ShippingPriceFilters label {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  margin-right: 15px; }

.ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage {
  overflow-y: hidden; }
  .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent {
    overflow: auto; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th {
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
      padding: 16px;
      background-color: #F4F6F8;
      border-bottom: 0;
      vertical-align: middle; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th .RangeValue > div {
        display: inline-block;
        vertical-align: middle; }
        .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th .RangeValue > div > div {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative; }
          .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th .RangeValue > div > div input {
            text-align: center;
            max-width: 62px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #000000;
            background-color: #FFF;
            border: 1px solid #dddddd;
            margin-right: 5px;
            padding: 5px; }
            .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th .RangeValue > div > div input:last-of-type {
              margin-left: 5px;
              margin-right: 0; }
            .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th .RangeValue > div > div input:focus, .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th .RangeValue > div > div input:hover {
              border: 1px solid #007BFF;
              background-color: #FFFFFF; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th .RangeValue i {
        font-size: 16px;
        margin-right: -18px;
        opacity: 0;
        position: absolute;
        top: 50%;
        margin-top: -8px; }
        .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th .RangeValue i:hover {
          opacity: 1;
          color: #9a0000;
          cursor: pointer; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th .RangeValue.Error input {
        border-color: #9a0000;
        color: #9a0000; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th:last-child {
        text-align: left; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th.CountryCode {
        min-width: 150px; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th.ShippingRange {
        max-width: 175px; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th.AddColumnShippingCost {
        padding: 0 15px; }
        .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th.AddColumnShippingCost i {
          padding: 5px;
          font-size: 16px;
          color: #000000;
          border: 1px dashed #000000; }
          .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table thead tr th.AddColumnShippingCost i:hover {
            border-color: #0f9d58;
            cursor: pointer;
            color: #0f9d58; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr td {
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #000000;
      padding: 16px;
      border-bottom: 0; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr td:first-child {
        font-weight: 600; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr td:last-child {
        text-align: left; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr td input {
        text-align: center;
        max-width: 68px;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
        background-color: #FFF;
        border: 1px solid #dddddd;
        margin-right: 0px;
        padding: 5px 10px; }
        .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr td input:focus, .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr td input:hover {
          border: 1px solid #007BFF;
          background-color: #FFFFFF; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr.AddRowShippingCost td {
      padding-left: 0; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr.AddRowShippingCost i {
      padding: 6px 80px;
      font-size: 20px;
      color: #000000;
      border: 1px dashed #000000; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr.AddRowShippingCost i:hover {
        border-color: #0f9d58;
        cursor: pointer;
        color: #0f9d58; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr .CountryCode .RemoveRow,
    .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr .CountryCode .ErrorDuplicateCountry {
      font-size: 16px;
      position: absolute;
      top: 50%;
      margin-top: -8px; }
      .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr .CountryCode .RemoveRow:hover,
      .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr .CountryCode .ErrorDuplicateCountry:hover {
        opacity: 1;
        color: #9a0000;
        cursor: pointer; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr .CountryCode .RemoveRow {
      opacity: 0;
      margin-left: -20px; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr .CountryCode .ErrorDuplicateCountry {
      margin-left: 5px; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr.DuplicateCountryCode td > * {
      color: #dc3545; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent .table tbody tr.Highlight td {
      background: #f0f8ff; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent.fixed-x td.CountryCode {
      background: #F4F6F8; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Content .TabContent.fixed-x .Highlight .CountryCode {
      background: #e6eff7; }
  .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Actions {
    text-align: right;
    margin-top: 30px; }
    .ShippingPriceContainer .ShippingPricePage .SectionInner .BodyPage .Actions button {
      margin-left: 15px; }

.ShippingPriceContainer #ShippingCosts .ant-table-content tr {
  color: #FF0000; }
  .ShippingPriceContainer #ShippingCosts .ant-table-content tr:last-child td {
    border-bottom: 1px solid #dee2e6; }

.ShippingPriceContainer .ErrorDataMessage {
  margin-bottom: 0; }

.ShippingPriceContainer .AddRowShippingCost i {
  padding: 6px 80px;
  font-size: 20px;
  color: #000000;
  border: 1px dashed #000000;
  display: inline-block; }
  .ShippingPriceContainer .AddRowShippingCost i:hover {
    border-color: #0f9d58;
    cursor: pointer;
    color: #0f9d58; }

.ImportShippingModal .FormItemImport .Label {
  font-weight: 500; }

.ImportShippingModal .FormItemImport input.Hidden {
  display: none; }

.ImportShippingModal .FormItemImport .SelectFile {
  text-align: center;
  width: 100%;
  padding: 15px 30px;
  border: 1px dashed #dddddd; }
  .ImportShippingModal .FormItemImport .SelectFile:hover {
    border-color: #0f9d58;
    color: #0f9d58; }
    .ImportShippingModal .FormItemImport .SelectFile:hover svg {
      color: #0f9d58; }
  .ImportShippingModal .FormItemImport .SelectFile svg {
    margin-right: 10px; }

.ImportShippingModal .FormItemImport .Template a {
  margin-bottom: 0.3rem; }

.ImportShippingModal .ListFileInput {
  margin-top: 15px; }
  .ImportShippingModal .ListFileInput ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px; }
    .ImportShippingModal .ListFileInput ul li:last-child {
      margin-bottom: 0; }
    .ImportShippingModal .ListFileInput ul li .Left {
      display: flex;
      align-items: center; }
      .ImportShippingModal .ListFileInput ul li .Left .FileName {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #477BF6;
        margin-left: 10px; }
    .ImportShippingModal .ListFileInput ul li svg.Delete:hover {
      cursor: pointer; }
      .ImportShippingModal .ListFileInput ul li svg.Delete:hover path {
        fill: #9a0000; }

.ProductDetailInfo .ProductInfo .form-group label:not(.form-check-label) {
  display: block;
  font-weight: 600; }

.ProductDetailInfo .ProductInfo .form-group .ant-select {
  width: 100%; }
  .ProductDetailInfo .ProductInfo .form-group .ant-select .ant-select-selector {
    border-radius: 0.25rem;
    height: auto; }
    .ProductDetailInfo .ProductInfo .form-group .ant-select .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item {
      height: 30px;
      line-height: 26px;
      margin-top: 0;
      background-color: #1890ff;
      border-radius: 0.25rem; }
      .ProductDetailInfo .ProductInfo .form-group .ant-select .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-content {
        color: #FFFFFF; }
      .ProductDetailInfo .ProductInfo .form-group .ant-select .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-remove svg {
        fill: #FFFFFF; }

.ProductDetailInfo .ProductInfo .form-group .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: #495057;
  background: #e9ecef;
  cursor: not-allowed; }

.ProductDetailInfo .ProductInfo .form-group textarea {
  resize: none; }

.ProductCategories {
  margin-top: 50px; }

.ProductImages .ProductImagesList .ProductImagesItem {
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  margin-right: 5px; }
  .ProductImages .ProductImagesList .ProductImagesItem:last-child {
    margin-right: 0; }
  .ProductImages .ProductImagesList .ProductImagesItem .RemoveImage {
    background: #fff;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 50%; }
    .ProductImages .ProductImagesList .ProductImagesItem .RemoveImage:hover {
      background-color: #9a0000;
      color: #FFFFFF !important;
      border-color: #9a0000; }

.ProductInfo .form-group label:not(.form-check-label) {
  display: block;
  font-weight: 600; }

.ProductInfo .form-group .ant-select {
  width: 100%; }
  .ProductInfo .form-group .ant-select .ant-select-selector {
    border-radius: 0.25rem;
    height: 38px; }
    .ProductInfo .form-group .ant-select .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item {
      height: 30px;
      line-height: 26px;
      margin-top: 0;
      background-color: #1890ff;
      border-radius: 0.25rem; }
      .ProductInfo .form-group .ant-select .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-content {
        color: #FFFFFF; }
      .ProductInfo .form-group .ant-select .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-remove svg {
        fill: #FFFFFF; }

.ProductInfo .form-group textarea {
  resize: none; }

.ProductVariants .TopPageHeading {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .ProductVariants .TopPageHeading .ProductVariantSupplier {
    position: relative; }
    .ProductVariants .TopPageHeading .ProductVariantSupplier .add-store {
      margin-right: 15px;
      padding: 5px 15px;
      display: flex;
      align-items: center;
      border: 1px solid #dddddd;
      border-radius: 4px; }
      .ProductVariants .TopPageHeading .ProductVariantSupplier .add-store:hover {
        cursor: pointer;
        border: 1px solid #0f9d58; }
        .ProductVariants .TopPageHeading .ProductVariantSupplier .add-store:hover span {
          color: #0f9d58; }
        .ProductVariants .TopPageHeading .ProductVariantSupplier .add-store:hover i {
          color: #0f9d58; }
    .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplierVariant .CloneVariant {
      padding: 5px 15px;
      display: flex;
      align-items: center;
      border: 1px solid #dddddd;
      border-radius: 4px; }
      .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplierVariant .CloneVariant span {
        color: #333333; }
      .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplierVariant .CloneVariant i {
        color: #333333;
        margin-left: 5px; }
      .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplierVariant .CloneVariant:hover {
        cursor: pointer;
        border: 1px solid #0f9d58; }
        .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplierVariant .CloneVariant:hover span {
          color: #0f9d58; }
        .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplierVariant .CloneVariant:hover i {
          color: #0f9d58; }
    .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplier {
      display: none;
      position: absolute;
      top: 37px;
      right: 0;
      min-width: 270px;
      background: #FFFFFF;
      border: 1px solid #dddddd;
      max-height: 300px;
      overflow: auto;
      z-index: 10;
      border-radius: 6px; }
      .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplier::-webkit-scrollbar {
        width: 6px; }
      .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplier::-webkit-scrollbar-thumb {
        background: #D3DCD7;
        border-radius: 6px; }
      .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplier.IsOpen {
        display: block; }
      .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplier li {
        padding: 5px 15px;
        border-bottom: 1px solid #dddddd; }
        .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplier li:hover {
          cursor: pointer;
          background: #0f9d58;
          color: #FFFFFF; }
        .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplier li:last-child {
          border-bottom: none; }
        .ProductVariants .TopPageHeading .ProductVariantSupplier .ListSupplier li.Disable {
          pointer-events: none;
          opacity: .5; }
    .ProductVariants .TopPageHeading .ProductVariantSupplier .Background {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 8; }

.ProductVariants .VariantTab .VariantTabHeader ul {
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 2px solid #007BFF;
  overflow: auto; }
  .ProductVariants .VariantTab .VariantTabHeader ul::-webkit-scrollbar {
    height: 8px; }
  .ProductVariants .VariantTab .VariantTabHeader ul::-webkit-scrollbar-thumb {
    background: #D3DCD7;
    border-radius: 4px; }
  .ProductVariants .VariantTab .VariantTabHeader ul li {
    border: 2px solid #D8DBDE;
    padding: 8px 22px;
    color: #65676B;
    font-weight: 600;
    margin-right: 5px;
    border-radius: 4px;
    white-space: pre; }
    .ProductVariants .VariantTab .VariantTabHeader ul li:hover {
      cursor: pointer;
      background: #007BFF;
      color: #FFFFFF;
      border: 2px solid #007BFF; }
    .ProductVariants .VariantTab .VariantTabHeader ul li:last-child {
      margin-right: 0; }
    .ProductVariants .VariantTab .VariantTabHeader ul li.Active {
      background: #007BFF;
      color: #FFFFFF;
      border: 2px solid #007BFF; }

.ProductVariants .VariantTab .VariantTabBody .ProductVariantsFilter {
  padding: 15px 0;
  display: flex;
  align-items: center; }
  .ProductVariants .VariantTab .VariantTabBody .ProductVariantsFilter .FilterItem {
    min-width: 150px;
    margin-right: 10px; }
    .ProductVariants .VariantTab .VariantTabBody .ProductVariantsFilter .FilterItem:last-child {
      margin-right: 0; }

.ProductVariants .VariantTab .VariantTabBody .ProductVariantsTable {
  overflow: auto; }
  .ProductVariants .VariantTab .VariantTabBody .ProductVariantsTable .table {
    min-width: 1740px; }
  .ProductVariants .VariantTab .VariantTabBody .ProductVariantsTable .ProductVariantRowDimension {
    width: 118px;
    text-align: center; }
    .ProductVariants .VariantTab .VariantTabBody .ProductVariantsTable .ProductVariantRowDimension input {
      text-align: center; }
  .ProductVariants .VariantTab .VariantTabBody .ProductVariantsTable .Quantity {
    width: 50px !important; }
  .ProductVariants .VariantTab .VariantTabBody .ProductVariantsTable tbody {
    border-bottom: 1px solid #dddddd; }
    .ProductVariants .VariantTab .VariantTabBody .ProductVariantsTable tbody td {
      word-break: break-all; }
      .ProductVariants .VariantTab .VariantTabBody .ProductVariantsTable tbody td.ProductVariantPrice .hasHistory {
        display: flex;
        align-items: center; }

.ProductVariants .WarningVariantsDuplicate {
  background: #ffc58b33;
  border: 1px solid #ffe2c8;
  padding: 0.8em 1.2em; }

.ProductVariants .MoveToDuplicateSKU {
  transition: none;
  font-size: 0.9em;
  background: #DDD;
  padding: 5px 8px;
  border-radius: 4px;
  font-weight: bold; }
  .ProductVariants .MoveToDuplicateSKU:first-child {
    margin-left: 10px; }
  .ProductVariants .MoveToDuplicateSKU:hover {
    background: #0f9d58;
    color: #FFF; }

.ProductVariants .ProductVariantsRow.highlight {
  background: #007bff0d; }

.ProductVariants .VariantsHighlight > p > span {
  display: inline-block;
  margin-right: 10px; }

.ProductVariants .VariantsHighlight.highlight span {
  color: #28a745; }

.ProductVariants .VariantsHighlight > p {
  display: inline-block;
  margin: 0; }

.ManageAutoMapContainer .ManageAutoMapPage .CustomSelect .ant-select {
  width: 100px;
  margin-right: 24px; }
  .ManageAutoMapContainer .ManageAutoMapPage .CustomSelect .ant-select .ant-select-selector {
    height: 40px;
    border-radius: 4px; }
    .ManageAutoMapContainer .ManageAutoMapPage .CustomSelect .ant-select .ant-select-selector .ant-select-selection-item {
      line-height: 40px; }

.ManageAutoMapContainer .ManageAutoMapPage .RuleTable table {
  width: 100%;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 15px; }
  .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleThead tr th {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #303238;
    padding: 16px;
    border-top: 1px solid #D8DBDE;
    background: #F4F6F8; }
  .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleThead .PackageChangeStatus {
    display: flex;
    align-items: baseline; }
    .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleThead .PackageChangeStatus .Actions {
      position: relative; }
      .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleThead .PackageChangeStatus .Actions i {
        position: relative;
        margin-left: 5px;
        z-index: 10; }
        .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleThead .PackageChangeStatus .Actions i:hover {
          cursor: pointer;
          color: #0f9d58; }
      .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleThead .PackageChangeStatus .Actions .Background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        opacity: 0;
        z-index: 9; }
      .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleThead .PackageChangeStatus .Actions .ListActions {
        display: none;
        position: absolute;
        width: 148px;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;
        margin: 0;
        padding: 0;
        z-index: 10; }
        .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleThead .PackageChangeStatus .Actions .ListActions li {
          color: #333333;
          list-style: none;
          padding: 10px 15px;
          font-size: 15px;
          font-weight: normal;
          border-bottom: 1px solid #dddddd; }
          .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleThead .PackageChangeStatus .Actions .ListActions li:last-child {
            border-bottom: 0; }
          .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleThead .PackageChangeStatus .Actions .ListActions li:hover {
            color: #0f9d58;
            cursor: pointer; }
        .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleThead .PackageChangeStatus .Actions .ListActions.IsOpen {
          display: block; }
        .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleThead .PackageChangeStatus .Actions .ListActions.Disable li {
          pointer-events: none;
          color: #adb5bd; }
  .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleBody tr td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #303238;
    padding: 16px;
    border-top: 1px solid #D8DBDE; }
    .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleBody tr td .MappingRuleDetail span {
      cursor: pointer;
      color: #007BFF; }
      .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .RuleBody tr td .MappingRuleDetail span:hover {
        text-decoration: underline; }
  .ManageAutoMapContainer .ManageAutoMapPage .RuleTable table .ProductTitle {
    width: 400px; }

.ManageAutoMapContainer .ManageAutoMapPage .noData {
  padding: 90px 0; }

.ManageAutoMapContainer .ManageAutoMapPage .Total {
  text-align: right;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #303238;
  margin-bottom: 19px; }
  .ManageAutoMapContainer .ManageAutoMapPage .Total span {
    text-align: right;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #303238;
    margin-bottom: 19px; }

.MappingRuleModal {
  width: 76% !important; }
  .MappingRuleModal .ContentDetail .OrderItemDesignV3 .OrderItemGroupArtworkFirst .ArtworkListFirst .ArtworkListNew li .ArtworkImages .ImageArt:hover .Hover {
    right: -190%; }
  .MappingRuleModal .table tbody tr td {
    vertical-align: top; }
  .MappingRuleModal .Information {
    margin-bottom: 24px; }
  .MappingRuleModal .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .MappingRuleModal .ant-modal-footer span.text-danger.cursor-pointer {
      background: #DF1F1F;
      border-radius: 4px;
      padding: 8px 32px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF !important; }

@media (max-width: 1536px) {
  .ManageAutoMapPage .CustomMultiSelect .InputSelect {
    min-width: 200px; }
  .ManageAutoMapPage .MultiFilterInput .MultiFilter .ant-select .ant-select-selector {
    min-width: 200px; }
  .ManageAutoMapPage .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
    width: 300px; } }

@media (max-width: 1440px) {
  .ManageAutoMapPage .CustomMultiSelect .InputSelect {
    min-width: 200px; }
  .ManageAutoMapPage .MultiFilterInput .MultiFilter .ant-select .ant-select-selector {
    min-width: 200px; }
  .ManageAutoMapPage .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
    width: 300px; }
  .ManageAutoMapPage .MultiFilterInput .MultiFilter .Right input {
    width: 300px; } }

@media (max-width: 1367px) {
  .ManageAutoMapPage .CustomMultiSelect .InputSelect {
    min-width: 200px; }
  .ManageAutoMapPage .MultiFilterInput .MultiFilter .ant-select .ant-select-selector {
    min-width: 200px; }
  .ManageAutoMapPage .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
    width: 300px; } }

.Variants {
  padding-top: 16px; }
  .Variants .VariantHeader {
    margin-top: 16px; }
  .Variants table tbody tr td:nth-child(4) {
    white-space: nowrap; }

.ShippingFeePage .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
  min-height: 38px;
  max-height: 112px;
  overflow: auto;
  border-radius: 4px;
  align-items: center; }
  .ShippingFeePage .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-input-affix-wrapper {
    min-height: 38px;
    border-radius: 4px; }

.ShippingFeePage .ant-select-selection-placeholder {
  color: #6c757d; }

@media (max-width: 1500px) {
  .ShippingFeePage .ShippingFeeTable {
    overflow-x: scroll;
    min-height: 300px; }
    .ShippingFeePage .ShippingFeeTable .table {
      width: auto;
      width: -moz-max-content;
      width: max-content; } }

.ShippingFeePage .ShippingFeeTable .ShippingsTableHead {
  background-color: #e9ecef; }

.ShippingFeePage .ShippingFeeTable .Supplier, .ShippingFeePage .ShippingFeeTable .Shipping_plan, .ShippingFeePage .ShippingFeeTable .Zone, .ShippingFeePage .ShippingFeeTable .Store, .ShippingFeePage .ShippingFeeTable .Additional_item {
  max-width: 150px;
  word-break: break-word; }

.ShippingFeePage .ShippingFeeTable .HiddenStore {
  max-height: 48px;
  overflow: hidden; }

.ShippingFeeNewPageContainer .TabNewShipping, .ShippingFeeEditPageContainer .TabNewShipping {
  padding: 30px 0; }
  .ShippingFeeNewPageContainer .TabNewShipping ul, .ShippingFeeEditPageContainer .TabNewShipping ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
    .ShippingFeeNewPageContainer .TabNewShipping ul .itemTab, .ShippingFeeEditPageContainer .TabNewShipping ul .itemTab {
      display: inline-block;
      line-height: 50px;
      padding: 0 45px;
      text-transform: uppercase;
      cursor: pointer;
      color: #fff;
      background-color: #bfbfbf;
      font-weight: 500; }
    .ShippingFeeNewPageContainer .TabNewShipping ul .active, .ShippingFeeEditPageContainer .TabNewShipping ul .active {
      background-color: #477bf6;
      color: #fff; }

.ShippingFeeNewPageContainer .ant-select:not(.ant-select-customize-input) .ant-select-selector, .ShippingFeeEditPageContainer .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px !important;
  min-height: 38px;
  max-height: 112px;
  overflow: auto;
  border-radius: 4px;
  align-items: center; }
  .ShippingFeeNewPageContainer .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-input-affix-wrapper, .ShippingFeeEditPageContainer .ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-input-affix-wrapper {
    min-height: 38px;
    border-radius: 4px; }

.ShippingFeeNewPageContainer .GeneralPage .General_store, .ShippingFeeEditPageContainer .GeneralPage .General_store {
  max-width: 350px; }
  .ShippingFeeNewPageContainer .GeneralPage .General_store .storeItem, .ShippingFeeEditPageContainer .GeneralPage .General_store .storeItem {
    background: #E9ECEF;
    border-radius: 4px;
    padding: 0px 9px; }

.ShippingFeeNewPageContainer .ProductlinePage .VariantTable table thead .itemAtt, .ShippingFeeEditPageContainer .ProductlinePage .VariantTable table thead .itemAtt {
  width: 180px;
  transition: all .2s ease; }

.ShippingFeeNewPageContainer .ProductlinePage .VariantTable table thead .ant-select-arrow, .ShippingFeeEditPageContainer .ProductlinePage .VariantTable table thead .ant-select-arrow {
  right: 107px !important; }

.ShippingFeeNewPageContainer .ProductlinePage .VariantTable table thead .ant-select-selector, .ShippingFeeEditPageContainer .ProductlinePage .VariantTable table thead .ant-select-selector {
  width: 80px; }

.ShippingFeeNewPageContainer .ProductlinePage .VariantTable table thead .AdditionalItem, .ShippingFeeEditPageContainer .ProductlinePage .VariantTable table thead .AdditionalItem {
  width: 130px; }

.ShippingFeeNewPageContainer .ShippingFeeNewPages .ProductlinePage .ProductPrice .form-control,
.ShippingFeeNewPageContainer .ShippingFeeEditPages .ProductlinePage .ProductPrice .form-control, .ShippingFeeEditPageContainer .ShippingFeeNewPages .ProductlinePage .ProductPrice .form-control,
.ShippingFeeEditPageContainer .ShippingFeeEditPages .ProductlinePage .ProductPrice .form-control {
  max-width: 100px;
  margin-left: 20px; }

.ShippingFeeNewPageContainer .ShippingFeeNewPages .ProductlinePage .ProductVariant .History:hover,
.ShippingFeeNewPageContainer .ShippingFeeEditPages .ProductlinePage .ProductVariant .History:hover, .ShippingFeeEditPageContainer .ShippingFeeNewPages .ProductlinePage .ProductVariant .History:hover,
.ShippingFeeEditPageContainer .ShippingFeeEditPages .ProductlinePage .ProductVariant .History:hover {
  color: #0f9d58; }

.ShippingFeeNewPageContainer .ShippingFeeNewPages .ProductlinePage .VariantTable table tbody td .form-control,
.ShippingFeeNewPageContainer .ShippingFeeEditPages .ProductlinePage .VariantTable table tbody td .form-control, .ShippingFeeEditPageContainer .ShippingFeeNewPages .ProductlinePage .VariantTable table tbody td .form-control,
.ShippingFeeEditPageContainer .ShippingFeeEditPages .ProductlinePage .VariantTable table tbody td .form-control {
  max-width: 100px;
  margin-right: 30px; }

.shippingFeeHistoryModal .ant-modal-body {
  max-height: 431px;
  overflow: auto; }
  .shippingFeeHistoryModal .ant-modal-body ul li {
    margin-bottom: 20px; }

.ShippingZonesPageContainer .ShippingZonesHeader h1.PageTitle {
  margin: 12px 0 0 0;
  font-size: 20px;
  width: 100%; }

.ShippingZonesPageContainer .ShippingZonesHeader .BtnAddZone {
  margin: 27px 0 10px 0;
  background: #477BF6;
  width: 123px;
  height: 40px;
  border-radius: 4px; }

.ShippingZonesPageContainer .ShippingZonesPage .SectionInner .filterSelect {
  width: 200px;
  margin: 0 0 0 22px; }

.ShippingZonesPageContainer .ShippingZonesPage .tableWrapResponsive table tbody tr td.ShippingZoneSupplier {
  width: 13%; }

.ShippingZonesPageContainer .ShippingZonesPage .tableWrapResponsive table tbody tr td.ShippingZoneTitle {
  width: 9%; }

.ShippingZonesPageContainer .ShippingZonesPage .tableWrapResponsive table tbody tr td.ShippingZoneCountries {
  width: 44%; }

.ShippingZonesPageContainer .ShippingZonesPage .tableWrapResponsive table tbody tr td.ShippingZoneCountries span {
  word-break: break-all; }

.ShippingZonesPageContainer .ShippingZonesPage .tableWrapResponsive table tbody tr td.action a.edit {
  color: #477BF6; }

.ShippingZonesPageContainer .ShippingZonesPage .tableWrapResponsive table tbody tr td.action a.delete {
  color: #df1f1f; }

.HiddenCountry {
  max-height: 90px;
  overflow: hidden; }

.ShippingZoneCreateForm .Header .BtnCancel {
  width: 115px;
  background: #BFBFBF;
  height: 40px;
  border-radius: 4px;
  border-color: #BFBFBF; }

.ShippingZoneCreateForm .Header .BtnSave {
  margin: 0 0 0 15px;
  background: #4A80F7;
  width: 115px;
  height: 40px;
  border-radius: 4px; }

.ShippingZoneCreateForm .FormCreate {
  margin: 51px 0 0 70px;
  display: flex;
  width: 35%;
  flex-direction: column; }
  .ShippingZoneCreateForm .FormCreate .formFilter {
    margin-bottom: 15px; }

.MappingConditionForm .MappingConditionFormInner .tab-content,
.MappingConditionForm .MappingConditionFormInner .tab-history {
  display: none; }

.MappingConditionForm .MappingConditionFormInner[data-tab="content"] .tab-content {
  display: block; }

.MappingConditionForm .MappingConditionFormInner[data-tab="history"] .tab-history {
  display: block; }

.MappingConditionForm .ant-modal-footer > button,
.MappingConditionForm .ant-modal-footer > span,
.MappingConditionForm .ant-modal-footer > a {
  margin-left: 8px; }
  .MappingConditionForm .ant-modal-footer > button.disabled,
  .MappingConditionForm .ant-modal-footer > span.disabled,
  .MappingConditionForm .ant-modal-footer > a.disabled {
    color: #999;
    pointer-events: none; }
  .MappingConditionForm .ant-modal-footer > button.cancel,
  .MappingConditionForm .ant-modal-footer > span.cancel,
  .MappingConditionForm .ant-modal-footer > a.cancel {
    float: left; }

.MappingConditionForm .ant-modal-footer > span {
  color: #ccc; }

.MappingConditionForm.Edit .ant-modal-header {
  padding-bottom: 50px; }

.MappingConditionForm.Edit .tabs {
  position: absolute;
  top: 53px;
  font-size: 14px; }
  .MappingConditionForm.Edit .tabs > a {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 14px;
    outline: none;
    box-shadow: none;
    color: #999;
    transition: none;
    -webkit-transition: none;
    -moz-transition: none; }
    .MappingConditionForm.Edit .tabs > a.active {
      border: 1px solid #f0f0f0;
      border-bottom-color: #fff;
      color: #007bff; }

.MappingConditionForm.Edit .tab-history ul li {
  margin-bottom: 20px; }

.MappingConditionForm.Edit .tab-history .history-actions {
  text-align: right; }
  .MappingConditionForm.Edit .tab-history .history-actions > * {
    margin-left: 8px; }

.MappingConditionForm.Edit .tab-history .loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.45); }
  .MappingConditionForm.Edit .tab-history .loading .ant-spin {
    left: 50%;
    top: 50%;
    position: absolute;
    margin-left: -16px;
    margin-top: -16px; }

.MappingConditionForm.Edit .move-item {
  position: fixed;
  width: 50px;
  height: 40px;
  opacity: 0.2;
  top: 150px;
  z-index: 100;
  left: 50%; }
  .MappingConditionForm.Edit .move-item:hover {
    opacity: 1; }
  .MappingConditionForm.Edit .move-item.move-left {
    margin-left: -435px; }
  .MappingConditionForm.Edit .move-item.move-right {
    margin-left: 370px; }

.MappingConditionForm .ConfirmCancel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.45); }
  .MappingConditionForm .ConfirmCancel > div {
    background: #fff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    margin: 50px auto;
    max-width: 80%;
    padding: 20px; }
    .MappingConditionForm .ConfirmCancel > div .actions {
      text-align: right; }
      .MappingConditionForm .ConfirmCancel > div .actions button {
        margin-left: 8px; }

.MappingConditionPageContainer .MappingConditionTable table {
  table-layout: fixed; }
  .MappingConditionPageContainer .MappingConditionTable table td,
  .MappingConditionPageContainer .MappingConditionTable table th {
    overflow-wrap: break-word; }
    .MappingConditionPageContainer .MappingConditionTable table td.RowIndex, .MappingConditionPageContainer .MappingConditionTable table td.Action,
    .MappingConditionPageContainer .MappingConditionTable table th.RowIndex,
    .MappingConditionPageContainer .MappingConditionTable table th.Action {
      width: 100px; }
    .MappingConditionPageContainer .MappingConditionTable table td.Store,
    .MappingConditionPageContainer .MappingConditionTable table th.Store {
      width: 150px; }
    .MappingConditionPageContainer .MappingConditionTable table td.UpdatedAt, .MappingConditionPageContainer .MappingConditionTable table td.User,
    .MappingConditionPageContainer .MappingConditionTable table th.UpdatedAt,
    .MappingConditionPageContainer .MappingConditionTable table th.User {
      width: 200px; }

.MappingConditionPageContainer .MappingConditionTable .BulkActions {
  display: flex;
  align-items: baseline; }
  .MappingConditionPageContainer .MappingConditionTable .BulkActions .Actions i {
    position: relative;
    margin-left: 5px;
    z-index: 10; }
    .MappingConditionPageContainer .MappingConditionTable .BulkActions .Actions i:hover {
      cursor: pointer;
      color: #0f9d58; }
  .MappingConditionPageContainer .MappingConditionTable .BulkActions .Actions .ListActions {
    display: none;
    position: absolute;
    width: 250px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    margin: 0;
    padding: 0;
    z-index: 10; }
    .MappingConditionPageContainer .MappingConditionTable .BulkActions .Actions .ListActions li {
      color: #333;
      list-style: none;
      padding: 10px 15px;
      font-size: 15px;
      font-weight: 400; }
      .MappingConditionPageContainer .MappingConditionTable .BulkActions .Actions .ListActions li:hover {
        cursor: pointer;
        color: #0f9d58; }
  .MappingConditionPageContainer .MappingConditionTable .BulkActions .Actions .Disable li {
    pointer-events: none;
    color: #adb5bd; }
  .MappingConditionPageContainer .MappingConditionTable .BulkActions .Actions .Background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    z-index: 9; }

.MappingConditionPageContainer .TableFooter {
  display: flex; }

.MappingConditionPageContainer .page-counter {
  margin: 0 8px 0 auto; }
  .MappingConditionPageContainer .page-counter span {
    font-weight: bold; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table thead tr th {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #303238;
  padding: 16px;
  border-top: 1px solid #d8dbde;
  background: #f4f6f8;
  border-bottom: none;
  white-space: nowrap; }
  .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table thead tr th:not(.CheckBox) {
    padding: 16px 10px; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #303238;
  padding: 16px;
  border-top: 1px solid #d8dbde; }
  .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr td:not(.CheckBox) {
    padding: 16px 10px; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .OrderNote {
  max-width: 240px; }
  .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .OrderNote .ShowMore {
    cursor: pointer; }
    .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .OrderNote .ShowMore:hover {
      color: #0f9d58; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .CheckBox {
  min-width: 50px; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .RequestId {
  min-width: 240px; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .Order {
  min-width: 150px; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .Reason {
  word-break: break-word;
  max-width: 390px; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .Type label {
  display: inline-block;
  height: 18px;
  line-height: 17px;
  position: relative;
  padding: 0 7px 0 12px;
  background: #dc3545;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  color: #fff;
  font-size: 11px;
  text-decoration: none;
  font-weight: 400;
  margin: 0 0 0 7px;
  white-space: nowrap; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .Type label.refund {
  background: #28a745; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .Type label::before {
  content: "";
  position: absolute;
  top: 0;
  left: -7px;
  width: 0;
  height: 0;
  border-color: transparent #dc3545 transparent transparent;
  border-style: solid;
  border-width: 9px 7px 9px 0; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .Type label.refund::before {
  border-color: transparent #28a745 transparent transparent; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .Type label::after {
  content: "";
  position: absolute;
  top: 7px;
  left: 1px;
  float: left;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #fff; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr:last-child {
  border-bottom: 1px solid #d8dbde; }

.ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table .BulkActions {
  display: flex;
  align-items: baseline; }
  .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table .BulkActions .Actions i {
    position: relative;
    margin-left: 5px;
    z-index: 10; }
    .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table .BulkActions .Actions i:hover {
      cursor: pointer;
      color: #0f9d58; }
  .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table .BulkActions .Actions .ListActions {
    display: none;
    position: absolute;
    width: 250px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    margin: 0;
    padding: 0;
    z-index: 10; }
    .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table .BulkActions .Actions .ListActions li {
      color: #333;
      list-style: none;
      padding: 10px 15px;
      font-size: 15px;
      font-weight: 400; }
      .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table .BulkActions .Actions .ListActions li:hover {
        cursor: pointer;
        color: #0f9d58; }
  .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table .BulkActions .Actions .Disable li {
    pointer-events: none;
    color: #adb5bd; }
  .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table .BulkActions .Actions .Background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    z-index: 9; }

@media only screen and (max-width: 1733px) {
  .ChargeRefundPageContainer .ChargeRefundTable .wrapTable {
    overflow: auto; }
    .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr td {
      padding: 6px; }
    .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .RequestId {
      min-width: 252px; }
    .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .Order {
      min-width: 160px; }
    .ChargeRefundPageContainer .ChargeRefundTable .wrapTable .table tbody tr .User {
      word-break: inherit; } }

.ChargeRefundPageContainer .ChargeRefundPage .PageHeading .RightTop {
  display: flex;
  align-items: center; }
  .ChargeRefundPageContainer .ChargeRefundPage .PageHeading .RightTop .searchOrder {
    border: 0;
    height: 38px;
    padding: 9px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    border-radius: 5px;
    width: 300px;
    border: 1px solid #d8dbde; }
  .ChargeRefundPageContainer .ChargeRefundPage .PageHeading .RightTop .MoreFilter {
    border: 1px solid #eee;
    padding: 8px 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #303238;
    margin-left: 5px;
    border-radius: 5px;
    min-width: 127px;
    cursor: pointer; }
  .ChargeRefundPageContainer .ChargeRefundPage .PageHeading .RightTop .MoreFilter:hover {
    background: #28a745;
    color: #ffffff; }

.ChargeRefundPageContainer .ChargeRefundPage .SectionInner .Filter .CustomSelect .ant-select .ant-select-selector {
  height: 40px;
  border-radius: 5px;
  align-items: center; }

.ChargeRefundPageContainer .ChargeRefundPage .SectionInner .Filter .CustomSelect .ant-picker.ant-picker-range {
  height: 40px;
  border-radius: 5px;
  align-items: center; }

.ChargeRefundPageContainer .ChargeRefundPage .SectionInner .Filter .CustomSelectSearch .ant-select .ant-select-selector {
  height: 40px;
  border-radius: 0 5px 5px 0;
  align-items: center;
  justify-content: center; }
  .ChargeRefundPageContainer .ChargeRefundPage .SectionInner .Filter .CustomSelectSearch .ant-select .ant-select-selector input {
    height: 100%; }

.ChargeRefundPageContainer .ChargeRefundPage .SectionInner .Total span {
  text-align: right;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #303238;
  margin-bottom: 19px; }

.ReasonModal .ant-modal-content .ant-modal-body {
  padding: 12px !important; }

.CostControlPageContainer .CostControlTable .wrapTable .table thead tr th, .CostControlPageContainer .FileDetailTable .wrapTable .table thead tr th, .FileDetailPageContainer .CostControlTable .wrapTable .table thead tr th, .FileDetailPageContainer .FileDetailTable .wrapTable .table thead tr th {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #303238;
  padding: 16px;
  border-top: 1px solid #d8dbde;
  background: #f4f6f8;
  border-bottom: none; }

.CostControlPageContainer .CostControlTable .wrapTable .table thead tr th.Supplier, .CostControlPageContainer .FileDetailTable .wrapTable .table thead tr th.Supplier, .FileDetailPageContainer .CostControlTable .wrapTable .table thead tr th.Supplier, .FileDetailPageContainer .FileDetailTable .wrapTable .table thead tr th.Supplier {
  width: unset; }

.CostControlPageContainer .CostControlTable .wrapTable .table tbody tr td, .CostControlPageContainer .FileDetailTable .wrapTable .table tbody tr td, .FileDetailPageContainer .CostControlTable .wrapTable .table tbody tr td, .FileDetailPageContainer .FileDetailTable .wrapTable .table tbody tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #303238;
  padding: 16px;
  border-top: 1px solid #d8dbde; }

.CostControlPageContainer .CostControlTable .wrapTable .table tbody tr .Approval_status a:not([href]), .CostControlPageContainer .FileDetailTable .wrapTable .table tbody tr .Approval_status a:not([href]), .FileDetailPageContainer .CostControlTable .wrapTable .table tbody tr .Approval_status a:not([href]), .FileDetailPageContainer .FileDetailTable .wrapTable .table tbody tr .Approval_status a:not([href]) {
  text-decoration: underline; }

.CostControlPageContainer .CostControlTable .wrapTable .table tbody tr .Supplier, .CostControlPageContainer .FileDetailTable .wrapTable .table tbody tr .Supplier, .FileDetailPageContainer .CostControlTable .wrapTable .table tbody tr .Supplier, .FileDetailPageContainer .FileDetailTable .wrapTable .table tbody tr .Supplier {
  width: unset; }

.CostControlPageContainer .CostControlTable .wrapTable .table tbody tr .Message, .CostControlPageContainer .FileDetailTable .wrapTable .table tbody tr .Message, .FileDetailPageContainer .CostControlTable .wrapTable .table tbody tr .Message, .FileDetailPageContainer .FileDetailTable .wrapTable .table tbody tr .Message {
  word-break: break-word;
  max-width: 300px;
  min-width: 208px; }

.CostControlPageContainer .CostControlTable .wrapTable .table tbody tr .Package_name, .CostControlPageContainer .FileDetailTable .wrapTable .table tbody tr .Package_name, .FileDetailPageContainer .CostControlTable .wrapTable .table tbody tr .Package_name, .FileDetailPageContainer .FileDetailTable .wrapTable .table tbody tr .Package_name {
  min-width: 160px; }

.CostControlPageContainer .CostControlTable .wrapTable .table tbody tr .Order_supplier_id, .CostControlPageContainer .FileDetailTable .wrapTable .table tbody tr .Order_supplier_id, .FileDetailPageContainer .CostControlTable .wrapTable .table tbody tr .Order_supplier_id, .FileDetailPageContainer .FileDetailTable .wrapTable .table tbody tr .Order_supplier_id {
  min-width: 143px; }

.CostControlPageContainer .CostControlTable .wrapTable .table tbody tr .Shipping_fee, .CostControlPageContainer .CostControlTable .wrapTable .table tbody tr .Production_fee, .CostControlPageContainer .FileDetailTable .wrapTable .table tbody tr .Shipping_fee, .CostControlPageContainer .FileDetailTable .wrapTable .table tbody tr .Production_fee, .FileDetailPageContainer .CostControlTable .wrapTable .table tbody tr .Shipping_fee, .FileDetailPageContainer .CostControlTable .wrapTable .table tbody tr .Production_fee, .FileDetailPageContainer .FileDetailTable .wrapTable .table tbody tr .Shipping_fee, .FileDetailPageContainer .FileDetailTable .wrapTable .table tbody tr .Production_fee {
  min-width: 130px; }

.CostControlPageContainer .CostControlTable .wrapTable .table tbody tr .File_name a, .CostControlPageContainer .FileDetailTable .wrapTable .table tbody tr .File_name a, .FileDetailPageContainer .CostControlTable .wrapTable .table tbody tr .File_name a, .FileDetailPageContainer .FileDetailTable .wrapTable .table tbody tr .File_name a {
  float: left; }

.CostControlPageContainer .CostControlTable .wrapTable .table tbody tr .File_name small, .CostControlPageContainer .FileDetailTable .wrapTable .table tbody tr .File_name small, .FileDetailPageContainer .CostControlTable .wrapTable .table tbody tr .File_name small, .FileDetailPageContainer .FileDetailTable .wrapTable .table tbody tr .File_name small {
  display: block; }

.CostControlPageContainer .CostControlTable .wrapTable .table tbody tr:last-child, .CostControlPageContainer .FileDetailTable .wrapTable .table tbody tr:last-child, .FileDetailPageContainer .CostControlTable .wrapTable .table tbody tr:last-child, .FileDetailPageContainer .FileDetailTable .wrapTable .table tbody tr:last-child {
  border-bottom: 1px solid #d8dbde; }

@media only screen and (max-width: 1733px) {
  .CostControlPageContainer .CostControlTable .wrapTable, .CostControlPageContainer .FileDetailTable .wrapTable, .FileDetailPageContainer .CostControlTable .wrapTable, .FileDetailPageContainer .FileDetailTable .wrapTable {
    overflow: auto; } }

.CostControlPageContainer .CostControlPage .PageHeading .RightTop, .CostControlPageContainer .FileDetailPage .PageHeading .RightTop, .FileDetailPageContainer .CostControlPage .PageHeading .RightTop, .FileDetailPageContainer .FileDetailPage .PageHeading .RightTop {
  display: flex;
  align-items: center; }

.CostControlPageContainer .CostControlPage .SectionInner .Filter .FilterWrapper .searchFileName, .CostControlPageContainer .CostControlPage .SectionInner .Filter .FilterWrapper .search, .CostControlPageContainer .FileDetailPage .SectionInner .Filter .FilterWrapper .searchFileName, .CostControlPageContainer .FileDetailPage .SectionInner .Filter .FilterWrapper .search, .FileDetailPageContainer .CostControlPage .SectionInner .Filter .FilterWrapper .searchFileName, .FileDetailPageContainer .CostControlPage .SectionInner .Filter .FilterWrapper .search, .FileDetailPageContainer .FileDetailPage .SectionInner .Filter .FilterWrapper .searchFileName, .FileDetailPageContainer .FileDetailPage .SectionInner .Filter .FilterWrapper .search {
  height: 40px;
  border-radius: 4px;
  align-items: center; }

.CostControlPageContainer .CostControlPage .SectionInner .Filter .CustomSelect .ant-select .ant-select-selector, .CostControlPageContainer .FileDetailPage .SectionInner .Filter .CustomSelect .ant-select .ant-select-selector, .FileDetailPageContainer .CostControlPage .SectionInner .Filter .CustomSelect .ant-select .ant-select-selector, .FileDetailPageContainer .FileDetailPage .SectionInner .Filter .CustomSelect .ant-select .ant-select-selector {
  height: 40px;
  border-radius: 4px;
  align-items: center; }

.CostControlPageContainer .CostControlPage .SectionInner .Filter .CustomSelect .ant-picker.ant-picker-range, .CostControlPageContainer .FileDetailPage .SectionInner .Filter .CustomSelect .ant-picker.ant-picker-range, .FileDetailPageContainer .CostControlPage .SectionInner .Filter .CustomSelect .ant-picker.ant-picker-range, .FileDetailPageContainer .FileDetailPage .SectionInner .Filter .CustomSelect .ant-picker.ant-picker-range {
  height: 40px;
  border-radius: 4px;
  align-items: center; }

.CostControlPageContainer .CostControlPage .SectionInner .Total span, .CostControlPageContainer .FileDetailPage .SectionInner .Total span, .FileDetailPageContainer .CostControlPage .SectionInner .Total span, .FileDetailPageContainer .FileDetailPage .SectionInner .Total span {
  text-align: right;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #303238;
  margin-bottom: 19px; }

.UploadModal .ant-btn, .ExportFileDetails .ant-btn {
  border-radius: 4px; }

.UploadModal .ant-btn.ant-btn-primary, .ExportFileDetails .ant-btn.ant-btn-primary {
  background: #477BF6; }

.UploadModal .ant-btn.ant-btn-primary[disabled], .ExportFileDetails .ant-btn.ant-btn-primary[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none; }

.UploadModal .ant-select .ant-select-selector, .UploadModal .file_name, .UploadModal .FileName, .ExportFileDetails .ant-select .ant-select-selector, .ExportFileDetails .file_name, .ExportFileDetails .FileName {
  border-radius: 4px; }

.OrderProfitPageContainer .OrderProfitTable .wrapTable .table thead tr th {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #303238;
  padding: 16px;
  border-top: 1px solid #d8dbde;
  background: #f4f6f8;
  border-bottom: none; }

.OrderProfitPageContainer .OrderProfitTable .wrapTable .table thead tr th.Supplier {
  width: unset; }

.OrderProfitPageContainer .OrderProfitTable .wrapTable .table tbody tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #303238;
  padding: 16px;
  border-top: 1px solid #d8dbde; }

.OrderProfitPageContainer .OrderProfitTable .wrapTable .table tbody tr .profit a:not([href]) {
  color: #007bff; }

.OrderProfitPageContainer .OrderProfitTable .wrapTable .table tbody tr .profit a:hover {
  color: #0062cc; }

.OrderProfitPageContainer .OrderProfitTable .wrapTable .table tbody tr .Supplier {
  width: unset; }

.OrderProfitPageContainer .OrderProfitTable .wrapTable .table tbody tr:last-child {
  border-bottom: 1px solid #d8dbde; }

@media only screen and (max-width: 1733px) {
  .OrderProfitPageContainer .OrderProfitTable .wrapTable {
    overflow: auto; } }

.OrderProfitPageContainer .OrderProfitPage .PageHeading .RightTop {
  display: flex;
  align-items: center; }

.OrderProfitPageContainer .OrderProfitPage .SectionInner .Filter .FilterWrapper .searchFileName, .OrderProfitPageContainer .OrderProfitPage .SectionInner .Filter .FilterWrapper .search {
  height: 40px;
  border-radius: 4px;
  align-items: center; }
  .OrderProfitPageContainer .OrderProfitPage .SectionInner .Filter .FilterWrapper .searchFileName.site-input-left, .OrderProfitPageContainer .OrderProfitPage .SectionInner .Filter .FilterWrapper .search.site-input-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .OrderProfitPageContainer .OrderProfitPage .SectionInner .Filter .FilterWrapper .searchFileName.site-input-split, .OrderProfitPageContainer .OrderProfitPage .SectionInner .Filter .FilterWrapper .search.site-input-split {
    border-radius: 0; }
  .OrderProfitPageContainer .OrderProfitPage .SectionInner .Filter .FilterWrapper .searchFileName.site-input-right, .OrderProfitPageContainer .OrderProfitPage .SectionInner .Filter .FilterWrapper .search.site-input-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.OrderProfitPageContainer .OrderProfitPage .SectionInner .Filter .CustomSelect .ant-select .ant-select-selector {
  height: 40px;
  border-radius: 4px;
  align-items: center; }

.OrderProfitPageContainer .OrderProfitPage .SectionInner .Filter .CustomSelect .ant-picker.ant-picker-range {
  height: 40px;
  border-radius: 4px;
  align-items: center; }

.OrderProfitPageContainer .OrderProfitPage .SectionInner .Total span {
  text-align: right;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #303238;
  margin-bottom: 19px; }

.ExportOrderProfitModal .ant-btn {
  border-radius: 4px; }

.ExportOrderProfitModal .ant-btn.ant-btn-primary {
  background: #477BF6; }

.ExportOrderProfitModal .FileName {
  border-radius: 4px; }

.PrintFilesTable .table {
  overflow: hidden; }
  @media (max-width: 1200px) {
    .PrintFilesTable .table {
      width: max-content; } }
  .PrintFilesTable .table td {
    vertical-align: top; }
  .PrintFilesTable .table tr {
    border-top: 1px solid #dee2e6; }
  .PrintFilesTable .table th {
    vertical-align: middle; }

.PrintFilesTable th,
.PrintFilesTable td {
  padding-left: 5px;
  padding-right: 5px; }

.PrintFilesTable th.Template {
  width: 160px; }

.PrintFilesTable th.Design {
  width: 225px; }

.PrintFilesTable th.Type {
  width: 120px;
  white-space: nowrap; }

.PrintFilesTable th.Supplier {
  width: 80px; }

.PrintFilesTable th.Image {
  width: 150px; }

.PrintFilesTable th.Quantity,
.PrintFilesTable th.Status {
  width: 90px; }

.PrintFilesTable th.Size,
.PrintFilesTable th.Color {
  width: 50px; }

.PrintFilesTable th.Time {
  width: 180px; }

.PrintFilesTable th.Note,
.PrintFilesTable th.Error {
  width: 140px; }

.PrintFilesTable th.Actions {
  width: 115px; }

.PrintFilesTable td.ItemImage {
  display: grid;
  grid-template-columns: 1fr 1fr; }

.PrintFilesTable td.ItemImage .Preview {
  width: 160px;
  height: 160px;
  overflow: hidden; }

.PrintFilesTable td.ItemImage .Preview img {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

.PrintFilesTable td.ItemImage .Preview .Actions {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100; }

.PrintFilesTable td.ItemImage .Preview:hover .Actions {
  opacity: 1;
  visibility: visible; }

.PrintFilesTable td.ItemImage .Icon {
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 25px;
  height: 25px;
  background-color: #007bff;
  border-radius: 50%; }

.PrintFilesTable td.ItemType .ItemBrand span {
  background: #ff4d4f;
  color: #ffffff;
  border-radius: 3px;
  border: unset;
  padding: 3px 7px; }

.PrintFilesTable td.ItemType .ItemSupplier span {
  background: #9f9d9d;
  color: #fff;
  border-radius: 3px;
  border: unset;
  padding: 3px 7px; }

.PrintFilesTable .DesignFront {
  overflow: hidden; }

.PrintFilesTable .ItemSide .Icon {
  min-width: 25px;
  min-height: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer; }

.PrintFilesTable .OrdersAllSelection {
  z-index: 2;
  width: 50px;
  vertical-align: middle; }

.PrintFilesTable .OrdersAllSelection .MenuActions {
  top: 0;
  left: 45px;
  bottom: 0;
  width: calc(100vw - 120px);
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  padding-left: 20px; }

.PrintFilesTable tr.Printed td:not(:last-child) {
  color: #7c8695;
  opacity: .75; }

.PrintFilesTable .Marketplace .orderTemplate {
  font-size: 13px;
  font-weight: 700; }
  .PrintFilesTable .Marketplace .orderTemplate:hover {
    cursor: pointer; }

.PrintFilesTable .Marketplace .orderNumber {
  color: #666;
  font-size: 12px;
  font-weight: 500; }

.PrintFilesTable .Marketplace .Namespace {
  font-size: .75rem;
  color: #666; }

.PrintFilesTable .Marketplace .ant-tag {
  line-height: 15px;
  padding: 0 5px; }

.PrintFilesTable .Marketplace .BatchInfo {
  margin-top: 5px; }
  .PrintFilesTable .Marketplace .BatchInfo span {
    background: #28a745;
    padding: 0 5px;
    border-radius: 5px;
    margin-top: 18px;
    color: #fff;
    border: 1px solid #28a745; }
    .PrintFilesTable .Marketplace .BatchInfo span a {
      color: #fff; }
  .PrintFilesTable .Marketplace .BatchInfo:hover span {
    background: #fff; }
    .PrintFilesTable .Marketplace .BatchInfo:hover span a {
      color: #28a745; }

.PrintFilesTable .ItemSideImage .Preview {
  width: 160px;
  height: 160px;
  overflow: hidden; }

.PrintFilesTable .ItemSideImage img {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto; }

.PrintFilesTable .ItemSideImage .Side {
  z-index: 1;
  left: 4px; }

.PrintFilesTable .ItemSideImage.hasPreview .Actions {
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4); }

.PrintFilesTable .ItemSideImage .Actions {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.PrintFilesTable .ItemSideImage.hasPreview .Icon {
  background-color: #007bff;
  border-radius: 50%;
  margin: 4px;
  cursor: pointer; }

.PrintFilesTable .Actions .ant-typography .ant-typography-copy {
  margin-left: 0;
  margin-top: 2px; }
  .PrintFilesTable .Actions .ant-typography .ant-typography-copy .anticon-copy {
    color: #ffffff; }

.PrintFilesTable .ItemSideImage.hasPreview:hover .Actions {
  opacity: 1;
  visibility: visible; }

.PrintFilesTable .DesignFront {
  overflow: hidden; }
  .PrintFilesTable .DesignFront .ItemSideDefault {
    display: flex;
    justify-content: space-between; }

.PrintFilesTable .ItemImage {
  position: relative; }
  .PrintFilesTable .ItemImage .Preview .Safezone {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    z-index: 10; }

.PrintFilesTable .OrderActions .Icon {
  width: 30px;
  height: 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  cursor: pointer;
  margin: 0 3px; }

.PrintFilesTable .MenuActions {
  top: 0;
  left: 30px;
  bottom: 0;
  width: 98%;
  background-color: #e9ecef;
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 96px; }

.PrintFilesTable .autoApprove {
  background-color: #47beff;
  color: #fff;
  font-weight: 500; }

.PrintedFileModal {
  width: auto !important; }
  .PrintedFileModal .Previewer {
    position: relative;
    display: inline-block; }
    .PrintedFileModal .Previewer .Safezone {
      position: absolute;
      top: 0;
      width: 100%;
      left: 50%;
      margin: auto;
      z-index: 10;
      transform: translate(-50%, 0); }
    .PrintedFileModal .Previewer .box {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
      cursor: grab; }
  .PrintedFileModal .ant-modal-body {
    padding: 24px 40px;
    margin: 0 25px; }
  .PrintedFileModal .OrderActions .Actions {
    text-align: center; }
  .PrintedFileModal .OrderActions .Icon {
    font-size: 70px;
    display: inline-block;
    cursor: pointer;
    margin: 0 20px; }
  .PrintedFileModal .OrderActions .ChangeOnHoldState {
    color: #007bff; }
  .PrintedFileModal .OrderActions .MarkAsDone {
    color: #dc3545; }
  .PrintedFileModal .OrderActions .ToggleSafeZone {
    z-index: 999; }
    .PrintedFileModal .OrderActions .ToggleSafeZone i {
      color: #0f9d58; }
  .PrintedFileModal .OrderActions .GenerateTemplate,
  .PrintedFileModal .OrderActions .ChangePrintState,
  .PrintedFileModal .OrderActions .MoreActions {
    display: none; }
  @media (max-width: 1700px) {
    .PrintedFileModal .MockupViewer {
      top: 0px; } }

.ant-popover-inner-content .Preview {
  height: 200px;
  width: 250px;
  overflow: hidden;
  position: relative; }
  .ant-popover-inner-content .Preview img {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 30%; }

.previewFixed {
  position: fixed !important;
  top: 0 !important;
  width: auto !important; }
  .previewFixed .ant-popover-inner-content {
    max-width: none !important;
    max-height: none; }
    .previewFixed .ant-popover-inner-content .PriviewFileIn {
      height: calc(100vh - 50px) !important;
      min-width: 40vw !important; }
  @media (max-width: 1775px) {
    .previewFixed .MockupViewer img {
      width: 200px; } }
  @media (max-width: 1560px) {
    .previewFixed .MockupViewer img {
      width: 160px; } }
  @media (max-width: 1378px) {
    .previewFixed .MockupViewer img {
      width: 120px; } }

.ActionBackgroundPrintFile {
  margin-top: 10px; }
  .ActionBackgroundPrintFile button {
    border: none;
    outline: none; }
  .ActionBackgroundPrintFile button.Active {
    background: #28a745;
    color: #ffffff; }

.popoverArtwork {
  position: fixed !important;
  top: 0 !important; }
  .popoverArtwork .ant-popover-arrow {
    display: none; }
  .popoverArtwork .ant-popover-inner {
    min-width: 40vw !important; }
  .popoverArtwork .ant-popover-inner-content {
    max-height: none;
    overflow: unset !important;
    max-width: unset !important; }
    .popoverArtwork .ant-popover-inner-content .previewArtwork {
      width: 100%; }
      .popoverArtwork .ant-popover-inner-content .previewArtwork img {
        width: 100%;
        height: auto; }

.total-package-push {
  font-size: 20px;
  color: red;
  font-weight: 600;
  cursor: pointer; }
  .total-package-push:hover {
    text-decoration: underline; }

.print-files-warning-item {
  display: block;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  background-color: #fafafa;
  border-bottom: 1px solid #d9d9d9; }
  .print-files-warning-item:hover {
    text-decoration: none; }

.table-design-customized-artworks table {
  width: 100%; }
  .table-design-customized-artworks table thead {
    background: #e9ecef; }
    .table-design-customized-artworks table thead th {
      padding: 5px 15px; }
  .table-design-customized-artworks table tbody .searchDroplist.show {
    display: block; }
  .table-design-customized-artworks table tbody .searchDroplist.hide {
    display: none; }

.url-design-customized-artworks {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px; }

.table-design-customized-artworks table td {
  padding: 5px 15px; }

.table-design-customized-artworks .searchDroplist {
  position: absolute;
  top: 95%;
  left: 15px;
  z-index: 9;
  width: calc(100% + 9px); }
  @media (max-width: 992px) {
    .table-design-customized-artworks .searchDroplist {
      top: 100%; } }
  .table-design-customized-artworks .searchDroplist.hide {
    display: none; }
  .table-design-customized-artworks .searchDroplist.show {
    display: block; }
  .table-design-customized-artworks .searchDroplist table {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: #fff; }
    .table-design-customized-artworks .searchDroplist table thead tr {
      display: table;
      width: 100%;
      table-layout: fixed; }
    .table-design-customized-artworks .searchDroplist table tbody {
      max-height: 300px;
      overflow: auto;
      display: block; }
      .table-design-customized-artworks .searchDroplist table tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed; }
        .table-design-customized-artworks .searchDroplist table tbody tr:hover {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.03); }
        .table-design-customized-artworks .searchDroplist table tbody tr td {
          padding: 5px 15px; }

.MappingVariantsPageContainer .MappingVariantsPage .SectionInner .filter .InputWrapper .ant-select .ant-select-selector {
  height: 38px;
  border-radius: 0 5px 5px 0;
  font-size: 1rem; }
  .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .filter .InputWrapper .ant-select .ant-select-selector .ant-select-selection-search-input {
    height: 38px; }
  .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .filter .InputWrapper .ant-select .ant-select-selector .ant-select-selection-placeholder {
    line-height: 36px;
    color: #495057; }

.MappingVariantsPageContainer .MappingVariantsPage .SectionInner .filter .CustomSelect .ant-select .ant-select-selector {
  height: 38px;
  border-radius: 5px;
  font-size: 1rem; }
  .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .filter .CustomSelect .ant-select .ant-select-selector .ant-select-selection-search-input {
    height: 38px; }
  .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .filter .CustomSelect .ant-select .ant-select-selector .ant-select-selection-placeholder {
    line-height: 36px;
    color: #495057; }

.MappingVariantsPageContainer .MappingVariantsPage .SectionInner .filter .CustomFilterDate form {
  display: flex;
  align-items: center;
  height: 38px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 0 15px; }
  .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .filter .CustomFilterDate form label {
    margin: 0 !important; }
  .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .filter .CustomFilterDate form .FilterDateRange .ant-picker {
    border: none;
    box-shadow: none; }

.MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table thead {
  background: #e9ecef; }

.MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td {
  vertical-align: top;
  min-height: 100px; }
  .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.SkuVariantMerchize {
    width: 330px; }
    .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.SkuVariantMerchize a {
      font-weight: bold;
      font-size: 18px;
      color: #2196f3;
      padding: 0 !important; }
    .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.SkuVariantMerchize span {
      display: block;
      color: #2196f3; }
      .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.SkuVariantMerchize span.catalogStatus {
        text-transform: capitalize;
        color: green;
        font-style: italic; }
        .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.SkuVariantMerchize span.catalogStatus.inactive {
          color: rgba(0, 0, 0, 0.4); }
  .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.VariantMerchize, .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.SkuVariantSupplier, .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.VariantSupplier, .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.ProductTypeFulfill {
    width: 220px; }
  .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.SkuVariantSupplier {
    color: #2196f3; }
  .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.UpdateAt {
    width: 250px; }
  .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.Mode {
    text-transform: capitalize; }
  .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.Status {
    width: 150px; }
    .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.Status span {
      display: inline-block;
      background: green;
      color: #FFF;
      text-transform: capitalize;
      font-size: 14px;
      border-radius: 7px;
      padding: 1px 10px; }
    .MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr td.Status.unmapped span {
      background: red; }

.MappingVariantsPageContainer .MappingVariantsPage .SectionInner .MappingVariantsTable table tbody tr.topDashed td {
  border-top: 1px dashed #dee2e6; }

.MappingVariantDetailContainer .TitlePage .leftBox .mapping-status {
  padding: 2px 15px;
  border-radius: 7px;
  background: green;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize; }
  .MappingVariantDetailContainer .TitlePage .leftBox .mapping-status.unmapped {
    background: red; }

.MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .table-container table {
  width: 100%; }

@media (max-width: 1500px) {
  .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .table-container {
    overflow: auto; } }

.MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .itemValue .itemValueInput {
  display: inline-block;
  width: 400px;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 5px 15px;
  border-radius: 7px; }

.MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .itemValue .ant-radio-disabled .ant-radio-inner {
  border-color: #d9d9d9 !important;
  background-color: #fff; }
  .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .itemValue .ant-radio-disabled .ant-radio-inner::after {
    background-color: #1890ff; }

.MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .itemValue .ant-radio-checked .ant-radio-inner {
  border-color: #1890ff !important; }

.MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .itemValue .ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.85); }

.MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField table thead {
  background: #e9ecef; }
  .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField table thead tr th {
    padding: 5px 15px; }

.MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField table tbody tr td {
  padding: 10px 15px; }
  .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField table tbody tr td .searchAdvance {
    display: inline-block;
    position: relative; }
    .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField table tbody tr td .searchAdvance .std-select-arrow {
      position: absolute;
      top: 8px;
      right: 11px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.25); }
    .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField table tbody tr td .searchAdvance .std-select-search {
      position: absolute;
      top: 8px;
      right: 11px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.25); }

.MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField table tbody tr.error {
  background-color: rgba(255, 0, 0, 0.15); }

.MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .searchAdvance {
  position: relative; }
  .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .searchAdvance .searchDroplist {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9;
    width: 1100px; }
    .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .searchAdvance .searchDroplist.hide {
      display: none; }
    .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .searchAdvance .searchDroplist.show {
      display: block; }
    .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .searchAdvance .searchDroplist table {
      width: 100%;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      background-color: #fff; }
      .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .searchAdvance .searchDroplist table thead tr {
        display: table;
        width: 100%;
        table-layout: fixed; }
      .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .searchAdvance .searchDroplist table tbody {
        max-height: 300px;
        overflow: auto;
        display: block; }
        .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .searchAdvance .searchDroplist table tbody tr {
          display: table;
          width: 100%;
          table-layout: fixed; }
          .MappingVariantDetailContainer .MappingVariantDetail .FormMappingVariant .MappingVariantField .itemField .searchAdvance .searchDroplist table tbody tr:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.03); }

.SpecialCaseTable .Store, .SpecialCaseTable .Country, .SpecialCaseTable .OrderTag, .SpecialCaseTable .Title, .SpecialCaseTable .ShippingPlan, .SpecialCaseTable .ProductCatalogSku {
  max-width: 200px; }

.SpecialCaseNewPageCreate .SectionInner .ant-select-selector, .SpecialCaseEditPage .SectionInner .ant-select-selector {
  min-height: 40px;
  border-radius: 5px;
  align-items: center;
  max-height: 140px;
  overflow-y: auto; }
  .SpecialCaseNewPageCreate .SectionInner .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item, .SpecialCaseEditPage .SectionInner .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item {
    height: 30px !important;
    line-height: 26px !important; }
    .SpecialCaseNewPageCreate .SectionInner .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-remove, .SpecialCaseEditPage .SectionInner .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item .ant-select-selection-item-remove {
      font-size: 12px !important;
      margin-left: 5px;
      color: black; }
