.AutoMapping {
  .ant-switch-checked, .ant-radio-inner:after, .ant-btn-primary, .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #477BF6;
  }

  .ant-btn-primary[disabled] {
    color: #ffffff;
  }

  .ant-checkbox-checked:after, .ant-checkbox-checked .ant-checkbox-inner, .ant-radio-checked .ant-radio-inner, .ant-radio-checked:after {
    border-color: #477BF6;
  }

  input {
    font-size: 14px;
  }

  .ant-radio, .ant-radio-wrapper, .ant-checkbox-wrapper {
    font-size: 16px;
  }

  .ant-input-affix-wrapper .ant-input-prefix {
    left: 14px;
  }

  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 10px;
  }

  .TogglePush {
    > label {
      font-weight: bold;
    }
  }

  .MappingCheck {
    > label {
      padding: 10px 0;
    }

    input {
      height: 40px;
    }
  }

  .listStore {
    margin-top: 25px;
    border: 1px solid #E0E3E7;
    border-radius: 4px;
    padding: 15px 15px 5px 15px;
    display: flex;
    flex-wrap: wrap;
    max-height: 350px;
    overflow-y: scroll;

    .itemStore {
      width: 20%;
      font-size: 14px;
      margin-bottom: 10px;
      line-height: 24px;

      span:not(.anticon.anticon-close) {
        background: #F5F5F5;
        border-radius: 2px;
        line-height: 28px;
        padding: 0 12px;
        display: inline-block;

        .anticon.anticon-close {
          color: #6C757D;
          margin-left: 5px;
          font-size: 11px;
          cursor: pointer;
        }
      }

      &.error {
        color: #DC3545;

        span {
          background-color: #F9E3E5;

          i {
            color: #DC3545;
          }
        }
      }
    }
  }

  .actions {
    margin-top: 24px;
    padding-top: 24px;
    position: relative;
    border-top: 1px solid #E0E3E7;

    .errorStore {
      position: absolute;
      width: 100%;
      text-align: center;
      padding: 0 140px;
      top: 24px;
    }

    button {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}
