.OrderAdvanceActions {
  .Options {
    top: calc(100% + 15px);
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 2;
    background-color: #fff;
    border: none;
    min-width: 200px;
    overflow: hidden;
    box-shadow: 0 0 0 1px rgba(39, 44, 48, 0.05), 0 2px 7px 1px rgba(39, 44, 48, 0.16);
  }

  .Option {
    &:hover {
      background-color: rgba(33, 43, 54, 0.1);
      //color: #fff;
    }
  }

  //
  &.active {
    .ToggleMenu {
      background-color: rgba(33, 43, 54, 0.1);
    }

    .Options {
      opacity: 1;
      visibility: visible;
    }
  }

  .dropdown-item {
    min-width: 250px;
  }

  .PushOrderExport {
    &.disabled {
      opacity: .4;
      pointer-events: none;
    }
  }
}
