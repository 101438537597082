.ProductLineModalNavigation {
  height: 40px;
}

.ProductLineVariants {
  .ProductLineVariantsRow {
    &.Disabled {
      color: #212529;
      background-color: rgba(117, 107, 107, 0.5);
      border-bottom: 1px solid #dddddd;
    }
  }
}
