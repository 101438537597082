.table-col-5 {
  width: 5%;
}

.table-col-10 {
  width: 10%;
}

.table-col-15 {
  width: 15%;
}

.table-col-20 {
  width: 20%;
}

.table-col-25 {
  width: 25%;
}

.table-col-30 {
  width: 30%;
}

.table-col-35 {
  width: 35%;
}

.table-col-40 {
  width: 40%;
}

.table-col-45 {
  width: 45%;
}

.table-col-50 {
  width: 50%;
}

.table-col-55 {
  width: 55%;
}

.table-col-60 {
  width: 60%;
}

.table-col-65 {
  width: 65%;
}

.table-col-70 {
  width: 70%;
}

.table-col-75 {
  width: 75%;
}

.table-col-80 {
  width: 80%;
}

.table-col-85 {
  width: 85%;
}

.table-col-90 {
  width: 90%;
}

.table-col-95 {
  width: 95%;
}

.table-col-100 {
  width: 100%;
}

.SectionHeading {
  font-weight: 500;
  font-size: 1.5rem;
}

.cursor-pointer {
  cursor: pointer;
}

.font-weight-500 {
  font-weight: 500;
}

.AspectRatioBox {
  position: relative;
  width: 100%;
  padding-bottom: 100%;

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
}

.UploadInput {
  position: relative;

  input[type=file] {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }

}

.ant-notification-notice-description {
  word-break: break-all;
}

.LargePage {
  font-size: 25px;

  .PageTitle {
    font-size: 1.75em;
    margin-bottom: .5em;
  }

  input[type=text], button {
    height: 60px;
    font-size: 25px;
    padding: calc(.375 * 25px) calc(.75 * 25px);
  }

  .ant-divider {
    font-size: 25px;
  }

  .ant-input-clear-icon {
    font-size: 30px;
  }
}
