.FastProductionRulesContainer {
    .FastProductionRulesPage {
        .SectionInner {
            .filter {
                .itemFilter {
                    .Group.ant-select {
                        .ant-select-selector {
                            min-height: 40px;
                            border-top-left-radius: 5px;
                            border-top-right-radius: 0;
                            border-bottom-left-radius: 5px;
                            border-bottom-right-radius: 0;
                            align-items: center;

                            .ant-input-affix-wrapper {
                                min-height: 40px;
                                border-radius: 5px;
                            }
                        }
                    }

                    .ant-select-selector {
                        min-height: 40px;
                        border-radius: 5px;
                        align-items: center;

                        .ant-input-affix-wrapper {
                            min-height: 40px;
                            border-radius: 5px;
                        }
                    }

                    .ant-picker.ant-picker-range {
                        min-height: 40px;
                        border-radius: 5px;
                        align-items: center;
                    }
                }
            }
        }

        td {
            vertical-align: top;
        }

        .ant-input-group .ant-input-affix-wrapper {
            min-height: 40px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            align-items: center;
        }

        .FastProductionRulesTable {
            overflow: auto;
        }
    }
    .HiddenProductTypes{
        max-height: 98px;
        overflow: hidden;
    }
}