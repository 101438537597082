.ProductDetailInfo {
  .ProductInfo {
    .form-group {
      label:not(.form-check-label) {
        display: block;
        font-weight: 600;
      }

      .ant-select {
        width: 100%;

        .ant-select-selector {
          border-radius: 0.25rem;
          height: auto;

          .ant-select-selection-overflow-item {
            .ant-select-selection-item {
              height: 30px;
              line-height: 26px;
              margin-top: 0;
              background-color: #1890ff;
              border-radius: 0.25rem;

              .ant-select-selection-item-content {
                color: #FFFFFF;
              }

              .ant-select-selection-item-remove {
                svg {
                  fill: #FFFFFF;
                }
              }
            }
          }
        }
      }

      .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: #495057;
        background: #e9ecef;
        cursor: not-allowed;
      }

      textarea {
        resize: none;
      }
    }
  }
}

.ProductCategories {
  margin-top: 50px;
}

.ProductImages {
  .ProductImagesList {
    .ProductImagesItem {
      border: 1px solid #ddd;
      border-radius: 0.25rem;
      margin-right: 5px;

      &:last-child {
        margin-right: 0;
      }

      .RemoveImage {
        background: #fff;
        padding: 5px;
        border: 1px solid #ddd;
        border-radius: 50%;

        &:hover {
          background-color: #9a0000;
          color: #FFFFFF !important;
          border-color: #9a0000;
        }
      }
    }
  }
}

.ProductInfo {
  .form-group {
    label:not(.form-check-label) {
      display: block;
      font-weight: 600;
    }

    .ant-select {
      width: 100%;

      .ant-select-selector {
        border-radius: 0.25rem;
        height: 38px;

        .ant-select-selection-overflow-item {
          .ant-select-selection-item {
            height: 30px;
            line-height: 26px;
            margin-top: 0;
            background-color: #1890ff;
            border-radius: 0.25rem;

            .ant-select-selection-item-content {
              color: #FFFFFF;
            }

            .ant-select-selection-item-remove {
              svg {
                fill: #FFFFFF;
              }
            }
          }
        }
      }
    }

    textarea {
      resize: none;
    }
  }
}
