.OrderFulfillmentContainer {
  margin-top: 20px;

  .Fulfillment {
    .Summary {
      margin-top: 20px;
    }
  }

  .MarkAsFulfilled {
    margin-top: 20px;

    .cursor-pointer {
      cursor: pointer;
    }

    .NumericInput {
      width: 80px;
      height: 100%;
      text-align: center;
    }
  }

  .QuantityFulfill {
    tr {
      border-bottom: 1px solid #eee;
    }

    .ItemInfo {
      margin: auto;
    }
  }
}

.OrderFulfillmentPage {
  .is-dragging-over {
    background-color: rgba(0, 123, 255, 0.15) !important;

    .split-package__empty {
      border: none;
      background-color: rgba(0, 123, 255, 0.15) !important;
    }
  }

  .is-dragging {
    user-select: none;

    .split-package__item {
      background-color: #d9d9d9;
    }
  }

  &__loading {
    min-height: 95px;

    > .ant-spin-dot {
      left: 0 !important;
      margin-left: 0 !important;
    }

    .ant-spin-text {
      text-align: left;
    }
  }

  .split-package {
    &__item {
      margin: 15px 0;
      border: 1px solid #cccc;
      border-radius: 2px;
    }

    &__header {
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #cccc;
      padding: 10px 15px;

      &-name {
        margin-bottom: 0;
      }

      &-input-skip {
        display: inline-block;
        min-width: 130px;
      }
    }

    &__btn-remove {
      color: $default-danger;
      cursor: pointer;

      &--is-disabled {
        color: $grey-700;
        cursor: not-allowed;
        opacity: 0.8
      }
    }

    &__body {
      background-color: #ffffff;

      &-error {
        .ant-spin-text {
          top: 20px !important;
          font-weight: 700;
          color: $default-danger !important;
        }

        .ant-spin-dot {
          display: none !important;
        }
      }

      &-skip.split-package__body-processing {
        .ant-spin {
          display: none !important;
        }
      }
    }

    &__empty {
      min-height: 200px;
      display: flex;
      align-content: center;
      justify-content: center;
      align-items: center;
      color: $default-grey;
      border: 10px solid #f0f0f0;
      background: #dee2e6;
    }

    &__supplier {
      display: inline-block;
      padding: 0 100px;

      &-select {
        min-width: 150px;
      }

      &--is-carrier {
        min-width: 190px;
      }
    }

    &__order-item {
      border-radius: 2px;
      padding: 15px;
      border: 1px solid #f0f0f0;
      display: flex;
      flex-wrap: nowrap;
      align-content: center;
      cursor: move;
      word-break: break-word;

      li {
        margin-bottom: 5px;
      }

      .Label {
        display: inline-block;
        padding-right: 5px;
        min-width: 85px;
        font-weight: bold;
      }

      .Value {
        word-break: break-word;
      }
    }

    &__order-item-thumbnail {
      flex: 0 0 100px;
    }

    &__order-item-info {
      padding: 0 15px;
      width: 100%;
    }


    &__footer {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;

      &-btn {
        margin-left: 20px;
      }
    }
  }
}

.split-package__modal {
  width: 90% !important;
  max-width: 600px;
}

.split-quantity {
  text-align: center;

  &__button {
    margin-left: 10px;
  }

  &__footer {
    border-top: 1px solid rgba(0, 0, 0, .06);
    margin-top: 10px;
    padding-top: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    button {
      margin: 0 5px;
    }
  }
}