.ProductLineModalContainer {

}

.ProductLineModal {
  width: calc(100vw - 60px);
  max-width: 100%;

  .modal-content {
    height: calc(100vh - 56px);
    overflow-y: auto;
  }

  .ProductLineCategories {
    .ListCategories {
      border-bottom: none;

      .ProductLineCategory {
        span.nav-link {
          padding: 5px 15px;
          border: 1px solid #dddddd;
          margin-bottom: 5px;
          margin-right: 5px;
          border-radius: 4px;

          &.active, &:hover {
            border-color: #0f9d58;
            border-radius: 4px;
            background: #0f9d58;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.AddChildProductsModal {
  .modalContent {
    max-height: 497px;
    overflow: auto;
  }
  .list-child.table {
    tr {
      td {
        vertical-align: top;
      }
    }
  }
}
