.ReportProductionTime {
  .col-total,
  .col-completed,
  .col-processing,
  .col-due {
    width: 150px;
  }

  .col-avg {
    width: 250px;
  }
  .col-type {
    width: 350px;
  }

  tbody {
    tr {
      &:hover {
        background: #44895d08;
      }
    }
  }

  td, th {
    &.linkable {
      padding: 0;

      a {
        color: #212529;
        padding: 0.75rem;
        display: block;
      }

      &:hover {
        cursor: pointer;

        a {
          color: #0f9d58;;
        }
      }
    }
  }
  table thead th {
    vertical-align: top!important;
  }
}

@media (max-width: 1200px) {
  .ReportProductionTime {
    .ReportHeading {
      flex-direction: column;

      .Filter {
        margin-left: auto;
      }
    }
  }
}