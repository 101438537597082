.SettingAutoPage {
  .TabList {
    .FilterTab {
      border-bottom: 1px solid #E0E3E7;

      .FilterList {
        display: flex;
        align-items: center;

        .Filter {
          position: relative;
          padding: 10px 25px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: center;

          &:after {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #477BF6;
            opacity: 0;
            z-index: 2;
          }

          &.active, &:hover {
            cursor: pointer;
            color: #477BF6;
            position: relative;

            &:after {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
