.RequestList {
  margin-bottom: 15px;

  .HeadingRequest {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 12px;
    background: rgba(222, 226, 230, 0.73);
    border: 1px solid rgba(117, 107, 107, 0.6);
    border-radius: 4px;

    .Title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      color: #303238;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .ContentRequest {
    display: none;

    .ItemRequest {
      padding: 12px;
      margin-top: 5px;
      border-radius: 4px;

      &.waiting {
        border: 1px solid #F3CC00;
        background: rgba(247, 231, 147, 0.33);
      }

      &.processing {
        border: 1px solid #F3CC00;
        background: rgba(247, 231, 147, 0.33);
      }

      &.updated {
        background: rgba(64, 146, 87, 0.13);
        border: 1px solid #409257;
      }

      > div {
        margin-bottom: 7px;

        span {
          text-transform: capitalize;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          color: #303238;
        }

        .Link {
          font-weight: normal;
          color: #0A7BFE;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }

    &.isOpen {
      display: block;
    }
  }
}

.ImageRequest {
  width: 50% !important;
  text-align: center;
}
