.PlatformSettingsPage {
  .SettingsWrapper {
    display: flex;
    flex-wrap: wrap;

    .SettingInner {
      padding: 12px;

      &:hover {
        background: #f9fafb;

        .IconWrapper {
          background: #dfe3e8;
        }

        .Info {

          .Title {
            color: #084e8a;
          }

          .Description {
            color: #303238 !important;
          }
        }
      }
    }

    .SettingWrapper {
      cursor: pointer;

      .IconWrapper {
        height: 2.5rem;
        width: 2.5rem;
        background: #f4f6f8;
        color: #919eab;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        flex-shrink: 0;
      }


      .Info {
        padding: 0 1rem;
        margin-top: -6px;

        p {
          padding: 0;
          margin: 0;
        }

        .Title {
          color: #007ace;
          margin: 0;
        }
      }
    }
  }
}
