.PlatformSettingPage {
  .SettingInner .Info .Description {
    color: #303238 !important;
  }

  .BackTitle {
    margin: 0 1rem;
  }

  .SettingWrapper {
    .Webhook {
      .HeaderTop {
        border-bottom: 1px solid #dfe3e8;
      }
    }

    .Title {
      width: 100%;
      font-weight: 600;
      font-size: 1.75rem;
      padding: .5rem 0;
    }

    .SettingSection {
      display: flex;
      width: 100%;
      margin: 1.5rem 0;

      .SectionDetails {
        width: 25%;
      }

      .SectionSettings {
        width: 75%;

        .InputWrapper {
          margin-bottom: 1rem;

          label {
            margin: 0;
          }
        }
      }
    }

    .SaveButtonGroup {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.SettingModal {
  .ant-modal-body {
    padding: 20px 24px;
    height: 620px;
    overflow: auto;
  }

  .ant-modal-close {
    &:focus {
      border: 0;
      outline: none !important;
    }
  }

  .Content {
    .Webhook {
      .HeaderTop {
        text-align: right;
        margin-bottom: 5px;
      }

      .SettingSection {
        .SectionSettings {
          .SectionInner {
            .Url {
              .InputWrapper {
                margin-bottom: 20px;

                label {
                  margin-bottom: 8px;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 15px;
                  line-height: 20px;
                  color: #000000;
                }

                input {
                  font-style: normal;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 20px;
                  color: #000000;
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .ant-modal-footer {
    padding: 15px 24px;

    button {
      padding: 6.4px 25px;
    }
  }
}
