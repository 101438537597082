.FulfilledPackageSupplier {
  position: relative;

  .IconWrapper {
    position: absolute;
    right: 20px;
    top: 8px;

    &.done {
      .text-success {
        animation-name: FadeOut;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-delay: .5s;
      }
    }

    .spin {
      border-color: transparent;
    }
  }

  .form-control {
    width: auto !important;
  }
}
