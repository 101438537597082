.ImportTrackingModal {
    .modal-content {
        min-height: 500px;
    }

    .ImportResult {
        border-left: solid 5px $success;
        background-color: rgba($success, .2);
    }
}
