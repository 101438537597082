.NotificationPage {
  @media (max-width: 991px) {
    padding-left: 15px;
    padding-right: 15px;
    .PageTitle {
      padding: 0;
    }
  }

  .hiden-suffix{
    .ant-picker-suffix{
        display: none !important;
    }
  }
}

.NotificationTable {
  .ShippingMethod{
    .ShowMore {
      cursor: pointer;
      color: #13b838;
      transition: all 0.3s ease-in-out;
  
      &:hover {
        color: #0f9d58;
      }
    }
  }
  @media (max-width: 1024px) {
    .wrapTable {
      overflow-x: scroll;

      table {
        width: max-content;
      }
    }
  }

  .noData {
    padding: 90px 0;
  }

  .BulkSelectNotification {
    width: 65px;
  }

  .MenuActions {
    position: absolute;
    left: 10px;
    top: 8px;
  }

  .custom-control {
    padding-left: 10px;
  }

  .table .thead-light th {

  }

  .storeId {
    width: 100px;
  }

  .title {
    width: 200px;
  }

  .orderNumber {
    width: 168px;
    word-break: break-all;
  }

  .image {
    width: 110px;

    a {
      text-decoration: underline;
      text-underline-position: under;
    }
  }

  .status {
    width: 150px;
  }

  &.OrderSupplies {
    @media (max-width: 1700px) {
      .wrapTable {
        overflow-x: scroll;

        .table {
          width: -moz-max-content;
          width: max-content;
        }
      }
    }

    .PackageChangeStatus {
      display: flex;
      align-items: baseline;

      .Actions {
        position: relative;

        i {
          position: relative;
          margin-left: 5px;
          z-index: 10;

          &:hover {
            cursor: pointer;
            color: #0f9d58;
          }
        }

        .Background {
          position: fixed;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          opacity: 0;
          z-index: 9;
        }

        .ListActions {
          display: none;
          position: absolute;
          width: 250px;
          background: #fff;
          border: 1px solid rgba(0, 0, 0, .15);
          border-radius: 0.25rem;
          margin: 0;
          padding: 0;
          z-index: 10;

          li {
            color: #333333;
            list-style: none;
            padding: 10px 15px;
            font-size: 15px;
            font-weight: normal;

            &:hover {
              color: #0f9d58;
              cursor: pointer;
            }
            &.Disable {
              pointer-events: none;
              color: #adb5bd;;
            }
          }

          &.IsOpen {
            display: block;
          }

          &.Disable {
            li {
              pointer-events: none;
              color: #adb5bd;;
            }
          }
        }
      }
    }

    .Address {
      width: 145px;
    }

    .Info {
      width: 270px;

      .PackageName {
        strong {
          a {

          }
        }
      }

      .StoreName {
        font-size: 15px;
        color: #e83e8c;
        word-wrap: break-word;
      }

      .OrderReference {
        font-size: 15px;
        color: #2F3230;
      }

      .PackagePushTime {
        font-size: 15px;
      }

      .CustomReference {
        font-size: 15px;
      }
    }

    .Supplier {
      width: 130px;
    }

    .ShippingMethod {
      width: 300px;

      > div {
        span {
          font-weight: bold;
          font-size: 14px;
        }
      }
    }

    .Status {
      width: 200px;
    }

    .PushType {
      width: 130px;
    }

    .Tracking {
      width: 300px;
    }

    .Note {
      width: 250px;
      word-break: break-word;

      .ShowMore {
        cursor: pointer;

        &:hover {
          color: #0f9d58;
        }
      }

      .NoteOrder {

      }

      .NotePackage {
        span {
          margin-right: 5px;
          color: #9a0000;
        }

        i {
          &:hover {
            cursor: pointer;
            color: #0f9d58;
          }
        }
      }
    }

    td.Note {
      font-size: 14px;
    }

    table{
      tbody{
        td{
          .error-box{
            position: relative;

            .error-message{
              position: absolute;
              bottom: 100%;
              left: 50%;
              transform: translate(-50%, -5px);
              width: 250px;
              background: #e9ecef;
              padding: 7px 15px;
              border-radius: 4px;
              box-shadow: 0px -4px 4px 0px rgba(0,0,0,0.1);
              transition: all 0.3s ease 0s;
              visibility: hidden;
              opacity: 0;
              z-index: 999;
              word-break: break-word;

              &:before{
                position: absolute;
                top: 100%;
                left: 50%;
                content: '';
                display: block;
                width: 0;
                height: 0;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 7px solid #e9ecef;
                transform: translate(-3px,0);
              }
            }

            &:hover{
              .error-message{
                visibility: visible;
                opacity: 1;
              }
            }
          }

          .supplier{
            position: relative;

            .supplierModalContainer{
              position: absolute;
              top: 100%;
              left:0;
              margin-left: -100px;
              min-width: 1200px;
              background-color: #fff;
              z-index: 99;
              padding: 16px;
              border-radius: 2px;
              box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
              visibility: visible;
              opacity: 1;
            }

            // &:hover{
            //   .supplierModalContainer{
            //     visibility: visible;
            //     opacity: 1;
            //   }
            // }
          }
        }
      }
    }
  }

  &.FulfillmentDiscounts {
    .DiscountName {
      width: 400px;
      word-break: break-all;
    }

    .Status {
      width: 100px;
    }

    .Date {
      width: 200px;
    }

    .DateApply {
      width: 250px;
    }

    .Type {
      width: 150px;
    }

    .Value {
      width: 150px;
    }

    .Actions {
      width: 100px;
    }

    .Store {
      width: 200px;
    }

    .badge {
      padding: 6px 10px;
      border-radius: 80px;
      font-weight: 500;
    }
  }
}

.PackageNoteModal {
  .ant-modal-close {
    outline: none;
  }

  textarea {
    resize: none;

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
      background-color: rgba(0, 0, 0, .1);
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }
  }
}

.ChangeStatusPackageModal, .ChangeConfirmPackageModal, .AddFastProductionModalTag {
  width: 680px !important;

  .ant-modal-close {
    outline: none;
  }

  .FormItem {
    margin-bottom: 15px;

    &:nth-child(2) {
      padding-bottom: 15px;
      border-bottom: 1px solid #dddddd;
    }

    label {
      font-weight: 500;
      margin-bottom: 5px;
    }

    .ant-select {
      width: 100%;
    }
  }

  h3 {
    font-size: 16px;
    margin-bottom: 15px;
    font-weight: 500;

    span {
      font-weight: bold;
    }
  }

  .Results {
    display: flex;
    align-items: center;

    .ResultSuccess {
      margin-right: 10px;

      span {
        color: #0f9d58;
        font-weight: bold;
      }
    }

    .ResultError {
      span {
        font-weight: bold;
        color: #9a0000;
      }
    }
  }

  .ListProcessing {
    margin-top: 15px;
    max-height: 250px;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
      background-color: rgba(0, 0, 0, .1);
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    ul {
      li {
        margin-bottom: 10px;

        span:first-child {
          font-weight: 500;
        }

        .Error {
          margin-left: 10px;
          color: #9a0000;

          .anticon {
            svg {
              fill: #9a0000;
            }
          }
        }

        .Success {
          margin-left: 10px;
          color: #0f9d58;

          .anticon {
            svg {
              fill: #0f9d58;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.ant-popover{
  width: 300px !important;

  .ant-popover-inner-content {
    max-height: 168px;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
      background-color: rgba(0, 0, 0, .1);
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }
  }

  &.listBarcodeNumbers{
    width: 200px !important;
  }
}

.addTagsModal{
  .ListProcessing{
    margin-top: 15px;
    max-height: 270px;
    overflow: auto;

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
      background-color: rgba(0, 0, 0, .1);
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    ul {
      li {
        margin-bottom: 10px;

        span:first-child {
          font-weight: 500;
        }

        .Error {
          margin-left: 10px;
          color: #9a0000;

          .anticon {
            svg {
              fill: #9a0000;
            }
          }
        }

        .Success {
          margin-left: 10px;
          color: #0f9d58;

          .anticon {
            svg {
              fill: #0f9d58;
            }
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
