.OrderMeta {
  .Statues {
    &.unfulfilled {
      > .badge {
        background-color: #ffc107;
        color: #212529;
      }
    }

    &.partial {
      > .badge {
        background-color: #ffc58b;
        color: #212b36;
      }
    }
  }
}

.OrderInvoice {
  .card-header {
    > * {
      vertical-align: middle;
      display: inline-block;
    }

    .EmbroiderySurchargeTooltip {
      color: #FF9800;
      margin-left: 5px;
    }
  }
}