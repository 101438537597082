$waiting_color: #0aa7ef;
$processing_color: #00897b;
$updated_color: #00c853;
$hold_color: #ECD141;

.RequestsContainer {
  .RequestsPage {
    .PageHeading {
      .RightTop {
        display: flex;
        align-items: center;

        .ExportRequestGoogleSheet {
          margin-left: 24px;

          button {
            height: 40px;
          }
        }

        .MoreFilter {
          border: 1px solid #eeeeee;
          padding: 8px 15px;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #303238;
          margin-left: 5px;
          border-radius: 4px;

          i {
            font-size: 14px;
          }

          &:hover {
            cursor: pointer;
            background: #0f9d58;
            color: #FFFFFF;

            i {
              color: #FFFFFF;
            }
          }
        }
      }
    }

    .SectionInner {
      position: relative;
      padding: 24px 16px;

      .RequestFilters {
        .Filters {
          display: flex;
          align-items: center;
          margin-bottom: 24px;

          .MultiFilterInput {
            margin-right: 24px;
          }

          .FilterDateRange {
            margin-right: 24px;

            .ant-picker {
              height: 40px;
              border-radius: 4px;
            }
          }

          .CustomMultiSelect {
            margin-bottom: 0;
            margin-left: 0;
            margin-right: 24px;

            &:last-child {
              margin-right: 0;
            }
          }
        }

        .DisplayFilter {
          display: flex;
          align-items: center;
          margin-top: 12px;

          .ProductStatus {
            margin-right: 12px;
          }

          .ProductStatus, .Variants {
            padding: 5px 12px;
            background: #F8F9FA;
            border: 1px solid #D8DBDE;
            box-sizing: border-box;
            border-radius: 4px;

            span {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              color: #303238;

              &:not(.Title) {
                font-weight: 500;
                margin-right: 7px;
                position: relative;

                &:after {
                  content: ',';
                  position: absolute;
                  right: -4px;
                  bottom: 0;
                  color: #303238;
                }
              }

              &:last-of-type {
                &:after {
                  display: none;
                }
              }
            }

            i {
              font-size: 12px;
              margin-left: 5px;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }

      .Total {
        text-align: right;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #303238;
        margin-bottom: 19px;

        span {
          text-align: right;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #303238;
          margin-bottom: 19px;
        }
      }

      .RequestTable {
        table {
          width: 100%;

          .RequestThead {
            tr {
              th {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                color: #303238;
                padding: 16px;
                border-top: 1px solid #D8DBDE;
                background: #F4F6F8;
              }
            }
          }

          .RequestBody {
            tr {
              td {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: #303238;
                padding: 16px;
                border-top: 1px solid #D8DBDE;
              }

              &:last-child {
                border-bottom: 1px solid #D8DBDE;
              }

              &.RequestItem {
                .RequestId, .Type, .Info {
                  width: 10%;
                  word-break: break-word;
                }

                .Source {
                  width: 7%;
                }

                .RequestId {
                  color: #0A7BFE;
                  font-size: 14px;
                }

                .Source {
                  text-transform: uppercase;

                  &.StatusProduction {
                    text-transform: none;
                  }
                }

                .Info {
                  span {
                    color: #0A7BFE;
                    font-style: italic;

                    &:last-child {
                      font-style: normal;
                      color: #2F3230;
                      display: block;
                      font-weight: bold;
                    }

                    a {
                      color: inherit;
                    }
                  }
                }

                .Content, .Created, .Updated {
                  width: 10%;
                  word-break: break-word;
                }

                .Type {
                  .MoreInfo {
                    span {
                      display: inline-block;
                      height: 18px;
                      line-height: 17px;
                      position: relative;
                      padding: 0 7px 0 12px;
                      background: #DC3545;
                      border-bottom-right-radius: 3px;
                      border-top-right-radius: 3px;
                      color: #fff;
                      font-size: 11px;
                      text-decoration: none;
                      font-weight: 400;
                      margin: 0 0 0 7px;
                      white-space: nowrap;

                      &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: -7px;
                        width: 0;
                        height: 0;
                        border-color: transparent #DC3545 transparent transparent;
                        border-style: solid;
                        border-width: 9px 7px 9px 0;
                      }

                      &:after {
                        content: "";
                        position: absolute;
                        top: 7px;
                        left: 1px;
                        float: left;
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background: #fff;
                      }
                    }
                  }

                  span {
                    &.pending, &.paid, &.cancelled, &.NoCharge {
                      display: block;
                      width: fit-content;
                      white-space: nowrap;
                    }

                    &.pending {
                      background: #007BFF;

                      &:before {
                        border-color: transparent #007BFF transparent transparent;
                      }
                    }

                    &.paid {
                      background: #0f9d58;

                      &:before {
                        border-color: transparent #0f9d58 transparent transparent;
                      }
                    }

                    &.cancelled, &.NoCharge {
                      background: #9a0000;

                      &:before {
                        border-color: transparent #9a0000 transparent transparent;
                      }
                    }
                  }
                }

                .Status {
                  width: 8%;
                  word-break: break-word;
                  white-space: nowrap;

                  .itemStatus {
                    display: inline-block;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: center;
                    padding: 1px 10px;
                    border-radius: 80px;
                    color: #FFFFFF;

                    &.waiting {
                      background: $waiting_color;
                    }

                    &.processing {
                      background: $processing_color;
                    }

                    &.updated {
                      background: $updated_color;
                    }

                    &.hold {
                      background-color: $hold_color;
                    }
                  }

                  .ConfirmCharge {
                    color: #0A7BFE;

                    svg {
                      font-size: 22px;
                      margin-left: 12px;
                      top: 2px;
                      position: relative;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .ant-pagination {
        padding-top: 24px;
      }
    }
  }
}

.ModalConfirmChargeUpdateDesign {
  .RequestInfo {
    margin-bottom: 10px;

    strong {
      margin-bottom: 10px;
      display: block;
    }

    label {
      margin-bottom: 4px;
      width: 130px;
    }
  }

  .Note {
    background: #F9FAFB;
    border: 1px solid #DFDFDF;
    border-radius: 3px;
    padding: 14px 20px;
    margin-bottom: 18px;
  }

  .ChargeInfo {
    strong {
      margin-bottom: 10px;
      display: block;
    }

    label {
      margin-bottom: 4px;
      width: 100px;
    }

    .ValueCharge {
      input[type="text"] {
        border: 1px solid #d9d9d9;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
        line-height: 30px;
        padding: 0 10px;
      }

      .error {
        input[type="text"] {
          border-color: #ff0000;
        }

        button[type="button"] {
          border-bottom-color: #ff0000;
          border-top-color: #ff0000;
          border-right-color: #ff0000;
        }
      }
    }
  }
}

.Created {
  left: 733px !important;
}

.CreatedMapping {
  left: 770px !important;
}

.Updated {
  left: 1045px !important;
}

@media (max-width: 1898px) {
  .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
    width: 250px;
  }
}

@media (max-width: 1536px) {
  .CustomMultiSelect .InputSelect {
    min-width: 120px;
  }

  .MultiFilterInput .MultiFilter .ant-select .ant-select-selector {
    min-width: 100px;
  }

  .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
    width: 240px;
  }
}

@media (max-width: 1440px) {
  .CustomMultiSelect .InputSelect {
    min-width: 120px;
  }

  .MultiFilterInput .MultiFilter .ant-select .ant-select-selector {
    min-width: 100px;
  }

  .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
    width: 220px;
  }

  .MultiFilterInput .MultiFilter .Right input {
    width: 100px;
  }
}

@media (max-width: 1367px) {
  .CustomMultiSelect .InputSelect {
    min-width: 110px;
  }

  .MultiFilterInput .MultiFilter .ant-select .ant-select-selector {
    min-width: 100px;
  }

  .RequestsContainer .RequestsPage .SectionInner .RequestFilters .Filters .FilterDateRange .ant-picker {
    width: 200px;
  }
}

.ExportRequestUpdate {
  .Content {
    .FormItem {
      margin-bottom: 20px;

      label {
        display: block;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #303238;
      }

      input {
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #D8DBDE;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 9px 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #303238;

        &:focus {
          border: 1px solid #0f9d58;
        }
      }
    }
  }
}

.RequestFilters {
  .Filters {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .MultiFilterInput {
      margin-right: 24px;
    }

    .FilterDateRange {

      .ant-picker {
        height: 40px;
        border-radius: 4px;
      }
    }

    .CustomMultiSelect {
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 24px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .DisplayFilter {
    display: flex;
    align-items: center;
    margin-top: 12px;

    .ProductStatus {
      margin-right: 12px;
    }

    .ProductStatus, .Variants {
      padding: 5px 12px;
      background: #F8F9FA;
      border: 1px solid #D8DBDE;
      box-sizing: border-box;
      border-radius: 4px;

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #303238;

        &:not(.Title) {
          font-weight: 500;
          margin-right: 7px;
          position: relative;

          &:after {
            content: ',';
            position: absolute;
            right: -4px;
            bottom: 0;
            color: #303238;
          }
        }

        &:last-of-type {
          &:after {
            display: none;
          }
        }
      }

      i {
        font-size: 12px;
        margin-left: 5px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
