.Upload {
    .UploadFile {
        display: flex;
        align-items: center;
        margin-top: 16px;
        position: relative;

        .ClickUpload {
            padding: 7px 23px;
            background: #FFFFFF;
            border: 1px solid #C9CCCE;
            box-sizing: border-box;
            border-radius: 4px;
            margin-right: 16px;
            cursor: pointer;
            width: 164px;

            span {
                margin-left: 14px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #303238;
            }

            .anticon-upload {
                margin-left: 0;
            }
        }

        .File {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #64666B;
            width: calc(100% - 180px);

            .FileName {
                display: flex;
                align-items: center;
                padding: 5px 6px;

                .FloatRight {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: calc(100% - 14px);

                    span {
                        margin-left: 10px;
                    }

                    .anticon-delete {
                        display: none;
                    }
                }

                &:hover {
                    background-color: #F9FAFB;

                    .FloatRight {
                        .anticon-delete {
                            display: block;

                            &:hover {
                                color: #dc3545;
                            }
                        }
                    }
                }
            }
        }

        &.Error {
            .ClickUpload {
                border: 1px solid #DC3545;

                span {
                    color: #DC3545;
                }

                .anticon-upload {
                    color: #DC3545;
                }
            }

            .File {
                color: #dc3545;
            }
        }

        #CustomUpload {
            position: absolute;
            width: 164px;
            height: 40px;
            z-index: 9;
            opacity: 0;
            border-radius: 4px;

            &:hover {
                cursor: pointer;
            }
        }

        &:hover {
            .ClickUpload {
                border: 1px solid #477BF6;

                span {
                    color: #477BF6;
                }

                .anticon-upload {
                    color: #477BF6;
                }
            }
        }
    }

    .ErrorText {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #64666B;
        margin-top: 8px;

        .anticon-exclamation-circle {
            font-size: 15px;
            margin-right: 5px;
            color: #DC3545;
        }
    }

    .hide {
        display: none;
    }
}

.ModalUploadFile {
    border-radius: 4px;

    .ant-modal-content {
        .ant-modal-close {
            &:hover, &:active, &:focus {
                outline: none;
            }
        }

        .ant-modal-body {
            .Upload {
                .Title {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 24px;
                    color: #303238;
                    margin-bottom: 4px;
                    margin-right: 15px;
                }

                .FileTemplate {
                    margin-bottom: 4px;

                    a {
                        text-decoration: none;

                        &:hover {

                        }
                    }
                }

                p {
                    font-style: italic;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    color: #64666B;
                    margin-bottom: 16px;
                }
            }
        }

        .ant-modal-footer {
            .ant-btn {
                height: 40px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: #303238;
                padding: 0 32px;
                border-radius: 4px;

                &.ant-btn-primary {
                    color: #FFFFFF;
                }

                &:disabled {
                    background: #1890ff;
                    opacity: 0.8;
                }
            }
        }
    }
}

.UploadFilePage {
    .ant-btn.ant-btn-primary {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
        padding: 0 27px;
        background: #4A80F7;
        border-radius: 4px;
        height: 40px;
    }
}
