.OrderPartnerPage {
    .OrderPreference {
        .OrderPreferenceShipping {
            .Tracking {
                .Value {
                    a {
                        color: #0A7BFE;
                    }
                }
            }
        }
    }
}

.ScanTracking {
    .Content {
        display: flex;
        align-items: center;

        label {
            color: #303238;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            margin-right: 30px;
            margin-bottom: 0;
        }

        .ant-select {
            .ant-select-selector {
                min-width: 300px;
                width: 100%;
            }
        }
    }

    .ant-modal-footer {
        padding: 24px 16px;

        button {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 24px;
            height: 40px;
            padding: 0 32px;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
