$height: height('.tabsStatics');
.StaticsContainer {
    .StaticsPage {
        .PageTitle {
            margin-top: 30px;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            color: #000000;
            margin-bottom: 50px;
        }

        .InnerPage {
            .DetailStatics {
                width: 100%;

                .table {
                    text-align: center;
                    width: 100%;

                    .Supplier, .ColumnTable {
                        width: 10%;
                    }

                    .Delay {
                        color: #D94646;

                        a {
                            color: #D94646;
                        }
                    }

                    thead {
                        background: #4A80F7;

                        th {
                            padding: 28px 0;
                            font-style: normal;
                            font-weight: bold;
                            font-size: 18px;
                            line-height: 21px;
                            color: #FFFFFF !important;
                        }
                    }

                    tbody {
                        tr {
                            td {
                                padding: 28px 0;
                                font-style: normal;
                                font-weight: normal;
                                font-size: 18px;
                                line-height: 21px;
                                color: #000000;

                                a {
                                    color: #000000;
                                }


                                &.Supplier {
                                    font-style: normal;
                                    font-weight: bold;
                                    font-size: 18px;
                                    line-height: 21px;
                                    color: #000000;
                                }
                            }

                            &.Total {
                                td {
                                    font-style: normal;
                                    font-weight: bold;
                                    font-size: 18px;
                                    line-height: 21px;
                                    color: #000000;

                                    a {
                                        color: #000000;
                                    }

                                    &.Delay {
                                        color: #D94646;

                                        a {
                                            color: #D94646;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .ShipmentStatusTable, .ProductionTimeTable, .ShipmentStatusByStoreTable{
            border-collapse: separate;
            border-spacing : 0px;
            thead th {
                background: rgb(249, 250, 251);
                border-top: 1px solid #dee2e6 ;
                border-bottom: 2px solid #dee2e6 ;
                border-left: 1px solid #dee2e6 ;
            }
            thead th:last-child{
                border-right: 1px solid #dee2e6 ;
            }
            tbody td {
                border-bottom: 1px solid #dee2e6;
                border-left: 1px solid #dee2e6;
            }
            tbody td:last-child{
                border-right: 1px solid #dee2e6 ;
            }
        }
    }
}
.fixedHeader {
    .tabsStatics {
        position: sticky;
        top: 0;
        background: rgb(249, 250, 251);
        z-index: 1000;
    }
    .contentTab {
        .DailyReports{
            .ReportShipStatus, .ReportShipStatusByStore{
                .Filter{
                    position: sticky;
                    background: rgb(249, 250, 251);
                    z-index: 1;
                }
                .Error{
                    position: sticky;
                    background: rgb(249, 250, 251);
                    z-index: 1;
                }
                .ShipmentStatusTable{
                    border-collapse: separate;
                    border-spacing : 0px;
                    thead{
                        position: sticky;
                        z-index: 100;
                    }
                }
            }
        }
        .ProductionReports, .ReportFastProductionTime{
            .ReportHeading{
                position: sticky;
                background: rgb(249, 250, 251);
                z-index: 1;
            }
            .Error{
                position: sticky;
                background: rgb(249, 250, 251);
                z-index: 1;
            }
            .ProductionTimeTable{
                border-collapse: separate;
                border-spacing : 0px;
                thead{
                    position: sticky;
                    z-index: 100;
                }
            }
        }
    }
}

@media (max-width: 1645px) {
    .StaticsContainer {
        .StaticsPage {
            .InnerPage {
                overflow-x: scroll;

                .DetailStatics {
                    width: 1550px;

                    .table {
                        overflow: scroll;
                        -webkit-overflow-scrolling: touch;
                    }
                }
            }
        }
    }
}