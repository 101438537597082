.PlatformPage {
    .StatusBar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .DetailsWrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 1rem 0;

        .P-5 {
            width: 50%;

            &:nth-child(2n + 1) {
                padding-right: .5rem;
            }

            &:nth-child(2n) {
                padding-left: .5rem;
            }
        }
    }

    .SaveButtonGroup {
        display: flex;
        justify-content: flex-end;
    }
}
