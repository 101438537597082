.OrderContainer {
    h3 {
        color: gray;
    }
}

.OrderPage {
  word-break: break-word;

  .OrderItemRow {
    .ThumbnailWrapper {
      min-width: 125px;
    }

    .Label {
      min-width: 90px;
    }
  }

  .OrderItemMapDetails {
    .Label {
      min-width: 60px;
    }
  }
  .alertWarning {
    .alert.alert-warning {
      border-color : #e2c46f;
      color: #cb9801;
      font-weight: 400; 
      background: #fff8e3;
      padding: 0.75rem 4rem 0.75rem 0.75rem;
      button.close { 
        color: #cb9801;
        position: absolute;
        right: 0.75rem;
        display: flex;
        align-items: center;
        opacity: unset;
        padding: unset;
        &:hover{
          opacity: 0.5;
        }
        &:focus {
          outline: unset;
        }
      }
    }
  }
}

.thumbnail-bottom{
  display: flex;

  .mockup-open{
    &.disable{
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.mockup-message{
  color: red;
}

.modal-dialog{
  &.modal-mockup{
    max-width: 900px;

    .modal-body{
      .mockup-image{
        text-align: center;
      }
    }
  }
}