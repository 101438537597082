.OrderItemDesign {
  position: relative;

  .RequestUpdateModal {
    svg {
      position: absolute;
      right: 10px;
      top: 10px;

      &:hover {
        cursor: pointer;

        path {
          fill: #0f9d58;
        }
      }
    }
  }
}

.RequestModal {
  .ContentModal {
    .RequestType {
      margin-bottom: 15px;

      label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #303238;
        min-width: 100px;
      }

      .ant-select {
        width: calc(100% - 100px);

        .ant-select-selection-item {
          color: #333333;
        }
      }
    }

    .RequestDescription {
      margin-bottom: 15px;
      position: relative;

      label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #303238;
        display: block;
      }

      textarea {
        padding: 16px;
        width: 100%;
        resize: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #303238;

        &::placeholder {
          font-style: italic;
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
          color: #BFBFBF;
        }

        &:focus, &:active, &:visited {
          border: 1px solid #0f9d58;
          outline: none;
        }
      }

      .Language {
        margin-left: 10px;

        .NameSpace {
          font-size: 87.5%;
          color: #e83e8c;
          word-wrap: break-word;
          margin-right: 5px;
        }
      }

      .Results {
        position: absolute;
        width: 100%;
        background: #FFFFFF;
        z-index: 99;
        max-height: 150px;
        overflow-y: scroll;
        box-sizing: border-box;
        box-shadow: 2px 8px 16px rgba(0, 0, 0, 0.16);
        border-radius: 2px 0 0 2px;

        ul {
          margin: 0;
          padding: 0;

          li {
            list-style: none;
            padding: 10px 20px;
            border-bottom: 1px solid #E5E5E5;

            &:hover {
              cursor: pointer;
              background: #0f9d58;
              color: #FFFFFF;
            }
          }
        }
      }
    }

    .AttachFile {
      margin-bottom: 14px;

      input {
        display: none;
      }

      label {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #477BF6;
      }
    }

    .ListFileInput {
      ul {
        li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          .Left {
            display: flex;
            align-items: center;

            .FileName {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: #477BF6;
              margin-left: 10px;
            }
          }

          svg.Delete {
            &:hover {
              cursor: pointer;

              path {
                fill: #9a0000;
              }
            }
          }
        }
      }
    }
  }

  .ant-modal-footer {
    padding: 24px;

    button {
      height: 40px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      padding: 0 32px;
      border-radius: 4px;
    }
  }
}

