$collapsed-size: 70px;
$offscreen-size: 280px;
$border-color: rgba(0, 0, 0, 0.0625);

$default-danger       : #f44336;
$default-dark         : #313435;
$default-grey         : #565a5c;
$default-info         : #007bff;
$default-primary      : #0f9d58;
$default-success      : #37c936;
$default-text-color   : #303238;
$default-warning      : #fc0;
$default-white        : #fff;


$grey-100             : #f9fafb;
$grey-200             : #f2f3f5;
$grey-300             : #e6eaf0;
$grey-400             : #d3d9e3;
$grey-500             : #b9c2d0;
$grey-600             : #7c8695;
$grey-700             : #72777a;
$grey-800             : #565a5c;
$grey-900             : #313435;
