.PrintFilesTable {
    .table {
        overflow: hidden;

        @media (max-width: 1200px) {
            width: max-content;
        }

        td {
            vertical-align: top;
        }

        tr {
            border-top: 1px solid #dee2e6;
        }

        th {
            vertical-align: middle;
        }
    }

    th,
    td {
        padding-left: 5px;
        padding-right: 5px;
    }

    th.Template {
        width: 160px;
    }

    th.Design {
        width: 225px;
    }

    th.Type {
        width: 120px;
        white-space: nowrap;
    }

    th.Supplier {
        width: 80px;
    }

    th.Image {
        width: 150px;
    }

    th.Quantity,
    th.Status {
        width: 90px;
    }

    th.Size,
    th.Color {
        width: 50px;
    }

    th.Time {
        width: 180px;
    }

    th.Note,
    th.Error {
        width: 140px;
    }

    th.Actions {
        width: 115px;
    }

    td.ItemImage {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    td.ItemImage .Preview {
        width: 160px;
        height: 160px;
        overflow: hidden;
    }

    td.ItemImage .Preview img {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }

    td.ItemImage .Preview .Actions {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        background-color: rgba(0, 0, 0, .4);
        z-index: 100;
    }

    td.ItemImage .Preview:hover .Actions {
        opacity: 1;
        visibility: visible;
    }

    td.ItemImage .Icon {
        color: #fff;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        width: 25px;
        height: 25px;
        background-color: #007bff;
        border-radius: 50%;
    }

    td.ItemImages {}

    td.ItemType {
        .ItemBrand span {
            background: #ff4d4f;
            color: #ffffff;
            border-radius: 3px;
            border: unset;
            padding: 3px 7px;
        }

        .ItemSupplier span {
            background: #9f9d9d;
            color: #fff;
            border-radius: 3px;
            border: unset;
            padding: 3px 7px;
        }
    }

    .DesignFront {
        overflow: hidden;
    }

    .ItemSide {
        .Icon {
            min-width: 25px;
            min-height: 25px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }

    .OrdersAllSelection {
        z-index: 2;
        width: 50px;
        vertical-align: middle;
    }

    .OrdersAllSelection .MenuActions {
        top: 0;
        left: 45px;
        bottom: 0;
        width: calc(100vw - 120px);
        background-color: #e9ecef;
        display: flex;
        align-items: center;
        padding-left: 20px;
    }

    tr.Printed td:not(:last-child) {
        color: #7c8695;
        opacity: .75;
    }

    .Marketplace {
        .orderTemplate {
            font-size: 13px;
            font-weight: 700;

            &:hover {
                cursor: pointer;
            }
        }

        .orderNumber {
            color: #666;
            font-size: 12px;
            font-weight: 500;
        }

        .Namespace {
            font-size: .75rem;
            color: #666;
        }

        .ant-tag {
            line-height: 15px;
            padding: 0 5px;
        }

        .BatchInfo {
            margin-top: 5px;

            span {
                background: #28a745;
                padding: 0 5px;
                border-radius: 5px;
                margin-top: 18px;
                color: #fff;
                border: 1px solid #28a745;

                a {
                    color: #fff;
                }
            }

            &:hover {
                span {
                    background: #fff;

                    a {
                        color: #28a745;
                    }
                }
            }
        }
    }

    .ItemSideImage .Preview {
        width: 160px;
        height: 160px;
        overflow: hidden;
    }

    .ItemSideImage img {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }

    .ItemSideImage .Side {
        z-index: 1;
        left: 4px;
    }

    .ItemSideImage.hasPreview .Actions {
        opacity: 0;
        visibility: hidden;
        transition: all .3s;
        color: #fff;
        background-color: rgba(0, 0, 0, .4);
    }

    .ItemSideImage .Actions {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .ItemSideImage.hasPreview .Icon {
        background-color: #007bff;
        border-radius: 50%;
        margin: 4px;
        cursor: pointer;
    }

    .Actions {
        .ant-typography {
            .ant-typography-copy {
                margin-left: 0;
                margin-top: 2px;

                .anticon-copy {
                    color: #ffffff;
                }
            }
        }
    }

    .ItemSideImage.hasPreview:hover {
        .Actions {
            opacity: 1;
            visibility: visible;
        }
    }

    .DesignFront {
        overflow: hidden;

        .ItemSideDefault {
            // width: 100px;
            display: flex;
            justify-content: space-between;
        }
    }

    .ItemImage {
        position: relative;

        .Preview {
            .Safezone {
                position: absolute;
                top: 0;
                left: 0;
                margin: auto;
                z-index: 10;
            }
        }
    }

    .OrderActions {
        .Icon {
            width: 30px;
            height: 30px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            cursor: pointer;
            margin: 0 3px;
        }
    }

    .MenuActions {
        top: 0;
        left: 30px;
        bottom: 0;
        width: 98%;
        background-color: #e9ecef;
        display: flex;
        align-items: center;
        padding-left: 20px;
        height: 96px;
    }

    .autoApprove {
        background-color: #47beff;
        color: #fff;
        font-weight: 500;
    }
}

.PrintedFileModal {
    width: auto !important;

    .Previewer {
        position: relative;
        display: inline-block;

        .Safezone {
            position: absolute;
            top: 0;
            width: 100%;
            left: 50%;
            margin: auto;
            z-index: 10;
            transform: translate(-50%, 0);
        }

        .box {
            position: absolute;
            top: 0;
            // width: 120px;
            // height: 120px;
            left: 0;
            z-index: 10;
            cursor: grab;
        }
    }

    .ant-modal-body {
        padding: 24px 40px;
        margin: 0 25px;
    }

    .OrderActions {
        .Actions {
            text-align: center;
        }

        .Icon {
            font-size: 70px;
            display: inline-block;
            cursor: pointer;
            margin: 0 20px;
        }

        .ChangeOnHoldState {
            color: $primary
        }

        .MarkAsDone {
            color: $danger;
        }

        .ToggleSafeZone {
            z-index: 999;

            i {
                color: #0f9d58;
            }
        }

        .GenerateTemplate,
        .ChangePrintState,
        .MoreActions {
            display: none;
        }
    }

    .MockupViewer {
        @media (max-width : 1700px) {
            top: 0px;
        }
    }
}

.ant-popover-inner-content {
    .Preview {
        height: 200px;
        width: 250px;
        overflow: hidden;
        position: relative;

        img {
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            width: 30%;
        }
    }
}

.previewFixed {
    position: fixed !important;
    top: 0 !important;
    width: auto !important;

    .ant-popover-inner-content {
        max-width: none !important;
        max-height: none;

        .PriviewFileIn {
            height: calc(100vh - 50px) !important;
            min-width: 40vw !important;
        }
    }

    .MockupViewer {
        @media (max-width : 1775px) {
            img {
                width: 200px;
            }
        }

        @media (max-width : 1560px) {
            img {
                width: 160px;
            }
        }

        @media (max-width : 1378px) {
            img {
                width: 120px;
            }
        }
    }
}

.ActionBackgroundPrintFile {
    margin-top: 10px;

    button {
        border: none;
        outline: none;
    }

    button.Active {
        background: #28a745;
        color: #ffffff;
    }
}

.popoverArtwork {
    position: fixed !important;
    top: 0 !important;

    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        min-width: 40vw !important;
    }

    .ant-popover-inner-content {
        max-height: none;
        overflow: unset !important;
        max-width: unset !important;

        .previewArtwork {
            width: 100%;

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}

.total-package-push {
    font-size: 20px;
    color: red;
    font-weight: 600;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.print-files-warning-item{
    display: block;
    padding: 12px 16px;
    color: rgba(0, 0, 0, .85);
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    background-color: #fafafa;
    border-bottom: 1px solid #d9d9d9;
    
    &:hover{
        text-decoration: none;
    }
}

.table-design-customized-artworks {
    table {
        width: 100%;

        thead {
            background: #e9ecef;

            th {
                padding: 5px 15px;
            }
        }

        tbody {
            .searchDroplist.show {
                display: block;
            }

            .searchDroplist.hide {
                display: none;
            }
        }
    }
}

.url-design-customized-artworks {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.table-design-customized-artworks {
    table {
        td {
            padding: 5px 15px;
        }
    }
    
    .searchDroplist {
        position: absolute;
        top: 95%;
        left: 15px;
        z-index: 9;
        width: calc(100% + 9px);

        @media (max-width: 992px) {
            top: 100%;
        }

        &.hide {
            display: none;
        }

        &.show {
            display: block;
        }

        table {
            width: 100%;
            border: 1px solid #d9d9d9;
            border-radius: 4px;
            background-color: #fff;

            thead {
                tr {
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                }
            }

            tbody {
                max-height: 300px;
                overflow: auto;
                display: block;

                tr {
                    display: table;
                    width: 100%;
                    table-layout: fixed;

                    &:hover {
                        cursor: pointer;
                        background-color: rgba(0, 0, 0, 0.03);
                    }

                    td {
                        padding: 5px 15px;
                    }
                }
            }
        }
    }
}