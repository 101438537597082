.DashboardContainer {
    .DashboardInner {
        min-height: 100vh;
        padding-left: $offscreen-size;
        transition: all 0.2s ease;
    }

    .MainContentInner {
        .SectionInner {
            background-color: #fff;
            padding: 15px;
            border: solid 1px $border-color;
            border-radius: 4px;

            .imageMockup{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 500px;
                background: #FFFFFF;
                box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .12), 0 6px 16px 0 rgba(0, 0, 0, .08), 0 9px 28px 8px rgba(0, 0, 0, .05);
                z-index: 999999;
                padding: 15px;
                opacity: 0;
                visibility: hidden;

                &.visible{
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .MainContent {
        padding: 0 30px 60px;
        min-height: 100vh;
        background-color: #f9fafb;
    }

    .nav-link .Label {
        color: #fff;
    }

    &.isCollapsed {
        .nav-link .Label {
            display: none;
        }

        .NavbarLeft {
            .Title {
                display: none;
            }

            .NavbarLeftInner {
                width: $collapsed-size;
            }

            // .IconHolder:hover {
            //     &::after {
            //         content: attr(data-tooltip);
            //         position: absolute;
            //         padding: 4px 15px;
            //         color: #fff;
            //         text-align: left;
            //         text-decoration: none;
            //         background-color: $default-primary;
            //         border-radius: 4px;
            //         box-shadow: 0 2px 8px rgba(0,0,0,.15);
            //         min-height: 32px;
            //         word-wrap: break-word;
            //         left: calc(100% + 10px);
            //         top: 50%;
            //         transform: translateY(-50%);
            //         line-height: 1.3;
            //         font-weight: 400;
            //     }
            // }
        }

        .DashboardInner {
            padding-left: $collapsed-size;
        }

        .NavbarTop {
            width: calc(100% - #{$collapsed-size});
        }
    }
}

.nav-item-tooltip{
    .ant-tooltip-inner{
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba(0,0,0,.15);
        word-wrap: break-word;
        background-color: $default-primary;
        color: #fff;
        font-weight: 400;
        padding: 4px 15px;
        line-height: 2;
        font-size: 15px;
    }
    .ant-tooltip-arrow{
        display: none;
    }
}

.popover-user{
    .ant-popover-inner{
        border-radius: 0.3ren;
        max-width: 276px;
    }
}

@include media-breakpoint-down(md) {
    .DashboardContainer {
        overflow-x: hidden;
        .MainContent {
            padding: 0;
        }

        .MainContentInner .SectionInner {
            border: none;
        }

        //.DashboardInner {
        //    padding-left: 0 !important;
        //}

        &.isCollapsed {
            .NavbarLeft {
                width: 0;
                overflow: hidden;
                transition: all .3s cubic-bezier(.7,.3,.1,1);

                .NavbarMask {
                    position: static;
                    opacity: 0;
                }

                //.Title {
                //    display: inline-block;
                //}
            }

            //.NavbarLeftInner {
            //    transform: translateX(-100%);
            //}

            .NavbarTop {
                width: 100%;
            }
        }
    }
}

.ant-drawer.OrdersWarnings {
    .ant-collapse-content-box {
      padding: 24px;
    }
    ul.ant-list-items{
        list-style: auto;
    }
  }
