.PackageSplittingRulesContainer {
    .MenuTitle {
        font-weight: 600;
        font-size: 1.75rem;
        border-bottom: 1px solid #dfe3e8;
        padding-bottom: 0.5rem;
    }
    .SectionInner {
        .ant-select {
            .ant-select-selector {
                min-height: 40px;
                max-height: 144px;
                overflow: auto;
                border-radius: 5px;
                align-items: center;

                .ant-input-affix-wrapper {
                    min-height: 40px;
                    border-radius: 5px;
                }
            }
        }

        .ant-picker.ant-picker-range {
            min-height: 40px;
            border-radius: 5px;
            align-items: center;
        }

        .ProductType .ant-input-affix-wrapper {
            min-height: 40px;
            border-radius: 5px;
            align-items: center;
        }

    }
    .HiddenProductTypes{
        max-height: 98px;
        overflow: hidden;
    }
    .PackageSplittingRulesTable {
        overflow: auto;
        td .NoteHover:hover {
            color: #0f9d58;
        }
    }
}
.ant-popover-inner-content {
    word-break: break-word;
}