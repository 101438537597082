.OrderRestore {
    &.disabled {
        opacity: .4;
        pointer-events: none;
    }
}

.ConfirmRestoreModal {
    min-height: 200px;
    display: flex;
}
