.StoreCurrency {
    .SectionHeader {
        display: block;

        label {
            float: left;
        }

        span {
            float: right;
            color: #007bff;
            cursor: pointer;
        }

    }
}
