.CostControlPageContainer, .FileDetailPageContainer {

  .CostControlTable, .FileDetailTable {
    .wrapTable {
      .table {
        thead tr th {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #303238;
          padding: 16px;
          border-top: 1px solid #d8dbde;
          background: #f4f6f8;
          border-bottom: none;
        }

        thead tr th.Supplier {
          width: unset;
        }

        tbody tr {
          td {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #303238;
            padding: 16px;
            border-top: 1px solid #d8dbde;
          }

          .Approval_status {
            a:not([href]) {
              text-decoration: underline;
            }
          }

          .Supplier {
            width: unset;
          }

          .Message {
            word-break: break-word;
            max-width: 300px;
            min-width: 208px;
            .PackageName{

            }
          }

          .Package_name {
            min-width: 160px;
          }

          .Order_supplier_id {
            min-width: 143px;
          }

          .Shipping_fee, .Production_fee {
            min-width: 130px;
          }

          .File_name {
            a {
              float: left;
            }

            small {
              display: block;
            }
          }
        }

        tbody tr:last-child {
          border-bottom: 1px solid #d8dbde;
        }
      }
    }

    @media only screen and (max-width: 1733px) {
      .wrapTable {
        overflow: auto;
      }
    }

  }

  .CostControlPage, .FileDetailPage {
    .PageHeading {
      .RightTop {
        display: flex;
        align-items: center;
      }
    }

    .SectionInner {
      .Filter {
        .FilterWrapper {
          .searchFileName, .search {
            height: 40px;
            border-radius: 4px;
            align-items: center;
          }
        }

        .CustomSelect {
          .ant-select {
            .ant-select-selector {
              height: 40px;
              border-radius: 4px;
              align-items: center;
            }
          }

          .ant-picker.ant-picker-range {
            height: 40px;
            border-radius: 4px;
            align-items: center;
          }
        }
      }

      .Total {
        span {
          text-align: right;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
          color: #303238;
          margin-bottom: 19px;
        }
      }
    }
  }
}

.UploadModal, .ExportFileDetails {
  .ant-btn {
    border-radius: 4px;
  }

  .ant-btn.ant-btn-primary {
    background: #477BF6;
  }

  .ant-btn.ant-btn-primary[disabled] {
    color: rgba(0, 0, 0, .25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
  }

  .ant-select .ant-select-selector, .file_name, .FileName {
    border-radius: 4px;
  }
}
