tr.ProductVariantsRow {
    > td > input {
        border-color: transparent;
    }

    &:hover {
        > td > input {
            border-color: #ced4da;
        }
    }
}

tr.ProductVariantsRow {
    > td > .hasHistory > input {
        border-color: transparent;
    }

    &:hover {
        > td > .hasHistory > input {
            border-color: #ced4da;
        }
    }
}
