.OrderItemTable {
    @media (max-width: 768px) {
        overflow-x: scroll;
        table {
            width: max-content;
        }
    }
    table {
        table-layout: fixed;

        td {
            vertical-align: top;
        }
    }
}

.OrderJobTable {
    @media (max-width: 768px) {
        overflow-x: scroll;
        table {
            width: max-content;
        }
    }
    
    td.Information {
        span.ti-info-alt {
            margin-left: 10px;

            &:hover {
                cursor: pointer;
                color: #0f9d58;
            }
        }
    }
}
