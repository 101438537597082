.NotificationFilters {
  &.OrdersSuplier {
    width: 100%;
    position: relative;
    z-index: 11;

    .GroupFilter {
      margin-right: -15px;
      @media (max-width: 1200px) {
        flex-wrap: wrap;
      }
    }

    .itemFilter {
      padding-right: 15px;
      width: 16.67%;
      @media (max-width: 1200px) {
        width: 33%;
      }
      @media (max-width: 767px) {
        width: 50%;
      }
      @media (max-width: 480px) {
        width: 100%;
      }

      .InputSearch {
        position: relative;
      }

      .DatePaid .DatePickerWrapper {
        padding-top: 0;
        padding-bottom: 0;
      }

      //&.filterPushedAt {
      //  top: -3px;
      //}

      .FilterDateRange {
        > div {
          flex-direction: column;
        }

        .Heading {
          margin-bottom: 0.25rem;
        }
      }

      &.status {

      }

      &.end {
        margin-right: 0 !important;
      }

      .d-sm-inline-flex.align-items-baseline {
        width: 100%;

        .ant-picker.ant-picker-range {
          width: 100%;
        }
      }
    }

    &.listDiscounts {
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 38px;
      }

      .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 36px;
      }

      .ant-input {
        line-height: 28px;
      }

      .itemFilter .icon {
        bottom: 7px;
      }

      .ant-picker-input > input {
        line-height: 28px;
      }
    }

    .CustomMultiSelect {
      margin-left: 0;

      .InputSelect {
        padding-top: 4px;
        padding-bottom: 4px;
        border-radius: 0;

        &:after {
          display: none;
        }
      }

      .PopupSelect {
        max-height: 100px;
        overflow: auto;

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
          background-color: rgba(0, 0, 0, .1);
        }

        &::-webkit-scrollbar {
          width: 6px;
          background-color: transparent;
        }
      }

      .PopupSelect.true {
        max-height: 300px;
        z-index: 9;
        opacity: 1;
        top: 60px;
      }
    }
  }

  .itemFilter {
    position: relative;
    width: 320px;

    .icon {
      position: absolute;
      right: 10px;
      bottom: 5px;
      color: #A2A2A4;
      z-index: 2;
      font-size: 14px;
    }
  }

  @media (max-width: 767px) {
    flex-wrap: wrap;
    .itemFilter {
      width: 45%;
    }
  }
}

.CustomMultiSelect {
  position: relative;

  .Background {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 8;
  }

  .InputSelect {
    display: block;
    width: 100%;
    padding: 0 11px;
    line-height: 30px;
    font-size: 14px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ddd;
    position: relative;
    border-radius: 2px;

    span {
      overflow-wrap: break-word;
      overflow: hidden;
      height: 31px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    svg {
      position: absolute;
      top: 10px;
      fill: #cccccc;
      right: 15px;
    }
  }

  .PopupSelect {
    position: absolute;
    padding: 1px 0 0 0;
    top: 65px;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    opacity: 0;
    z-index: -1;
    border: 1px solid #eeeeee;

    &.true {
      z-index: 9;
      opacity: 1;
      top: 65px;
    }

    .Item {
      display: flex;
      align-items: center;
      margin-bottom: 1px;
      position: relative;
      padding: 5px 10px;

      label {
        margin-bottom: 0;
        margin-left: 10px;
        font-size: 14px;
        position: relative;
        z-index: 10;
      }

      > * {
        position: relative;
        z-index: 10;
      }

      .bg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #e0f5ff;
        content: '';
        z-index: 0;
        display: none;
      }

      input:checked ~ .bg {
        display: block;
        background: #eef9ff;
      }

      &:hover {
        .bg {
          display: block;
        }
      }
    }

    .Actions {
      position: sticky;
      padding: 10px 0;
      bottom: 0;
      white-space: nowrap;
      text-align: left;
      background: #FFF;
      z-index: 10;
      box-shadow: 0 0 13px 0 #30323826;

      button {
        border: 1px solid #0a6ebd;
        background-color: #0a6ebd;
        color: #FFFFFF;
        //margin-right: 15px;
        padding: 5px 15px;
        border-radius: 4px;

        &.LinkAction {
          border: 0;
          padding: 0;
          background-color: #FFFFFF;
          color: #0a6ebd;
        }

        &:hover, &:active, &:focus {
          cursor: pointer;
          background-color: #0aa7ef;
          border-color: #0aa7ef;
          outline: none;

          &.LinkAction {
            background-color: #FFFFFF;
            color: #0aa7ef;
            outline: none;
          }
        }

        &:disabled {
          cursor: not-allowed;
          color: #999;
        }

        &:last-child {
          margin-right: 10px;
          float: right;
        }
      }
    }
  }
}
