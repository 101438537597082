.OrderItemRow, .FulfilledPackageItem, .ContentDetail {
  .OrderItemDesignV3 {
    .OrderItemGroupArtworkFirst {
      margin-bottom: 5px;

      &:last-child {
        border-bottom: 0;
      }

      &.More {
        border-top: 1px solid #D8DBDE;
        border-bottom: 0;
      }

      .TypeArtwork {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #303238;
        margin-bottom: 5px;

        span {
          font-size: 18px;
          float: right;
          color: #0A7BFE;
          font-weight: bold;
        }
      }

      .ValidateDimension {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        margin-top: 10px;

        span {
          margin-left: 5px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 22px;
          color: #F70000;
        }
      }

      .Actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        margin-top: 10px;

        .QuantityArtwork {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
          color: #303238;

          span {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #303238;
          }
        }

        .ActionBackground {
          button {
            padding: 1px 5px;
            border: 1px solid #D8DBDE;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            background: #FFFFFF;
            color: #303238;
            outline: none;

            &:active {
              border: 1px solid #0f9d58;
              outline: none;
            }

            &.Active {
              background: #0f9d58;
              border: 1px solid #0f9d58;
              color: #FFFFFF;
              outline: none;
            }
          }
        }
      }

      .ArtworkListFirst {
        .ArtworkListNew {
          li {
            .ArtworkImages {
              position: relative;
              margin-bottom: 5px;

              .ImageArt {
                text-align: center;
                width: 100px;
                height: 100px;
                margin: 0 auto;

                .OriginImage {
                  text-align: center;
                  width: 100px;
                  height: 100px;
                  overflow: hidden;
                  border: 1px solid #D8DBDE;
                }

                img.ArtworkItem {
                  height: 100%;
                  -o-object-fit: contain;
                  object-fit: contain;
                  margin: 0 auto;

                  &.HasWrong {
                    border: 3px solid #F70000;
                  }
                }

                .Hover {
                  display: none;
                }

                &:hover {
                  cursor: pointer;

                  .Hover {
                    display: block;
                    position: absolute;
                    right: -426%;
                    top: 30px;
                    left: 99px;
                    z-index: 99;
                    padding: 5px;
                    background: #FFFFFF;
                    border: 1px solid #D8DBDE;
                    box-shadow: 3px 3px 8px -4px rgba(0, 0, 0, 0.08), 3px 6px 16px rgba(0, 0, 0, 0.06), 3px 9px 32px 8px rgba(0, 0, 0, 0.04);

                    img {
                      width: 400px;
                      height: auto;
                    }
                  }
                }
              }

              > .mb-2 {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }

              i {
                &:hover {
                  color: #0f9d58;
                }
              }

              a {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
              }

              .Meta {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                margin-top: 5px;
              }

              .ErrorDimension {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 22px;
                color: #F70000;
              }
            }
          }
        }
        &.EmbroiderySurchargeContainer {
          .ImageArt {
            .OriginImage {
              position: relative;

              .CheckComplex {
                position: absolute;
                width: 24px;
                height: 24px;
                border: 2px solid #ff3b15;
                right: 5px;
                top: 5px;
                z-index: 200;
              }
            }
            &.selected {
              .OriginImage{
                &:before {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  content: '';
                  background: rgba(0, 0, 0, 0.8);
                  z-index: 100;
                }
                .CheckComplex{
                  &:after {
                    width: 14px;
                    height: 10px;
                    border: 4px solid #ff3b15;
                    border-top: none;
                    border-right: none;
                    content: '';
                    transform: rotate(-45deg);
                    position: absolute;
                    left: 4px;
                    top: 3px;
                  }
                }
              }
            }

            &:hover {
              .Hover {
                left: 102px !important;
                width: 212px;
              }
            }
          }
          .ArtworkListNew{
            > li{
              &:hover{
                z-index: 1000;
              }
            }
          }
        }
      }
    }

    .ShowAll {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 16px;
      color: #278DFC;

      &:hover {
        cursor: pointer;
        text-decoration-line: underline;
      }
    }
  }
}

.ProductSource {
  .ThumbnailWrapper {
    text-align: center;
  }

  .Actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-top: 10px;

    .QuantityArtwork {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #303238;

      span {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #303238;
      }
    }

    .ActionBackground {
      button {
        padding: 1px 5px;
        border: 1px solid #D8DBDE;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        background: #FFFFFF;
        color: #303238;
        outline: none;

        &:active {
          border: 1px solid #0f9d58;
          outline: none;
        }

        &.Active {
          background: #0f9d58;
          border: 1px solid #0f9d58;
          color: #FFFFFF;
          outline: none;
        }
      }
    }
  }

  .listArtwork {
    margin-top: 30px;

    .ArtworkImages {
      position: relative;
      margin-bottom: 5px;

      .ImageArt {
        text-align: center;
        width: 100px;
        height: 100px;
        margin: 0 auto;

        .OriginImage {
          text-align: center;
          width: 100px;
          height: 100px;
          overflow: hidden;
          border: 1px solid #D8DBDE;
        }

        img.ArtworkItem {
          height: 100%;
          -o-object-fit: contain;
          object-fit: contain;
          margin: 0 auto;

          &.HasWrong {
            border: 3px solid #F70000;
          }
        }

        .Hover {
          display: none;
        }

        &:hover {
          cursor: pointer;

          .Hover {
            display: block;
            position: absolute;
            right: -117%;
            top: 30px;
            z-index: 99;
            padding: 5px;
            background: #FFFFFF;
            border: 1px solid #D8DBDE;
            box-shadow: 3px 3px 8px -4px rgba(0, 0, 0, 0.08), 3px 6px 16px rgba(0, 0, 0, 0.06), 3px 9px 32px 8px rgba(0, 0, 0, 0.04);

            img {
              width: 200px;
              height: auto;
            }
          }
        }
      }

      a {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
      }

      .Meta {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        margin-top: 5px;
      }

      .ErrorDimension {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #F70000;
      }
    }
  }

  .ShowAll {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: #278DFC;

    &:hover {
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
}
