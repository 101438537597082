.ProductsPage {
    .CustomSelect {
        color: #495057;

        .ant-select-selection--multiple, .ant-select-selection--multiple .ant-select-selection__rendered {
            min-height: 38px !important;
            padding-bottom: 0 !important;
        }

        .ant-select-selection--multiple .ant-select-selection__rendered > ul > li, .ant-select-selection--multiple > ul > li {
            height: 30px;
            line-height: 30px;
        }

        .ant-select-selection--multiple .ant-select-arrow, .ant-select-selection--multiple .ant-select-selection__clear {
            top: 20px;
        }
    }

    .customTotal {
        &.TotalEntity {
            align-items: normal;
        }
    }

    .TableFilter {
        @media (max-width: 1850px) {
            display: block!important;

            .ShowTotal {
                .TableLength {
                    margin-left: 0!important;
                }
            }

            .Filter {
                position: relative;
                // padding-left: 493px;
                // margin-right: -1rem;
                @media (min-width: 1200px) and (max-width: 1400px) {
                    // padding-left: 311px;
                }
                @media (max-width: 1200px) {
                    // padding-left: 0;
                    // padding-top: 82px;
                }

                .FilterOptions {
                    width: calc(25% - 1rem);
                    @media (max-width: 768px) {
                        width: calc(50% - 1rem);
                    }
                    .ant-select {
                        width: 100%!important;
                    }
                    &.FilterWrapper {
                        // position: absolute;
                        // top: 0;
                        // width: 473px;
                        // left: 0;
                        // @media (min-width: 1025px) and (max-width: 1400px) {
                        //     width: 293px;
                        // }
                        // @media (max-width: 768px) {
                        //     width: 293px;
                        // }
                    }
                }
            }
        }
        @media (max-width: 768px) {
            .InputWrapper {
                width: 220px;
            }
        }
        @media (max-width: 575px) {
            .TotalEntity {
                justify-content: flex-start;
                margin-left: 0!important;
                margin-top: 10px;
            }
        }
    }
}

.ListPrintFileContainer{
    .ListPrintFilePage{
        .PrintFilesFilters{
            .itemFilter{
                .ant-select{
                    &.ant-select-multiple{
                        .ant-select-selector{
                            max-height: 88px;
                            overflow-y: auto;

                            &::-webkit-scrollbar {
                                width: 6px;
                            }

                            &::-webkit-scrollbar-track {
                                background-color: rgba(0,0,0,0.3);
                                border-radius: 0px;
                            }

                            &::-webkit-scrollbar-thumb {
                                border-radius: 3px;
                                background-color: rgba(0,0,0,0.5); 
                            }
                        }
                    }
                }
            }
        }
    }
}
