.PlatformsTable {
    .Table {
        td, th {
            text-align: center;
        }

        .EditButton {
            background-color: #0f9d58;
            min-width: 2rem;
            height: 2rem;
            border: 1px solid #0f9d58;
            color: #fff;
            border-radius: 4px;
            outline: none;
            transition: all .1s linear;

            &:hover {
                color: #0f9d58;
                background: #fff;
            }
        }
    }
}
