.ShippingFeePage{
    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
        border-radius: 4px !important;
        min-height: 38px;
        max-height: 112px;
        overflow: auto;
        border-radius: 4px;
        align-items: center;

        .ant-input-affix-wrapper {
            min-height: 38px;
            border-radius: 4px;
        }
    }

    .ant-select-selection-placeholder{
        color: #6c757d
    }


    .ShippingFeeTable{
        @media (max-width: 1500px) {
            overflow-x: scroll;
            min-height: 300px;
            .table {
                width: auto;
                width: -moz-max-content;
                width: max-content;
            }
        }

        .ShippingsTableHead{
            background-color: #e9ecef;
        }

        .Supplier,.Shipping_plan, .Zone, .Store, .Additional_item{
            max-width: 150px;
            word-break: break-word;
        }
        .HiddenStore{
            max-height: 48px;
            overflow: hidden;
        }
    }
}