.NotificationPage {
  .SaveFilter {
    button {
      font-size: 14px;
      padding: 0 15px;
      height: auto;
      line-height: 30px;
    }
  }
  .cursor-pointer.OrderNote{
    &:hover{
      color:#0f9d58;
    }
  }
}

.QuickReportContainer {
  .IconFilters {
    position: fixed;
    top: 100px;
    background-color: #ffffff;
    right: -1px;
    width: 32px;
    z-index: 3;
    border: solid 1px rgba(0, 0, 0, 0.0625);
    align-items: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
    display: flex;
    height: 32px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    svg {
      font-size: 24px;
      transition: color 0.3s;
    }

    &:hover {
      i {
        color: #00c853;
      }
    }
  }

  .QuickReportOverlay {
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1001;
  }

  .QuickReportsListsWrap {
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1002;
    width: 285px;
    margin-left: auto;
    background-color: #FFFFFF;
    border: 0.5px solid rgba(217, 217, 217, 0.5);
    box-sizing: border-box;
    box-shadow: 0px 2px 2px 2px rgba(222, 226, 230, 0.73);
    overflow-y: auto;

    .ReportHead {
      padding: 15px;
      border-bottom: 1px solid #dee2e6;
      margin-bottom: 10px;

      h3 {
        font-size: 16px;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      .iconClose {
        &:hover {
          svg {
            color: #1890ff;
          }
        }
      }
    }

    .ReportContent {
      .QuickReportsItem {
        border-bottom: 1px solid #dee2e6;
        padding: 10px 15px;
        position: relative;

        .IconDelete {
          position: absolute;
          right: 15px;
          top: 10px;
          cursor: pointer;

          &:hover {
            svg {
              color: #1890ff;
            }
          }
        }

        &.canDelete {
          .Title {
            margin-right: 30px;
          }
        }

        &.active {
          .Title {
            color: #1890ff;
          }
        }
      }
    }
  }
}

.SaveFilter {
  text-align: right;
  position: relative;

  #btnSaveFilter {
    background: rgba(222, 226, 230, 0.5);
    border: 1px solid #DEE2E6;
  }

  .note {
    font-size: 12px;
    margin-bottom: 12px;
  }

  .overlaySave {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  }

  .PopupSave {
    text-align: left;
    position: absolute;
    top: calc(100% + 15px);
    right: 0;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    padding: 15px;
    z-index: 999;
    width: 353px;

    .arrow {
      position: absolute;
      display: block;
      width: 1rem;
      right: 45px;
      top: -9px;
      height: 0.5rem;
      margin: 0 0.3rem;

      &:before, &:after {
        position: absolute;
        display: block;
        content: "";
        border-color: transparent;
        border-style: solid;
      }

      &:before {
        top: 0;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: rgba(0, 0, 0, 0.25);
      }

      &:after {
        top: 1px;
        border-width: 0 0.5rem 0.5rem 0.5rem;
        border-bottom-color: #fff;
      }
    }
    &.isAdmin {
      .checkDefault {
        .ant-checkbox-wrapper {
          padding-bottom: 6px;
        }
      }
    }
  }
}

.itemFilter {
  .SaveFilter {
    margin-top: 17px;
  }
}

.listSuggestNotes {
  margin-top: 5px;
  position: absolute;
  z-index: 2;
  background: white;
  width: 100%;
  transition: all 0.2s ease;
  max-height: 200px;
  overflow-x: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .suggestItem{
    color: rgba(0,0,0,.85);
    cursor: pointer;
    padding: 5px 10px;
    &:hover{
      background-color: #f5f5f5;
    }
  }
}


body.quickFilters {
  //overflow-y: hidden;
}
