.OrderBuyerDetails {
  .card-header > i {
    cursor: pointer;
  }

  .Error {
    display: none;

    .Text {
      font-size: 0.75rem;
      margin-bottom: 0;
    }
  }

  &.isChecked {
    &.valid {
      .card {
        border-color: $default-success;
      }
    }

    &.invalid {
      .card {
        border-color: $default-danger;
      }

      .card-body {
        .Error {
          display: block;
          margin-top: 10px;
        }
      }
    }
  }

  .RightActions {
    display: flex;
    align-items: center;

    .RequestUpdateModal {
      line-height: 1px;
      margin-right: 1rem;
    }
  }
}

.RequestUpdateModal {
  line-height: 1px;

  &:hover {
    svg {
      cursor: pointer;

      path {
        fill: #0f9d58;
      }
    }
  }
}
