.tabsStatics {
  padding-top: 30px;
  padding-bottom: 30px;

  .itemTab {
    display: inline-block;
    line-height: 50px;
    margin-right: 10px;
    padding: 0 45px;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #FFFFFF;
    border: 1px solid #dee2e6;

    &.active {
      background-color: #0f9d58;
      color: #FFFFFF;
    }
  }
}

.BtnClearStore {
  .anticon {
    color: #777;
    margin-left: 5px;

    &.anticon-close {
      display: none;
    }
  }

  &:hover {
    background: #FFF;

    .anticon {
      color: #ff3b15;
      display: none;

      &.anticon-close {
        display: inline;
      }
    }
  }

}

@import "StaticsContainerV2";
@import "ReportOverIntransit";
@import "ReportProductionTime";
@import "ReportShipmentStatusByStore";
