.ReportOverInTransit, .ReportOverShip, .ReportShipStatus, .ReportUSPreTransitDelay, .ReportShipStatusByStore {
  .bg_1 {
    background-color: #0f9d58;
    color: #FFFFFF;
  }

  .bg_2 {
    background-color: #9a0000;
    color: #FFFFFF;
  }

  .Total {
    td {
      font-weight: 600;
    }
  }

  tbody {
    td {
      a {
        color: #212529;
      }

      &:hover a {
        color: #0f9d58;
      }
    }
  }

  .rowSub {
    td {
      background-color: #dedede;
    }
  }
}

.ReportOverShip,
.ReportOverInTransit,
.ReportShipStatus, 
.ReportShipStatusByStore {
  .FilterDateRange {
    margin-top: 8px;

    .Heading {
      float: left;
      margin: 5px 0 0 0;
    }
  }

  .ax {
    color: #666;
  }
}

.ReportShipStatus,
.ReportShipStatusByStore {
  th, td {
    span {
      display: inline-block;
      margin-left: 5px;
      color: #666;
    }
  }
}