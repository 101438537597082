.ChargeRefundPageContainer {
    .ChargeRefundTable {
        .wrapTable {
            .table {
                thead tr th {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 22px;
                    color: #303238;
                    padding: 16px;
                    border-top: 1px solid #d8dbde;
                    background: #f4f6f8;
                    border-bottom: none;
                    white-space: nowrap;

                    &:not(.CheckBox){
                        padding: 16px 10px;
                    }
                }
                tbody tr {
                    td {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 22px;
                        color: #303238;
                        padding: 16px;
                        border-top: 1px solid #d8dbde;

                        &:not(.CheckBox){
                            padding: 16px 10px;
                        }
                    }
                    .OrderNote{
                        max-width: 240px;
                        .ShowMore{
                            cursor: pointer;
                            &:hover{
                                color: #0f9d58;
                            }
                        }
                    }
                    .CheckBox{
                        min-width: 50px;
                    }
                    .RequestId {
                        min-width: 240px;
                    }
                    .Order {
                        min-width: 150px;
                    }
                    .Reason {
                        word-break: break-word;
                        max-width: 390px;
                    }
                    .Type {
                        label {
                            display: inline-block;
                            height: 18px;
                            line-height: 17px;
                            position: relative;
                            padding: 0 7px 0 12px;
                            background: #dc3545;
                            border-bottom-right-radius: 3px;
                            border-top-right-radius: 3px;
                            color: #fff;
                            font-size: 11px;
                            text-decoration: none;
                            font-weight: 400;
                            margin: 0 0 0 7px;
                            white-space: nowrap;
                        }
                        label.refund {
                            background: #28a745;
                        }
                        label::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: -7px;
                            width: 0;
                            height: 0;
                            border-color: transparent #dc3545 transparent transparent;
                            border-style: solid;
                            border-width: 9px 7px 9px 0;
                        }
                        label.refund::before {
                            border-color: transparent #28a745 transparent transparent;
                        }
                        label::after {
                            content: "";
                            position: absolute;
                            top: 7px;
                            left: 1px;
                            float: left;
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            background: #fff;
                        }
                    }
                }
                tbody tr:last-child {
                    border-bottom: 1px solid #d8dbde;
                }
                .BulkActions {
                    display: flex;
                    align-items: baseline;
                    .Actions {
                        i {
                            position: relative;
                            margin-left: 5px;
                            z-index: 10;
                            &:hover {
                                cursor: pointer;
                                color: #0f9d58;
                            }
                        }
                        .ListActions {
                            display: none;
                            position: absolute;
                            width: 250px;
                            background: #fff;
                            border: 1px solid rgba(0, 0, 0, 0.15);
                            border-radius: 0.25rem;
                            margin: 0;
                            padding: 0;
                            z-index: 10;
                            li {
                                color: #333;
                                list-style: none;
                                padding: 10px 15px;
                                font-size: 15px;
                                font-weight: 400;
                                &:hover {
                                    cursor: pointer;
                                    color: #0f9d58;
                                }
                            }
                        }
                        .Disable li {
                            pointer-events: none;
                            color: #adb5bd;
                        }
                        .Background {
                            position: fixed;
                            top: 0;
                            left: 0;
                            width: 100vw;
                            height: 100vh;
                            opacity: 0;
                            z-index: 9;
                        }
                    }
                }
            }
        }
        @media only screen and (max-width: 1733px) {
            .wrapTable {
                overflow: auto;
                .table {
                    tbody tr {
                        td {
                            padding: 6px;
                        }
                        .RequestId {
                            min-width: 252px;
                        }
                        .Order {
                            min-width: 160px;
                        }
                        .User {
                            word-break: inherit;
                        }
                    }
                }
            }
        }
    }
    .ChargeRefundPage {
        .PageHeading {
            .RightTop {
                display: flex;
                align-items: center;
                .searchOrder {
                    border: 0;
                    height: 38px;
                    padding: 9px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 22px;
                    border-radius: 5px;
                    width: 300px;
                    border: 1px solid #d8dbde;
                }
                .MoreFilter {
                    border: 1px solid #eee;
                    padding: 8px 15px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 22px;
                    color: #303238;
                    margin-left: 5px;
                    border-radius: 5px;
                    min-width: 127px;
                    cursor: pointer;
                }
                .MoreFilter:hover {
                    background: #28a745;
                    color: #ffffff;
                }
            }
        }
        .SectionInner {
            .Filter {
                .CustomSelect {
                    .ant-select {
                        .ant-select-selector {
                            height: 40px;
                            border-radius: 5px;
                            align-items: center;
                        }
                    }
                    .ant-picker.ant-picker-range {
                        height: 40px;
                        border-radius: 5px;
                        align-items: center;
                    }
                }
                .CustomSelectSearch {
                    .ant-select {
                        .ant-select-selector {
                            height: 40px;
                            border-radius: 0 5px 5px 0;
                            align-items: center;
                            justify-content: center;
                            input {
                                height: 100%;
                            }
                        }
                    }
                }
            }
            .Total {
                span {
                    text-align: right;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 22px;
                    color: #303238;
                    margin-bottom: 19px;
                }
            }
        }
    }
}
.ReasonModal {
    .ant-modal-content {
        .ant-modal-body {
            padding: 12px !important;
        }
    }
}
