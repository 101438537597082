.ShippingAddressCod {
  .Info {
    padding: 12px 0;
    border-bottom: 1px solid #eee;

    .Title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #303238;
      min-width: 150px;

      small {
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        color: #737A81;
      }
    }

    .Value {
      text-align: right;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #303238;
      word-break: break-word;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}