.OrderItemMap {
    .OrderItemMapActions {
        button {
            width: 120px;
        }
    }

    .Thumbnail {
        & ~ .Actions {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: rgba(255, 255, 255, .8);
            display: flex;
            justify-content: center;
            align-items: center;
            visibility: hidden;
            opacity: 0;
            transition: all .3s;

            .OrderItemMapActions {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    .ThumbnailWrapper:hover {
        .Thumbnail ~ .Actions {
            opacity: 1;
            visibility: visible;
        }
    }
}

.ant-modal-wrap:has(.confirm-force){
    z-index: 1051;
}