.MappingVariantsPageContainer{
    .MappingVariantsPage{
        .SectionInner{
            .filter{
                .InputWrapper{
                    .ant-select{
                        .ant-select-selector{
                            height: 38px;
                            border-radius: 0 5px 5px 0;
                            font-size: 1rem;

                            .ant-select-selection-search-input{
                                height: 38px;
                            }

                            .ant-select-selection-placeholder{
                                line-height: 36px;
                                color: #495057;
                            }
                        }
                    }
                }

                .CustomSelect{
                    .ant-select{
                        .ant-select-selector{
                            height: 38px;
                            border-radius: 5px;
                            font-size: 1rem;

                            .ant-select-selection-search-input{
                                height: 38px;
                            }

                            .ant-select-selection-placeholder{
                                line-height: 36px;
                                color: #495057;
                            }
                        }
                    }
                }

                .CustomFilterDate{
                    form{
                        display: flex;
                        align-items: center;
                        height: 38px;
                        border: 1px solid #ced4da;
                        border-radius: 5px;
                        padding: 0 15px;
                        
                        label{
                            margin: 0 !important;
                        }

                        .FilterDateRange{
                            .ant-picker{
                                border: none;
                                box-shadow: none;
                            }
                        }
                    }
                }
            }

            .MappingVariantsTable{
                table{
                    thead{
                        background: #e9ecef;
                    }

                    tbody{
                        tr{
                            td{
                                vertical-align: top;
                                min-height: 100px;

                                &.SkuVariantMerchize{
                                    width: 330px;

                                    a{
                                        font-weight: bold;
                                        font-size: 18px;
                                        color: #2196f3;
                                        padding: 0 !important;
                                    }

                                    span{
                                        display: block;
                                        color: #2196f3;

                                        &.catalogStatus{
                                            text-transform: capitalize;
                                            color: green;
                                            font-style: italic;

                                            &.inactive{
                                                color: rgba(0,0,0,0.4);
                                            }
                                        }
                                    }
                                }

                                &.VariantMerchize,
                                &.SkuVariantSupplier,
                                &.VariantSupplier,
                                &.ProductTypeFulfill{
                                    width: 220px;
                                }

                                &.SkuVariantSupplier{
                                    color: #2196f3;
                                }

                                &.UpdateAt{
                                    width: 250px;
                                }

                                &.Mode{
                                    text-transform: capitalize;
                                }

                                &.Status{
                                    width: 150px;

                                    span{
                                        display: inline-block;
                                        background: green;
                                        color: #FFF;
                                        text-transform: capitalize;
                                        font-size: 14px;
                                        border-radius: 7px;
                                        padding: 1px 10px;
                                    }

                                    &.unmapped{
                                        span{
                                            background: red;
                                        }
                                    }
                                }
                            }

                            &.topDashed{
                                td{
                                    border-top: 1px dashed #dee2e6;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}