.ReportShipStatusByStore {
  @import "~font-awesome/css/font-awesome.css";
  position: relative;

  .Filters, .FilterAndAction {
    position: relative;

    .Clear {
      // position: absolute;
      // left: -20px;
      // bottom: 9px;
      color: #AAA;

      svg {
        font-size: 14px;
      }

      &:hover {
        color: #0f9d58;
      }
    }
  }

  .ListTopBar {
    position: sticky;
    top: 0;
    z-index: 100;
    background: #f9fafb;
    padding-bottom: 20px;
    margin-right: -30px;
    padding-right: 30px;
  }

  .ListHeader {
    position: sticky;
    top: 70px;
    z-index: 100;
    background: #f9fafb;
  }

  table {
    border-collapse: separate;
    border-top: 0;
    border-left: 0;
    border-spacing: 0;

    td, th {
      border-spacing: 0;
      border-right-width: 0;
      border-bottom-width: 0;

      &.sorted {
        //font-weight: bold;
        background: #fbfbfbeb;
      }
    }

    thead {
      tr:last-child {
        td, th {
          border-bottom-width: 1px;
        }
      }
    }

    tbody {
      tr:first-child {
        td, th {
          border-top-width: 0;
        }
      }
    }
  }

  thead {
    td, th {
      position: relative;
      padding: 0;

      > div {
        //border: 1px solid #DDD;
        padding: 10px 14px 10px 5px;
        margin: -1px -1px 0 0;
        white-space: nowrap;
      }

      .SortColumnHandle {
        display: inline-block;
        margin-right: 3px;

        .anticon {
          svg {
            font-size: 12px;
            color: #CCC;
          }
        }

        &.Up {
          .SortDown {
            svg {
              color: #0f9d58;
            }
          }
        }

        &.Down {
          .SortUp {
            svg {
              color: #0f9d58;
            }
          }
        }

        .SortDown,
        .SortUp {
          &:hover {
            svg {
              color: #0f9d58;
            }
          }
        }
      }
    }

    .merge-total {
      border-bottom-width: 1px;

      div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        text-indent: -1000px;
      }
    }

    tr {
      th:last-child {
        div {
          border-right: 0;
        }
      }
    }
  }

  tbody {
    background: #F5F5F5;
  }

  tfoot {
    //position: sticky;
    //bottom: 0;
    background: #FFF;
  }
  .SortField {
    .dropdown-item.Selected {
      background: #007bff;
      color: #fff;
      border-radius: 4px;
    }
    &.hasSorted {
      .Sort {
        display: inline;
        color: #0f9d58;
      }
    }
  }
}

.ProductionReports, .ReportFastProductionTime {
  .SortField {
    .Sort {
      display: none;
    }

    &:hover {
      .Sort {
        display: inline;
      }
    }

    &.hasSorted {
      .Sort {
        display: inline;
        color: #0f9d58;
      }
    }
  }

  table {
    .Sorted {
      background: #1890ff0a;
    }
  }

  .ant-spin-spinning {
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 300px;
    background: #ffffff3b;
  }
}

.ProductionReports,
.ReportShipStatus,
.ReportShipStatusByStore,
.ReportFastProductionTime {
  .SwitchButton {
    background: #FFF;
    border: 1px solid #DDD;
    font-size: 14px;
    vertical-align: middle;
    padding: 4px 14px;

    &.btn-export {
      color: #fff;
      background-color: #007bff;
      border-color: #007bff;
    }
  }

  .Export-table-xls-button {
    padding: 4px 14px;
    font-size: 14px;
    vertical-align: middle;
    border: 1px solid #007bff;
    color: #fff;
    background-color: #007bff;
  }

  table {
    tr {
      td, th {
        width: 230px;

        &:first-child {
          width: auto;
        }
      }
    }
  }
}

.DailyReports {
  position: relative;

  &.Loading {
    .ant-spin {
      position: fixed;
      top: 200px;
      z-index: 1000;
      width: 200px;
      left: 50%;
      margin-left: -100px;
      background: #FFF;
      padding: 20px;
      box-shadow: 0 0 30px 0 rgba(171, 171, 171, 0.37);
      -webkit-box-shadow: 0 0 30px 0 rgba(171, 171, 171, 0.37);
      -moz-box-shadow: 0 0 30px 0 rgba(171, 171, 171, 0.37);
      border-radius: 4px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
    }

    pointer-events: none;

    &:after {
      opacity: 0.5;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #FFF;
      content: '';
      z-index: 100;
    }
  }

  .ToTop {
    position: fixed;
    bottom: 5px;
    z-index: 1000;
    right: 5px;
    background: rgba(255, 255, 255, 0.42);
    padding: 10px;
    cursor: pointer;

    &:hover {
      background: #FFF;
      color: #0f9d58;
    }

    > * {
      margin: 0;
    }
  }
}