.PackageListItemsEditor {
    table {
        table-layout: fixed;

        th.Actions {
            width: 50px;
        }

        th.Quantity {
            width: 100px;
        }

        th.Size {
            width: 100px;
        }
    }
}
