.ArtworkPreview {
    .ArtworkItem {
        border: 1px dashed red;
        transition: all 200ms ease-out;
    }

    .Colors {

        .Color {
            cursor: pointer;
            padding: 2px 5px;
            border: 1px solid #bbb;
            color: #eee;

            &.active {
                border-color: #333;
                color: #333;
            }
        }
    }
}
