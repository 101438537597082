.ArtworksTableRow {
  .ThumbnailWrapper {
    display: flex;

    .ThumbnailInner {
      position: relative;

      .ThumbnailImage {
        height: 35px;
        width: 35px;
        object-fit: cover;
        border-radius: 5px;

        &:hover + .OriginImage {
          display: block;
        }
      }

      .OriginImage {
        display: none;
        position: absolute;
        left: calc(100% + 15px);
        top: 50%;
        transform: translateY(-50%);
        max-width: 200px;
      }
    }
  }
}
