.PackageIssueReportPage {

    .PackageIssueReportTable {
        margin: 15px;

        th,
        td {
            border: 2px solid #dee2e6;    
        }

        .col-confirm,
        .col-type {
            vertical-align: middle;
        }

        .col-type {
            width: 25%;
        }

        .col-case-quantity,
        .col-item-quantity {
            width: 30%;
        }

        .col-confirm {
            width: 15%;
            font-weight: 700;
            margin-left: 100px;
        }

    }

    .PackageIssueProductTypeTable {
        margin: 15px;
        max-width: 50vw;

        th,
        td {
            border: 2px solid #dee2e6;
        }

        .col-confirm,
        .col-product-type {
            vertical-align: middle;
        }

        .col-product-type {
            width: 25%;
        }

        .col-error-quantity {
            width: 25%;
        }
    }

    .PackageIssueStoreReportTable {
        margin: 15px;
        overflow-x: auto;

        table {
            border-collapse: collapse;
            text-align: left;
        }

        .table-left {
            width: 50%;
            margin-right: 20px;

            .col-store {
                vertical-align: middle;
                width: 20%;
                font-weight: 500;
            }
        }

        .table-right {
            width: 40%;
        }

        th,
        td {
            border: 2px solid #dee2e6;
        }
    }

    .IssuePackageItem {
        margin: 15px;
        overflow-x: auto;
        justify-content: space-between;

        table {
            border-collapse: collapse;
            text-align: left;
        }

        .table-left {
            width: 58%;
            margin-right: 20px;

            .col-supplier-confirm {
                width: 41%;
            }
        }

        .table-right {
            width: 45%;

            .col-supplier-confirm {
                width: 50%;
            }
        }

        .col-store {
            vertical-align: middle;
            width: 20%;
            font-weight: 500;
        }


        th,
        td {
            border: 2px solid #dee2e6;
        }
    }

    .custom-month-issue-report-store {
        border: none !important;
        background: none !important;
        font-size: 18px;

        &:hover {
            border-color: unset !important;
            border-right-width: 0 !important;
        }

        input {
            font-size: 18px !important;
            font-weight: 700 !important;
        }

        .ant-picker-suffix {
            color: unset;
            display: block;
            cursor: pointer;
            pointer-events: auto !important;
        }
    }

    .table-left .custom-month-issue-report-store{
        .ant-picker-suffix {
            display: none !important;
        }
    }

    table {
        th {
            font-weight: 500;
        }
    }

    .custom-month-issue-report-store.ant-picker-focused {
        outline: none !important;
        box-shadow: none !important;
    }
}

// .PackageIssueReportPage.fixedHeaderIssue {
//     .BackWrapper {
//         position: sticky;
//         top: 0;
//         background: rgb(249, 250, 251);
//         z-index: 1000;
//     }

//     .MenuTitle{
//         position: sticky;
//         background: #f9fafb;
//         z-index: 1;
//     }

//     .table {
//         thead {
//             position: sticky;
//             z-index: 100;
//         }
//     }
// }