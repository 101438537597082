.ShippingPriceContainer {
  .ShippingPricePage {
    .SectionInner {
      .QuickSelect {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;

        li {
          //font-style: normal;
          //font-weight: 500;
          //font-size: 14px;
          //line-height: 22px;
          //padding: 16px 20px;
          //color: #000000;
          //border-bottom: 2px solid transparent;
          //margin-right: 15px;
          position: relative;
          border-bottom: 2px solid transparent;
          margin-right: 15px;
          color: #000000;

          span {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            padding: 16px 20px;
            position: relative;
            display: block;
          }

          [data-action="remove"] {
            position: absolute;
            visibility: hidden;
            top: 50%;
            right: 0;
            width: 16px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            margin-top: -8px;
            color: #999;

            &:hover {
              color: #9a0000;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          &:hover {
            cursor: pointer;
            color: #007BFF;
            border-bottom: 2px solid #007BFF;

            [data-action="remove"] {
              visibility: visible;
            }
          }

          &.Active {
            color: #007BFF;
            border-bottom: 2px solid #007BFF;
          }
        }
      }

      .ShippingPriceFilters {
        label {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: #000000;
          margin-right: 15px;
        }
      }

      .BodyPage {
        overflow-y: hidden;
        .Content {
          .TabContent {
            overflow: auto;

            .table {
              thead {
                tr {
                  th {
                    text-align: center;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #000000;
                    padding: 16px;
                    background-color: #F4F6F8;
                    border-bottom: 0;
                    vertical-align: middle;

                    .RangeValue {
                      > div {
                        display: inline-block;
                        vertical-align: middle;

                        > div {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          position: relative;

                          input {
                            text-align: center;
                            max-width: 62px;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 20px;
                            color: #000000;
                            background-color: #FFF;
                            border: 1px solid #dddddd;
                            margin-right: 5px;
                            padding: 5px;

                            &:last-of-type {
                              margin-left: 5px;
                              margin-right: 0;
                            }

                            &:focus, &:hover {
                              border: 1px solid #007BFF;
                              background-color: #FFFFFF;
                            }
                          }
                        }
                      }

                      i {
                        font-size: 16px;
                        margin-right: -18px;
                        opacity: 0;
                        position: absolute;
                        top: 50%;
                        margin-top: -8px;

                        &:hover {
                          opacity: 1;
                          color: #9a0000;
                          cursor: pointer;
                        }
                      }

                      &.Error {
                        input {
                          border-color: #9a0000;
                          color: #9a0000;
                        }
                      }
                    }

                    &:last-child {
                      text-align: left;
                    }

                    &.CountryCode {
                      min-width: 150px;

                    }

                    &.ShippingRange {
                      max-width: 175px;
                    }

                    &.AddColumnShippingCost {
                      padding: 0 15px;

                      i {
                        padding: 5px;
                        font-size: 16px;
                        color: #000000;
                        border: 1px dashed #000000;

                        &:hover {
                          border-color: #0f9d58;
                          cursor: pointer;
                          color: #0f9d58;
                        }
                      }
                    }
                  }
                }
              }

              tbody {
                tr {
                  td {
                    text-align: center;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 22px;
                    color: #000000;
                    padding: 16px;
                    border-bottom: 0;

                    &:first-child {
                      font-weight: 600;
                    }

                    &:last-child {
                      text-align: left;
                    }

                    input {
                      text-align: center;
                      max-width: 68px;
                      font-style: normal;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                      color: #000000;
                      background-color: #FFF;
                      border: 1px solid #dddddd;
                      margin-right: 0px;
                      padding: 5px 10px;

                      &:focus, &:hover {
                        border: 1px solid #007BFF;
                        background-color: #FFFFFF;
                      }
                    }
                  }

                  &.AddRowShippingCost {
                    td {
                      padding-left: 0;
                    }

                    i {
                      padding: 6px 80px;
                      font-size: 20px;
                      color: #000000;
                      border: 1px dashed #000000;

                      &:hover {
                        border-color: #0f9d58;
                        cursor: pointer;
                        color: #0f9d58;
                      }
                    }
                  }

                  .CountryCode {
                    .RemoveRow,
                    .ErrorDuplicateCountry {
                      font-size: 16px;
                      position: absolute;
                      top: 50%;
                      margin-top: -8px;

                      &:hover {
                        opacity: 1;
                        color: #9a0000;
                        cursor: pointer;
                      }
                    }

                    .RemoveRow {
                      opacity: 0;
                      margin-left: -20px;
                    }

                    .ErrorDuplicateCountry {
                      margin-left: 5px;
                    }
                  }

                  &.DuplicateCountryCode {
                    td {
                      > * {
                        color: #dc3545;
                      }
                    }
                  }

                  &.Highlight {
                    td {
                      //background: #007bff0d;
                      background: #f0f8ff;
                    }
                  }
                }
              }
            }

            &.fixed-x {
              td {
                &.CountryCode {
                  background: #F4F6F8;
                }
              }

              .Highlight {
                .CountryCode {
                  background: #e6eff7;
                }
              }
            }
          }
        }

        .Actions {
          text-align: right;
          margin-top: 30px;

          button {
            margin-left: 15px;
            //&:first-child {
            //  margin-right: 15px;
            //}
          }
        }
      }
    }
  }

  #ShippingCosts {
    .ant-table-content {
      tr {
        color: #FF0000;

        &:last-child {
          td {
            border-bottom: 1px solid #dee2e6;
          }
        }
      }
    }
  }

  .ErrorDataMessage {
    margin-bottom: 0;
    //display: inline-block;
  }

  .AddRowShippingCost {
    i {
      padding: 6px 80px;
      font-size: 20px;
      color: #000000;
      border: 1px dashed #000000;
      display: inline-block;

      &:hover {
        border-color: #0f9d58;
        cursor: pointer;
        color: #0f9d58;
      }
    }
  }
}

.ImportShippingModal {
  .FormItemImport {
    .Label {
      font-weight: 500;
    }

    input.Hidden {
      display: none;
    }

    .SelectFile {
      text-align: center;
      width: 100%;
      padding: 15px 30px;
      border: 1px dashed #dddddd;

      &:hover {
        border-color: #0f9d58;
        color: #0f9d58;

        svg {
          color: #0f9d58;
        }
      }

      svg {
        margin-right: 10px;
      }
    }

    .Template {
      a {
        margin-bottom: 0.3rem;
      }
    }
  }

  .ListFileInput {
    margin-top: 15px;

    ul {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        .Left {
          display: flex;
          align-items: center;

          .FileName {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #477BF6;
            margin-left: 10px;
          }
        }

        svg.Delete {
          &:hover {
            cursor: pointer;

            path {
              fill: #9a0000;
            }
          }
        }
      }
    }
  }
}