.OrdersTableRow {
  transition: all 200ms ease-out;

  &.archived {
    td, a, code {
      color: #637381;
    }

    .badge, i.fas {
      opacity: 0.6;
    }
  }

  &.is_international {
    .OrderAddress {
      .Country {
        font-weight: bold;
      }
    }
  }

  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.01);
  }

  &:hover {
    background-color: #f9fafb;
  }

  &.fulfillment-fulfilled {
    td {
      color: #637381;

      a {
        color: #637381;
      }
    }
  }

  .MappingCondition {
    text-align: center;
  }
}

.ant-popover {
  &.MappingConditionPopover {
    width: 500px !important;

    .ant-popover-inner-content {
      display: flex;
      max-height: 300px;
      flex-direction: column;

      .OrderRowMappingContent {
        overflow: auto;

        .MappingContentLine {
          margin-bottom: 10px;
        }
      }
    }
  }
}

.MappingConditionPopover {
  .CreatedAt {
    font-size: 100%;
    color: #666;
  }

  .MappingConditionAction {
    color: #666;
    margin: 0 5px;

    &:hover {
      color: #28a745;
    }

    &.Edit {
      float: right;
    }
  }

  .XXX {
    margin: 10px 0 0 0;
    color: #666;
    font-style: italic;
    font-size: small;
    line-height: 1;
  }
}
