.SettingContainer, .RolesPageContainer {
    // margin: 20px 0;

    .BackWrapper {
        .BackButton {
            display: inline-block;
            cursor: pointer;
            padding-left: 0;
            padding-right: 0;

            span {
                margin: 0 1rem;
            }
        }
    }

    .MenuTitle {
        font-weight: 600;
        font-size: 1.75rem;
        border-bottom: 1px solid #dfe3e8;
        padding-bottom: 0.5rem;
    }

    .Section {
        display: inline-grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 10px;
        width: 100%;

        .SectionSetting {
            width: 100%;
            grid-column: 2/5;
            height: 100%;

            .Card {
                height: 100%;
            }
        }

        border-bottom: 1px solid #ccc;
        padding: 1.5rem 0;
    }

    .ButtonSave {
        float: right;

        button {
            margin: 1rem 0 2rem;
            min-width: 150px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            transition: all .3s;
        }
    }

    .BreakLine {
        border-bottom: 1px solid #ccc;
        margin: 1rem 0;
    }
}

.rolePermissions {
    .ant-select-selector {
        overflow: auto;
        max-height: 300px;
    }
}
